import { createSlice } from '@reduxjs/toolkit';


const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    orders: null,
    page: 0,
    size: 10,
    parcelsCount: 0,
    chatCount: 0,
    userSpecificParcelsCount: 0,
    unConfirmedParcelsCount: 0,
    ParcelSelected: 0,
  },
  reducers: {
    getOrders: (state, { payload }) => {
      if(!arraysEqual(state.orders, payload)){
        state.orders = payload;
      }
    },
    onPageChange: (state, { payload }) => {
      state.page = payload;
    },
    onParcelPageChange: (state, { payload }) => {
      state.page = payload;
    },
    onSizeChange: (state, { payload }) => {
      state.size = payload;
    },
    onParcelsCountChange: (state, { payload }) => {
      state.parcelsCount = payload;
    },
    onChatCountChange: (state, { payload }) => {
      state.chatCount = payload;
    },
    onUserSpecificParcelsCountChange: (state, { payload }) => {
      state.userSpecificParcelsCount = payload;
    },
    onUnConfirmedParcelsCountChange: (state, { payload }) => {
      state.unConfirmedParcelsCount = payload;
    },
    onParcelSelected: (state, { payload }) => {
      state.ParcelSelected = payload;
    },
    addOrder: (state, { payload }) => {
      state.orders = [...state.orders, payload];
    },
    updateOrder: (state, { payload }) => {
      const { id, updatedOrder } = payload;
      const order = state.orders.find(order => order.id === id);
      const orderIndex = state.orders.indexOf(order);
      const before = state.orders.slice(0, orderIndex);
      const after = state.orders.slice(orderIndex + 1);
      const newOrders = [...before, updatedOrder, ...after];
      state.orders = newOrders;
    },
    deleteOrder: (state, { payload }) => {
      state.orders = state.orders.filter(order => order.id !== payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { getOrders, addOrder, updateOrder, deleteOrder, onParcelPageChange, onPageChange, onSizeChange, onParcelsCountChange, onChatCountChange, onUserSpecificParcelsCountChange, onParcelSelected, onUnConfirmedParcelsCountChange } =
  ordersSlice.actions;

export default ordersSlice.reducer;

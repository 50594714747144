import { useCallback, useState, useEffect } from 'react';

import { sendRequest, REQUEST_ACTIONS } from 'utils/Communicator';
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ConfirmDialog, Dialog, SelectField, TextField } from 'style-guide';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';

const PredefinedDataSettingsList = ({ listData, title, field, onFieldChange, shippingModeId, setShippingModeId }) => {
  const dispatch = useDispatch();
  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

  const [id, setId] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(false);
  const [name, setName] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { shippingModeData } = useSelector(state => state.predefinedData);

  const onOpenDialog = (id, name) => {
    setId(id);
    setName(name);
    setOpenDialog(true);
  };
  const onCancelDialog = () => {
    setId('');
    setName('');
    setOpenDialog(false);
  };
  const onOpenConfirmDialog = id => {
    setId(id);
    setOpenConfirmDialog(true);
  };
  const onCancelConfirmDialog = () => {
    setId('');
    setOpenConfirmDialog(false);
  };

  useEffect(() => {
    if (!listData) {
      sendRequest(`/api/${field}`, REQUEST_ACTIONS.GET, '', {
        successCallback: response => dispatch(onFieldChange(response)),
        failedCallback,
      });
    }
  }, [dispatch, failedCallback, field, onFieldChange, listData]);

  const onDeleteName = id => {
    sendRequest(`/api/${field}/${id}`, REQUEST_ACTIONS.DELETE, null, {
      successCallback: message => {
        sendRequest(`/api/${field}`, REQUEST_ACTIONS.GET, '', {
          successCallback: response => {
            dispatch(onFieldChange(response));
            dispatch(
              snackbarToggle({
                type: 'success',
                message,
              }),
            );
            onCancelConfirmDialog();
          },
          failedCallback,
        });
      },
      failedCallback,
    });
  };

  const onSaveName = id => {
    const newName = {
      id,
      name,
    };

    if (field === 'pol' || field === 'poa') {
      newName['shippingModeId'] = shippingModeId;
    }

    sendRequest(`/api/${field}`, id ? REQUEST_ACTIONS.PUT : REQUEST_ACTIONS.POST, newName, {
      successCallback: message => {
        sendRequest(`/api/${field}`, REQUEST_ACTIONS.GET, '', {
          successCallback: response => {
            dispatch(onFieldChange(response));
            dispatch(
              snackbarToggle({
                type: 'success',
                message,
              }),
            );
            onCancelDialog();
          },
          failedCallback,
        });
      },
      failedCallback,
    });
  };

  const handleListItemMouseover = value => {
    if (hoveredItem !== value) {
      setHoveredItem(value);
    }
  };
  const handleListItemMouseleave = value => {
    if (hoveredItem === value) {
      setHoveredItem(null);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} mb={5}>
      <Typography variant='h6' mb={2}>
        {title}
      </Typography>
      {field === 'pol' || field === 'poa' ? (
        <SelectField
          lg={null}
          md={null}
          sm={null}
          label='Shipping mode'
          value={shippingModeId}
          options={shippingModeData}
          onChange={event => setShippingModeId(event.target.value)}
        />
      ) : null}
      <List
        component={Paper}
        sx={{ my: 1.5, height: field === 'pol' || field === 'poa' ? 200 : 250, overflow: 'auto' }}>
        {listData?.map(item => {
          return (
            <ListItem
              key={item.id}
              id={item.id}
              onMouseOver={() => handleListItemMouseover(item.id)}
              onMouseLeave={() => handleListItemMouseleave(item.id)}>
              {hoveredItem === item.id && (
                <ListItemSecondaryAction>
                  <IconButton edge='end' color='primary' size='small' onClick={() => onOpenDialog(item.id, item.name)}>
                    <EditIcon fontSize='small' />
                  </IconButton>
                  <IconButton edge='end' color='error' size='small' onClick={() => onOpenConfirmDialog(item.id)}>
                    <DeleteIcon fontSize='small' />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
              <ListItemText primary={item.name} />
            </ListItem>
          );
        })}
        {openConfirmDialog && (
          <ConfirmDialog
            open={openConfirmDialog}
            onHandleClose={onCancelConfirmDialog}
            message='Are you sure you want to permanently delete this item?'
            onHandleYes={() => onDeleteName(id)}
          />
        )}
      </List>
      <Button onClick={() => onOpenDialog(null)} variant='contained'>
        <Typography noWrap variant='button'>
          + Add {title}
        </Typography>
      </Button>
      {openDialog && (
        <Dialog open={openDialog} onHandleCancel={onCancelDialog} title={title} maxWidth='md'>
          <TextField
            lg={null}
            md={null}
            sm={null}
            label={title}
            value={name}
            onChange={event => setName(event.target.value)}
          />
          <Grid container justifyContent='flex-end' mt={2}>
            <Button variant='contained' sx={{ mr: 2 }} onClick={() => onSaveName(id)}>
              Save
            </Button>
            <Button onClick={onCancelDialog} variant='contained' color='secondary'>
              Cancel
            </Button>
          </Grid>
        </Dialog>
      )}
    </Grid>
  );
};

export default PredefinedDataSettingsList;

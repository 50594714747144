import { useCallback, useEffect, useState } from 'react';

import { Grid, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from 'style-guide';

import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';

import { useDispatch, useSelector } from 'react-redux';
import { getOrders, onParcelsCountChange, onChatCountChange, onUserSpecificParcelsCountChange,onPageChange, onUnConfirmedParcelsCountChange } from 'reducers/parcel-reducers/ordersSlicer';
import {
//  onUserLocalNumberChange,
  onUserAbroadNumberChange,
  onSearchChange,
} from 'reducers/parcel-reducers/orderTableFilterSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import {addParcelFilter, addSearchFilterParcel, usePrevious} from 'Utils';

const OrderSearch = ({ loginUser, setSearchSpinner, pageName }) => {
  const dispatch = useDispatch();
  const onHandleChange = (event, action) => dispatch(action(event.target.value));
  const [varTimeout, setVarTimeout] = useState();

  const { 
    userLocalNumber,  
    userAbroadNumber,  
    consigneeId,
    shipperId,
    countryOfOriginId, originState,
    shippingModeId,
    subModeId,
    userLocalId,
    userAbroadId,
    dueDate,
    lastUpdateDate,
    ilReminderDate,
    reminderDate,
    selectedLocationIndex,
    selectedTabIndexName,
    search,
    startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.parcelTableFilter);
    const { page, size, chatCount, userSpecificParcelsCount } = useSelector(state => state.parcels);
    const prevPage = usePrevious({page});
  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
  useEffect(() => {
      if (varTimeout) {
        clearTimeout(varTimeout);
      }
      if (!search || search?.length == 0 ||  search?.length >= 3) {
        setVarTimeout(setTimeout(() => {
            sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                successCallback: response => { dispatch(onParcelsCountChange(response?.parcelsCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);  dispatch(onChatCountChange(response?.unreadChatCount));sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount));dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount)); },
            failedCallback,
          });

          setSearchSpinner(true);
          // filtered orders get
          sendRequest(
            `/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${prevPage?.page == page && addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
            REQUEST_ACTIONS.GET,
            '',
            {
              successCallback: response => {
                dispatch(getOrders(response));
                setSearchSpinner(false);
                if (prevPage?.page == page && addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                  dispatch(onPageChange(0));
                }
              },
              failedCallback,
            },
          );
        }, varTimeout? 700 : 0));
    }
  }, [userLocalNumber, userAbroadNumber, page, size, setSearchSpinner, search, dispatch, failedCallback, consigneeId,
    shipperId,
    countryOfOriginId, originState,
    shippingModeId,
    subModeId,
    userLocalId,
    userAbroadId,
    dueDate,
    lastUpdateDate,
    selectedLocationIndex,
    selectedTabIndexName,
      startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv,]);

  return (
      <Grid container item spacing={2} xs={7} justifyContent='flex-end' className="header">
      
      <TextField
        xs={12}
        sm={null}
        md={null}
        lg={null}
        className="search"
        label=''
        type='search'
        value={search}
        endAdornment={
          <InputAdornment position='end'>
            <SearchIcon />
          </InputAdornment>
        }
        onChange={event => onHandleChange(event, onSearchChange)}
      />
    </Grid>
  );
};

//      <TextField
//        xs={2}
//        sm={null}
//        md={null}
//        lg={null}
//        type='number'
//        label='IL job #'
//        value={userLocalNumber}
//        onChange={event => onHandleChange(event, onUserLocalNumberChange)}
//      />
//      <TextField
//        xs={2}
//        sm={null}
//        md={null}
//        lg={null}
//        type='number'
//        label='NL job #'
//        value={userAbroadNumber}
//        onChange={event => onHandleChange(event, onUserAbroadNumberChange)}
//      />

export default OrderSearch;

import React from 'react';
import { useState, useEffect } from 'react';
import './Login.scss';
import { useNavigate } from 'react-router-dom';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import {resetOrderTableFilterSlice} from 'reducers/order-reducers/orderTableFilterSlicer';
import { useDispatch } from 'react-redux';
import {
  onUserLocalIdChange,
  onUserTracerChange,
  onUserCustomsChange,
  onUserAbroadIdChange,
} from 'reducers/order-reducers/orderTableFilterSlicer';
export const Login = () => {
    const dispatch = useDispatch();
    const [userName, setUserName] = new useState("");
    const [password, setPassword] = new useState("");
    const [isEnable, setEnabled] = new useState(true);
    const [hasError, setHasError] = new useState(false);
    const navigate = useNavigate();

    const doLogin = () => {
        setEnabled(false);
        setHasError(false);
        dispatch(onUserLocalIdChange(null));
        let loginObj = { "username": userName, "password": password }
        sendRequest('/api/login', REQUEST_ACTIONS.POST, loginObj, {
            successCallback: (response) => {
                if (response?.authority === 'User') {
                    if (response?.locationCode === "NL") {
                        dispatch(onUserAbroadIdChange(response?.id));
                        dispatch(onUserLocalIdChange(null));
                    } else if (response?.locationCode === "US") {
                        dispatch(onUserAbroadIdChange(response?.id));
                        dispatch(onUserLocalIdChange(null));
                    } else if (response?.locationCode === "IL") {
                        if (response.department == null || response.department == 1) {
                            dispatch(onUserLocalIdChange(response?.id));
                            dispatch(onUserTracerChange(null));
                            dispatch(onUserCustomsChange(null));
                            dispatch(onUserAbroadIdChange(null));
                        } else if (response.department == 2) {
                            dispatch(onUserLocalIdChange(null));
                            //dispatch(onUserTracerChange(response?.id));
                            dispatch(onUserTracerChange(null));
                            dispatch(onUserCustomsChange(null));
                            dispatch(onUserAbroadIdChange(null));
                        } else if (response.department == 3) {
                            dispatch(onUserLocalIdChange(null));
                            dispatch(onUserCustomsChange(response?.id));
                            dispatch(onUserTracerChange(null));
                            dispatch(onUserAbroadIdChange(null));
                        }
                    }
                } else {
                    dispatch(onUserAbroadIdChange(null));
                    dispatch(onUserLocalIdChange(null));
                }
                navigate("/home");
            },
            failedCallback: error => {
                setEnabled(true);
                setHasError(true);
            }
        });
    }

    const keyPressed = (e) => {
      if(e.keyCode == 13){
        doLogin();
      }
    }

    useEffect(() => {
        dispatch(resetOrderTableFilterSlice(null));
    });

    return (
        <div className="login-main-container">
            <div className="login-container">
                <div className="login-title">
                    <div className="login-title-strong"></div>
                    <div className="login-title-sub">Order Management Service</div>
                </div>
                <div className="login_form"  >
                    <div className="login-label">Username</div>
                    <input type="text" className="login-input" disabled={!isEnable} onChange={e => setUserName(e.target.value)} />
                    <div className="login-label password">Password</div>
                    <input type="password" className="login-input" disabled={!isEnable} onKeyDown={e => keyPressed(e)} onChange={e => setPassword(e.target.value)} />
                    <div>
                        <button type="button" value="Login" disabled={!isEnable} className="login-button" onClick={doLogin}>
                            <span className="btn-label">Login</span>
                            {!isEnable &&
                                <em className="btn-loading" />
                            }
                        </button>
                    </div>
                </div>
                <div className="login-error">{hasError && "Invalid login!"}</div>
            </div>
        </div>
    );
}

export default Login;
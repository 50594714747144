import { memo } from 'react';
import { useState, useCallback, useEffect } from 'react';
import { SelectField, TextField, MultilineField } from 'style-guide';
import { TableCell, TableHead, TableRow, Typography, FormControlLabel, Checkbox, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

const UserHeader = props => {
    const { roles, customers } = useSelector(state => state.users);
    const [locations, setLocations] = useState([{ "id": 1, "code": "NL", "name": "Nederland" }, { "id": 2, "code": "IL", "name": "Israel" }, { "id": 4, "code": "US", "name": "US" }]);
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [location, setLocation] = useState(1);
    const [role, setRole] = useState(1);
    const [active, setActive] = useState(true);
    return (
        <TableHead>
            <TableRow>
                <TableCell />
                <TableCell>
                    <TextField
                        required
                        lg={11}
                        value={name}
                        onChange={event => {
                            setName(event.target.value);
                        }}
                        label='Name'
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        required
                        lg={11}
                        value={username}
                        onChange={event => {
                            setUsername(event.target.value);
                        }}
                        label='User Name'
                    />
                </TableCell>
                <TableCell>
                    {/*<SelectField
                        required
                        lg={11}
                        label='Location'
                        value={location}
                        options={locations}
                        onChange={event => {
                            setLocation(event.target.value)
                        }} />*/}
                    <Typography variant='h6' style={{ textAlign: "left" }}>Location</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h6' style={{ textAlign: "left" }}>Notes</Typography>
                </TableCell>
                <TableCell>
                    {/*<SelectField
                        required
                        value={role}
                        lg={5.5}
                        onChange={event => {
                            setRole(event.target.value);
                        }}
                        label='Role'
                        options={roles}
                    />*/}
                    <Typography variant='h6' style={{ textAlign: "left" }}>Role</Typography>
                </TableCell>
                <TableCell>
                    <FormControlLabel
                        control={
                            <Checkbox checked={(active != null) ? active : true} onChange={event => {
                                setActive(event.target.checked)
                            }} />
                        }
                        label={active ? "Status" : "Status"}
                    />
                </TableCell>
                <TableCell>
                    <Button variant='contained' onClick={() => { props.setFilter(name, username, location, role, active) }}>Search</Button>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export default memo(UserHeader);

import { TableCell, TableRow, Typography } from '@mui/material';
import { MultilineField } from 'style-guide';
const AuditLogsRow = ({ data }) => {

  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell>
        <Typography variant='body1' component='span'>
          {data.userName || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' component='span'>
          {data.userFullName || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' component='span'>
          {data.userRole || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' component='span'>
          {data.actionOn || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' component='span'>
          {data.jobNumber || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' component='span'>
          {new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(data.date) || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <MultilineField
          xs={12}
          sm={null}
          md={null}
          lg={null}
          value={data.dataChanged || '-'}
        />
      </TableCell>
    </TableRow>
  );
};

export default AuditLogsRow;

import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import * as React from "react";
import { Grid, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { MultilineField, SelectField, TextField, Dropzone, FilesRender, Autocomplete } from 'style-guide';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { createCustomEqual } from "fast-equals";

import { snackbarToggle } from 'reducers/snackbarSlicer';


const ContainerTracker = () => {
  const dispatch = useDispatch();
  const [containerNumber, setContainerNumber] = useState("ZMOU8848288");
  const [containerData, setContainerData] = useState();
  const [keyData, setKeyData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
  const failedCallback1 = useCallback(message => {dispatch(snackbarToggle({ type: 'error', message }));setDataLoaded(true);}, [dispatch]);

  useEffect(() => {
    if(!isLoaded){
      onHandleShipFind()
      setIsLoaded(true);
    }
  }, [isLoaded]);


  const onHandleShipFind = () => {
    sendRequest('/api/track/container/'+containerNumber, REQUEST_ACTIONS.GET, '', {
      successCallback: response => {
        for(var i = 0 ; i < response.data.CargoList.length; i++){
          if(response.data.CargoList[i].CustomsAgent == 514855865){
            setContainerData(response.data.CargoList[i])
          }
        }
        setDataLoaded(true);
        var arr = [];
        Object.keys(containerData).forEach(function(key) {
          if(containerData[key] != null)
            arr.push({key: key.toString(), value:JSON.stringify(containerData[key])});
          else
            arr.push({key:key.toString(), value:"null"});
        });
        setKeyData(arr)
      },
      failedCallback1,
    });
  }

  const dataDiv = {
    border: "1px solid grey",
    padding: "5px",
    borderRadius: "5px"
  };
  const mapSpan = {
    background: "blue",
    padding: "5px",
    borderRadius: "5px"
  };
  const dataData = {
    color: "black",
    padding: "5px",
    borderRadius: "5px"
  };
  const mapDiv = {
    width: "100%",
    height: "300px"
  };

  return (
    <Grid container>
      <Grid container item xs={12} justifyContent='space-between' style={{maxHeight:'60px'}}>
        <Typography variant='h5'>Container Tracker</Typography>
        <TextField
          lg={null}
          type="Text"
          label='Container ID'
          value={containerNumber}
          onChange={event => setContainerNumber(event.target.value)}
        />
        {dataLoaded?
        <Button onClick={onHandleShipFind} variant='contained' sx={{ mb: 3 }}>
          {`Find`}
        </Button>:<Button variant='contained' sx={{ mb: 3 }}>Loading...</Button>}
      </Grid>
      <Grid container item xs={5} justifyContent='flex-end' flexDirection='column-reverse'>
        <div style={{width:'100%', height:'calc(100vh - 150px)', maxHeight:'500px', overflowY:'auto', padding: '10px'}}>
          {keyData.map(item => <div style={dataDiv}>
              <span style={{color:'green'}}>{item['key']}</span>: <span style={{color:'blue'}}>{item['value']}</span>
            </div>)
          }
        </div>
      </Grid>
    </Grid>
  );
};

export default ContainerTracker;

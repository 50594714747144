import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { snackbarToggle } from 'reducers/snackbarSlicer';

import MarineTracker from 'shipTracker/MarineTracker';
import MarineTrackerHist from 'shipTracker/MarineTrackerHist';
import ShipTracker from 'shipTracker/ShipTracker';
import AirTracker from 'shipTracker/AirTracker';
import AirTrackerHist from 'shipTracker/AirTrackerHist';
import ContainerTracker from 'shipTracker/ContainerTracker';



const Tracker = ({ fullName }) => {
  const dispatch = useDispatch();
  const [showAir, setShowAir] = useState(false);
  const [showAirHist, setShowAirHist] = useState(false);
  const [showSea, setShowSea] = useState(false);
  const [showSeaHist, setShowSeaHist] = useState(false);
  const [showContainer, setShowContainer] = useState(false);

    useEffect(() => {
        onHandleSea();
    }, []);

    function onHandleAir() {
        setShowAir(true);
        setShowSea(false);
        setShowAirHist(false);
        setShowSeaHist(false);
        setShowContainer(false);
    }
    function onHandleAirHist() {
        setShowAir(false);
        setShowSea(false);
        setShowAirHist(true);
        setShowSeaHist(false);
        setShowContainer(false);
    }

    function onHandleSea() {
        setShowAir(false);
        setShowSea(true);
        setShowAirHist(false);
        setShowSeaHist(false);
        setShowContainer(false);
    }
    function onHandleSeaHist() {
        setShowAir(false);
        setShowSea(false);
        setShowAirHist(false);
        setShowSeaHist(true);
        setShowContainer(false);
    }

  const onHandleContainer = () => {
    setShowAir(false);
    setShowSea(false);
    setShowContainer(true);
    }

  return (
      <Grid item container style={{ minWidth: "1400px" }}>
          {/*<Grid container justifyContent="flex-start" item xs={12} alignContent="flex-start" style={{ maxHeight: "50px" }} >
              {<Button onClick={onHandleSea} variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                  {`Sea`}
              </Button>}
              {<Button variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                  {`Sea History`}
              </Button>}
              {<Button onClick={onHandleAir} variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                  {`Air`}
              </Button>}
              {<Button variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                  {`Air History`}
              </Button>}
          </Grid>*/}
          {/*<Grid container item xs={12} justifyContent='space-between'>
              <Button onClick={onHandleAir} variant='contained' sx={{ mb: 3 }}>
                  {`Air`}
              </Button>
              <Button onClick={onHandleSea} variant='contained' sx={{ mb: 3 }}>
                  {`Sea`}
              </Button>
              <Button onClick={onHandleContainer} variant='contained' sx={{ mb: 3 }}>
                  {`Container`}
              </Button>
          </Grid>*/}
          <Grid container item xs={12} justifyContent='space-between' style={{ width: '100%', height: 'calc(100vh - 100px)' }}>
              {showSea ? <MarineTracker fullName={fullName} onHandleSea={() => { onHandleSea(); }} onHandleAir={() => { onHandleAir(); }} onHandleSeaHist={() => { onHandleSeaHist(); }} onHandleAirHist={() => { onHandleAirHist(); }} /> : (null)}
              {showAir ? <AirTracker fullName={fullName} onHandleSea={() => { onHandleSea(); }} onHandleAir={() => { onHandleAir(); }} onHandleSeaHist={() => { onHandleSeaHist(); }} onHandleAirHist={() => { onHandleAirHist(); }} /> : (null)}
              {showSeaHist ? <MarineTrackerHist fullName={fullName} onHandleSea={() => { onHandleSea(); }} onHandleAir={() => { onHandleAir(); }} onHandleSeaHist={() => { onHandleSeaHist(); }} onHandleAirHist={() => { onHandleAirHist(); }} /> : (null)}
              {showAirHist ? <AirTrackerHist fullName={fullName} onHandleSea={() => { onHandleSea(); }} onHandleAir={() => { onHandleAir(); }} onHandleSeaHist={() => { onHandleSeaHist(); }} onHandleAirHist={() => { onHandleAirHist(); }} /> : (null)}
              {showContainer ? <ContainerTracker /> : (null)}
          </Grid>
      </Grid>
  );
};

export default Tracker;

import { useState, memo } from 'react';

import StageButton from '../StageButton';
import DepartureDialog from './DepartureDialog';
import {StyledTableCell} from 'Utils';

const Departure = ({ loginUser, order }) => {
    const currentStage = order.orderStage ? order.orderStage[4] : null;
    const getStatus = () => {
        if (order.beingTracked) {
            if (order.incotermsId != 9) {
                if (order.orderStatus[4].status === "SUCCESS" && order.orderStatus[5]?.status === "SUCCESS")
                    return "SUCCESS";
                else if (order.orderStatus[4].status === "SUCCESS" && order.orderStatus[5]?.status !== "SUCCESS")
                    return "IN_PROGRESS";
                else
                    return order.orderStatus[4].status;
            } else {
                if (order.orderStatus[3].status === "SUCCESS" && order.orderStatus[5]?.status === "SUCCESS")
                    return "SUCCESS";
                else if (order.orderStatus[3].status === "SUCCESS" && order.orderStatus[5]?.status !== "SUCCESS")
                    return "IN_PROGRESS";
                else
                    return order.orderStatus[4].status;
            }
        } else {
            return order.orderStage ? order.orderStage[4].status : null
        }
    }

  const [openDialog, setOpenDialog] = useState(false);
  const onHandleOpenDialog = () => setOpenDialog(true);
    
  return (
      <StyledTableCell style={{ width: "5vw", p: 0 }}>
          <StageButton stage={order.orderStatus ? order.orderStatus[4] : null} onHandleOpenDialog={onHandleOpenDialog} stageName='Port' loginUser={loginUser}/>
      {openDialog && <DepartureDialog loginUser={loginUser} order={order} openDialog={openDialog} setOpenDialog={setOpenDialog} />}
    </StyledTableCell>
  );
};

export default memo(Departure);

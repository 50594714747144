import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomerOrderDetail = ({ detail }) => {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.caption,
    padding: theme.spacing(1),
  }));
/*
    <Grid container spacing={2}>
      <Grid item>
          <Item><Typography fontSize="0.8rem" variant='body1' component='span'>Pieces: {detail.numberOfPieces}</Typography></Item>
          <Item><Typography fontSize="0.8rem" variant='body1' component='span'>L (cm): {detail.length}</Typography></Item>
          <Item><Typography fontSize="0.8rem" variant='body1' component='span'>W (cm): {detail.width}</Typography></Item>
          <Item><Typography fontSize="0.8rem" variant='body1' component='span'>H (cm): {detail.height}</Typography></Item>
          <Item><Typography fontSize="0.8rem" variant='body1' component='span'>Weight per Piece (kg): {detail.weightPerPiece}</Typography></Item>
          <Item><Typography fontSize="0.8rem" variant='body1' component='span'>Total Weight (kg): {detail.numberOfPieces * detail.weightPerPiece}</Typography></Item>
          <Item><Typography fontSize="0.8rem" variant='body1' component='span'>Total Volume (m<sup>3</sup>): {(detail.numberOfPieces * (detail.length * detail.width * detail.height)) / 1000}</Typography></Item>
      </Grid>
    </Grid>
*/
  return (
    <Grid container item xs={12} style={{marginLeft:'10px'}}>
      <Typography fontSize="0.8rem" variant='body1' component='span'>Pieces: {detail.numberOfPieces}</Typography>
      <Typography fontSize="0.8rem" variant='body1' component='span'>L (cm): {detail.length}</Typography>
      <Typography fontSize="0.8rem" variant='body1' component='span'>W (cm): {detail.width}</Typography>
      <Typography fontSize="0.8rem" variant='body1' component='span'>H (cm): {detail.height}</Typography>
      <Typography fontSize="0.8rem" variant='body1' component='span'>Weight per Piece (kg): {detail.weightPerPiece}</Typography>
      <Typography fontSize="0.8rem" variant='body1' component='span'>Total Weight (kg): {detail.numberOfPieces * detail.weightPerPiece}</Typography>
      <Typography fontSize="0.8rem" variant='body1' component='span'>Total Volume (m<sup>3</sup>): {(detail.numberOfPieces * (detail.length * detail.width * detail.height)) / 1000}</Typography>
    </Grid>
  );
};

export default CustomerOrderDetail;

import { useState } from 'react';
import { TableCell, TableRow, Typography, Box, Button } from '@mui/material';
import AddUser from './AddUser';
import { Dialog } from 'style-guide';
import { useSelector } from 'react-redux';

const UserRow = ({ data }) => {
    const { roles, locations } = useSelector(state => state.users);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const inActiveBgColor = {
        'background-color': '#F5F5F5'
    }

    return (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={(data.active == null || data.active) ? null : inActiveBgColor}>
            <TableCell />
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left" }}>
                    {data.fullName || '-'}
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left" }}>
                    {data.userName || '-'}
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left" }}>
                    {locations.find(location => location.id === data.locationId)?.name || '-'}
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left" }}>
                    {data.description || '-'}
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left" }}>
                    {roles.find(role => role.id === data.roleId)?.name || '-'}
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left" }}>
                    {(data.active != null) ? (data.active ? 'Active' : 'In-Active') : 'Active'}
                </p>
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button onClick={onHandleOpenModal} variant='outlined'>
                        Edit
                    </Button>
                    {openOrderModal && (
                        <Dialog open={openOrderModal} title={'User'} onHandleCancel={onHandleCancelModal}>
                            <AddUser data={data} onHandleCancel={onHandleCancelModal} id={null} viewProfile={false} />
                        </Dialog>
                    )}
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default UserRow;

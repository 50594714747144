import { useState, useEffect, useCallback, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  TablePagination,
  CircularProgress,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import OrderTableHeader from './OrderTableHeader';
import OrderRow from './OrderRow';

import { getOrders, onOrdersCountChange } from 'reducers/order-reducers/ordersSlicer';

import { useSelector, useDispatch } from 'react-redux';
import { onPageChange, onSizeChange } from 'reducers/order-reducers/ordersSlicer';

const OrderTable = ({ searchSpinner, loginUser, pageName, searchText, setSearchText }) => {
  const dispatch = useDispatch();
  const { orders, page, size, ordersCount } = useSelector(state => state.orders);

  const [filterSpinner, setFilterSpinner] = useState(false);
  const [filterAtStart, setFilterAtStart] = useState(false);

  // pagination
  const onPageHandleChange = (_, newPage) => {
    dispatch(onPageChange(newPage));
  };
  const onRowsPerPageHandleChange = event => {
    dispatch(onSizeChange(+event.target.value));
    dispatch(onPageChange(0));
  };

  return (
    <Paper sx={{ width: '100%', marginTop: '15px', overflow: 'hidden', boxShadow: 'rgb(0 0 0) 0px 2px 1px -1px, rgb(0 0 0) 0px 1px 1px 0px, rgb(0 0 0) 0px 1px 3px 0px' }}>
          <TableContainer sx={{ maxHeight: "calc(100vh - 200px)", overflow: 'auto' }} className="orderTable">
              <Table className="orderTable">
                  <OrderTableHeader loginUser={loginUser} setFilterSpinner={setFilterSpinner} filterAtStart={filterAtStart} setFilterAtStart={setFilterAtStart} pageName={pageName} searchText={searchText} setSearchText={setSearchText} />
                  {filterSpinner || searchSpinner ? (
                      <TableBody className="orderTable">
                          <TableRow style={{ height: "200px" }}>
                              <TableCell colSpan={13} align='center'>
                                  <CircularProgress style={{ marginTop: "20px" }} />
                              </TableCell>
                          </TableRow>
                      </TableBody>
                  ) : orders?.length ? (
                      <TableBody>
                          {orders.map(order => (
                              <OrderRow loginUser={loginUser} key={order?.id || uuidv4()} order={order} pageName={pageName} />
                          ))}
                      </TableBody>
                  ) : (
                      <TableBody>
                          <TableRow>
                              <TableCell colSpan={13} align='center'>
                                  <Typography variant='h4'>No data available</Typography>
                              </TableCell>
                          </TableRow>
                      </TableBody>
                  )}
              </Table>
          </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 50, 100]}
        component='div'
        count={ordersCount}
        rowsPerPage={size}
        page={page}
        onPageChange={onPageHandleChange}
        onRowsPerPageChange={onRowsPerPageHandleChange}
      />
    </Paper>
  );
};

export default OrderTable;

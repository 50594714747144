import { createSlice } from '@reduxjs/toolkit';

export const truckingStageSlice = createSlice({
  name: 'truckingStage',
  initialState: {
    id: null,
    truckingStatuses: null,
    truckingModeData: null,
    stageStatusInfoId: null,
    truckingModeId: 1,
    eta: null,
    puDate: null,
    requiredTimeSlot: null,
    requiredTimeSlotStart: null,
    requiredTimeSlotStop: null,
    truckingCompanyId: null,
    truckingNumber: null,
    platNumber: null,
    sealNumber: null,
    teuNumber: null,
    approveFlag: null,
    buyingRate: null,
    additionalCharges: null,
    sellingRate: null,
    readyForPickUpDate: null,
    notes: null,
  },
  reducers: {
    resetTruckingStage: state => {
      state.id = null;
      state.stageStatusInfoId = null;
      state.truckingModeId = 1;
      state.eta = null;
      state.puDate = null;
      state.requiredTimeSlot = null;
      state.requiredTimeSlotStart = null;
      state.requiredTimeSlotStop = null;
      state.truckingCompanyId = null;
      state.truckingNumber = null;
      state.platNumber = null;
      state.sealNumber = null;
      state.teuNumber = null;
      state.approveFlag = null;
      state.buyingRate = null;
      state.additionalCharges = null;
      state.sellingRate = null;
      state.readyForPickUpDate = null;
      state.notes = null;
    },
    onIdChange: (state, { payload }) => {
      state.id = payload;
    },
    onTruckingModeDataChange: (state, { payload }) => {
      state.truckingModeData = payload;
    },
    onSageStatusInfoIdChange: (state, { payload }) => {
      state.stageStatusInfoId = payload;
    },
    onTruckingStatusesChange: (state, { payload }) => {
      state.truckingStatuses = payload.slice(1);
    },
    onTruckingModeIdChange: (state, { payload }) => {
      state.truckingModeId = payload;
    },
    onEtaChange: (state, { payload }) => {
      state.eta = payload;
    },
    onPuDateChange: (state, { payload }) => {
      state.puDate = payload;
    },

    onReadyForPickUpDateChange: (state, { payload }) => {
      state.readyForPickUpDate = payload;
      state.puDate = payload;
    },
    onRequiredTimeSlotChange: (state, { payload }) => {
      state.requiredTimeSlot = payload;
    },
    onRequiredTimeSlotStartChange: (state, { payload }) => {
      state.requiredTimeSlotStart = payload;
    },
    onRequiredTimeSlotStopChange: (state, { payload }) => {
      state.requiredTimeSlotStop = payload;
    },
    onTruckingCompanyIdChange: (state, { payload }) => {
      state.truckingCompanyId = payload;
    },
    onTruckingNumberChange: (state, { payload }) => {
      state.truckingNumber = payload;
    },
    onPlatNumberChange: (state, { payload }) => {
      state.platNumber = payload;
    },
    onSealNumberChange: (state, { payload }) => {
      state.sealNumber = payload;
    },
    onTeuNumberChange: (state, { payload }) => {
      state.teuNumber = payload;
    },
    onApproveFlagChange: (state, { payload }) => {
      state.approveFlag = payload;
    },
    onBuyingRateChange: (state, { payload }) => {
      state.buyingRate = payload;
    },
    onAdditionalChargesChange: (state, { payload }) => {
      state.additionalCharges = payload;
    },
    onSellingRateChange: (state, { payload }) => {
      state.sellingRate = payload;
    },
    onNotesChange: (state, { payload }) => {
      state.notes = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetTruckingStage,
  onIdChange,
  onTruckingModeDataChange,
  onSageStatusInfoIdChange,
  onTruckingModeIdChange,
  onTruckingStatusesChange,
  onEtaChange,
  onPuDateChange,
  onRequiredTimeSlotChange,
  onRequiredTimeSlotStartChange,
  onRequiredTimeSlotStopChange,
  onTruckingCompanyIdChange,
  onTruckingNumberChange,
  onPlatNumberChange,
  onSealNumberChange,
  onTeuNumberChange,
  onApproveFlagChange,
  onBuyingRateChange,
  onAdditionalChargesChange,
  onSellingRateChange,
  onNotesChange,
  onReadyForPickUpDateChange,
} = truckingStageSlice.actions;

export default truckingStageSlice.reducer;

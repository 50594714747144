import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.caption,
  padding: theme.spacing(1),
}));

const CustomerOrderFCLDetail = ({ detail }) => {
  return (
    <Grid container item xs={12} style={{marginLeft:'10px'}}>
      <Typography fontSize="0.8rem" variant='body1' component='span'>Quantity 20': {detail.quantity20}</Typography>
      <Typography fontSize="0.8rem" variant='body1' component='span'>FCL Type 20': {detail.fclType20Id}</Typography>
      <Typography fontSize="0.8rem" variant='body1' component='span'>Quantity 40': {detail.quantity40}</Typography>
      <Typography fontSize="0.8rem" variant='body1' component='span'>FCL Type 40': {detail.fclType40Id}</Typography>
    </Grid>
  );
};

export default CustomerOrderFCLDetail;

import { useState, useEffect, useCallback, Fragment } from 'react';
import { Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import { Button, CircularProgress, Grid, Typography, Tab, Tabs, TableCell, Box, Paper, Checkbox, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { TextField, DateTimePicker } from 'style-guide';
import { styled } from '@mui/system';

import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import './Manifest.scss';
import ManifestRow from './ManifestRow';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { onSelectedLocationIndexChange, onUserAbroadIdChange, onSelectedTabIndexNameChange } from 'reducers/parcel-reducers/orderTableFilterSlicer';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { addParcelFilter, addSearchFilter, getPreviousDate } from 'Utils';
import { getManifests, onStartCreateDateChange, onEndCreateDateChange, onManifestsSearchChange, resetManifestSlice } from 'reducers/parcel-reducers/manifestsSlicer';
import { getOrders, onPageChange } from 'reducers/parcel-reducers/ordersSlicer';
import ManifestDialog from './ManifestDialog';
const tableTh = [
    { id: 1, name: 'Create Date' },
    { id: 15, name: 'HAWB' },
    { id: 13, name: 'Locker' },
    { id: 5, name: 'Receiver Name' },
    { id: 3, name: 'Weight' },
    { id: 9, name: 'Add/Remove' },
]
const Manifest = ({ loginUser }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const pageName = 'manifest';
    const onHandleChange = (event, action) => dispatch(action(event.target.value));
    const { selectedLocationIndex, selectedTabIndexName } = useSelector(state => state.parcelTableFilter);
    const { manifests, startCreateDate, endCreateDate, manifestsSearch } = useSelector(state => state.manifests);
    const [openDialog, setOpenDialog] = useState(false);
    const onHandleOpenDialog = () => setOpenDialog(true);

    const onHandleSelectedLocationIndexChange = (event, newValue) => {
        dispatch(onUserAbroadIdChange(null));
        dispatch(onSelectedLocationIndexChange(newValue));
    }

    const onSearch = (resetData) => {
        setIsLoaded(false);
        let startCreateDateData = startCreateDate;
        if (!startCreateDateData || resetData) {
            startCreateDateData = getPreviousDate();
        }

        let endCreateDateData = endCreateDate;
        if (!endCreateDateData || resetData) {
            endCreateDateData = new Date().toISOString();
        }

        let searchData = manifestsSearch;
        if (resetData) {
            searchData = "";
        }

        sendRequest(
            `/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}&page=0&size=0&manifestEligibleParcelsOnly=true&showManifestNotClosed=true&startCreateDate=${startCreateDateData?.split('.')[0]?.replace('T', ' ')}&endCreateDate=${endCreateDateData?.split('.')[0]?.replace('T', ' ')}`,
            REQUEST_ACTIONS.GET,
            '',
            {
                successCallback: response => {
                    dispatch(getManifests(response?.sort(function (a, b) {
                        return new Date(b?.date) - new Date(a?.date);
                    })));
                    setIsLoaded(true);
                },
                failedCallback,
            },
        );

    }

    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);


    useEffect(() => {
        dispatch(resetManifestSlice(null));
        onSearch(true);
    }, [
        selectedLocationIndex,
        selectedTabIndexName,
        dispatch,
        failedCallback,
    ]);

    const [isLoaded, setIsLoaded] = useState(true);

    useEffect(() => {
        if (location?.state?.name == "location") {
            dispatch(onSelectedLocationIndexChange(location?.state.locInd));
        }
    }, []);

    const getCount = (data) => {
        var c = 0;

        return manifests?.length;
    }

    const [selectedForExport, setSelectedForExport] = useState([]);
    const [validatedList, setValidatedList] = useState([]);
    const [inManifestList, setInManifestList] = useState([]);
    const addInValidList = (d) => {
        console.log(d)
        var t = [...validatedList]
        t.push(d);
        setValidatedList(t);
        //updateWarehouse(t, selectedForExport, inManifestList)
    }
    const addInManifestList = (d) => {
        var t = [...inManifestList]
        t.push(d);
        setInManifestList(t);
        //updateWarehouse(validatedList, selectedForExport, t)
    }
    const removeInManifestList = (d) => {
        var t = [...inManifestList]
        for (var i = 0; i < t.length; i++) {
            if (t[i].id == d.id) {
                t.splice(i, 1);
            }
        }
        setInManifestList(t);
        //updateWarehouse(validatedList, selectedForExport, t)
    }
    const removeInSelectedForExport = (d) => {
        var t = [...selectedForExport]
        for (var i = 0; i < t.length; i++) {
            if (t[i].id == d.id) {
                t.splice(i, 1);
            }
        }
        setSelectedForExport(t);
        //updateWarehouse(validatedList, t, inManifestList)
    }
    const addInSelectedForExport = (d) => {
        var t = [...selectedForExport]
        t.push(d);
        setSelectedForExport(t);
        //updateWarehouse(validatedList, t, inManifestList)
    }
    var chWtMultiplier = 167 / 1000000;
    const chargableWeightInList = (list) => {
        var tWt = 0;
        for (var i = 0; i < list.length; i++) {
            /*var weight = list[i].params?.userPaymentData.weight;
            var h = list[i].params?.userPaymentData.height;
            var w = list[i].params?.userPaymentData.width;
            var l = list[i].params?.userPaymentData['length'];
            var wt = Number(l) * Number(w) * Number(h) * chWtMultiplier;
            var chWt = Number(wt) > Number(weight) ? Number(wt) : Number(weight);*/
            tWt += list[i].chargeWeight;
        }
        return tWt;
    }


    const isInValidList = (d) => {
        var t = [...validatedList]
        for (var i = 0; i < t.length; i++) {
            if (t[i].id == d.id) {
                return true;
            }
        }
        return false;
    }
    return (
        <Grid container>
            {!isLoaded ? (
                <Grid container item justifyContent='center' mt={8}>
                    <CircularProgress />
                </Grid>
            ) : (
                <Fragment>
                    <Grid container rowSpacing={1}>
                        {!(loginUser?.authorities && loginUser?.authorities[0]?.authority === 'User' && (loginUser?.location?.code === 'NL' || loginUser?.location?.code === 'US')) ?
                            (
                                <Grid item xs={12}>
                                    <Tabs value={selectedLocationIndex} onChange={onHandleSelectedLocationIndexChange} sx={{ minHeight: "30px", overflow: "hidden", border: "1px solid #ccc", backgroundColor: "#f1f1f10a" }}>
                                        <StyledTab label="NL" className="locationTab" />
                                        <StyledTab label="US" className="locationTab" />
                                        <StyledTab label="IL" className="locationTab" />
                                    </Tabs>
                                </Grid>) : null}
                        <Grid container item xs={9.4} spacing={1} style={{ marginTop: '0' }}>
                            {/* <Typography noWrap variant='h5'>Orders History</Typography>*/}
                                <Button color='secondary' variant='contained' sx={{ fontWeight: "400", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "11%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("Orders"));
                                dispatch(getOrders([]));
                                dispatch(onPageChange(0));
                                window.setTimeout(function () {
                                    navigate("/home", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Orders" } })
                                }, 500)
                                //agentOrders()
                            }}>
                                Orders
                            </Button>
                                <Button color='secondary' variant='contained' sx={{ fontWeight: "400", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "16%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("OrdersHistory"));
                                dispatch(getOrders([]));
                                // navigate("/home", { state: { name: "location", locInd: selectedLocationIndex } })
                                dispatch(onPageChange(0));
                                window.setTimeout(function () {
                                    navigate("/orderHistory", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "OrdersHistory" } })
                                }, 500)
                            }}>
                                Order History
                            </Button>
                                <Button color='secondary' variant='contained' sx={{ fontWeight: "400", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "11%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("Parcels"));
                                dispatch(getOrders([]));
                                dispatch(onPageChange(0));
                                window.setTimeout(function () {
                                    navigate("/phome", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Parcels" } })
                                }, 500)
                                //redboxParcels()
                            }}>
                                Redbox
                            </Button>
                                <Button color='secondary' variant='contained' sx={{ fontWeight: "400", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "17%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("ParcelsHistory"));
                                dispatch(getOrders([]));
                                // navigate("/home", { state: { name: "location", locInd: selectedLocationIndex } })
                                dispatch(onPageChange(0));
                                window.setTimeout(function () {
                                    navigate("/parcelHistory", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "ParcelsHistory" } })
                                }, 500)
                                //redboxParcels()
                            }}>
                                Redbox History
                            </Button>
                                <Button color='secondary' variant='contained' sx={{ fontWeight: "400", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "11%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("Drops"));
                                dispatch(getOrders([]));
                                dispatch(onPageChange(0));
                                window.setTimeout(function () {
                                    navigate("/dhome", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Drops" } })
                                }, 500)
                                //redboxParcels()
                            }}>
                                Drop
                            </Button>
                                <Button color='secondary' variant='contained' sx={{ fontWeight: "400", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "17%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("DropsHistory"));
                                dispatch(getOrders([]));
                                // navigate("/home", { state: { name: "location", locInd: selectedLocationIndex } })
                                dispatch(onPageChange(0));
                                window.setTimeout(function () {
                                    navigate("/dropHistory", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "DropsHistory" } })
                                }, 500)
                                //redboxParcels()
                            }}>
                                Drop History
                            </Button>
                        </Grid>
                        <Grid container sx={{ justifyContent: "flex-start" }} className="box-container" xs={12} style={{ marginTop: "10px" }}>
                            <Grid container sx={{ justifyContent: 'space-between', minWidth: '220px' }}  className='border-box grey-bg light-border content-box' item xs={2}>
                                <Grid sx={{ width: '100%' }} className='title-bold'>{'Ready to manifest'}</Grid>
                                <Grid sx={{ width: '100%' }} className='title-lg'>{

                                    getCount()
                                }<span className='title-sm'>Parcels</span>
                                </Grid>
                            </Grid>

                            <Grid container sx={{ justifyContent: "center", alignItems: 'center', minWidth: '75px' }} xs={0.5}
                                className='gar-arrow' item >
                                <TrendingFlatIcon style={{ width: '100%', fill: '#999' }} />
                            </Grid>

                            <Grid container sx={{ minWidth: '220px'}} className='border-box grey-bg light-border content-box' item xs={2}>
                                <Box sx={{ width: '100%' }} className='title-bold'>{'In manifest'}</Box>
                                <Grid className='tb-colomn' item lg={6} sm={6} sx={6}>
                                    <Box className='tb-row'>Parcels: <strong>{inManifestList.length}</strong></Box>
                                </Grid>
                                <Grid className='tb-colomn' item lg={6} sm={6} sx={6}>
                                    <Box className='tb-row'>Total weight: <strong>{chargableWeightInList(inManifestList)}kg</strong></Box>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ justifyContent: "flex-end", alignItems: 'center' }} xs={2}
                                className='gar-arrow' item >
                                {selectedForExport.length > 0 && <Button disabled={inManifestList.length == 0} sx={{ float: "right", color: '#fff !important' }} onClick={onHandleOpenDialog} variant="contained">
                                    Close manifest
                                </Button>}
                                    {openDialog && <ManifestDialog loginUser={loginUser} inManifestList={inManifestList} openDialog={openDialog} setOpenDialog={setOpenDialog} setInManifestList={setInManifestList} setSelectedForExport={setSelectedForExport} setValidatedList={setValidatedList} loginUser={loginUser}/>}
                            </Grid>
                        </Grid>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <Grid container item spacing={2} sx={{ mt: 0 }} xs={12}>
                                <TextField
                                    sm={2}
                                    md={2}
                                    xs={2}
                                    sx={{  }}
                                    size={"small"}
                                    label={<Typography fontSize="0.8rem" fontWeight='bold'>Search</Typography>}
                                    value={manifestsSearch}
                                    onChange={event => onHandleChange(event, onManifestsSearchChange)}
                                    className={'search-input-1'}
                                />
                                <DateTimePicker
                                    sm={2}
                                    md={2}
                                    xs={2}
                                    label={<Typography fontSize="0.8rem" fontWeight='bold'>Start</Typography>}
                                    style={{ 'top': '15px', left: '35px' }}
                                    value={startCreateDate}
                                    onChange={date => dispatch(onStartCreateDateChange(date?.toISOString()))}
                                />
                                <DateTimePicker
                                    sm={2}
                                    md={2}
                                    xs={2}
                                    label={<Typography fontSize="0.8rem" fontWeight='bold'>End</Typography>}
                                    value={endCreateDate}
                                    onChange={date => dispatch(onEndCreateDateChange(date?.toISOString()))}
                                />

                                <Button variant='contained' sx={{ mt: 2, ml: 2, height: '36px', lineHeight: 1, color: '#fff !important' }} onClick={() => { onSearch(false); }}>
                                    Go
                                </Button>
                                <Button variant='contained' sx={{ mt: 2, ml: 2, height: '36px', lineHeight: 1, color: '#fff !important' }} onClick={event => { onHandleChange(event, resetManifestSlice); onSearch(true); }} >
                                    Reset
                                </Button>
                            </Grid>

                            <TableContainer sx={{ height: "calc(100vh - 225px)", padding: '0 3px' }}>
                                <Table className='parcel-table' sx={{ marginTop: '10px', width: '100%', overflow: 'hidden', boxShadow: 'rgb(0 0 0) 0px 2px 1px -1px, rgb(0 0 0) 0px 1px 1px 0px, rgb(0 0 0) 0px 1px 3px 0px' }} stickyHeader aria-label="sticky table">
                                    {/*<TableHead>*/}
                                    <TableRow>
                                        <TableCell> <Checkbox className='checkbox' /></TableCell>
                                        {tableTh.map((th) => <TableCell style={{ fontSize: "14px", fontWeight: "bold" }} key={th.id}> {th.name} </TableCell>)}
                                    </TableRow>
                                    {/*</TableHead>*/}
                                    {manifests?.length ? (
                                        <TableBody>
                                            {manifests.map(order => (
                                                <ManifestRow loginUser={loginUser} order={order} removeInSelectedForExport={removeInSelectedForExport} addInSelectedForExport={addInSelectedForExport} isInValidList={isInValidList} removeInManifestList={removeInManifestList} addInValidList={addInValidList} addInManifestList={addInManifestList} />
                                            ))}
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={13} align='center'>
                                                    <Typography variant='h4'>No data available</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>

                        </Paper>
                    </Grid>
                    {
                        (selectedForExport.length > 0 && <Grid container className='botton-dialog'>
                            <Grid container item lg={10} sm={10} sx={{ alignItems: 'center', justifyContent: 'center', fontSize: '14px' }}>
                                {/*<Grid container item lg={6} sm={6} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                {selectedForExport.length > 0 && admin.data.user.type == 2 && <Button disabled={inManifestList.length == 0} className='btn-border' onClick={() => { onHandleOpenModal(); setIsBottom(!isBottom) }}>
                                    Close manifest
                                </Button>
                                }
                            </Grid>*/}
                            </Grid>
                            <Grid container item lg={2} sm={2} className='total-parcels'>
                                <Grid container sx={{ alignItems: 'center', justifyContent: 'center', fontSize: '14px', fontWeight: 'bold' }}>
                                    {selectedForExport.length} parcels
                                </Grid>
                                <Grid container sx={{ alignItems: 'flex-start', justifyContent: 'center', fontSize: '18px', fontWeight: 'bold' }}>
                                    {chargableWeightInList(selectedForExport)} kg
                                </Grid>
                            </Grid>
                        </Grid>)
                    }
                </Fragment>
            )}
        </Grid>
    );
};

export default Manifest;

export const StyledTab = styled(Tab)({
    border: '1px solid black',
    'font-size': '17px',
    'margin-right': '2px',
    'padding': '14px 16px',
    'min-height': '30px',
});

/*global google*/
import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import * as React from "react";
import { Grid, Button, Typography, IconButton, TablePagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { MultilineField, SelectField, TextField, DateTimePicker, Dropzone, FilesRender, Autocomplete, Dialog, Tooltip } from 'style-guide';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { createCustomEqual } from "fast-equals";
import BackIcon from '@mui/icons-material/SettingsBackupRestore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import EditIcon from '@mui/icons-material/Edit';
import * as XLSX from 'xlsx';

import { snackbarToggle } from 'reducers/snackbarSlicer';

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Polyline,
  InfoWindow,
} from '@react-google-maps/api'

const googleMapURL =
  'https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=AIzaSyDgpzrxExQg_AUW8h199onppaSz72SYlSY';

const AirTracker = props => {
    const dispatch = useDispatch();
    const [airNumber, setAirNumber] = useState("");
    const [airData, setAirData] = useState([]);
    const [curContainerData, setCurContainerData] = useState({});
    const [curContainerDataLoaded, setCurContainerDataLoaded] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(true);
    const [depTime, setDepTime] = useState(new Date());
    const [center, setCenter] = useState({ lat: 32, lng: 35 });
    const [originCenter, setOriginCenter] = useState({ lat: 32, lng: 35 });
    const [originText, setOriginText] = useState("");
    const [originCity, setOriginCity] = useState("");
    const [destCenter, setDestCenter] = useState({ lat: 32, lng: 35 });
    const [destText, setDestText] = useState("");
    const [destCity, setDestCity] = useState("");
    const [flightText, setFlightText] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [map, setMap] = useState(/** @type google.maps.Map */(null))
    const [mapRef, setMapRef] = useState()
    const [airPath, setAirPath] = useState([originCenter, center, destCenter]);
    const [rot, setRot] = useState(0);

    const {
        subModeData,
        shippingModeData,
        countryOfOriginData,
        userILNames,
        incotermsData,
        airPolData,
        airPoaData,
        lclPolData,
        lclPoaData,
    } = useSelector(state => state.predefinedData);
    const { consigneeNameOptions } = useSelector(state => state.consignees);

    const [airPolData1, setAirPolData1] = useState("")
    const [airPoaData1, setAirPoaData1] = useState("")
    const [consigneeNameOptions1, setConsigneeNameOptions1] = useState("")


    const [ordersCount, setOrdersCount] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [pageNumber, setPageNumber] = useState(0);
    const onPageHandleChange = (_, newPage) => {
        setPageNumber(newPage);
        getAlltracksByPageChange(newPage, pageSize);
    }
    const onRowsPerPageHandleChange = (event) => {
        setPageSize(event.target.value);
        setPageNumber(0);
        getAlltracksByPageChange(0, event.target.value);
    }

    const getAlltracksByPageChange = (pageN, pageS) => {
        getAlltracksBySearch(pageN, pageS, customerSearch, awbSearch, polSearch, podSearch, alcSearch, alnSearch, fdSearch);
    }
    const getAlltracks = () => {
        getAlltracksBySearch(pageNumber, pageSize, customerSearch, awbSearch, polSearch, podSearch, alcSearch, alnSearch, fdSearch);
    }
    const getAlltracksBySearch = (pn, ps, cs, as1, pols, pods, alc, aln, fd) => {
        sendRequest('/api/track/allAirTrackBySearch?pageNum=' + pn + "&pageSize=" + ps + "&customer=" + cs + "&awb=" + as1 + "&pol=" + pols + "&pod=" + pods + "&alCompany=" + alc + "&flightNumber=" + aln + "&flightDate=" + fd, REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                var dts = setTracks(response.tracks);
                setOrdersCount(response.count ? response.count : 0);
                /*var t = dts?.filter((word) => word.customer?.includes(customerSearch)).
                    filter((word) => word.awb?.includes(awbSearch)).
                    filter((word) => word.alCompany?.includes(alcSearch)).
                    filter((word) => word.pol?.includes(polSearch)).
                    filter((word) => word.pod?.includes(podSearch)).
                    filter((word) => word.flightNumber?.includes(alnSearch)).
                    filter((word) => word.flightDate?.includes(fdSearch));*/
                setTracks1(dts);
            },
            failedCallback: response => {
            },
        });
    }

    const [customer, setCustomer] = useState("");
    const [customerid, setCustomerid] = useState("");
    const [pol, setPol] = useState("");
    const [polid, setPolid] = useState("");
    const [pod, setPod] = useState("");
    const [podid, setPodid] = useState("");
    const [st, setSt] = useState("");
    const [stInd, setStInd] = useState(0);
    const [airTrackId, setAirTrackId] = useState(0);
    const [awb, setAwb] = useState("");
    const [flightNumber, setFlightNumber] = useState("");
    const [flightDate, setFlightDate] = useState("");
    const [airlineCompany, setAirlineCompany] = useState("");
    const [transactionID, setTransactionID] = useState("");
    const [showEditTrack, setShowEditTrack] = useState(false);
    const [addTrackItem, setAddTrackItem] = useState(false);
    const [orgTracks, setOrgTracks] = useState(false);
    const [tracks, setTracks1] = useState(false);
    const setTracks = (ds) => {
        var dt = []
        if (!ds) {
            setTracks1(dt);
            return dt;
        }
        for (var j = 0; j < ds.length; j++) {
            var d = { ...ds[j] };
            if (d.airStatus?.events) {
                var events = d.airStatus?.events;
                events.sort(function (a, b) {
                    return (new Date(a.timeOfEvent)).getTime() - (new Date(b.timeOfEvent)).getTime();
                });
                d.airStatus.events = events
            }
            if (d.airStatus?.flights) {
                var places = [];
                var flights = d.airStatus?.flights;
                for (var k = 0; k < flights.length; k++) {
                    if (flights[k].origin) {
                        var origin = flights[k].origin;
                        var exists = false;
                        for (var k1 = 0; k1 < places.length; k1++) {
                            if (places[k1].name == origin && d.airStatus.originAndDestination.origin !== origin && d.airStatus.originAndDestination.destination !== origin) {
                                places[k1].num += 1;
                                exists = true;
                            }
                            if (d.airStatus.originAndDestination.origin === origin || d.airStatus.originAndDestination.destination === origin)
                                exists = true;
                        }
                        if (d.airStatus.originAndDestination.origin === origin || d.airStatus.originAndDestination.destination === origin)
                            exists = true;
                        if (!exists) {
                            places.push({ name: origin, num: 1 });
                        }
                    }
                    if (flights[k].destination) {
                        var origin = flights[k].destination;
                        var exists = false;
                        for (var k1 = 0; k1 < places.length; k1++) {
                            if (places[k1].name == origin && d.airStatus.originAndDestination.origin !== origin && d.airStatus.originAndDestination.destination !== origin) {
                                places[k1].num += 1;
                                exists = true;
                            }
                            if (d.airStatus.originAndDestination.origin === origin || d.airStatus.originAndDestination.destination === origin)
                                exists = true;
                        }
                        if (d.airStatus.originAndDestination.origin === origin || d.airStatus.originAndDestination.destination === origin)
                            exists = true;
                        if (!exists) {
                            places.push({ name: origin, num: 1 });
                        }
                    }
                }
                var notNeededExists = [];
                for (var k2 = 0; k2 < places.length; k2++) {
                    if (places[k2].num == 1) {
                        notNeededExists.push(places[k2].name)
                    }
                }
                var fls = [];
                for (var k3 = 0; k3 < flights.length; k3++) {
                    var exists = false;
                    for (var k4 = 0; k4 < notNeededExists.length; k4++) {
                        if (notNeededExists[k4] == flights[k3].origin || notNeededExists[k4] == flights[k3].destination) {
                            exists = true;
                        }
                    }
                    if (!exists) {
                        fls.push(flights[k3]);
                    }
                }
                d.airStatus.flights = fls;
            }
            dt.push(d);
        }
        setTracks1(dt);
        setOrgTracks(dt);
        return dt;

    }

    useEffect(() => {
        getAlltracks();
        window.setTimeout(function () {
            sendRequest(`/api/parcel/count?selectedLocation=NL&showUnassignedParcels=false&dropStatus=false`, REQUEST_ACTIONS.GET, '', {
                successCallback: response => { },
                failedCallback,
            });
        }, 5000);
    }, []);

    const deleteTrack = (id) => {
        if (confirm("Are you sure you want to move this track to history?") == true) {
            sendRequest('/api/track/hideAirTrack/' + id, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    getAlltracks();
                },
                failedCallback,
            });
        } else {
            //
        }

    }

    const editTrack1 = (track) => {
        //let obj = lclPolData.find(o => o.id === v);
        var airPolData2 = lclPolData.find(o => o.name === track.pol);
        var airPoaData2 = lclPoaData.find(o => o.name === track.pod);
        var cust = consigneeNameOptions.find(o => o.name === track.customer);
        var airPolData3 = [...airPolData];
        var airPoaData3 = [...airPoaData];
        var consigneeNameOptions3 = [...consigneeNameOptions];
        if (!airPolData2) {
            airPolData3.push({ id: 10000, name: track.pol })
        }
        if (!airPoaData2) {
            airPoaData3.push({ id: 10000, name: track.pod })
        }
        if (!cust) {
            consigneeNameOptions3.push({ id: 10000, name: track.customer })
        }
        var polid1 = airPolData3.find(o => o.name === track.pol);
        var podid1 = airPoaData3.find(o => o.name === track.pod);
        var custid1 = consigneeNameOptions3.find(o => o.name === track.customer);

        setPolid(polid1.id);
        setPodid(podid1.id);
        setCustomerid(custid1.id);
        setCustomer(custid1.name);
        setPol(polid1.name);
        setPod(podid1.name);

        /*track.polid = polid1.id;
        track.podid = podid1.id;
        track.custid = custid1.id;*/

        setAirPolData1(airPolData3);
        setAirPoaData1(airPoaData3);
        setConsigneeNameOptions1(consigneeNameOptions3);

        setAwb(track.awb);
        setAirlineCompany(track.alCompany);
        setFlightNumber(track.flightNumber);
        setFlightDate(track.flightDate);
        setAirTrackId(track.id);
        setAddTrackItem(true);
    }

    const [dialogErrorText, setDialogErrorText] = useState("");
    const types = [{ id: 1, name: "LCL" }, { id: 2, name: "FCL" }];
    const getType = (id) => {
        for (var i = 0; i < types.length; i++) {
            if (types[i].id == id) {
                return types[i].name;
            }
        }
        return "";
    }

    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDgpzrxExQg_AUW8h199onppaSz72SYlSY",
        libraries: ['places'],
    })

    function downloadURI() {
        var link = document.createElement("a");
        link.download = "AirTemplate.csv";
        link.href = "/AirTemplate.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const addTrack = () => {
        if (pol == "") {
            setDialogErrorText("Select proper POL option.");
            return;
        }
        if (pod == "") {
            setDialogErrorText("Select proper POD option.");
            return;
        }
        if (customer == "") {
            setDialogErrorText("Select proper customer option.");
            return;
        }
        if (awb != "" && airlineCompany != "" && customer != "" && pol != "" && pod != "" && flightNumber != "" && flightDate != "") {
            sendRequest('/api/track/addAirtrack/' + customer + '/' + awb + '/' + airlineCompany + '/' + flightNumber + '/' + pol + '/' + pod + "/" + flightDate + '/' + airTrackId, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    getAlltracks();
                    setAddTrackItem(false);
                },
                failedCallback,
            });
        } else {
            setDialogErrorText("Please fill in all the details.");
        }
    }
    const reRegister = (id) => {
        sendRequest('/api/track/reRegAirtrack/' + id, REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                getAlltracks();
            },
            failedCallback,
        });
    }
    const refreshAirTrack = () => {
        openLoadingDialog();
        sendRequest('/api/track/refreshAirTrack', REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                if (response.msg != "SUCCESS") {
                    setShowLoadingMsg("Contact support for: " + response.msg);
                } else {
                    getAlltracks();
                }
                setShowLoadingSuccess(true);
            },
            failedCallback: response => {
                setShowLoadingMsg("Air Tracker refresh failed: " + response)
                setShowLoadingSuccess(true);
            },
        });
    }
    const addAirTrack = () => {
        setCustomer("");
        setCustomerid(0);
        setPol("");
        setPolid(0);
        setPodid(0);
        setPod("");
        setAirlineCompany("");
        setFlightDate(0);
        setAwb("");
        setFlightNumber("");
        setDialogErrorText("");
        setAirTrackId(0);
        setAddTrackItem(true);
    }
    const closeAddTrackItem = () => {
        setCustomer("");
        setPol("");
        setPod("");
        setSt("");
        setStInd(0);
        setAwb("");
        setFlightNumber("");
        setTransactionID("");
        setDialogErrorText(null)
        setAddTrackItem(false);
    }
    const jsonToExcel = function (data, name, header) {
        //{ skipHeader: true }
        var ws = XLSX.utils.json_to_sheet(data, header);

        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "All");

        /* write workbook and force a download */
        XLSX.writeFile(wb, name + ".xlsx");
    }
    const exportCSV = () => {
        var data_main = [];
        var header = ["Customer", "AWB", "Airline Company", "POL", "POD", "Flight Number", "Flight Date", "Status", "ETA", "placeholder1", "placeholder2"]
        for (var i = 0; i < tracks.length; i++) {
            var data = {
                "Customer": tracks[i].customer.replace(/,/g, ''),
                "AWB": tracks[i].awb,
                "Airline Company": tracks[i].alCompany,
                "Flight Number": tracks[i].flightNumber,
                "Flight Date": formatDate(tracks[i].flightDate),
                "POL": tracks[i].pol,
                "POD": tracks[i].pod,
                "Status": tracks[i].airStatus?.lStatus ? tracks[i].airStatus?.lStatus : "-",
                "ETA": tracks[i].airStatus?.lETA ? formatDate(tracks[i].airStatus?.lETA) : "-",
                "placeholder1": "-",
                "placeholder12": "-",
            };
            data_main.push(data);
        }
        jsonToExcel(data_main, "AirTracks", header);
    }
    const importCSV = () => {
        var fileInput = document.getElementById('file');
        var element = document.getElementById("file_container");
        if (fileInput) {
            element.removeChild(fileInput);
        }
        var x = document.createElement("INPUT");
        x.setAttribute("type", "file");
        x.setAttribute("id", 'file');
        x.style.display = "none";
        element.appendChild(x);

        document.getElementById("file").addEventListener("change", changeHandler, true);
        document.getElementById('file').click();
    }

    const changeHandler = (event) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            var keys = data[0];
            var XL_row_object = []
            for (var i = 1; i < data.length; i++) {
                var t = {}
                keys.forEach(function (key, index) {
                    if (data[i][index])
                        t[key] = data[i][index];
                })
                //console.log(t);
                XL_row_object.push(t);
            }
            var customersNotAdded = [];
            //console.log(XL_row_object);
            var index = 0;
            var totalIndex = XL_row_object.length;
            for (var j = 0; j < XL_row_object.length; j++) {
                sendRequest('/api/track/addAirtrack/' + XL_row_object[j].Customer + '/' + XL_row_object[j].AWB + '/' + XL_row_object[j].AirlineCompany + '/' + XL_row_object[j].FlightNumber + '/' + XL_row_object[j].POL + '/' + XL_row_object[j].POD + "/" + XL_row_object[j].FlightDate + '/0', REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        index++;
                        if (index == totalIndex) {
                            alert("Import sucessful");
                            getAlltracks();
                        }
                    },
                    failedCallback: response => {
                        alert("This customer was not imported: " + XL_row_object[j].Customer);
                    },
                });
            }
        };
        var file = event.target.files[0];
        if (file) {
            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        }
    };
    const formatDate = (date) => {
        if (!date)
            return "-";
        var str = [];
        if (date.includes("T")) {
            str = date.split("T")[1].split(":");
            var time = str[0] + ":" + str[1];

            const dt = new Date(date);
            const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
            return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)} ${time}`;
        } else {
            return date;
        }
    }

    const [customerSearch, setCustomerSearch] = useState("");
    const [awbSearch, setAwbSearch] = useState("");
    const [alcSearch, setAlcSearch] = useState("");
    const [polSearch, setPolSearch] = useState("");
    const [podSearch, setPodSearch] = useState("");
    const [alnSearch, setAlnSearch] = useState("");
    const [fdSearch, setFdSearch] = useState("");
    const setSearchBlur = (v, c, d) => {
        v(d);
        getAlltracks();
        var cs = c == 0 ? d : customerSearch;
        var as1 = c == 1 ? d : awbSearch;
        var pols = c == 3 ? d : polSearch;
        var pods = c == 4 ? d : podSearch;
        var alc = c == 2 ? d : alcSearch;
        var aln = c == 5 ? d : alnSearch;
        var fd = c == 6 ? d : fdSearch;
        getAlltracksBySearch(pageNumber, pageSize, cs, as1, pols, pods, alc, aln, fd);
        /*if (orgTracks) {
            var t = [];
            t = orgTracks?.filter((word) => word.customer?.includes(c == 0 ? d : customerSearch)).
                filter((word) => word.awb?.includes(c == 1 ? d : awbSearch)).
                filter((word) => word.alCompany?.includes(c == 2 ? d : alcSearch)).
                filter((word) => word.pol?.includes(c == 3 ? d : polSearch)).
                filter((word) => word.pod?.includes(c == 4 ? d : podSearch)).
                filter((word) => word.flightNumber?.includes(c == 5 ? d : alnSearch)).
                filter((word) => word.flightDate?.includes(c == 6 ? d : fdSearch));
            setTracks1(t);
        }*/
    }

    const dataDiv = {
        border: "1px solid grey",
        padding: "5px",
        borderRadius: "5px"
    };
    const mapSpan = {
        background: "blue",
        padding: "5px",
        borderRadius: "5px"
    };
    const dataData = {
        color: "black",
        padding: "5px",
        borderRadius: "5px"
    };
    const mapDiv = {
        width: "100%",
        height: "300px"
    };
    const getPlaneSymbol = () => {
        return {
            path: 'M362.985,430.724l-10.248,51.234l62.332,57.969l-3.293,26.145 l-71.345-23.599l-2.001,13.069l-2.057-13.529l-71.278,22.928l-5.762-23.984l64.097-59.271l-8.913-51.359l0.858-114.43 l-21.945-11.338l-189.358,88.76l-1.18-32.262l213.344-180.08l0.875-107.436l7.973-32.005l7.642-12.054l7.377-3.958l9.238,3.65 l6.367,14.925l7.369,30.363v106.375l211.592,182.082l-1.496,32.247l-188.479-90.61l-21.616,10.087l-0.094,115.684',
            scale: 0.05,
            strokeOpacity: 1,
            fillOpacity: 1,
            fillColor: 'red',
            color: 'red',
            stroke: true,
            weight: 2,
            anchor: new window.google.maps.Point(400, 400),
            rotation: rot,
        };
    }

    const lineSymbol = {
        path: 'M 0,-1 0,1',
        scale: 2.5,
        strokeColor: 'blue',
        strokeOpacity: 1,
    }

    const emptySymbol = {
        path: 'M 0,0 0,0',
        scale: 0,
        strokeColor: 'blue',
        strokeOpacity: 0,
    }

    const [showAirDetails, setShowAirDetails] = useState(false);
    const [selectedAirDetails, setSelectedAirDetails] = useState({});
    const closeAirDetails = () => {
        setShowAirDetails(false);
        setSelectedAirDetails(null);
    }
    const showAirDetailsDialog = (t) => {
        setSelectedAirDetails(t);
        setShowAirDetails(true);
    }
    const getWeight = (t) => {
        if (t.airStatus?.quantity?.weight) {
            return t.airStatus?.quantity?.weight.unit + ", " + t.airStatus?.quantity?.weight.amount;
        }
        return "-";
    }


    const [showLoadingDialog, setShowLoadingDialog] = useState(false);
    const [showLoadingSuccess, setShowLoadingSuccess] = useState({});
    const [showLoadingMsg, setShowLoadingMsg] = useState({});
    const closeLoadingDialog = () => {
        if (showLoadingSuccess)
            setShowLoadingDialog(false);
    }
    const openLoadingDialog = (t) => {
        setShowLoadingDialog(true);
        setShowLoadingSuccess(false);
        setShowLoadingMsg("");
    }
  return (
      <Grid container item id="file_container">
          {showLoadingDialog && (
              <Dialog
                  open={showLoadingDialog}
                  onHandleCancel={closeLoadingDialog}
                  title={"Air Tracker Details"}
                  minWidth='500px'>
                  <Grid container item xs={12} justifyContent='center' style={{ }}>
                  <Grid container item xs={12} justifyContent='center' style={{ fontSize: "2vw", margin:"25px" }}>
                      {showLoadingSuccess?"Loading Completed.":"Loading..."}
                  </Grid>
                  <Grid container item xs={12} justifyContent='center' style={{ fontSize: "1vw", margin: "25px" }}>
                      {showLoadingMsg}
                  </Grid>
                  </Grid>
              </Dialog>
          )}
          {showAirDetails&& (
              <Dialog
                  open={showAirDetails}
                  onHandleCancel={closeAirDetails}
                  title={"Air Tracker Details"}
                  maxWidth='lg'>
                  <Grid container item xs={12} justifyContent='flex-end' style={{fontSize:"1vw"}}>
                      {selectedAirDetails.airStatus && <>
                          <Grid item container lg={12}>
                              <Grid item container lg={6} >
                                  <Grid item container lg={6} style={{ border: "1px solid grey" }}>AWB</Grid>
                                  <Grid item container lg={6} style={{ border: "1px solid grey" }}>{selectedAirDetails.awb}</Grid>
                              </Grid>
                              <Grid item container >
                                  <Grid item container lg={6}>
                                      <Grid item container lg={6} style={{ border: "1px solid grey" }}>POL</Grid>
                                      <Grid item container lg={6} style={{ border: "1px solid grey" }}>{selectedAirDetails.airStatus?.originAndDestination ? selectedAirDetails.airStatus.originAndDestination.origin : "-"}</Grid>
                                  </Grid>
                              </Grid>
                              <Grid item container >
                                  <Grid item container lg={6} >
                                      <Grid item container lg={6} style={{ border: "1px solid grey" }}>POD</Grid>
                                      <Grid item container lg={6} style={{ border: "1px solid grey" }}>{selectedAirDetails.airStatus?.originAndDestination ? selectedAirDetails.airStatus.originAndDestination.destination : "-"}</Grid>
                                  </Grid>
                              </Grid>
                              <Grid item container >
                                  <Grid item container lg={6} >
                                      <Grid item container lg={6} style={{ border: "1px solid grey" }}>Pieces</Grid>
                                      <Grid item container lg={6} style={{ border: "1px solid grey" }}>{selectedAirDetails.airStatus?.totalNumberOfPieces ? selectedAirDetails.airStatus?.totalNumberOfPieces : "-"}</Grid>
                                  </Grid>
                              </Grid>
                              <Grid item container >
                                  <Grid item container lg={6} >
                                      <Grid item container lg={6} style={{ border: "1px solid grey" }}>Weight</Grid>
                                      <Grid item container lg={6} style={{ border: "1px solid grey" }}>{getWeight(selectedAirDetails)}</Grid>
                                  </Grid>
                              </Grid>
                          </Grid>
                          {selectedAirDetails.airStatus?.flights && <Grid item container lg={12} style={{marginTop:"25px"}}>
                              <Grid item container flexWrap="nowrap" style={{fontWeight:"bold"}} >
                                  <Grid item container style={{ border: "1px solid grey" }}>Flight Number</Grid>
                                  <Grid item container style={{ border: "1px solid grey" }}>Origin</Grid>
                                  <Grid item container style={{ border: "1px solid grey" }}>Desitination</Grid>
                                  <Grid item container style={{ border: "1px solid grey" }}>Scheduled Departure</Grid>
                                  <Grid item container style={{ border: "1px solid grey" }}>Scheduled Arival</Grid>
                                  <Grid item container style={{ border: "1px solid grey" }}>ETA</Grid>
                                  <Grid item container style={{ border: "1px solid grey" }}>Actual Departure</Grid>
                                  <Grid item container style={{ border: "1px solid grey" }}>Actual Arival</Grid>
                                  {/*<Grid item container style={{ border: "1px solid grey" }}>Content</Grid>*/}
                              </Grid>
                              {selectedAirDetails.airStatus?.flights.map((t,index) => (
                                  <Grid item container flexWrap="nowrap" style={{ background: index % 2 == 1 ? "white" : "#eee" }}>
                                      <Grid item container style={{ border: "1px solid grey" }}>{t.flight}</Grid>
                                      <Grid item container style={{ border: "1px solid grey" }}>{t.origin}</Grid>
                                      <Grid item container style={{ border: "1px solid grey" }}>{t.destination}</Grid>
                                      <Grid item container style={{ border: "1px solid grey" }}>{t.tsd ? formatDate(t.tsd) : (t.std ? formatDate(t.std):"--")}</Grid>
                                      <Grid item container style={{ border: "1px solid grey" }}>{t.tsa ? formatDate(t.tsa) : (t.sta ? formatDate(t.sta) : "--")}</Grid>
                                      <Grid item container style={{ border: "1px solid grey" }}>{t.eta ? formatDate(t.eta) : "--"}</Grid>
                                      <Grid item container style={{ border: "1px solid grey" }}>{t.atd ? formatDate(t.atd) : "--"}</Grid>
                                      <Grid item container style={{ border: "1px solid grey" }}>{t.ata ? formatDate(t.ata) : "--"}</Grid>
                                      {/*<Grid item container style={{ border: "1px solid grey" }}>{t.numberOfPieces} pcs, {t.weight.unit} {t.weight.amount}</Grid>*/}
                                  </Grid>
                              ))}
                          </Grid>}
                          {selectedAirDetails.airStatus?.events && <Grid item container lg={12} style={{ marginTop: "25px", overflow:"auto", maxHeight:"300px" }}>
                              <Grid item container flexWrap="nowrap" style={{ fontWeight: "bold" }} >
                                  <Grid item container style={{ border: "1px solid grey" }}>Time</Grid>
                                  <Grid item container style={{ border: "1px solid grey" }}>Status</Grid>
                                  <Grid item container style={{ border: "1px solid grey" }}>Flight Number</Grid>
                                  {/*<Grid lg={1.2} item container style={{ border: "1px solid grey" }}>Origin</Grid>
                                  <Grid lg={1.2} item container style={{ border: "1px solid grey" }}>Destination</Grid>
                                  <Grid item container style={{ border: "1px solid grey" }}>Departure</Grid>
                                  <Grid item container style={{ border: "1px solid grey" }}>Arival</Grid>*/}
                                  <Grid item container style={{ border: "1px solid grey" }}>Content</Grid>
                              </Grid>
                              {selectedAirDetails.airStatus?.events.map((t,index) => (
                                  <Grid item container flexWrap="nowrap" style={{background:index%2==1?"white":"#eee"}}>
                                      <Grid item container style={{ border: "1px solid grey" }}>{formatDate(t.timeOfEvent)}</Grid>
                                      <Grid item container style={{ border: "1px solid grey" }}>{t.type}</Grid>
                                      <Grid item container style={{ border: "1px solid grey" }}>{t.flight}</Grid>
                                     {/* <Grid lg={1.2} item container style={{ border: "1px solid grey" }}>{t.origin}</Grid>
                                      <Grid lg={1.2} item container style={{ border: "1px solid grey" }}>{t.destination}</Grid>
                                      <Grid item container style={{ border: "1px solid grey" }}>{t.timeOfScheduledDeparture ? formatDate(t.timeOfScheduledDeparture) : (t.dateOfScheduledDeparture ? t.dateOfScheduledDeparture :"-")}</Grid>
                                      <Grid item container style={{ border: "1px solid grey" }}>{t.timeOfScheduledArrival ? formatDate(t.timeOfScheduledArrival) : (t.dateOfScheduledArrival ? t.dateOfScheduledArrival :"-")}</Grid>*/}
                                      <Grid item container style={{ border: "1px solid grey" }}>{t.numberOfPieces} pcs, {t.weight?.unit} {t.weight?.amount}</Grid>
                                  </Grid>
                              ))}
                          </Grid>}
                      </>}
                  </Grid>
              </Dialog>
          )}
          {addTrackItem && (
              <Dialog
                  open={addTrackItem}
                  onHandleCancel={closeAddTrackItem}
                  title={airTrackId?"Save Air Tracker":"Add Air Tracker" }
                  maxWidth='lg'>
                  <Grid container item xs={12} justifyContent='flex-end'>
                      <Grid container item xs={12} justifyContent='flex-end'>
                          <Grid item container lg={6} style={{ padding: "5px" }}>
                              <input style={{ width: "100%", padding: "5px", border:customer?"1px lightgrey solid":"1px solid red", borderRadius:"4px" }} value={customer} onChange={(e) => { setCustomer(e.target.value) }} placeholder="Customer *" list="opts" />
                              <datalist id="opts">
                                  {consigneeNameOptions.map((v, index) => (<option>{v.name}</option>))}
                              </datalist>
                          </Grid>
                          <TextField
                              lg={6}
                              required
                              style={{ padding: "5px" }}
                              type="Text"
                              label='AWB'
                              value={awb}
                              onChange={event => setAwb(event.target.value)}
                          />
                          <Grid item container lg={6} style={{ padding: "5px" }}><Autocomplete
                              field='name'
                              required
                              label='POL'
                              lg={12}
                              value={polid}
                              onChange={(e, v) => {
                                  if (v) {
                                      let obj = airPolData.find(o => o.id === v);
                                      setPolid(obj.id);
                                      setPol(obj.name);
                                  }
                              }}
                              options={airPolData}
                          /></Grid>
                          <Grid item container lg={6} style={{ padding: "5px" }}><Autocomplete
                              field='name'
                              required
                              label='POD'
                              lg={12}
                              value={podid}
                              onChange={(e, v) => {
                                  if (v) {
                                      let obj = airPoaData.find(o => o.id === v);
                                      setPodid(obj.id);
                                      setPod(obj.name);
                                  }
                              }}
                              options={airPoaData}
                          /></Grid>
                          <TextField
                              lg={6}
                              style={{ padding: "5px" }}
                              required
                              type="Text"
                              label='Airline Company'
                              value={airlineCompany}
                              onChange={event => setAirlineCompany(event.target.value)}
                          />
                          <TextField
                              lg={6}
                              style={{ padding: "5px" }}
                              required
                              type="Text"
                              label='Flight Number'
                              value={flightNumber}
                              onChange={event => setFlightNumber(event.target.value)}
                          />

                          <Grid item container lg={6} style={{ padding: "5px" }}><DateTimePicker
                              label={'Flight Date'}
                              required
                              xs={12}
                              sm={12}
                              md={12}
                              value={flightDate}
                              isShowTime={true}
                              format="dd/MM/yyyy"
                              onChange={date => setFlightDate(date?.toISOString())}
                          /></Grid>

                      </Grid>
                      {dialogErrorText && <Grid justifyContent="center" item container style={{ fontSize: "20px", color: "red", margin: "10px" }}>
                          {dialogErrorText}
                      </Grid>}
                      <Grid container item xs={12} justifyContent='flex-end'>
                          {<Button onClick={addTrack} variant='contained' sx={{ m: 3 }}>
                              {airTrackId ? "Save" : "Add"}
                          </Button>}
                      </Grid>
                  </Grid>
              </Dialog>
          )}
          <Grid container item xs={12} flexWrap="nowrap" justifyContent="space-between" alignContent="flex-start" style={{ maxHeight: "50px" }} >
              <Grid container justifyContent="flex-start" item xs={12} alignContent="flex-start" style={{ maxHeight: "50px", maxWidth:"40%" }} >
                  {<Button onClick={() => { props.onHandleSea() }} variant='contained' sx={{ fontSize: "1vw", background: "#bdbdbd", m: 1, maxHeight: "30px" }}>
                      {`Sea`}
                  </Button>}
                  {<Button onClick={() => { props.onHandleSeaHist() }} variant='contained' sx={{ fontSize: "1vw", background: "#bdbdbd", m: 1, maxHeight: "30px" }}>
                      {`Sea History`}
                  </Button>}
                  {<Button variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                      {`Air`}
                  </Button>}
                  {<Button onClick={() => { props.onHandleAirHist() }} variant='contained' sx={{ fontSize: "1vw", background: "#bdbdbd", m: 1, maxHeight: "30px" }}>
                      {`Air History`}
                  </Button>}
              </Grid>
              <Grid container justifyContent="flex-end" item xs={12} alignContent="flex-start" style={{ maxHeight: "50px" }} >
                  {props.fullName == "Admin" && <Button onClick={refreshAirTrack} variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                      {`Refresh Air Tracks`}
                  </Button>}
                  {<Button onClick={addAirTrack} variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                      {`Add Track`}
                  </Button>}
                  {<Button onClick={exportCSV} variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                      {`Export CSV`}
                  </Button>}
                  {<Button onClick={importCSV} variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                      {`Import CSV`}
                  </Button>}
                  {<Button onClick={downloadURI} variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                      {`Template`}
                  </Button>}
                  {/*{<Button onClick={refreshTrack} variant='contained' sx={{ m: 3 }}>
                      {`Load Tracks`}
                  </Button>}*/}

              </Grid>
          </Grid>
          <Grid container item xs={12} style={{ height: "calc(100% - 60px)", fontSize: "0.8vw" }}>
              <Grid item container flexDirection="row-reverse" alignContent="flex-start" style={{ maxHeight: "calc(100vh - 150px)", overflowY: "hidden", width: "98vw" }}>
                  {<Grid style={{ fontWeight: "bold", height: "52px", overflowY:"scroll" }} alignContent="flex-start" container item xs={12}>
                      <Grid alignItems="center" style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          Actions
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>Customer</Typography>}
                              value={customerSearch}
                              onChange={event => setSearchBlur(setCustomerSearch, 0, event.target.value)}
                          />
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>AWB</Typography>}
                              value={awbSearch}
                              onChange={event => setSearchBlur(setAwbSearch, 1, event.target.value)}
                          />
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>Airline Company</Typography>}
                              value={alcSearch}
                              onChange={event => setSearchBlur(setAlcSearch, 2, event.target.value)}
                          />
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>POL</Typography>}
                              value={polSearch}
                              onChange={event => setSearchBlur(setPolSearch, 3, event.target.value)}
                          />
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>POD</Typography>}
                              value={podSearch}
                              onChange={event => setSearchBlur(setPodSearch, 4, event.target.value)}
                          />
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>Flight Number</Typography>}
                              value={alnSearch}
                              onChange={event => setSearchBlur(setAlnSearch, 5, event.target.value)}
                          />
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>Flight Date</Typography>}
                              value={fdSearch}
                              onChange={event => setSearchBlur(setFdSearch, 6, event.target.value)}
                          />
                      </Grid>
                      <Grid alignItems="center" style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          Status
                      </Grid>
                      <Grid alignItems="center" style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          ETA
                      </Grid>
                      {<Grid alignItems="center" style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          ---
                      </Grid>}
                      <Grid alignItems="center" style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          ---
                      </Grid>
                  </Grid>}
                  <Grid xs={12} alignContent="flex-start" item container style={{
                      height: "Calc(100% - 100px)", overflowY: "scroll"}}>
                      {tracks.length > 0 && <>{tracks.map((t, index) => <Grid key={index} container item xs={12}>
                            <Grid item container style={{ height: "40px", border: "1px solid Grey", padding: "5px", background: index % 2 == 1 ? "#9ac3eb4a" : "" }} xs={1}  >
                                {<Grid item container lg={12}>
                                    <Tooltip title={"Move to History"}><IconButton style={{ width: '20px !important', height: '20px !important' }} size='small' onClick={() => { deleteTrack(t.id) }}>
                                      <ArrowRightIcon style={{ width: '20px !important', height: '20px !important' }} />
                                    </IconButton></Tooltip>
                                    <Tooltip title={"Edit row."}><IconButton style={{ width: '20px !important', height: '20px !important' }} size='small' onClick={() => { editTrack1(t) }}>
                                        <EditIcon style={{ width: '20px !important', height: '20px !important' }} />
                                    </IconButton></Tooltip>
                                    {props.fullName == "Admin" && <>
                                        <Tooltip title={"Re-Register"}><IconButton style={{ width: '20px !important', height: '20px !important' }} size='small' onClick={() => { reRegister(t.id) }}>
                                            <BackIcon style={{ width: '20px !important', height: '20px !important' }} />
                                        </IconButton></Tooltip>
                                    </>}
                                </Grid>}
                          </Grid>
                          <Grid whiteSpace="noWrap" alignItems="center" title={t.customer} style={{ overflow: "hidden", height: "40px", border: "1px solid Grey", padding: "5px", background: index % 2 == 1 ? "#9ac3eb4a" : "" }} item container xs={1}>
                              {t.customer}
                          </Grid>
                          <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px", background: index % 2 == 1 ? "#9ac3eb4a" : "" }} item container xs={1}>
                              {t.awb}
                          </Grid>
                          <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px", background: index % 2 == 1 ? "#9ac3eb4a" : "" }} item container xs={1}>
                              {t.alCompany}
                          </Grid>
                          <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px", background: index % 2 == 1 ? "#9ac3eb4a" : "" }} item container xs={1}>
                              {t.pol}
                          </Grid>
                          <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px", background: index % 2 == 1 ? "#9ac3eb4a" : "" }} item container xs={1}>
                              {t.pod}
                          </Grid>
                          <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px", background: index % 2 == 1 ? "#9ac3eb4a" : "" }} item container xs={1}>
                              {t.flightNumber}
                          </Grid>
                          <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px", background: index % 2 == 1 ? "#9ac3eb4a" : "" }} item container xs={1}>
                              {formatDate(t.flightDate)}
                          </Grid>
                          <Grid onClick={() => { showAirDetailsDialog(t) }} alignItems="center" style={{ textDecoration:"underline", cursor:"pointer", height: "40px", border: "1px solid Grey", padding: "5px", background: index % 2 == 1 ? "#9ac3eb4a" : "" }} item container xs={1}>
                              {t.airStatus?.lStatus ? t.airStatus?.lStatus : "--------------"}
                          </Grid>

                          <Grid onClick={() => { showAirDetailsDialog(t) }} alignItems="center" style={{ textDecoration: "underline", cursor: "pointer", height: "40px", border: "1px solid Grey", padding: "5px", background: index % 2 == 1 ? "#9ac3eb4a" : "" }} item container xs={1}>
                              {t.airStatus?.lETA ? formatDate(t.airStatus?.lETA) : "--------------"}
                          </Grid>
                          <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px", background: index % 2 == 1 ? "#9ac3eb4a" : "" }} item container xs={1}>--------------</Grid>

                          <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px", background: index % 2 == 1 ? "#9ac3eb4a" : "" }} item container xs={1}>--------------</Grid>

                      </Grid>)}</>}
                  </Grid>
                  <TablePagination
                      rowsPerPageOptions={[50, 100, 500, 1000]}
                      component='div'
                      count={ordersCount}
                      rowsPerPage={pageSize}
                      page={pageNumber}
                      onPageChange={onPageHandleChange}
                      onRowsPerPageChange={onRowsPerPageHandleChange}
                  />
              </Grid>
          </Grid>
      </Grid>
  );
};

export default AirTracker;

import { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TableCell, Grid, Checkbox, TableRow, Button } from '@mui/material';
import { TextField } from 'style-guide';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import './Manifest.scss';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { onManifestUsersChange } from 'reducers/parcel-reducers/manifestsSlicer';
import { Dialog } from 'style-guide';
import OrderForm from 'components/Parcel/OrderForm';
import AddServiceProvider from 'serviceProvider/AddServiceProvider';
const ManifestRow = ({ order, removeInSelectedForExport, addInSelectedForExport, isInValidList, removeInManifestList, addInValidList, addInManifestList, loginUser }) => {
    const dispatch = useDispatch();
    
  const { 
    userLocalNumber,  
    userAbroadNumber,  
    consigneeId,
    shipperId,
    countryOfOriginId, originState,
    shippingModeId,
    subModeId,
    userLocalId,
    userAbroadId,
    dueDate,
    lastUpdateDate,
    ilReminderDate,
    reminderDate,
    selectedLocationIndex,
    search,
    startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.parcelTableFilter);
  const orderTableFilter = { 
    userLocalNumber,  
    userAbroadNumber,  
    consigneeId,
    shipperId,
    countryOfOriginId, originState,
    shippingModeId,
    subModeId,
    userLocalId,
    userAbroadId,
    dueDate,
    lastUpdateDate,
    ilReminderDate,
    reminderDate,
    selectedLocationIndex,
    search,
    startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv, };
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [openParcelModal, setOpenParcelModal] = useState(false);
    const onParcelModalHandleOpen = () => setOpenParcelModal(true);
    const onParcelModalHandleCancel = () => setOpenParcelModal(false);
    const [openConsigneeModal, setOpenConsigneeModal] = useState(false);
    const onHandleOpenConsigneeModal = () => setOpenConsigneeModal(true);
    const onHandleCancelConsigneeModal = () => setOpenConsigneeModal(false);

    const [data, setData] = useState({...order});
    useEffect(() => {
        setData(prev => ({ ...prev, bValidated: false }));
    }, []);

    function formatDate(date) {
        if (!date) {
            return "-"
        }
        let p = new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            //second: '2-digit',
            hour12: false,
            timeZone: 'UTC',
            //timeZoneName: "longOffset",
        }).format(date)
        return p
        /*formatToParts(date).reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
    }, {});*/

        return `${p.day}/${p.month}/${p.year}, ${p.hour}:${p.minute}:${p.second} ${p.dayPeriod} `;
    }
    ;
    const getWeight = (parcelDetails) => {
        var tWeight = 0;
        for (var i = 0; i < parcelDetails.length; i++) {
            var pDetail = parcelDetails[i];
            tWeight = tWeight + (Number(pDetail.weightPerPiece ? pDetail.weightPerPiece : 0) * Number(pDetail.numberOfPieces ? pDetail.numberOfPieces:0)) 
        }
        return tWeight;
    }
   const keyPressed = (e, data) => {
        if (e.keyCode === 13) {
            validateToken(data)
        }
    }

    const validateToken = (data) => {
        const consigneeId = data?.consignee?.id;
        sendRequest('/api/parcel/manifestToken/'+consigneeId, REQUEST_ACTIONS.GET,'' , {
        successCallback: response => {
            const token = response.token;
            dispatch(onManifestUsersChange(response));
            if (token == data.newLocker) {
                //setData(prev => ({ ...prev, bValidated: true }))
                addInValidList(data);
                data.bValidated = true;
                setData(prev => ({ ...prev, bValidated: true }))
            } else {
                data.bValidated = false;
                setData(prev => ({ ...prev, bValidated: false }))
            }
        },
        failedCallback,
        });
        //need to validate token here
        //if (users.find(u => u.id === data.userId)?.token === data.newLocker) {
        
        //dispatch(onParcelListChange(event));
    }
    const getColor = (data) => {
        return data?.bAdded ? '#057c0587' : (data?.bValidated ? '#0000001a' : '#fff')
    }
    const addRemoveShInManifest = (event) => {
        if (isInValidList(event)) {
            if (event.bAdded) {
                //setData(prev => ({ ...prev, bValidated: true }))
                removeInManifestList(event);
                event.bAdded = false;
                setData(prev => ({ ...prev, bAdded: false }))
            } else {
                event.bAdded = true;
                addInManifestList(event);
                setData(prev => ({ ...prev, bAdded: true }))
            }
           // dispatch(onParcelListChange(event));
        } else {
            //dispatch(snackbarToggle({ type: 'error', message: 'Please Validate First.' }));
        }
    }
    return (
        <TableRow key={data.id || uuidv4()} style={{ backgroundColor: getColor(data) }}>
            <TableCell style={{ maxWidth: '1vw', width: '1vw' }}> <Checkbox className='checkbox' onChange={(event) => {
                setData(prev => ({ ...prev, bChecked: event.target.checked }))
                if (!event.target.checked) {
                    removeInSelectedForExport(data);
                } else {
                    addInSelectedForExport(data);
                }
            }} /> </TableCell>
            <TableCell style={{maxWidth:'10vw',width:'10vw'}}> {formatDate(new Date(order.date))}</TableCell>
            <TableCell style={{ textDecoration: "underline", cursor: "pointer", maxWidth: '7vw', width: '7vw' }} onClick={() => onParcelModalHandleOpen()} > {order?.jobNumber} </TableCell>
            {openParcelModal && (
            <Dialog
              open={openParcelModal}
              onHandleCancel={onParcelModalHandleCancel}
              title={'Parcel information - '+order?.jobNumber}
              maxWidth='md'>
              <OrderForm loginUser={loginUser} onHandleCancel={onParcelModalHandleCancel} order={order} orderTableFilter={orderTableFilter} pageName='manifest'/>
            </Dialog>
          )} 
            <TableCell style={{maxWidth:'18vw',width:'18vw'}}>
                <Grid className='flex-row'>
                    <TextField
                        lg={6}
                        onChange={event => setData(prev => ({ ...prev, newLocker: event.target.value }))}
                        readOnly={data.bValidated}
                        value={data.newLocker}
                        onKeyDown={e => keyPressed(e, data)}
                        className={'search-input'}
                    />
                    {!data.bValidated ? <Button variant='contained' sx={{ m: "2px !important", lineHeight: "1 !important", color: '#fff !important' }} onClick={event => validateToken(data)}>
                        Validate
                    </Button> : <Button disabled={data.bValidated} variant='contained' sx={{ m: "2px !important", lineHeight: "1 !important", color: '#fff !important' }}>
                        Validate
                    </Button>}
                </Grid>
            </TableCell>
            {/*<TableCell> {users.find(u => u.id === data.userId)?.token} </TableCell>*/}
            <TableCell style={{ textDecoration: "underline", cursor: "pointer" }} onClick={onHandleOpenConsigneeModal} > {order?.consignee?.companyName}{order?.consignee?.phone?'-'+order?.consignee?.phone:''}</TableCell>
             {openConsigneeModal && (
                <Dialog
                    maxWidth='md'
                    open={openConsigneeModal}
                    title={'Consignee Card'}
                    onHandleCancel={onHandleCancelConsigneeModal}>
                    <AddServiceProvider data={order?.consignee}  onHandleCancel={onHandleCancelConsigneeModal} id={null} readOnly={true} pageName="manifest"/>
                </Dialog>
                )}
            <TableCell style={{ maxWidth: '4vw', width: '4vw' }}> {getWeight(order?.parcelDetails)} kg</TableCell>
            <TableCell style={{ maxWidth: '1vw', width: '1vw' }}>
                <Grid className='flex-row' justifyContent="flex-end">
                    {!data.bAdded ? <Button disabled={!data.bValidated} variant='contained' sx={{ m: "2px !important", lineHeight: "1 !important" }} onClick={event => addRemoveShInManifest(data)}>
                        +
                    </Button> : <Button disabled={!data.bValidated} variant='contained' sx={{ m: "2px !important", lineHeight: "1 !important" }} onClick={event => addRemoveShInManifest(data)}>
                        -
                    </Button>}
                   
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default ManifestRow;

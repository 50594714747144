/* eslint-disable react/prop-types */
import { Box, Container } from "@mui/material";
import React, { Fragment } from "react";
import CustomerHeader from "../common/header/CustomerHeader";
import CustomerSidebar from "../common/sidebar/CustomerSidebar";
import './CustomerConatiner.scss'
import worldMap from "../../../resources/world.png"

const CustomerConatiner = ({ loginUser, logoutUser, children, jobSearch, setJobSearch, mapDisplay, setMapDisplay }) => {
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return <Fragment>
        <section className="customer-container">
            {!isMobile() && <CustomerSidebar />}
            <Box className="customer-content" style={{ height: "100vh", backgroundImage: `url(${worldMap})`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }}>
                <CustomerHeader loginUser={loginUser.fullName} logoutUser={logoutUser} jobSearch={jobSearch} setJobSearch={setJobSearch} mapDisplay={mapDisplay} setMapDisplay={setMapDisplay} />
                <Container maxWidth={false} style={{padding: 0}}>
                    {children}
                </Container>
            </Box>
        </section>
    </Fragment>;
}

export default CustomerConatiner;
import { useState, useEffect, useCallback, Fragment } from 'react';

import { Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Button, CircularProgress, Grid, Typography, Tab, Tabs, TableCell } from '@mui/material';
import { Dialog } from 'style-guide';
import OrderTable from './OrderTable/OrderTable';
import OrderForm from './OrderForm';
import OrderSearch from './OrderTable/OrderSearch';
import { styled } from '@mui/system';

import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { getOrders, onParcelsCountChange } from 'reducers/parcel-reducers/ordersSlicer';

import { useDispatch, useSelector } from 'react-redux';
import {
    onUserNLDataChange,
    onUserUSDataChange,
    onUserILDataChange,
    onUserILNamesChange,
    onUserNLNamesChange,
    onUserUSNamesChange,
    onCustomerNamesChange,
    onSubModeDataChange,
    onShippingModeDataChange,
    onCountryOfOriginDataChange,
    onAirPoaDataChange,
    onAirPolDataChange,
    onLclPoaDataChange,
    onLclPolDataChange,
    onIncotermsDataChange,
    onFileCodesChange,
} from 'reducers/parcel-reducers/predefinedDataSlicer';
import { onShipperNameOptionsChange, onShippersChange } from 'reducers/shippersSlicer';
import { onCustomerNameOptionsChange, onCustomersChange } from 'reducers/customersSlicer';
import { onConsigneeNameOptionsChange, onConsigneesChange } from 'reducers/consigneesSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { resetOrderTableFilterSlice, onSelectedLocationIndexChange, onSelectedTabIndexNameChange, onUserAbroadIdChange } from 'reducers/parcel-reducers/orderTableFilterSlicer';
import { onPageChange, onUnConfirmedParcelsCountChange } from 'reducers/parcel-reducers/ordersSlicer';
import { onUnConfirmedOrdersCountChange } from 'reducers/order-reducers/ordersSlicer';

export const orderRowBgColor = {
    'background-color': '#057c056b',
}

const Orders = ({ loginUser, redboxParcels, agentOrders, setIsUsTime }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { unConfirmedParcelsCount } = useSelector(state => state.parcels);
    const { subModeData, shippingModeData, countryOfOriginData, userILData, userNLData, userUSData } = useSelector(
        state => state.parcelPredefinedData,
    );
    const onHandleSelectedLocationIndexChange = (event, newValue) => {
        dispatch(resetOrderTableFilterSlice(null));
        dispatch(onUserAbroadIdChange(null));
        if (newValue === 'US') {
            setIsUsTime(1)
        } else {
            setIsUsTime(0)
        }
        dispatch(onPageChange(0));
        dispatch(onSelectedLocationIndexChange(newValue));
    }

    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, selectedTabIndexName } = useSelector(state => state.parcelTableFilter);
    const orderTableFilter = {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, selectedTabIndexName
    };
    const { shippers } = useSelector(state => state.shippers);
    const { customers } = useSelector(state => state.customers);
    const { consignees } = useSelector(state => state.consignees);

    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const [isLoaded, setIsLoaded] = useState(true);
    const [searchSpinner, setSearchSpinner] = useState(false);
    const [openOrderDialog, setOpenOrderDialog] = useState(false);
    const onHandleOpenDialog = () => setOpenOrderDialog(true);
    const onHandleCancelDialog = () => setOpenOrderDialog(false);
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(resetOrderTableFilterSlice(null));
        if (location?.state?.name == "location") {
            dispatch(onSelectedLocationIndexChange(location?.state.locInd));
            dispatch(onSelectedTabIndexNameChange(location?.state?.tabInd));
        }
    }, []);

    useEffect(() => {
        // predefined sub mode data get
        sendRequest('/api/subMode', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onSubModeDataChange(response)),
            failedCallback,
        });

        // predefined shipping mode data get
        sendRequest('/api/shippingMode', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onShippingModeDataChange(response)),
            failedCallback,
        });

        // predefined country of origin data get
        sendRequest('/api/countryOfOrigin', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onCountryOfOriginDataChange(response)),
            failedCallback,
        });

        // predefined shippers data get
        sendRequest('/api/serviceProvider/1?activeFlag=true', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onShippersChange(response)),
            failedCallback,
        });

        // predefined consignee data get
        sendRequest('/api/serviceProvider/2?activeFlag=true', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onConsigneesChange(response)),
            failedCallback,
        });

        // predefined customer data get
        sendRequest('/api/serviceProvider/13?activeFlag=true', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onCustomersChange(response)),
            failedCallback,
        });

        // predefined user local data get
        sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onUserILDataChange(response)),
            failedCallback,
        });

        // predefined user abroad data get
        sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onUserNLDataChange(response)),
            failedCallback,
        });

        // predefined user abroad data get
        sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onUserUSDataChange(response)),
            failedCallback,
        });

        // predefined air poa data get
        sendRequest('/api/poa/air', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onAirPoaDataChange(response)),
            failedCallback,
        });

        // predefined air pol data get
        sendRequest('/api/pol/air', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onAirPolDataChange(response)),
            failedCallback,
        });

        // predefined lcl poa data get
        sendRequest('/api/poa/lcl', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onLclPoaDataChange(response)),
            failedCallback,
        });

        // predefined lcl pol data get
        sendRequest('/api/pol/lcl', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onLclPolDataChange(response)),
            failedCallback,
        });

        // predefined incoterms data get
        sendRequest('/api/incoterms', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onIncotermsDataChange(response)),
            failedCallback,
        });

        // predefined code data get
        sendRequest('/api/codes', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onFileCodesChange(response)),
            failedCallback,
        });
    }, [dispatch, failedCallback]);

    useEffect(() => {
        const userILNameOptions = userILData.map(user => ({
            id: user.id,
            name: user.fullName,
            department: user.department,
        }));

        dispatch(onUserILNamesChange(userILNameOptions));
    }, [userILData, dispatch]);

    useEffect(() => {
        const userNLNameOptions = userNLData.map(user => ({
            id: user.id,
            name: user.fullName,
        }));

        dispatch(onUserNLNamesChange(userNLNameOptions));
    }, [userNLData, dispatch]);

    useEffect(() => {
        const userUSNameOptions = userUSData.map(user => ({
            id: user.id,
            name: user.fullName,
        }));

        dispatch(onUserUSNamesChange(userUSNameOptions));
    }, [userUSData, dispatch]);

    useEffect(() => {
        const shipperNameOptions = shippers.map(shipper => ({
            id: shipper.id,
            name: shipper.companyName,
            serviceProviderLocationId: shipper.serviceProviderLocationId,
        }));

        dispatch(onShipperNameOptionsChange(shipperNameOptions));
    }, [shippers, dispatch]);

    useEffect(() => {
        const customerNameOptions = customers.map(customer => ({
            id: customer.id,
            name: customer.companyName,
            serviceProviderLocationId: customer.serviceProviderLocationId,
        }));

        dispatch(onCustomerNameOptionsChange(customerNameOptions));
    }, [customers, dispatch]);

    useEffect(() => {
        const consigneeNameOptions = consignees.map(consignee => ({
            id: consignee.id,
            name: consignee.companyName,
            serviceProviderLocationId: consignee.serviceProviderLocationId,
        }));

        dispatch(onConsigneeNameOptionsChange(consigneeNameOptions));
    }, [consignees, dispatch]);

    useEffect(() => {
        setIsLoaded(subModeData && shippingModeData && countryOfOriginData && userILData && userNLData && userUSData);
    }, [subModeData, shippingModeData, countryOfOriginData, userILData, userNLData, userUSData]);



    return (
        <Grid container>
            {!isLoaded ? (
                <Grid container item justifyContent='center' mt={8}>
                    <CircularProgress style={{ marginTop: "20px" }} />
                </Grid>
            ) : (
                <Fragment>
                    <Grid container rowSpacing={1} >
                        {!(loginUser?.authorities && loginUser?.authorities[0]?.authority === 'User' && (loginUser?.location?.code === 'NL' || loginUser?.location?.code === 'US')) ?
                            (
                                <Grid item xs={12}>
                                    <Tabs value={selectedLocationIndex} onChange={onHandleSelectedLocationIndexChange} sx={{ minHeight: "30px", overflow: "hidden", border: "1px solid #ccc", backgroundColor: "#f1f1f10a" }}>
                                        <StyledTab label="NL" className="locationTab" />
                                        <StyledTab label="US" className="locationTab" />
                                        <StyledTab label="IL" className="locationTab" />
                                    </Tabs>
                                </Grid>) : null}
                        <Grid container item xs={9} spacing={1} style={{ marginTop: '0' }}>
                            {/* <Typography noWrap variant='h5'>Orders History</Typography>*/}
                                <Button color='secondary' variant='contained' sx={{ height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "11%", fontWeight: "400", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("Orders"));
                                dispatch(getOrders([]));
                                dispatch(onPageChange(0));
                                dispatch(onUnConfirmedOrdersCountChange(''));
                                window.setTimeout(function () {
                                    navigate("/home", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Orders" } })
                                }, 500)
                                //agentOrders()
                            }}>
                                Orders
                            </Button>
                                <Button color='secondary' variant='contained' sx={{ height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "16%", fontWeight: "400", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("OrdersHistory"));
                                dispatch(getOrders([]));
                                dispatch(onUnConfirmedOrdersCountChange(''));
                                // navigate("/home", { state: { name: "location", locInd: selectedLocationIndex } })
                                dispatch(onPageChange(0));
                                window.setTimeout(function () {
                                    navigate("/orderHistory", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "OrdersHistory" } })
                                }, 500)
                            }}>
                                Order History
                            </Button>
                                <Button {...(location?.state?.tabInd != 'Parcels' && { color: 'secondary' })} variant='contained' sx={{ ...(location?.state?.tabInd == 'Parcels' && { backgroundColor: "#1976d270 !important" }), fontWeight: "400", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "12%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("Parcels"));
                                dispatch(getOrders([]));
                                dispatch(onPageChange(0));
                                dispatch(onUnConfirmedParcelsCountChange(''));
                                window.setTimeout(function () {
                                    navigate("/phome", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Parcels" } })
                                }, 500)
                                //redboxParcels()
                            }}>
                                Redbox{(unConfirmedParcelsCount && location?.state?.tabInd == 'Parcels') ? ' (' + unConfirmedParcelsCount + ')' : ''}
                            </Button>
                                <Button color='secondary' variant='contained' sx={{ height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "17%", fontWeight: "400", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("ParcelsHistory"));
                                dispatch(getOrders([]));
                                // navigate("/home", { state: { name: "location", locInd: selectedLocationIndex } })
                                dispatch(onPageChange(0));
                                dispatch(onUnConfirmedParcelsCountChange(''));
                                window.setTimeout(function () {
                                    navigate("/parcelHistory", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "ParcelsHistory" } })
                                }, 500)
                                //redboxParcels()
                            }}>
                                Redbox History
                            </Button>
                                <Button variant='contained' {...(location?.state?.tabInd != 'Drops' && { color: 'secondary' })} sx={{ ...(location?.state?.tabInd == 'Drops' && { backgroundColor: "#1976d270 !important" }), fontWeight: "400", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "12%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("Drops"));
                                dispatch(getOrders([]));
                                dispatch(onPageChange(0));
                                dispatch(onUnConfirmedParcelsCountChange(''));
                                window.setTimeout(function () {
                                    navigate("/dhome", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Drops" } })
                                }, 500)
                                //redboxParcels()
                            }}>
                                Drop{(unConfirmedParcelsCount && location?.state?.tabInd == 'Drops') ? ' (' + unConfirmedParcelsCount + ')' : ''}
                            </Button>
                                <Button color='secondary' variant='contained' sx={{ fontWeight: "400", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "17%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("DropsHistory"));
                                dispatch(getOrders([]));
                                // navigate("/home", { state: { name: "location", locInd: selectedLocationIndex } })
                                dispatch(onPageChange(0));
                                dispatch(onUnConfirmedParcelsCountChange(''));
                                window.setTimeout(function () {
                                    navigate("/dropHistory", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "DropsHistory" } })
                                }, 500)
                                //redboxParcels()
                            }}>
                                Drop History
                            </Button>
                        </Grid>

                        <Grid container item xs={3} spacing={2} justifyContent='flex-end'>
                            <OrderSearch loginUser={loginUser} setSearchSpinner={setSearchSpinner} pageName={selectedTabIndexName} />
                            <Grid container item xs='auto' justifyContent='flex-end' alignContent="flex-start">
                                <Button onClick={onHandleOpenDialog} variant='contained' sx={{ padding: "0px 6px 0px 6px !important", backgroundColor: "#1976d270 !important", height: "2vw" }}>
                                    <Typography noWrap variant='button'>
                                        + {(selectedTabIndexName == 'Drops' || selectedTabIndexName == 'DropsHistory') ? 'Drop Info' : 'Parcel Info'}
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        {openOrderDialog && (
                            <Dialog
                                open={openOrderDialog}
                                onHandleCancel={onHandleCancelDialog}
                                title={(selectedTabIndexName == 'Drops' || selectedTabIndexName == 'DropsHistory') ? 'Drop information' : 'Parcel information'}
                                maxWidth='lg'>
                                <OrderForm loginUser={loginUser} onHandleCancel={onHandleCancelDialog} order={null} orderTableFilter={orderTableFilter} pageName={selectedTabIndexName} />
                            </Dialog>
                        )}
                    </Grid>
                    <OrderTable loginUser={loginUser} searchSpinner={searchSpinner} pageName={selectedTabIndexName} />
                    {/* {searchSpinner ? (
            <Grid container item justifyContent='center' mt={8}>
              <CircularProgress />
            </Grid>
          ) : orders?.length ? (
          ) : (
            <Grid container item justifyContent='center' mt={8}>
              <Typography variant='h4'>No data available</Typography>
            </Grid>
          )} */}
                </Fragment>
            )}
        </Grid>
    );
};

export default Orders;

export const StyledTab = styled(Tab)({
    border: '1px solid black',
    'font-size': '17px',
    'margin-right': '2px',
    'padding': '14px 16px',
    'min-height': '30px',
});

import { createSlice } from '@reduxjs/toolkit';
import { expandData } from 'utils/Communicator';

export const truckingCompaniesSlice = createSlice({
  name: 'truckingCompanies',
  initialState: {
    truckingCompanies: [],
    truckingCompanyNameOptions: [],
  },
  reducers: {
    onTruckingCompaniesChange: (state, { payload }) => {
      state.truckingCompanies = payload;
    },
    onTruckingCompanyNameOptionsChange: (state, { payload }) => {
      state.truckingCompanyNameOptions = payload;
    },
    addTruckingCompany: (state, { payload }) => {
      state.truckingCompanies = [...state.truckingCompanies, payload];
    },
    updateTruckingCompany: (state, { payload }) => {
      const truckingCompany1 = state.truckingCompanies.find(truckingCompany => truckingCompany.id === payload.id);
      const truckingCompanyIndex = state.truckingCompanies.indexOf(truckingCompany1);
      const before = state.truckingCompanies.slice(0, truckingCompanyIndex);
      const after = state.truckingCompanies.slice(truckingCompanyIndex + 1);
      const newTruckingCompanies = [...before, payload, ...after];
      state.truckingCompanies = newTruckingCompanies;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onTruckingCompaniesChange,
  onTruckingCompanyNameOptionsChange,
  addTruckingCompany,
  updateTruckingCompany,
} = truckingCompaniesSlice.actions;

export default truckingCompaniesSlice.reducer;

import React, { useCallback, useEffect, useState } from 'react';
import './OrderStagesTimeline.scss';
import { TextField } from 'style-guide';
import { Button } from '@mui/material';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { Snackbar } from 'style-guide';

/**
 * Component implementation presenting Order stages timeline.
 */
export const OrderStagesTimeline = () => {
  const dispatch = useDispatch();

  const [stagesInfoData, setStagesInfoData] = new useState([]);
  let currentPosition = null;

  const setCurrentPosition = value => {
    currentPosition = value;
  };
  const { snackbar } = useSelector(state => state.snackbar);
  const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));

  const onChangeHandler = (event, idValue) => {
    let newStagesInfoData = [...stagesInfoData];
    let index = newStagesInfoData.findIndex(el => el.id === idValue);
    newStagesInfoData[index].timeout = event.target.value;

    setStagesInfoData(newStagesInfoData);
  };

  const failedCallback = useCallback(
    message => dispatch(snackbarToggle({ type: 'error', message })),
    [dispatch],
  );

  const updateStagesTimeline = () => {
    sendRequest('/api/timeline', REQUEST_ACTIONS.PUT, stagesInfoData, {
      successCallback: response => {
        //console.log(response);
        dispatch(snackbarToggle({ type: 'success', message: response }));
      },
      failedCallback,
    });
  };
  useEffect(() => {
    if (!stagesInfoData || stagesInfoData.length === 0) {
      sendRequest(
        '/api/status',
        REQUEST_ACTIONS.GET,
        '',
        {
          successCallback: response => setStagesInfoData(response),
          failedCallback,
        },
        [stagesInfoData],
      );
    }
  });

  return (
    <div className='stages-timeline-container'>
      <div>
        <h2>Order Stages Timeline</h2>
      </div>
      {stagesInfoData.map(orderStageInfo => (
        <React.Fragment>
          <div className='stage-header'>
            {currentPosition !== orderStageInfo.stageInfo.name ? orderStageInfo.stageInfo.name : ''}
          </div>
          <div className='timeline-input'>
            <TextField
              lg={null}
              type='number'
              label={orderStageInfo.name}
              value={orderStageInfo.timeout}
              onChange={event => onChangeHandler(event, orderStageInfo.id)}
            />
          </div>
          {setCurrentPosition(orderStageInfo.stageInfo.name)}
        </React.Fragment>
      ))}
      <hr width='35%' align='left'></hr>
      <div className='actions-class'>
        <Button variant='contained' onClick={updateStagesTimeline} sx={{ mr: 2 }}>
          Save
        </Button>
        <Button variant='contained' color='secondary'>
          Reset To Default
        </Button>
      </div>
      {snackbar && (
        <Snackbar
          open={!!snackbar}
          message={snackbar.message}
          type={snackbar.type}
          onClose={onSnackbarHandleClose}
        />
      )}
    </div>
  );
};

export default OrderStagesTimeline;

import { createSlice } from '@reduxjs/toolkit';
import { expandData } from 'utils/Communicator';

export const shippersSlice = createSlice({
  name: 'shippers',
  initialState: {
    shippers: [],
    shipperNameOptions: [],
    pickupAddress:null,
  },
  reducers: {
    onShippersChange: (state, { payload }) => {
      state.shippers = payload;
    },

    onPickupAddressChange: (state, { payload }) => {
      state.pickupAddress = payload;
    },
    onShipperNameOptionsChange: (state, { payload }) => {
      state.shipperNameOptions = payload;
    },
    addShipper: (state, { payload }) => {
      state.shippers = [...state.shippers, payload];
    },
    updateShipper: (state, { payload }) => {
      const shipper = state.shippers.find(shipper => shipper.id === payload.id);
      const shipperIndex = state.shippers.indexOf(shipper);
      const before = state.shippers.slice(0, shipperIndex);
      const after = state.shippers.slice(shipperIndex + 1);
      const newShippers = [...before, payload, ...after];
      state.shippers = newShippers;
    },
  },
});

// Action creators are generated for each case reducer function
export const { onShippersChange, onShipperNameOptionsChange, updateShipper, addShipper ,onPickupAddressChange} = shippersSlice.actions;

export default shippersSlice.reducer;

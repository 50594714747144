import { useState } from 'react';

import { TableCell, TableRow, Typography, Box, Button } from '@mui/material';
import { Dialog } from 'style-guide';
import AddServiceProvider from './AddServiceProvider';
import { useSelector } from 'react-redux';

const RowServiceProvider = ({ data }) => {
    const { countryOfOriginData, serviceProviderType } = useSelector(state => state.predefinedData);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const [newData, setNewData] = useState(false);
    const onHandleOpenModal = () => {
        var nData = { ...data }
        nData.iuser = Number(data.iuser);
        nData.ouser = Number(data.ouser);
        nData.cuser = Number(data.cuser);
        setNewData(nData);
        setOpenOrderModal(true);
    }
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const { usersLocation } = useSelector(state => state.users);
    const inActiveBgColor = {
        'background-color': '#F5F5F5'
    }

    return (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={(data.active == null || data.active) ? null : inActiveBgColor}>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left", marginLeft: "10px" }}>
                    {data.companyName?.substring(0, 11) || '-'} ({data?.serviceProviderLocationId != null ? usersLocation?.find(location => location.id === data?.serviceProviderLocationId)?.code : "NL"})
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left", marginLeft: "10px" }}>
                    {data.city?.substring(0, 11) || '-'}
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left", marginLeft: "10px" }}>
                    {countryOfOriginData.find(country => country.id === data.countryId)?.name || '-'}
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left", marginLeft: "10px" }}>
                    {data.phone?.substring(0, 11) || '-'}
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left", marginLeft: "10px" }}>
                    {data.name?.substring(0, 11) || '-'}
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left", marginLeft: "10px" }}>
                    {data.email?.substring(0, 11) || '-'}
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left", marginLeft: "10px" }}>
                    {data.mobile?.substring(0, 11) || '-'}
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left", marginLeft: "10px" }}>
                    {serviceProviderType.find(serviceProvider => serviceProvider.id === data.serviceProviderTypeId)?.name || '-'}
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left", marginLeft: "10px" }}>
                    {data.uatnumber || '-'}
                </p>
            </TableCell>
            <TableCell>
                <p variant='body1' component='span' style={{ textAlign: "left", marginLeft: "10px" }}>
                    {(data.active != null) ? (data.active ? 'Active' : 'In-Active') : 'Active'}
                </p>
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={onHandleOpenModal} variant='outlined' sx={{ mr: 1 }}>
                        Edit
                    </Button>
                    {openOrderModal && (
                        <Dialog
                            maxWidth='md'
                            open={openOrderModal}
                            title={'CRM'}
                            onHandleCancel={onHandleCancelModal}>
                            <AddServiceProvider data={newData} onHandleCancel={onHandleCancelModal} id={null} readOnly={false} />
                        </Dialog>
                    )}
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default RowServiceProvider;

import { v4 as uuidv4 } from 'uuid';

import { Box, Collapse, TableRow, Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import OrderRowDetail from './OrderRowDetail';
import { onOrderSelected } from 'reducers/order-reducers/ordersSlicer';
import {orderRowBgColor} from '../Orders';
import { useSelector, useDispatch } from 'react-redux';
import {StyledTableCell} from 'Utils';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.caption,
  padding: theme.spacing(1),
}));

const OrderRowCollapse = ({ orderDetails, openCollapse, orderID }) => {
  const { orderSelected} = useSelector(state => state.orders);
  const dispatch = useDispatch();
  return (
    <TableRow onClick={() => dispatch(onOrderSelected(orderID))} style={orderID === orderSelected ?orderRowBgColor:null}>
      <StyledTableCell padding='none' />
      <StyledTableCell sx={{ pb: 0, pt: 0 }} colSpan={20}>
        <Collapse in={openCollapse} unmountOnExit>
          {orderDetails.length ? (
            <Box my={2}>
              <Typography variant='h6'>Shipment details</Typography>
              {orderDetails.map(detail => {
                return <OrderRowDetail detail={detail} key={detail.id || uuidv4()} />;
              })}
              <Grid container spacing={2} justifyContent='flex-end'>
                <Grid item xs={2}>
                  <Typography fontSize="0.9rem" noWrap variant='caption'>
                    Sum - Total Weight
                  </Typography>
                  <Item sx={{ bgcolor: 'grey.400' }}>
                    {orderDetails.reduce((acc, item) => {
                      return acc + (item.totalweight || item.numberOfPieces * item.weightPerPiece);
                    }, 0)}
                  </Item>
                </Grid>
                <Grid item xs={2}>
                  <Typography fontSize="0.9rem" noWrap variant='caption'>
                    Sum - Total W/M
                  </Typography>
                  <Item sx={{ bgcolor: 'grey.400' }}>
                    {orderDetails.reduce((acc, item) => {
                      return acc + item.totalvolume || ((item.numberOfPieces * (item.length * item.width * item.height)) / 1000);
                    }, 0)}
                  </Item>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Typography variant='h6' noWrap my={2}>
              Details are empty.
            </Typography>
          )}
        </Collapse>
      </StyledTableCell>
    </TableRow>
  );
};

export default OrderRowCollapse;

import { useEffect, useCallback, useState } from 'react';

import { Box, Grid, Button, CircularProgress } from '@mui/material';
import { SelectField, TextField, DateTimePicker, MultilineField, Dialog } from 'style-guide';

import { STATUS } from 'constants/global-constants';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';

import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from 'reducers/parcel-reducers/ordersSlicer';
import {
    resetDepartureStage,
    onStageStatusInfoIdChange,
    onCutOffTimeChange,
    onUpdateTimeChange,
    onRefNumberChange,
    onNotesChange,
    onMasterChange,
    onHouseChange,
    onIdChange,
    onETATimeChange,
    onETDTimeChange,
    onDepartureStatusesChange,
    onShippingLineNamesOptionChange,
    onShippingLineNamesChange,
    onShipNameChange,
    onImoChange,
    onContainerNumberChange,
    onBookingNumberChange,
    onVesselNumberChange,
    onShippingLineNameChange,
    onAirlineChange,
    onFlightNumberChange,
    onAWBChange,
    onDepartureDateChange,
} from 'reducers/parcel-stage-reducers/departureStageSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { addParcelFilter, addSearchFilterParcel, addStatusChatMessage, getStatusChatMessage } from 'Utils';
const DepartureDialog = ({ loginUser, order, openDialog, setOpenDialog, pageName }) => {
    const dispatch = useDispatch();
    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const onHandleChange = (event, action) => dispatch(action(event.target.value));
    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        selectedTabIndexName,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.parcelTableFilter);
    const { page, size } = useSelector(state => state.parcels);
    const {
        id,
        stageStatusInfoId,
        departureStatuses,
        shippingLineNames,
        shippingLineNamesOption,
        cutOffTime,
        updateTime,
        refNumber,
        master,
        house,
        notes,
        eta,
        etd,
        shipName,
        imo,
        containerNumber,
        bookingNumber,
        vesselNumber,
        shippingLineName,
        airline,
        flightNumber,
        awb,
        departureDate,
    } = useSelector(state => state.parcelDepartureStage);

    const [isLoaded, setIsLoaded] = useState(false);

    const [shippingLineNumber, setShippingLineNumber] = useState('');

    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const currentStage = order.parcelStage[4];

    const onHandleCloseDialog = () => {
        dispatch(resetDepartureStage());
        setOpenDialog(false);
    };
    useEffect(() => {
        // predefined user abroad data get
        if (!nlUsers || nlUsers == undefined) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setNlUsers(response); },
                failedCallback,
            });
        }
    }, [nlUsers, failedCallback, setNlUsers]);

    useEffect(() => {
        // predefined user abroad data get
        if (!usUsers || usUsers == undefined) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setUSUsers(response); },
                failedCallback,
            });
        }
    }, [usUsers, failedCallback, setUSUsers]);

    useEffect(() => {
        // predefined user local data get
        if (!ilUsers || ilUsers == undefined) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setIlUsers(response); },
                failedCallback,
            });
        }
    }, [ilUsers, failedCallback, setIlUsers]);

    useEffect(() => {
        if (!departureStatuses) {
            // predefined departure statuses data get
            sendRequest('/api/stage/5', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { response.splice(0, 1); dispatch(onDepartureStatusesChange(response)); },
                failedCallback,
            });
        }

        if (!shippingLineNames) {
            // predefined departure statuses data get
            sendRequest('/api/serviceProvider/6?activeFlag=true', REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    dispatch(onShippingLineNamesChange(response));
                    const shippingLineNamesOptions = response.map(company => {
                        return { id: company.companyName, name: company.companyName, serviceProviderLocationId: company.serviceProviderLocationId, };
                    });
                    dispatch(onShippingLineNamesOptionChange(shippingLineNamesOptions));
                },
                failedCallback,
            });
        }

    }, [dispatch, failedCallback, departureStatuses, shippingLineNames]);

    useEffect(() => {
        setIsLoaded(!!departureStatuses);
        setIsLoaded(!!shippingLineNames);
    }, [departureStatuses, shippingLineNames]);

    useEffect(() => {
        setShippingLineNumber(shippingLineNames?.find(shippingLineObj => shippingLineObj.companyName === shippingLineName)?.shippingLineNumber);
    }, [shippingLineName]);

    useEffect(() => {
        const parcelManifestStage = currentStage.parcelManifestStage[0];

        dispatch(onStageStatusInfoIdChange(currentStage.stageStatusInfoId));
        dispatch(onNotesChange(currentStage.notes));

        if (parcelManifestStage?.id) {
            dispatch(onIdChange(parcelManifestStage.id));
            dispatch(onCutOffTimeChange(parcelManifestStage.cutOffTime));
            dispatch(onETATimeChange(parcelManifestStage.eta));
            dispatch(onETDTimeChange(parcelManifestStage.etd));
            dispatch(onUpdateTimeChange(parcelManifestStage.updateTime));
            dispatch(onRefNumberChange(parcelManifestStage.refNumber));
            dispatch(onMasterChange(parcelManifestStage.master));
            dispatch(onHouseChange(parcelManifestStage.house));
            dispatch(onShipNameChange(parcelManifestStage.shipName));
            dispatch(onImoChange(parcelManifestStage.imo));
            dispatch(onContainerNumberChange(parcelManifestStage.containerNumber));
            dispatch(onBookingNumberChange(parcelManifestStage.bookingNumber));
            dispatch(onVesselNumberChange(parcelManifestStage.vesselNumber));
            dispatch(onShippingLineNameChange(parcelManifestStage.shippingLineName));
            dispatch(onAirlineChange(parcelManifestStage.airline));
            dispatch(onFlightNumberChange(parcelManifestStage.flightNumber));
            dispatch(onAWBChange(parcelManifestStage.awb));
            dispatch(onDepartureDateChange(parcelManifestStage.departureDate));
        }
    }, [dispatch]);

    // create/save stage
    const onStageHandleSave = id => {
        const parcelManifestStage = [
            {
                refNumber,
                master,
                house,
                updateTime,
                cutOffTime: cutOffTime || order.parcelStage[3].parcelwhStage[0]?.cutOffTime,
                eta: eta || order.parcelStage[3].parcelwhStage[0]?.eta,
                etd: etd || order.parcelStage[3].parcelwhStage[0]?.etdTime,
                shipName,
                imo,
                containerNumber,
                bookingNumber,
                vesselNumber,
                shippingLineName,
                airline,
                awb,
                flightNumber,
                departureDate: departureDate || order.parcelStage[3].parcelwhStage[0]?.eta,

            },
        ];
        /* if(parcelManifestStage[0].master == null || parcelManifestStage[0].master === ""){
          dispatch(snackbarToggle({ type: 'error', message: 'Master field is empty!' }));
          return;
        }
        if(parcelManifestStage[0].house == null || parcelManifestStage[0].house === ""){
          dispatch(snackbarToggle({ type: 'error', message: 'House field is empty!' }));
          return;
        } */

        if (id) {
            parcelManifestStage[0].id = id;
        }

        const newOrder = {
            ...order,
            parcelStage: [
                order.parcelStage[0],
                order.parcelStage[1],
                order.parcelStage[2],
                order.parcelStage[3],
                {
                    ...currentStage,
                    notes,
                    stageStatusInfoId,
                    parcelManifestStage,
                },
            ],
        };
        if (newOrder.parcelStage[4].stageStatusInfoId == null) {
            dispatch(snackbarToggle({ type: 'error', message: 'Choose status!' }));
            return;
        }

        /*if (order?.parcelStage[4]?.stageStatusInfoId != newOrder?.parcelStage[4]?.stageStatusInfoId) {
            newOrder.chat = addStatusChatMessage(order, 'Manifest', departureStatuses.find(status => status.id === newOrder?.parcelStage[4]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
        }*/
        sendRequest(`/api/parcel?fromStage=5&loginUserId=` + loginUser?.id + `&actionOn=ManifestDialog`, REQUEST_ACTIONS.PUT, newOrder, {
            successCallback: () => {
               // dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));
                

                sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));

                        if (order?.parcelStage[4]?.stageStatusInfoId != newOrder?.parcelStage[4]?.stageStatusInfoId) {
                            //newOrder.chat = addStatusChatMessage(order, 'WH', 'Arrived', ilUsers, nlUsers, usUsers, loginUser);
                            var chatString = getStatusChatMessage(order, 'Manifest', departureStatuses.find(status => status.id === newOrder?.parcelStage[4]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
                            //console.log(chatString)
                            if (chatString.includes("/")) {
                               // chatString = chatString.replace("/", " ");
                            }
                            onUpdateChat(order.id, chatString, 5)
                        } else {
                             dispatch(getOrders(response));
                            onHandleCloseDialog();
                        }
                    },
                    failedCallback,
                });
            },
            failedCallback,
        });
    };


    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/parcel/updateChat/${id}?fromStage=${fromStage}`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {

                        dispatch(getOrders(response));
                        onHandleCloseDialog();
                    },
                    failedCallback,
                });
            },
            failedCallback,
        });
    };
    return (
        <Dialog maxWidth='md' open={openDialog} onHandleCancel={onHandleCloseDialog} title={'MANIFEST - ' + order?.jobNumber}>
            <Box component='form' noValidate autoComplete='off'>
                {!isLoaded ? (
                    <Grid container justifyContent='center' mt={2}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        {/* <DateTimePicker
              label='Cut off time'
              value={cutOffTime || order.parcelStage[3].parcelwhStage[0]?.cutOffTime}
              onChange={date => dispatch(onCutOffTimeChange(date?.toISOString()))}
            /> */}
                        <SelectField
                            disabled={!!(currentStage.status === STATUS['2'])}
                            label='Status'
                            lg={null}
                            value={stageStatusInfoId || 5}
                            onChange={event => onHandleChange(event, onStageStatusInfoIdChange)}
                            options={departureStatuses}
                        />
                        <DateTimePicker
                            label='Update time'
                            value={updateTime}
                            onChange={date => dispatch(onUpdateTimeChange(date?.toISOString()))}
                        />
                        <Grid container item spacing={2}>
                            <DateTimePicker
                                label='ETD'
                                value={etd || (order.parcelStage[3].parcelwhStage ? order.parcelStage[3].parcelwhStage[0]?.etdTime : etd)}
                                onChange={date => dispatch(onETDTimeChange(date?.toISOString()))}
                            />
                            <DateTimePicker
                                label='ETA'
                                value={eta || (order.parcelStage[3].parcelwhStage ? order.parcelStage[3].parcelwhStage[0]?.eta : eta)}
                                onChange={date => dispatch(onETATimeChange(date?.toISOString()))}
                            />
                        </Grid>
                        {/*<Grid container item spacing={2}>
              <TextField
                required
                lg={null}
                label='Master'
                value={master}
                onChange={event => onHandleChange(event, onMasterChange)}
              />
              <TextField
                required
                lg={null}
                label='House'
                value={house}
                onChange={event => onHandleChange(event, onHouseChange)}
              />
          </Grid> */}

                        {/*{order.shippingModeId==1?
              (<Grid container item spacing={2}>
                <TextField
                  required
                  lg={null}
                  label='Airline'
                  value={airline}
                  onChange={event => onHandleChange(event, onAirlineChange)}
                />
                <TextField
                  required
                  lg={null}
                  label='Flight Number'
                  value={flightNumber}
                  onChange={event => onHandleChange(event, onFlightNumberChange)}
                 />
                <TextField
                  required
                  lg={null}
                  label='AWB'
                  value={awb}
                  onChange={event => onHandleChange(event, onAWBChange)}
                 />
              </Grid>):
              (<Grid container item spacing={2}>
                <TextField
                  required
                  lg={null}
                  label='SCAC'
                  value={shipName}
                  onChange={event => onHandleChange(event, onShipNameChange)}
                />
                <TextField
                  required
                  lg={null}
                  label='IMO'
                  value={imo}
                  onChange={event => onHandleChange(event, onImoChange)}
                />
               <TextField
                  required
                  lg={null}
                  label='Container Number'
                  value={containerNumber}
                  onChange={event => onHandleChange(event, onContainerNumberChange)}
                />
              </Grid>)
            }*/}
                        <DateTimePicker
                            label='Departure ETA'
                            value={departureDate || (order.parcelStage[3].parcelwhStage ? order.parcelStage[3].parcelwhStage[0]?.eta : departureDate)}
                            onChange={date => dispatch(onDepartureDateChange(date?.toISOString()))}
                        />
                        {order.shippingModeId == 2 &&
                            (
                                <Grid container item spacing={2}>
                                    <TextField
                                        lg={null}
                                        label='Booking Number'
                                        value={bookingNumber}
                                        onChange={event => onHandleChange(event, onBookingNumberChange)}
                                    />
                                    <TextField
                                        lg={null}
                                        label='Vessel Name'
                                        value={vesselNumber}
                                        onChange={event => onHandleChange(event, onVesselNumberChange)}
                                    />
                                </Grid>
                            )
                        }
                        {order.shippingModeId == 2 &&
                            (
                                <Grid container item spacing={2}>
                                    <SelectField
                                        label='Shipping Line Name'
                                        lg={null}
                                        value={shippingLineName}
                                        onChange={event => onHandleChange(event, onShippingLineNameChange)}
                                        options={[{ id: null, name: 'Reset' }, ...shippingLineNamesOption]}
                                    />
                                    <TextField
                                        lg={null}
                                        label='Shipping Line Number'
                                        value={shippingLineNumber}
                                        readonly={true}
                                    />
                                </Grid>
                            )}
                        <MultilineField
                            xs={12}
                            sm={null}
                            md={null}
                            lg={null}
                            label='Notes'
                            value={notes}
                            onChange={event => onHandleChange(event, onNotesChange)}
                        />
                        <Grid container item justifyContent='flex-end'>
                            <Button
                                variant='contained'
                                sx={{ mr: 2 }}
                                onClick={id ? () => onStageHandleSave(id) : () => onStageHandleSave(null)}>
                                Save
                            </Button>
                            <Button variant='contained' color='secondary' onClick={onHandleCloseDialog}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Dialog>
    );
};

export default DepartureDialog;

import { useState, useCallback, useEffect, memo } from 'react';

import { IconButton, TableCell, TableRow, Typography, Grid, Button } from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import RefreshIcon from '@mui/icons-material/Refresh';
import BackIcon from '@mui/icons-material/SettingsBackupRestore';
import { ConfirmDialog, Dialog, Tooltip } from 'style-guide';
import OrderForm from '../OrderForm';
import ChatDialog from '../ChatDialog';
import CustChatDialog from '../CustChatDialog';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EditLogo from './edit.svg';
import { styled } from '@mui/system';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';
import { REQUEST_ACTIONS, sendRequest, setOrderData } from 'utils/Communicator';
import AddServiceProvider from 'serviceProvider/AddServiceProvider';
import SetReminderDialog from './SetReminderDialog';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { deleteOrder, onOrdersCountChange, getOrders, onChatCountChange, onChatCountCChange, onChatCountTChange, onChatCountCTChange, onUserSpecificOrdersCountChange, onUnConfirmedOrdersCountChange } from 'reducers/order-reducers/ordersSlicer';
import { addOrderFilter, addSearchFilter, StyledTableCell } from 'Utils';
import { onOrderSelected } from 'reducers/order-reducers/ordersSlicer';
import { orderRowBgColor } from '../Orders';
import { COUNTRY_CODE_LIST } from 'constants/global-constants';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MailAuditDlg from './MailAuditDlg';
import CheckIcon from '@mui/icons-material/Check';
import RestoreIcon from '@mui/icons-material/Restore';
import AuditLogs from '../../../audit/AuditLogs';
const OrderRowInfo = ({ order, openCollapse, onHandleSwitchCollapse, loginUser, pageName, stages }) => {
    const dispatch = useDispatch();
    const { orders, page, size, chatCount, orderSelected, userSpecificOrdersCount } = useSelector(state => state.orders);
    const { subModeData, userNLNames, userUSNames, userILNames, shippingModeData, countryOfOriginData, airPolData, airPoaData, lclPolData, lclPoaData } = useSelector(
        state => state.predefinedData,
    );

    const getUser = (id) => {
        var user = userILNames.find(u => u.id == id);
        if (!user)
            user = userUSNames.find(u => u.id == id);
        if (!user)
            user = userNLNames.find(u => u.id == id);
        if (user) {
            console.log(user);
            return user.name;
        } else
            return "";
    }

    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        shipperId,
        customerId,
        countryOfOriginId, originState, fileNumber,
        shippingModeId,
        subModeId,
        userLocalId, userTracer, userCustoms, urgentShipment,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.orderTableFilter);
    const orderTableFilter = {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId, userTracer, userCustoms, urgentShipment,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv,
    };
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const [openLogOrderModal, setOpenLogOrderModal] = useState(false);
    const onOrderLogModalHandleOpen = () => setOpenLogOrderModal(true);
    const onOrderLogModalHandleCancel = () => setOpenLogOrderModal(false);

    const [openOrderModal, setOpenOrderModal] = useState(false);
    const [openMailModal, setOpenMailModal] = useState(false);
    const [openCopyOrderModal, setOpenCopyOrderModal] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openHistoryConfirmDialog, setOpenHistoryConfirmDialog] = useState(false);
    const [backStageConfirmDialog, setBackStageConfirmDialog] = useState(false);
    const [openHistoryRevertConfirmDialog, setOpenHistoryRevertConfirmDialog] = useState(false);
    const [openChatDialog, setOpenChatDialog] = useState(false);
    const [openCustChatDialog, setOpenCustChatDialog] = useState(false);
    const [chatActivity, setChatActivity] = useState(false);
    const onOrderModalHandleOpen = () => setOpenOrderModal(true);
    const onOrderModalHandleCancel = () => setOpenOrderModal(false);
    const onOrderCopyModalHandleOpen = () => setOpenCopyOrderModal(true);
    const onOrderCopyModalHandleCancel = () => setOpenCopyOrderModal(false);
    const onMailModalHandleOpen = () => setOpenMailModal(true);
    const onMailModalHandleCancel = () => setOpenMailModal(false);
    const onConfirmDialogHandleOpen = () => setOpenConfirmDialog(true);
    const onHistoryConfirmDialogHandleOpen = () => setOpenHistoryConfirmDialog(true);
    const onBackStageConfirmDialogHandleOpen = () => setBackStageConfirmDialog(true);
    const onHistoryRevertConfirmDialogHandleOpen = () => setOpenHistoryRevertConfirmDialog(true);
    const onConfirmDialogHandleClose = () => setOpenConfirmDialog(false);
    const onHistoryConfirmDialogHandleClose = () => setOpenHistoryConfirmDialog(false);
    const onBackStageConfirmDialogHandleClose = () => setBackStageConfirmDialog(false);
    const onHistoryRevertConfirmDialogHandleClose = () => setOpenHistoryRevertConfirmDialog(false);
    const onCustChatDialogHandleOpen = () => setOpenCustChatDialog(true);
    const onCustChatDialogHandleClose = () => setOpenCustChatDialog(false);
    const onChatDialogHandleOpen = () => setOpenChatDialog(true);
    const onChatDialogHandleClose = () => setOpenChatDialog(false);
    const [openShipperModal, setOpenShipperModal] = useState(false);
    const onHandleOpenShipperModal = () => setOpenShipperModal(true);
    const onHandleCancelShipperModal = () => setOpenShipperModal(false);
    const [openBrokerModal, setOpenBrokerModal] = useState(false);
    const onHandleOpenBrokerModal = () => setOpenBrokerModal(true);
    const onHandleCancelBrokerModal = () => setOpenBrokerModal(false);
    const [openConsigneeModal, setOpenConsigneeModal] = useState(false);
    const onHandleOpenConsigneeModal = () => setOpenConsigneeModal(true);
    const onHandleCancelConsigneeModal = () => setOpenConsigneeModal(false);
    const [openReminderDateDialog, setOpenReminderDateDialog] = useState(false);
    const onReminderDateDialogHandleOpen = () => setOpenReminderDateDialog(true);

    const getCopyOrder1 = (order) => {
        var jobNumber = null;
        var orderStage = null;
        var orderStatus = null;
        var codeFileCount = null;
        var chat = null;
        var chatCT = null;
        var chatCustomer = null;
        var chatCustoms = null;
        var chatTracer = null;
        var date = null;
        var lastUpdateDate = null;
        var orderDetails = [];
        var orderFCLDetails = null;
        var o = {
            ...order,
            jobNumber,
            orderStage,
            orderStatus,
            codeFileCount,
            chat,
            chatCT,
            chatCustomer,
            chatCustoms,
            chatTracer,
            date,
            lastUpdateDate,
            orderDetails,
            orderFCLDetails,
        };
        return o;
    }
    const getCopyOrder = (order) => {
        var id = null;
        var jobNumber = null;
        var orderStage = null;
        var orderStatus = null;
        var codeFileCount = null;
        var chat = null;
        var chatCT = null;
        var chatCustomer = null;
        var chatCustoms = null;
        var chatTracer = null;
        var date = null;
        var lastUpdateDate = null;
        var orderDetails = [];
        var orderFCLDetails = null;
        var o = {
            ...order,
            id,
            jobNumber,
            orderStage,
            orderStatus,
            codeFileCount,
            chat,
            chatCT,
            chatCustomer,
            chatCustoms,
            chatTracer,
            date,
            lastUpdateDate,
            orderDetails,
            orderFCLDetails,
        };
        return o;
    }

    const StyledTypography = styled(Typography)`
      cursor: pointer;
      text-decoration: underline;`;

    // delete order
    const onOrderHandleDelete = id => {
        sendRequest(`/api/order/${id}/${loginUser.id}`, REQUEST_ACTIONS.DELETE, '', {
            successCallback: data => {
                sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber)}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        dispatch(onOrdersCountChange(response?.ordersCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount));
                        dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                        dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                        dispatch(onChatCountCChange(response?.unreadChatCustomsCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount); dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount)); dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));
                    },
                    failedCallback,
                });
                dispatch(snackbarToggle({ type: 'success', message: data }));
                dispatch(deleteOrder(id));
            },
            failedCallback,
        });
    };

    const getDepartmentStatus = () => {
        if (loginUser.authority === "Admin")
            return 0;
        return loginUser.department ? loginUser.department : 1;
    }

    const onOrderHandleHistory = id => {
        sendRequest(`/api/order/updateHistoryFlag/${id}/true/${getDepartmentStatus()}?fromStage=0&userId=${loginUser.id}`, REQUEST_ACTIONS.PUT, '', {
            successCallback: data => {
                sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber)}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        dispatch(onOrdersCountChange(response?.ordersCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount));
                        dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                        dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                        dispatch(onChatCountCChange(response?.unreadChatCustomsCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount); dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount)); dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));
                    },
                    failedCallback,
                });
                dispatch(snackbarToggle({ type: 'success', message: data }));
                dispatch(deleteOrder(id));
            },
            failedCallback,
        });
    };

    const onOrderHandleHistoryRevert = id => {
        sendRequest(`/api/order/updateHistoryFlag/${id}/false/${getDepartmentStatus()}?fromStage=0&userId=${loginUser.id}`, REQUEST_ACTIONS.PUT, '', {
            successCallback: data => {
                sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber)}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        dispatch(onOrdersCountChange(response?.ordersCount)); dispatch(sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount)); dispatch(onChatCountChange(response?.unreadChatCount));
                        dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                        dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                        dispatch(onChatCountCChange(response?.unreadChatCustomsCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount); dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount)); dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));
                    },
                    failedCallback,
                });
                sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                    },
                    failedCallback,
                },
                );
                dispatch(snackbarToggle({ type: 'success', message: data }));
            },
            failedCallback,
        });
    };

    const onOrderHandleBackStage = id => {
        sendRequest(`/api/order/backstage/${id}?fromStage=0`, REQUEST_ACTIONS.PUT, '', {
            successCallback: data => {
                onBackStageConfirmDialogHandleClose();
                sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber)}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        dispatch(onOrdersCountChange(response?.ordersCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount));
                        dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                        dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                        dispatch(onChatCountCChange(response?.unreadChatCustomsCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount); dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount)); dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));
                    },
                    failedCallback,
                });
                sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                    },
                    failedCallback,
                },
                );
                dispatch(snackbarToggle({ type: 'success', message: data }));
            },
            failedCallback,
        });
    };
    useEffect(() => {
        onConfirmDialogHandleClose();
        onHistoryConfirmDialogHandleClose();
        onHistoryRevertConfirmDialogHandleClose();
        onBackStageConfirmDialogHandleClose();
    }, [orders.length]);

    useEffect(() => {
        onConfirmDialogHandleClose();
        onHistoryConfirmDialogHandleClose();
        onHistoryRevertConfirmDialogHandleClose();
        onBackStageConfirmDialogHandleClose();
    }, [orders.length]);

    const noteStyle = {
        backgroundColor: "#2e7d329c",
        padding: "3px",
        borderRadius: "5px",
        width: '1.6vw !important',
        height: '1.6vw !important',
    };

    const greenStyle = {
        backgroundColor: "#2e7d329c",
        padding: "3px",
        borderRadius: "5px",
        border: "2px solid grey",
        width: '1.6vw !important',
        height: '1.6vw !important',
    };
    const greyStyle = {
        backgroundColor: "white",
        padding: "3px",
        borderRadius: "5px",
        border: "2px solid grey",
        width: '1.6vw !important',
        height: '1.6vw !important',
    };
    const blueStyle = {
        backgroundColor: "#1976d270",
        padding: "3px",
        borderRadius: "5px",
        border: "2px solid grey",
        width: '1.6vw !important',
        height: '1.6vw !important',
    };
    const orangeStyle = {
        backgroundColor: "#feb7348f",
        padding: "3px",
        borderRadius: "5px",
        border: "2px solid grey",
        width: '1.6vw !important',
        height: '1.6vw !important',
    };

    const pastelStyle = {
        backgroundColor: "#F2A2E8",
        padding: "3px",
        borderRadius: "5px",
        border: "2px solid grey",
        width: '1.6vw !important',
        height: '1.6vw !important',
    };

    const defaultStyle = {
        backgroundColor: "white",
        padding: "3px",
        borderRadius: "5px",
        marginTop: "6px",
        width: '1.6vw !important',
        height: '1.6vw !important',
    };

    const getOrderChatStyle = (order) => {
        if (order['chat']) {
            var chat_list = JSON.parse(order['chat'])
            var len = chat_list.length;
            if (len > 0)
                return { display: 'block', marginTop: '0' };
        }
        return { display: 'block' };
    }
    const getOrderChatCustomerStyle = (order) => {
        if (order['chatCustomer']) {
            var chat_list = JSON.parse(order['chatCustomer'])
            var len = chat_list.length;
            if (len > 0)
                return { display: 'block', marginTop: '0' };
        }
        return { display: 'block' };
    }

    const getToolTip = (order) => {
        if (order['chat']) {
            var chat_list = JSON.parse(order['chat'])
            var len = chat_list.length;

            if (len > 0)
                return getChatLastUpdateDate(order) + ": " + chat_list[len - 1]['text'];
        }
        return '';
    }
    const getCustToolTip = (order) => {
        if (order['chatCustomer']) {
            var chat_list = JSON.parse(order['chatCustomer'])
            var len = chat_list.length;
            if (len > 0)
                return getCustChatLastUpdateDate(order) + ": " + chat_list[len - 1]['text'];
        }
        return '';
    }

    const getChatLastUpdateDate = (order) => {
        if (order['chat']) {
            var chat_list = JSON.parse(order['chat'])
            var len = chat_list.length;
            if (len > 0) {
                if (chat_list[len - 1]['time']) {
                    var chatDate = new Date(chat_list[len - 1]['time']);
                    return chatDate.getDate() + "-" + (chatDate.getMonth() + 1);
                }
            }
        }
        return '';
    }
    const getCustChatLastUpdateDate = (order) => {
        if (order['chatCustomer']) {
            var chat_list = JSON.parse(order['chatCustomer'])
            var len = chat_list.length;
            if (len > 0) {
                if (chat_list[len - 1]['time']) {
                    var chatDate = new Date(chat_list[len - 1]['time']);
                    return chatDate.getDate() + "-" + (chatDate.getMonth() + 1);
                }
            }
        }
        return '';
    }

    const getChatView = (order) => {
        if (order['chat']) {
            var chat_list = JSON.parse(order['chat'])
            var len = chat_list.length;
            if (len > 0) {
                if (chat_list[len - 1]['text']
                    && (chat_list[len - 1]['text'].indexOf(' status changed to ') > -1 || chat_list[len - 1]['text'].indexOf('Order has been confirmed') > -1))
                    return pastelStyle;
                if (chat_list[len - 1]['ILStatus'] && chat_list[len - 1]['NLStatus'])
                    return greyStyle;
                if (chat_list[len - 1]['location']) {
                    if (chat_list[len - 1]['location'] == 'IL')
                        return blueStyle;
                    if (chat_list[len - 1]['location'] == 'NL' || chat_list[len - 1]['location'] == 'US')
                        return orangeStyle;
                }
            }
            return greenStyle;
        }
        return defaultStyle;
    }
    const getCustChatView = (order) => {
        if (order['chatCustomer']) {
            var chat_list = JSON.parse(order['chatCustomer'])
            var len = chat_list.length;
            if (len > 0) {
                if (chat_list[len - 1]['text']
                    && (chat_list[len - 1]['text'].indexOf(' status changed to ') > -1 || chat_list[len - 1]['text'].indexOf('Order has been confirmed') > -1))
                    return pastelStyle;
                if (chat_list[len - 1]['ILStatus'] && chat_list[len - 1]['NLStatus'])
                    return greyStyle;
                if (chat_list[len - 1]['location']) {
                    if (chat_list[len - 1]['location'] == 'IL')
                        return blueStyle;
                    if (chat_list[len - 1]['location'] == 'NL' || chat_list[len - 1]['location'] == 'US')
                        return orangeStyle;
                }
            }
            return greenStyle;
        }
        return defaultStyle;
    }

    const getNLUSUserName = (id) => {
        var nlUser = userNLNames.find(user.id === id);
        var usUser = userUSNames.find(user.id === id);
        if (nlUser) {
            return nlUser.name;
        }
        if (usUser) {
            return usUser.name;
        }
        return "..."
    }

    const userILDisplay = userILNames?.find(user => user.id === order.userILId)?.name || '-';
    const JobNumberDisplay = '' + (order.jobNumber || '-') + '';
    //const JobNumberDisplay = ' ('+(order.jobNumber || '-')+')';
    const userNLDisplay = order?.orderStage ? (userNLNames?.find(user => user.id === order?.orderStage[0]?.orderConfirmationStage[0]?.userNLId)?.name || userUSNames?.find(user => user.id === order.orderStage[0].orderConfirmationStage[0]?.userNLId)?.name || '-') : "-";
    const userNLNumberDisplay = order?.orderStage ? (order?.orderStage[0].orderConfirmationStage[0]?.userNLNumber || '-') : "-";
    const shippingModeNameDisplay = shippingModeData?.find(mode => mode.id === order.shippingModeId)?.name || '-';
    const subModeNameDisplay = subModeData?.find(mode => mode.id === order.subModeId)?.name || '-';
    const countryOfOriginDisplay = ((loginUser.location.code === 'US' || selectedLocationIndex == 1) ? order?.originState : (COUNTRY_CODE_LIST?.find(country => country.country_id === order.countryOfOriginId)?.country_code || countryOfOriginData?.find(country => country.id === order.countryOfOriginId)?.name)) || '-';
    const countryOfOriginDisplayToolTip = ((loginUser.location.code === 'US' || selectedLocationIndex == 1) ? order?.originState : (countryOfOriginData?.find(country => country.id === order.countryOfOriginId)?.name)) || '-';
    const shipperNameDisplay = order?.shipper?.companyShortName ? order?.shipper?.companyShortName : (order?.shipper?.companyName || '-');
    const consigneeNameDisplay = order?.consignee?.companyShortName ? order?.consignee?.companyShortName : (order?.consignee?.companyName || '-');
    const brokerNameDisplay = order?.customer?.companyShortName ? order?.customer?.companyShortName : (order?.customer?.companyName || '-');
    //|| order.orderStage[3].whStageStage[0]?.etdTime
    const ETDWHDisplay = order?.orderStage[3].whStageStage.length > 0 ? (order?.orderStage[3].whStageStage[0].eta ? new Intl.DateTimeFormat('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        /* hour: '2-digit',
         minute: '2-digit',
         second: '2-digit',*/
    }).format(order.orderStage[3].whStageStage[0]?.etdTime) : "-") : "-";
    const ETAWHDisplay = order?.orderStage[3].whStageStage.length > 0 ? (order?.orderStage[3].whStageStage[0].eta ? new Intl.DateTimeFormat('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        /* hour: '2-digit',
         minute: '2-digit',
         second: '2-digit',*/
    }).format(order.orderStage[3].whStageStage[0]?.eta) : "-") : "-";

    const getDate = (date) => {
        if (!isNaN(date))
            date = new Date(Number(date));
        else {
            date = new Date(date);
        }
        if (date.getFullYear() < 2000)
            return "";
        var year = "" + date.getFullYear();
        return (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "/" + (date.getMonth() < 9 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)) + "/" + year[2] + year[3];
    }

    const getETDDisplay = () => {
        var dt = "";
        try {
            if (order?.astatus?.podDate)
                dt = getDate(order?.astatus?.podDate)
            else if (order?.cstatus?.podDate)
                dt = getDate(order?.cstatus?.podDate)
            else if (order?.cstatus?.arDate1)
                dt = getDate(order?.cstatus?.arDate1)
            else if (order.orderStage[4]?.departureStage[0]?.eta)
                dt = new Intl.DateTimeFormat('en-GB', {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                    /* hour: '2-digit',
                     minute: '2-digit',
                     second: '2-digit',*/
                }).format(order.orderStage[4]?.departureStage[0]?.eta)
            else
                dt = "";
        } catch (e) {
            dt = "";
        }
        if (dt.includes("NaN"))
            dt = "";
        return dt;
    }
    const getETADisplay = () => {
        try {
            var dt = "";
            if (order?.astatus?.polDate)
                dt = getDate(order?.astatus?.polDate)
            else if (order?.cstatus?.polDate)
                dt = getDate(order?.cstatus?.polDate)
            else if (order.orderStage[4]?.departureStage[0]?.etd)
                dt = new Intl.DateTimeFormat('en-GB', {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                    /* hour: '2-digit',
                     minute: '2-digit',
                     second: '2-digit',*/
                }).format(order.orderStage[4]?.departureStage[0]?.etd)
            else
                dt = "";
        } catch (e) {
            dt = "";
        }
        if (dt.includes("NaN"))
            dt = "";
        return dt;
    }

    /*const ETADisplay = order?.astatus?.podDate ? order?.astatus?.podDate : (order?.cstatus?.podDate ? order?.cstatus?.podDate : (order?.sinay?.arDate1 ? order?.sinay?.arDate1 : (order.orderStage[4]?.departureStage[0]?.eta ? order.orderStage[4]?.departureStage[0]?.eta : "-"))) ?  new Intl.DateTimeFormat('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        *//* hour: '2-digit',
         minute: '2-digit',
         second: '2-digit',*//*
    }).format(order?.astatus?.podDate ? order?.astatus?.podDate : (order?.cstatus?.podDate ? order?.cstatus?.podDate : (order?.sinay?.arDate1 ? order?.sinay?.arDate1 : (order.orderStage[4]?.departureStage[0]?.eta ? order.orderStage[4]?.departureStage[0]?.eta : "-")))) : null;

    const ETDDisplay = order?.astatus?.polDate ? order?.astatus?.polDate : (order?.cstatus?.polDate ? order?.cstatus?.polDate : (order?.orderStage[4]?.departureStage[0]?.etd ? order?.orderStage[4]?.departureStage[0]?.etd : ""))  ? new Intl.DateTimeFormat('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        *//*hour: '2-digit',
        minute: '2-digit',
        /*second: '2-digit',*//*
    }).format(order?.astatus?.polDate ? order?.astatus?.polDate : (order?.cstatus?.polDate ? order?.cstatus?.polDate : (order?.orderStage[4]?.departureStage[0]?.etd ? order?.orderStage[4]?.departureStage[0]?.etd : ""))) : null;
*/
    const dueDateDisplay = order.dueDate && pageName !== 'orderHistory' ? new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        /*hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',*/
    }).format(order.dueDate) :
        order.lastUpdateDate && pageName === 'orderHistory' ? new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            /* hour: '2-digit',
             minute: '2-digit',
             second: '2-digit',*/
        }).format(order.lastUpdateDate) : '-';
    const reminderDateDisplay = order.ilRemindDate && loginUser?.location?.code === 'IL' ? new Intl.DateTimeFormat('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        /*hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',*/
    }).format(order.ilRemindDate) :
        order.remindDate && loginUser?.location?.code != 'IL' ? new Intl.DateTimeFormat('en-GB', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            /* hour: '2-digit',
             minute: '2-digit',
             second: '2-digit',*/
        }).format(order.remindDate) : '...';
    const polDisplay = ((order?.shippingModeId === 1) ? (airPolData?.find(pol => pol.id === order.polId)?.name) : (lclPolData?.find(pol => pol.id === order.polId)?.name)) || '-';
    const poaDisplay = ((order?.shippingModeId === 1) ? (airPoaData?.find(poa => poa.id === order.poaId)?.name) : (lclPoaData?.find(poa => poa.id === order.poaId)?.name)) || '-';
    //const poDisplay = order?.suppliersPO ? order?.suppliersPO : '-';
    const poDisplay = order?.clientRefNumber ? order?.clientRefNumber : '-';
    const fileNumberDisplay = order.fileNumber ? order.fileNumber : (order.orderStage[4]?.departureStage[0]?.fileNumber ? order.orderStage[4]?.departureStage[0].fileNumber : "");
    const getFirstName = (name) => {
        var arr = name?.split(" ");
        if (arr?.length) {
            return arr[0]
        }
        return ""
    }
    return (
        <TableRow onClick={() => dispatch(onOrderSelected(order?.id))} style={order?.id === orderSelected ? orderRowBgColor : null}>
            {/*<StyledTableCell rowSpan={1} padding='none'>
        <IconButton size='small' onClick={onHandleSwitchCollapse}>
          {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </StyledTableCell>*/}
            <TableCell sx={{ width: "5vw", p: 0, overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word', lineHeight: '1' }}>
                <Tooltip title={userILDisplay + "(" + JobNumberDisplay + ")" + (order.notes ? ": " + order.notes : "") + "" } >
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", whiteSpace: 'nowrap', lineHeight: '1', fontWeight: `${order.notes ? "bold" : "400"}` }}>
                        {JobNumberDisplay.substring(0, 7)}
                        {/* {getFirstName(userILDisplay)}*/}
                    </p>
                </Tooltip>
                {/*<Tooltip title={JobNumberDisplay}>
                  <Typography fontSize="1vw" variant='body1' component='span' sx={{ lineHeight: '1'}}>
            {JobNumberDisplay}
          </Typography>
        </Tooltip>*/}
            </TableCell>
            {(selectedLocationIndex == 0 || selectedLocationIndex == 1) && <TableCell sx={{ width: "4vw", p: 0, overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word', lineHeight: '1' }}>
                <Tooltip title={userNLDisplay + " " + userNLNumberDisplay}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", whiteSpace: 'nowrap', lineHeight: '1' }}>
                        {userNLNumberDisplay}
                    </p>
                </Tooltip>
            </TableCell>}
            {((selectedLocationIndex == 0 || selectedLocationIndex == 1) && loginUser.authority != "Admin" && loginUser.department == null || loginUser.department == 1) && <TableCell sx={{ width: "6vw", p: 0, lineHeight: '1' }}>
                {(loginUser.location.code === "IL") && <Tooltip title={userILNames.find(user => user.id == order.userILId)?.name}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", lineHeight: '1' }}>
                        {userILNames.find(user => user.id == order.userILId)?.name ? userILNames.find(user => user.id == order.userILId)?.name.substring(0, 9) : "..."}
                    </p>
                </Tooltip>}
                {(loginUser.location.code != "IL" ) && <Tooltip title={userNLDisplay}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", lineHeight: '1' }}>
                        {(userNLDisplay && userNLDisplay != "-") ? userNLDisplay.substring(0, 9) : "..."}
                    </p>
                </Tooltip>}
            </TableCell>}
            {(loginUser.department == 2 || loginUser.department == 3) && loginUser.authority != "Admin" && <TableCell sx={{ width: "6vw", p: 0, lineHeight: '1' }}>
                {((loginUser.department == 2)) && <Tooltip title={userILNames.find(user => user.id == order.userILTracerId)?.name}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", lineHeight: '1' }}>
                        {userILNames.find(user => user.department == 2 && user.id == order.userILTracerId)?.name ? userILNames.find(user => user.id == order.userILTracerId)?.name.substring(0, 9) : "..."}
                    </p>
                </Tooltip>}
                {((loginUser.department == 3)) && <Tooltip title={userILNames.find(user => user.id == order.userILCustomsId)?.name}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", lineHeight: '1' }}>
                        {userILNames.find(user => user.department == 3 && user.id == order.userILCustomsId)?.name ? userILNames.find(user => user.id == order.userILCustomsId)?.name.substring(0, 9) : "..."}
                    </p>
                </Tooltip>
                }
            </TableCell>}
            <TableCell sx={{ maxWidth: "2vw", p: 0, lineHeight: '1' }}>
                <Tooltip title={shippingModeNameDisplay}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "center", lineHeight: '1' }}>
                        {shippingModeNameDisplay}
                    </p>
                </Tooltip>
            </TableCell>
            {/*<TableCell sx={{ minWidth: "3vw", p: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: '1' }}>
                <Tooltip title={subModeNameDisplay}>
                    <Typography fontSize="1vw" variant='body1' component='span' sx={{ lineHeight: '1' }}>
                        {subModeNameDisplay}
                    </Typography>
                </Tooltip>
            </TableCell>*/}
            <TableCell sx={{ minWidth: "2vw", p: 0, lineHeight: '1' }}>
                <Tooltip title={countryOfOriginDisplayToolTip}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: `${selectedLocationIndex == 1 ? "left": "center"}`, lineHeight: '1' }}>
                        {countryOfOriginDisplay.substring(0, 9)}
                    </p>
                </Tooltip>
            </TableCell>
            {selectedLocationIndex == 2 && <TableCell sx={{ maxWidth: "5vw", mixWidth: "5vw", width: "5vw", p: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: '1' }}>
                <Tooltip title={brokerNameDisplay}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", lineHeight: '1', cursor: "pointer" }} onClick={onHandleOpenBrokerModal}>
                        {brokerNameDisplay.length > 11 ? brokerNameDisplay.substring(0, 11).toLowerCase() : brokerNameDisplay.toLowerCase()}
                    </p>
                </Tooltip>
            </TableCell>}
            {openBrokerModal && (
                <Dialog
                    maxWidth='md'
                    open={openBrokerModal}
                    title={'Broker Card'}
                    onHandleCancel={onHandleCancelBrokerModal}>
                    <AddServiceProvider data={order?.customer} onHandleCancel={onHandleCancelBrokerModal} id={null} readOnly={true} />
                </Dialog>
            )}
            <TableCell sx={{ maxWidth: `${loginUser.authority != "Admin" ? "12vw" : "5vw"}`, p: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: '1' }}>
                <Tooltip title={shipperNameDisplay}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", lineHeight: '1', cursor: "pointer" }} onClick={onHandleOpenShipperModal}>
                        {shipperNameDisplay.length > 11 ? shipperNameDisplay.substring(0, 11).toLowerCase() : shipperNameDisplay.toLowerCase()}
                    </p>
                </Tooltip>
            </TableCell>
            {openShipperModal && (
                <Dialog
                    maxWidth='md'
                    open={openShipperModal}
                    title={'Shipper Card'}
                    onHandleCancel={onHandleCancelShipperModal}>
                    <AddServiceProvider data={order?.shipper} onHandleCancel={onHandleCancelShipperModal} id={null} readOnly={true} />
                </Dialog>
            )}
            <TableCell sx={{ maxWidth: `${loginUser.authority != "Admin" ? "12vw" : "5vw"}`, p: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: '1' }}>
                <Tooltip title={consigneeNameDisplay}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", lineHeight: '1', cursor: "pointer" }} onClick={onHandleOpenConsigneeModal}>
                        {consigneeNameDisplay.length > 11 ? consigneeNameDisplay.substring(0, 11).toLowerCase() : consigneeNameDisplay.toLowerCase()}
                    </p>
                </Tooltip>
            </TableCell>
            {/*<TableCell sx={{ maxWidth: `${loginUser.authority != "Admin" ? "12vw" : "5vw"}`, p: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: '1' }}>
                <Tooltip title={consigneeNameDisplay}>
                    <StyledTypography fontSize="1vw" variant='body1' component='span' sx={{ lineHeight: '1' }} onClick={onHandleOpenConsigneeModal}>
                        {consigneeNameDisplay.length > 11 ? consigneeNameDisplay.substring(0, 11) : consigneeNameDisplay}
                    </StyledTypography>
                </Tooltip>
            </TableCell>*/}
            {openConsigneeModal && (
                <Dialog
                    maxWidth='md'
                    open={openConsigneeModal}
                    title={'Consignee Card'}
                    onHandleCancel={onHandleCancelConsigneeModal}>
                    <AddServiceProvider data={order?.consignee} onHandleCancel={onHandleCancelConsigneeModal} id={null} readOnly={true} />
                </Dialog>
            )}
            <TableCell sx={{ maxWidth: "5vw", p: 0, lineHeight: '1' }}>
                <Tooltip title={reminderDateDisplay}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "center", lineHeight: '1', cursor:"pointer" }} onClick={onReminderDateDialogHandleOpen}>
                        {reminderDateDisplay}
                    </p>
                </Tooltip>
            </TableCell>
            {openReminderDateDialog && (
                <SetReminderDialog order={order} openDialog={openReminderDateDialog} setOpenDialog={setOpenReminderDateDialog} loginUser={loginUser} pageName={pageName} />
            )}
            {/* <TableCell sx={{ width: 50, p: 0, pl: "10px", lineHeight: '1', whiteSpace: 'nowrap' }}>
          <Tooltip title={polDisplay}>
            <Typography fontSize="1vw" variant='body1' component='span' sx={{lineHeight: '1'}}>
              {polDisplay}
            </Typography>
          </Tooltip>
      </TableCell> */}
            <TableCell sx={{ maxWidth: "7vw", p: 0, pl: "10px", lineHeight: '1', whiteSpace: 'nowrap' }}>
                <Tooltip title={getETADisplay()}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", lineHeight: '1' }}>
                        {getETADisplay()}
                    </p>
                </Tooltip>
            </TableCell>
            <TableCell sx={{ maxWidth: "7vw", p: 0, pl: "10px", lineHeight: '1', whiteSpace: 'nowrap' }}>
                <Tooltip title={getETDDisplay()}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", lineHeight: '1' }}>
                        {getETDDisplay()}
                    </p>
                </Tooltip>
            </TableCell>
            {(loginUser.department == 2 || loginUser.department == 3) && <TableCell sx={{ maxWidth: "7vw", p: 0, pl: "10px", lineHeight: '1', whiteSpace: 'nowrap' }}>
                <Tooltip title={poaDisplay}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", lineHeight: '1' }}>
                        {poaDisplay}
                    </p>
                </Tooltip>
            </TableCell>}
            {(loginUser.department != 2 && loginUser.department != 3) && <TableCell sx={{ maxWidth: "7vw", p: 0, pl: "10px", lineHeight: '1', whiteSpace: 'nowrap' }}>
                <Tooltip title={poDisplay}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", lineHeight: '1' }}>
                        {poDisplay.substring(0, 9)}
                    </p>
                </Tooltip>
            </TableCell>}
            {(loginUser.department == 2 || loginUser.department == 3) && <TableCell sx={{ maxWidth: "7vw", p: 0, pl: "10px", lineHeight: '1', whiteSpace: 'nowrap' }}>
                <Tooltip title={fileNumberDisplay}>
                    <p fontSize="1vw" variant='body1' component='span' style={{ textAlign: "left", lineHeight: '1' }}>
                        {fileNumberDisplay}
                    </p>
                </Tooltip>
            </TableCell>}
            {<TableCell sx={{ maxWidth: "7vw", p: 0, pl: "10px", lineHeight: '1', whiteSpace: 'nowrap' }}>
                {order.approval ? <Tooltip title={getUser(order.approvalUserId)}>
                    <CheckIcon style={{ width: '1.6vw !important', height: '1.6vw !important', color: "green" }} />
                </Tooltip> : <Tooltip title={getUser(order.approvalUserId)}>
                    <CheckIcon style={{ width: '1.6vw !important', height: '1.6vw !important', color: "#00000000" }} />
                </Tooltip>}
            </TableCell>}
            {/*<TableCell sx={{ width: 50, p: 0, lineHeight: '1', padding: "0 !important" }}>
              {stages}
          </TableCell>*/}
            {stages}
            <TableCell rowSpan={1} className="padding_0">
                <Grid container wrap='nowrap' justifyContent='center' flexDirection='row' alignItems='center' className="padding_0">
                    {/*{order.approval ? <Tooltip title={getUser(order.approvalUserId)}>
                        <CheckIcon style={{ width: '1.6vw !important', height: '1.6vw !important', color: "green" }} />
                    </Tooltip> : <Tooltip title={getUser(order.approvalUserId)}>
                            <CheckIcon style={{ width: '1.6vw !important', height: '1.6vw !important', color: "#00000000" }} />
                    </Tooltip>}*/}
                    <Tooltip title={getCustToolTip(order)}>
                        <IconButton size='small' onClick={onCustChatDialogHandleOpen} style={getOrderChatCustomerStyle(order)}>
                            <Grid container justifyContent="center" alignItems="center"> <MailOutlineIcon style={getCustChatView(order)} /></Grid>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={getToolTip(order)}>
                        <IconButton size='small' onClick={onChatDialogHandleOpen} style={getOrderChatStyle(order)}>
                            <Grid container justifyContent="center" alignItems="center"> <ChatIcon style={getChatView(order)} /></Grid>
                        </IconButton>
                    </Tooltip>
                    {/*<Tooltip title={getToolTip(order)}>
                      <IconButton size='small' onClick={onChatDialogHandleOpen} style={getOrderChatStyle(order)}>
                          <ChatIcon style={getChatView(order)} />
                          <Typography fontSize="1vw" variant='body1' component='span' style={{ display: 'block', lineHeight: '1' }}>{getChatLastUpdateDate(order)}</Typography>
                      </IconButton>
                  </Tooltip>*/}
                    {/*{order.notes ? (
                        <Tooltip title={order.notes}>
                            <NotesIcon style={noteStyle} />
                        </Tooltip>
                    ) : (
                        <NotesIcon />
                    )}*/}

                    {openCustChatDialog && (
                        <Dialog
                            open={openCustChatDialog}
                            onHandleCancel={onCustChatDialogHandleClose}
                            title={'Customer Chat Box - ' + order?.jobNumber}
                            maxWidth='md'>
                            <CustChatDialog onHandleCancel={onCustChatDialogHandleOpen} order={order} loginUser={loginUser} pageName={pageName} />
                        </Dialog>
                    )}
                    {openChatDialog && (
                        <Dialog
                            open={openChatDialog}
                            onHandleCancel={onChatDialogHandleClose}
                            title={'Chat Box - ' + order?.jobNumber}
                            maxWidth='md'>
                            <ChatDialog shipperNameDisplay={shipperNameDisplay} consigneeNameDisplay={consigneeNameDisplay} onHandleCancel={onChatDialogHandleClose} order={order} loginUser={loginUser} pageName={pageName} />
                        </Dialog>
                    )}
                    {/*<Button variant='outlined' sx={{ mr: 1, margin: "5px" }} onClick={onOrderModalHandleOpen}>
                    Edit
                  </Button>*/}
                    { /* pageName === 'orderHistory' ? null : */
                        <Tooltip title='Edit Order'>
                            <IconButton size='small' onClick={onOrderModalHandleOpen}>
                                <EditIcon style={{ width: '1.6vw !important', height: '1.6vw !important' }} />
                            </IconButton>
                        </Tooltip>
                    }
                    {openOrderModal && (
                        <Dialog
                            open={openOrderModal}
                            onHandleCancel={onOrderModalHandleCancel}
                            title={'Order information - ' + order?.jobNumber}
                            maxWidth='md'>
                            <OrderForm loginUser={loginUser} onHandleCancel={onOrderModalHandleCancel} order={order} orderTableFilter={orderTableFilter} pageName={pageName} />
                        </Dialog>
                    )}
                    {/*
                    <Button variant='outlined' color='error' onClick={onConfirmDialogHandleOpen}>
                      Del
                    </Button>
        */}
                    {loginUser.authorities && loginUser.authorities[0].authority === 'Admin' && (
                        <Tooltip title='Delete Order'>
                            <IconButton size='small' onClick={onConfirmDialogHandleOpen}>
                                <DeleteForeverIcon style={{ width: '1.6vw !important', height: '1.6vw !important' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                    {openConfirmDialog && (
                        <ConfirmDialog
                            open={openConfirmDialog}
                            onHandleClose={onConfirmDialogHandleClose}
                            message='Are you sure you want to permanently delete this order?'
                            onHandleYes={() => onOrderHandleDelete(order.id)}
                        />
                    )}
                    {pageName !== 'orderHistory' && (
                        <Tooltip title='Move to Order History'>
                            <IconButton size='small' onClick={onHistoryConfirmDialogHandleOpen}>
                                <ArrowRightIcon style={{ width: '1.6vw !important', height: '1.6vw !important' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                    {openHistoryConfirmDialog && (
                        <ConfirmDialog
                            open={openHistoryConfirmDialog}
                            onHandleClose={onHistoryConfirmDialogHandleClose}
                            message='Are you sure you want to move order to history?'
                            onHandleYes={() => onOrderHandleHistory(order.id)}
                        />
                    )}
                    {pageName === 'orderHistory' && (
                        <Tooltip title='Move to Order'>
                            <IconButton size='small' onClick={onHistoryRevertConfirmDialogHandleOpen}>
                                <RefreshIcon style={{ width: '1.6vw !important', height: '1.6vw !important' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                    {openHistoryRevertConfirmDialog && (
                        <ConfirmDialog
                            open={openHistoryRevertConfirmDialog}
                            onHandleClose={onHistoryRevertConfirmDialogHandleClose}
                            message='Are you sure you want to move order out of history?'
                            onHandleYes={() => onOrderHandleHistoryRevert(order.id)}
                        />
                    )}
                    
                    <Tooltip title='Check mail history'>
                        <IconButton size='small' onClick={onMailModalHandleOpen}>
                            <AlternateEmailIcon style={{ width: '1.6vw !important', height: '1.6vw !important' }} />
                        </IconButton>
                    </Tooltip>

                    {openMailModal && (
                        <Dialog
                            open={openMailModal}
                            onHandleCancel={onMailModalHandleCancel}
                            title={'Mails'}
                            maxWidth='md'>
                            <MailAuditDlg order={order} />
                        </Dialog>
                    )}

                    {(loginUser.department == 1 || loginUser.department == null) && <Tooltip title='Create a new order with this order as template'>
                        <IconButton size='small' onClick={onOrderCopyModalHandleOpen}>
                            <ContentCopyIcon style={{ width: '1.6vw !important', height: '1.6vw !important' }} />
                        </IconButton>
                    </Tooltip>}

                    {openCopyOrderModal && (
                        <Dialog
                            open={openCopyOrderModal}
                            onHandleCancel={onOrderCopyModalHandleCancel}
                            title={'Order information - ' + getCopyOrder(order)?.jobNumber}
                            maxWidth='md'>
                            <OrderForm loginUser={loginUser} onHandleCancel={onOrderCopyModalHandleCancel} order={getCopyOrder1(order)} copyOrder={getCopyOrder(order)} orderTableFilter={orderTableFilter} pageName={pageName} />
                        </Dialog>
                    )}
                    
                    {/*{(pageName !== 'orderHistory' && loginUser.authority === "Admin") && (
                        <Tooltip title='Back to Previous Stage'>
                            <IconButton disabled={order?.orderStage[4]?.departureStage?.length > 0} size='small' onClick={onBackStageConfirmDialogHandleOpen}>
                                <BackIcon style={{ width: '1.6vw !important', height: '1.6vw !important' }} />
                            </IconButton>
                        </Tooltip>
                    )}*/}
                    {backStageConfirmDialog && (
                        <ConfirmDialog
                            open={backStageConfirmDialog}
                            onHandleClose={onBackStageConfirmDialogHandleClose}
                            message='Are you sure you want to move order to previous stage?'
                            onHandleYes={() => onOrderHandleBackStage(order.id)}
                        />
                    )}
                    {(loginUser.authorities && loginUser.authorities[0].authority === 'Admin') && <Tooltip title='Get logs for this order.'>
                        <IconButton size='small' onClick={onOrderLogModalHandleOpen}>
                            <RestoreIcon style={{ width: '1.6vw !important', height: '1.6vw !important' }} />
                        </IconButton>
                    </Tooltip>}
                    {openLogOrderModal && (
                        <Dialog
                            open={openLogOrderModal}
                            onHandleCancel={onOrderLogModalHandleCancel}
                            title='Audit Logs'
                            fullWidth='true'
                            maxWidth='lg'>
                            <AuditLogs jSearch={order.jobNumber} />
                        </Dialog>
                    )}
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default memo(OrderRowInfo);

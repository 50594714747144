import { createSlice } from '@reduxjs/toolkit';
import { expandData } from 'utils/Communicator';

export const consigneesSlice = createSlice({
  name: 'consignees',
  initialState: {
    consignees: [],
    consigneeNameOptions: [],
  },
  reducers: {
    onConsigneesChange: (state, { payload }) => {
      state.consignees = payload;
    },
    onConsigneeNameOptionsChange: (state, { payload }) => {
      state.consigneeNameOptions = payload;
    },
    addConsignee: (state, { payload }) => {
      state.consignees = [...state.consignees, payload];
    },
    updateConsignee: (state, { payload }) => {
      const consignee1 = state.consignees.find(consignee => consignee.id === payload.id);
      const consigneeIndex = state.consignees.indexOf(consignee1);
      const before = state.consignees.slice(0, consigneeIndex);
      const after = state.consignees.slice(consigneeIndex + 1);
      const newConsignees = [...before, payload, ...after];
      state.consignees = newConsignees;
    },
  },
});

// Action creators are generated for each case reducer function
export const { onConsigneesChange, onConsigneeNameOptionsChange, addConsignee, updateConsignee } =
  consigneesSlice.actions;

export default consigneesSlice.reducer;

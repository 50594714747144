import { memo, useState } from 'react';
import StageButton from '../StageButton';
import { TableCell } from '@mui/material';
import ConfirmationDialog from './ConfirmationDialog';
import {StyledTableCell} from 'Utils';

const Confirmation = ({ order, loginUser, pageName }) => {
    const getStatus = () => {
        var stage = order.orderStage ? order.orderStage[0] : null;
        if (order.locationId != 2) {
            if (order.incotermsId == 3 || order.incotermsId == 4 || order.incotermsId == 5 || order.incotermsId == 6) {
                stage = {};
                stage.status = "dissable";
                stage.notes = "";
            }
        }
        return stage;
    }
    const currentStage = order.orderStage? order.orderStage[0]:null;

  const [openDialog, setOpenDialog] = useState(false);
  const onHandleOpenDialog = () => setOpenDialog(true);

  return (
      <StyledTableCell style={{ width: "5vw",p:0 }}>
          <StageButton stage={getStatus()} onHandleOpenDialog={onHandleOpenDialog} stageName='Confirm' loginUser={loginUser}/>
      {openDialog && <ConfirmationDialog loginUser={loginUser} order={order} openDialog={openDialog} setOpenDialog={setOpenDialog} pageName={pageName}/>}
    </StyledTableCell>
  );
};

export default memo(Confirmation);

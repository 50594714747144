import { Fragment, useCallback, useEffect, useState } from 'react';

import { Box, Grid, Button, CircularProgress } from '@mui/material';
import AirDetails from './OrderDetails/AirDetails';
import FCLDetails from './OrderDetails/FCLDetails';
import LCLDetails from './OrderDetails/LCLDetails';
import OrderInformation from './OrderInformation';

import { sendRequest, REQUEST_ACTIONS } from 'utils/Communicator';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { getOrders, onParcelsCountChange, onChatCountChange, onUserSpecificParcelsCountChange, onUnConfirmedParcelsCountChange } from 'reducers/parcel-reducers/ordersSlicer';
import {
  onAirPoaDataChange,
  onAirPolDataChange,
  onLclPoaDataChange,
  onLclPolDataChange,
  onIncotermsDataChange,
  onFCLType20DataChange,
  onFCLType40DataChange,
} from 'reducers/parcel-reducers/predefinedDataSlicer';
import { addParcelFilter, addSearchFilterParcel } from 'Utils';

const OrderForm = ({ onHandleCancel, order, loginUser, orderTableFilter, pageName }) => {
  const { incotermsData, airPolData, airPoaData, lclPolData, lclPoaData, userILNames, fclType20Data, fclType40Data } =
      useSelector(state => state.parcelPredefinedData);
  const { selectedLocationIndex, selectedTabIndexName } = useSelector(state => state.parcelTableFilter);
  const initialState = {
    userId: null,
    jobNumber: null,
    coClient: null,
    coVatId: null,
    price: null,
    priceVat: null,
    priceWeight: null,
    priceDesc: null,
    priceTbd1: null,
    priceTbd2: null,
    userILId:
      loginUser?.location.code === 'IL' ? userILNames.find(user => user.name === loginUser.fullName)?.id : null || null,
    incotermsId: null,
    shipperId: null,
    consigneeId: null,
    countryOfOriginId: null,
    clientRefNumber: null,
    suppliersPO: null,
    customerId: null,
    shippingModeId: 1,
    subModeId: null,
    polId: null,
    poaId: null,
    notes: null,
    parcelDetails: [],
    orderFCLDetails: null,
    orderStage: [],
    codeFileCount: [],
    files: [],
    numberOfPieces: null,
    weightPerPiece: null,
    width: null,
    height: null,
    length: null,
    manualVolume: null,
    manualWeight: null,
    totalweight: null,
    totalvolume: null,
    orderStageId: null,
    quantity20: null,
    quantity40: null,
    fclType20Id: null,
    fclType40Id: null,
  };

  const [state, setState] = useState(initialState);
  const [isLoaded, setIsLoaded] = useState(false);

  const onStateChange = (field, value) => setState(state => ({ ...state, [field]: value }));

  const dispatch = useDispatch();
    const { orders, page, size, chatCount, userSpecificParcelsCount } = useSelector(state => state.parcels);
    const { formData } = useSelector(state => state.parcelInfo);

  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

  // predefined incoterms data get
  useEffect(() => {
    if (!incotermsData) {
      sendRequest('/api/incoterms', REQUEST_ACTIONS.GET, '', {
        successCallback: response => dispatch(onIncotermsDataChange(response)),
        failedCallback,
      });
    }
  }, [dispatch, failedCallback, incotermsData]);

  // predefined air pol data get
  useEffect(() => {
    if (!airPolData) {
      sendRequest('/api/pol/air', REQUEST_ACTIONS.GET, '', {
        successCallback: response => dispatch(onAirPolDataChange(response)),
        failedCallback,
      });
    }
  }, [dispatch, failedCallback, airPolData]);

  // predefined air poa data get
  useEffect(() => {
    if (!airPoaData) {
      sendRequest('/api/poa/air', REQUEST_ACTIONS.GET, '', {
        successCallback: response => dispatch(onAirPoaDataChange(response)),
        failedCallback,
      });
    }
  }, [dispatch, failedCallback, airPoaData]);

  // predefined lcl/fcl pol data get
  useEffect(() => {
    if (!lclPolData) {
      sendRequest('/api/pol/lcl', REQUEST_ACTIONS.GET, '', {
        successCallback: response => dispatch(onLclPolDataChange(response)),
        failedCallback,
      });
    }
  }, [dispatch, failedCallback, lclPolData]);

  // predefined lcl/fcl poa data get
  useEffect(() => {
    if (!lclPoaData) {
      sendRequest('/api/poa/lcl', REQUEST_ACTIONS.GET, '', {
        successCallback: response => dispatch(onLclPoaDataChange(response)),
        failedCallback,
      });
    }
  }, [dispatch, failedCallback, lclPoaData]);

  // predefined fcl type 20 data get
  useEffect(() => {
    if (!fclType20Data) {
      sendRequest('/api/fclType20', REQUEST_ACTIONS.GET, '', {
        successCallback: response => dispatch(onFCLType20DataChange(response)),
        failedCallback,
      });
    }
  }, [dispatch, failedCallback, fclType20Data]);

  // predefined fcl type 40 data get
  useEffect(() => {
    if (!fclType40Data) {
      sendRequest('/api/fclType40', REQUEST_ACTIONS.GET, '', {
        successCallback: response => dispatch(onFCLType40DataChange(response)),
        failedCallback,
      });
    }
  }, [dispatch, failedCallback, fclType40Data]);

  // loading spinner toggle
  useEffect(() => {
    setIsLoaded(
      incotermsData && airPolData && airPoaData && lclPolData && lclPoaData && fclType20Data && fclType40Data,
    );
  }, [incotermsData, airPolData, airPoaData, lclPolData, lclPoaData, fclType20Data, fclType40Data]);

  // find current order and update state
  useEffect(() => {
    if (order?.id) {
      let filesArray = [];
      for (const parStage of order.parcelStage) {
        if (parStage.archives && parStage.archives.length > 0) {
          filesArray.push(...parStage.archives);
        } 
      }
      setState(state => ({
        ...state,
        ...order,
        ...order?.orderFCLDetails,
        consigneeId: order?.consignee?.id,
        shipperId: order?.shipper?.id,
        customerId: order?.customer?order?.customer?.id:null,
        orderStageId: order.parcelStage[0].id,
        files: filesArray,
      }));
    }
  }, [order?.id, setState]);

  // create order
  const onHandleCreateOrder = () => {

    const {
      jobNumber,
      coClient,
      coVatId,
      price,
      priceVat,
      priceWeight,
      priceDesc,
      priceTbd1,
      priceTbd2,
      userILId,
      incotermsId,
      shipperId,
      consigneeId,
      countryOfOriginId, originState,
      clientRefNumber,
      suppliersPO,
      customerId,
      shippingModeId,
      subModeId,
      polId,
      poaId,
      notes,
      numberOfPieces,
      weightPerPiece,
      width,
      height,
      length,
      quantity20,
      quantity40,
      fclType20Id,
      fclType40Id,
      totalweight,
      totalvolume,
    } = state;

    let parcelDetails = [];
    let orderFCLDetails = null;

    // shipping mode Air
    if (shippingModeId === 1 || shippingModeId === 2) {
      const isAddDetails = totalweight || totalvolume || numberOfPieces || weightPerPiece || width || height || length;

      // details without id
      const detailsWithoutId = state.parcelDetails.map(item => {
        if (typeof item.id === 'string') {
          return {
            numberOfPieces: item.numberOfPieces,
            length: item.length,
            width: item.width,
            height: item.height,
            weightPerPiece: item.weightPerPiece,
            manualWeight: null,
            manualVolume: null,
            totalweight: item.totalweight,
            totalvolume: item.totalvolume,
          };
        }
        return item;
      });

      if (isAddDetails) {
        parcelDetails = [
          ...detailsWithoutId,
          {
            numberOfPieces,
            length,
            width,
            height,
            weightPerPiece,
            manualWeight: null,
            manualVolume: null,
            totalweight,
            totalvolume,
          },
        ];
      }
    }

    // // shipping mode LCL
    // if (shippingModeId === 2) {
    //   const isAddAllDetails =
    //     numberOfPieces && weightPerPiece && width && height && length && manualWeight && manualVolume;

    //   // details without id
    //   const detailsWithoutId = state.parcelDetails.map(item => {
    //     if (typeof item.id === 'string') {
    //       return {
    //         numberOfPieces: item.numberOfPieces,
    //         length: item.length,
    //         width: item.width,
    //         height: item.height,
    //         weightPerPiece: item.weightPerPiece,
    //         manualWeight: item.manualWeight,
    //         manualVolume: item.manualVolume,
    //       };
    //     }
    //     return item;
    //   });

    //   if (isAddAllDetails) {
    //     parcelDetails = [
    //       ...detailsWithoutId,
    //       {
    //         numberOfPieces,
    //         length,
    //         width,
    //         height,
    //         weightPerPiece,
    //         manualWeight,
    //         manualVolume,
    //       },
    //     ];
    //   }
    // }

    // shipping mode FCL
    if (shippingModeId === 3) {
      const isAddDetails = quantity20 || quantity40 || fclType20Id || fclType40Id;

      if (isAddDetails) {
        orderFCLDetails = {
          quantity20,
          quantity40,
          fclType20Id,
          fclType40Id,
        };
      }
    }

    //if (!parcelDetails.length && !orderFCLDetails) {
    //  return dispatch(snackbarToggle({ type: 'error', message: 'Please add all details' }));
    //}
    const dropStatus = (selectedTabIndexName == 'Drops' || selectedTabIndexName == 'DropsHistory');
    // create new order data
    const newOrder = {
      userId: 1,
      jobNumber,
      coClient,
      coVatId,
      price,
      priceVat,
      priceWeight,
      priceDesc,
      priceTbd1,
      priceTbd2,
      dropStatus,
      userILId,
      incotermsId,
      shipper: { id: shipperId },
      consignee: { id: consigneeId },
      countryOfOriginId, originState,
      clientRefNumber,
      suppliersPO,
      customer: { id: customerId },
      shippingModeId,
      subModeId,
      polId,
      poaId,
      chargeWeight: 9,
      notes,
      parcelDetails,
      orderFCLDetails,
    };

    if(polId === undefined || polId === ""){
      dispatch(snackbarToggle({ type: 'error', message: 'POL can not be empty!' }));
      return;
    }
      if (!clientRefNumber?.match(/^[0-9a-zA-Z]+$/) && clientRefNumber !== null && clientRefNumber !== ""){
      dispatch(snackbarToggle({ type: 'error', message: 'Client Ref # can only be letters and numbers.' }));
      return;
    }

    var date = new Date();
    var chat = {
      'time': date.toUTCString(),
      'text': 'New Order has been placed',
      'initiator': loginUser.fullName,
      'initiatorType': loginUser.location.code == 'NL' ? '3' : '2',
      'location': loginUser.location.code,
      'ILStatus': loginUser.authorities[0].role.id == 1?false:loginUser.location.code == 'IL',
      'NLStatus': loginUser.authorities[0].role.id == 1?false:(loginUser.location.code == 'NL' || loginUser.location.code == 'US')
    }
	  var chat_list = [];
    chat_list[0] = chat;
    newOrder.chat = JSON.stringify(chat_list);
    var selectedLocation = "NL"

    if (loginUser?.authorities && loginUser?.authorities[0]?.authority === 'User' && (loginUser?.location?.code === 'NL' || loginUser?.location?.code === 'US')) {
      selectedLocation = loginUser?.location?.code;
    } else if (selectedLocationIndex == 1) {
      selectedLocation = "US"
    }

    let actionName = 'ParcelCreation';
    if(selectedTabIndexName == 'Drops' || selectedTabIndexName == 'DropsHistory') {
      actionName = 'DropCreation'
    }
      sendRequest('/api/parcel?fromStage=0&loginUserId='+loginUser?.id+'&actionOn='+actionName+'&selectedLocation='+selectedLocation, REQUEST_ACTIONS.POST, newOrder, {
      successCallback: response => {
        if (!formData) {
            sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, orderTableFilter?.selectedLocationIndex, orderTableFilter?.selectedTabIndexName)}${addSearchFilterParcel(orderTableFilter?.userLocalNumber, orderTableFilter?.userAbroadNumber, orderTableFilter?.consigneeId, orderTableFilter?.shipperId, orderTableFilter?.countryOfOriginId, orderTableFilter?.originState, orderTableFilter?.shippingModeId, orderTableFilter?.subModeId, orderTableFilter?.userLocalId, orderTableFilter?.userAbroadId, orderTableFilter?.dueDate, orderTableFilter?.search, orderTableFilter?.lastUpdateDate, orderTableFilter?.reminderDate, orderTableFilter?.ilReminderDate , orderTableFilter?.startReminderDate, orderTableFilter?.endReminderDate, orderTableFilter?.startIlReminderDate, orderTableFilter?.endIlReminderDate, orderTableFilter?.stageSearch, orderTableFilter?.stageStateSearch, orderTableFilter?.reminderDateAdv, orderTableFilter?.ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                    sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, orderTableFilter?.selectedLocationIndex, orderTableFilter?.selectedTabIndexName)}${addSearchFilterParcel(orderTableFilter?.userLocalNumber, orderTableFilter?.userAbroadNumber, orderTableFilter?.consigneeId, orderTableFilter?.shipperId, orderTableFilter?.countryOfOriginId, orderTableFilter?.originState, orderTableFilter?.shippingModeId, orderTableFilter?.subModeId, orderTableFilter?.userLocalId, orderTableFilter?.userAbroadId, orderTableFilter?.dueDate, orderTableFilter?.search, orderTableFilter?.lastUpdateDate, orderTableFilter?.reminderDate, orderTableFilter?.ilReminderDate , orderTableFilter?.startReminderDate, orderTableFilter?.endReminderDate, orderTableFilter?.startIlReminderDate, orderTableFilter?.endIlReminderDate, orderTableFilter?.stageSearch, orderTableFilter?.stageStateSearch, orderTableFilter?.reminderDateAdv, orderTableFilter?.ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                        successCallback: response => { dispatch(onParcelsCountChange(response?.parcelsCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount));sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount));dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount));},
                failedCallback,
              });
              dispatch(getOrders(response));
              dispatch(
                snackbarToggle({
                  type: 'success',
                  message: 'Order created successfully',
                }),
              );
              onHandleCancel();
            },
            failedCallback,
          });
          return;
        }
        formData.append('orderStageId', response.parcelStage[0].id);
        formData.append('orderId', response.id);
        sendRequest('/api/upload', REQUEST_ACTIONS.POST, formData, {
          successCallback: () => {
                sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, orderTableFilter?.selectedLocationIndex, orderTableFilter?.selectedTabIndexName)}${addSearchFilterParcel(orderTableFilter?.userLocalNumber, orderTableFilter?.userAbroadNumber, orderTableFilter?.consigneeId, orderTableFilter?.shipperId, orderTableFilter?.countryOfOriginId, orderTableFilter?.originState, orderTableFilter?.shippingModeId, orderTableFilter?.subModeId, orderTableFilter?.userLocalId, orderTableFilter?.userAbroadId, orderTableFilter?.dueDate, orderTableFilter?.search, orderTableFilter?.lastUpdateDate, orderTableFilter?.reminderDate, orderTableFilter?.ilReminderDate , orderTableFilter?.startReminderDate, orderTableFilter?.endReminderDate, orderTableFilter?.startIlReminderDate, orderTableFilter?.endIlReminderDate, orderTableFilter?.stageSearch, orderTableFilter?.stageStateSearch, orderTableFilter?.reminderDateAdv, orderTableFilter?.ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
              successCallback: response => {
                        sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, orderTableFilter?.selectedLocationIndex, orderTableFilter?.selectedTabIndexName)}${addSearchFilterParcel(orderTableFilter?.userLocalNumber, orderTableFilter?.userAbroadNumber, orderTableFilter?.consigneeId, orderTableFilter?.shipperId, orderTableFilter?.countryOfOriginId, orderTableFilter?.originState, orderTableFilter?.shippingModeId, orderTableFilter?.subModeId, orderTableFilter?.userLocalId, orderTableFilter?.userAbroadId, orderTableFilter?.dueDate, orderTableFilter?.search, orderTableFilter?.lastUpdateDate, orderTableFilter?.reminderDate, orderTableFilter?.ilReminderDate , orderTableFilter?.startReminderDate, orderTableFilter?.endReminderDate, orderTableFilter?.startIlReminderDate, orderTableFilter?.endIlReminderDate, orderTableFilter?.stageSearch, orderTableFilter?.stageStateSearch, orderTableFilter?.reminderDateAdv, orderTableFilter?.ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                            successCallback: response => { dispatch(onParcelsCountChange(response?.parcelsCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);  dispatch(onChatCountChange(response?.unreadChatCount));sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount));dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount));},
                  failedCallback,
                });
                dispatch(getOrders(response));
                dispatch(
                  snackbarToggle({
                    type: 'success',
                    message: 'Order created successfully',
                  }),
                );
                onHandleCancel();
              },
              failedCallback,
            });
          },
          failedCallback,
        });
      },
      failedCallback,
    });
  };

  // update order
  const onHandleUpdateOrder = () => {
    const {
      jobNumber,
      coClient,
      coVatId,
      price,
      priceVat,
      priceWeight,
      priceDesc,
      priceTbd1,
      priceTbd2,
      userILId,
      incotermsId,
      shipperId,
      consigneeId,
      countryOfOriginId, originState,
      clientRefNumber,
      suppliersPO,
      customerId,
      shippingModeId,
      subModeId,
      polId,
      poaId,
      notes,
      numberOfPieces,
      weightPerPiece,
      width,
      height,
      length,
      quantity20,
      quantity40,
      fclType20Id,
      fclType40Id,
      totalweight,
      totalvolume,
    } = state;

    let parcelDetails = [];
    let orderFCLDetails = null;

    // shipping mode Air
    if (shippingModeId === 1 || shippingModeId === 2) {
      const isAddDetails = totalweight || totalvolume || numberOfPieces || weightPerPiece || width || height || length;

      // details without id
      const detailsWithoutId = state.parcelDetails.map(item => {
        if (typeof item.id === 'string') {
          return {
            numberOfPieces: item.numberOfPieces,
            length: item.length,
            width: item.width,
            height: item.height,
            weightPerPiece: item.weightPerPiece,
            manualWeight: null,
            manualVolume: null,
            totalweight: item.totalweight,
            totalvolume: item.totalvolume,
          };
        }
        return item;
      });

      if (isAddDetails) {
        parcelDetails = [
          ...detailsWithoutId,
          {
            numberOfPieces,
            length,
            width,
            height,
            weightPerPiece,
            manualWeight: null,
            manualVolume: null,
            totalweight,
            totalvolume,
          },
        ];
      } else {
        parcelDetails = detailsWithoutId;
      }

     // if (!parcelDetails.length) {
     //   return dispatch(snackbarToggle({ type: 'error', message: 'Please add all details' }));
     // }
    }

    // // shipping mode LCL
    // if (shippingModeId === 2) {
    //   // details without id
    //   const detailsWithoutId = state.parcelDetails.map(item => {
    //     if (typeof item.id === 'string') {
    //       return {
    //         numberOfPieces: item.numberOfPieces,
    //         length: item.length,
    //         width: item.width,
    //         height: item.height,
    //         weightPerPiece: item.weightPerPiece,
    //         manualWeight: item.manualWeight,
    //         manualVolume: item.manualVolume,
    //       };
    //     }
    //     return item;
    //   });

    //   parcelDetails = [
    //     ...detailsWithoutId,
    //     {
    //       numberOfPieces,
    //       length,
    //       width,
    //       height,
    //       weightPerPiece,
    //       manualWeight,
    //       manualVolume,
    //     },
    //   ];
    // }

    // shipping mode FCL
    if (shippingModeId === 3) {
      const isAddDetails = quantity20 || quantity40 || fclType20Id || fclType40Id;

      if (isAddDetails) {
        orderFCLDetails = {
          quantity20,
          quantity40,
          fclType20Id,
          fclType40Id,
        };
      }
    }

    // updated order data
    const updatedOrder = {
      ...order,
      jobNumber,
      coClient,
      coVatId,
      price,
      priceVat,
      priceWeight,
      priceDesc,
      priceTbd1,
      priceTbd2,
      userILId,
      incotermsId,
      shipper: { id: shipperId },
      consignee: { id: consigneeId },
      countryOfOriginId, originState,
      clientRefNumber,
      suppliersPO,
      customer: { id: customerId },
      shippingModeId,
      subModeId,
      polId,
      poaId,
      notes,
      parcelDetails,
      orderFCLDetails,
    };

    let actionName = 'ParcelUpdate';
    if(selectedTabIndexName == 'Drops' || selectedTabIndexName == 'DropsHistory') {
      actionName = 'DropUpdate'
    }
      sendRequest(`/api/parcel?fromStage=0&loginUserId=`+loginUser?.id+`&actionOn=`+actionName, REQUEST_ACTIONS.PUT, updatedOrder, {
      successCallback: () => {
              sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, orderTableFilter?.selectedLocationIndex, orderTableFilter?.selectedTabIndexName)}${addSearchFilterParcel(orderTableFilter?.userLocalNumber, orderTableFilter?.userAbroadNumber, orderTableFilter?.consigneeId, orderTableFilter?.shipperId, orderTableFilter?.countryOfOriginId, orderTableFilter?.originState, orderTableFilter?.shippingModeId, orderTableFilter?.subModeId, orderTableFilter?.userLocalId, orderTableFilter?.userAbroadId, orderTableFilter?.dueDate, orderTableFilter?.search, orderTableFilter?.lastUpdateDate, orderTableFilter?.reminderDate, orderTableFilter?.ilReminderDate , orderTableFilter?.startReminderDate, orderTableFilter?.endReminderDate, orderTableFilter?.startIlReminderDate, orderTableFilter?.endIlReminderDate, orderTableFilter?.stageSearch, orderTableFilter?.stageStateSearch, orderTableFilter?.reminderDateAdv, orderTableFilter?.ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
          successCallback: response => {
            dispatch(getOrders(response));
            dispatch(
              snackbarToggle({
                type: 'success',
                message: 'Order updated successfully',
              }),
            );
            onHandleCancel();
          },
          failedCallback,
        });
      },
      failedCallback,
    });
  };

  return (
    <Box component='form' noValidate autoComplete='off'>
      <Grid container spacing={2} position='relative'>
        {!isLoaded ? (
          <Grid container item justifyContent='center' mt={2}>
            <CircularProgress />
          </Grid>
        ) : (
          <Fragment>
            <OrderInformation loginUser={loginUser} orderId={order?.id} state={state} onStateChange={onStateChange} pageName={pageName} onHandleCloseParentModal={onHandleCancel}/>
            {state.shippingModeId === 1 ? <AirDetails state={state} onStateChange={onStateChange} /> : null}
            {state.shippingModeId === 2 ? <LCLDetails state={state} onStateChange={onStateChange} /> : null}
            {state.shippingModeId === 3 ? <FCLDetails state={state} onStateChange={onStateChange} /> : null}
            <Grid container item justifyContent='flex-end'>
              <Button variant='contained' sx={{ mr: 2 }} onClick={order?.id ? onHandleUpdateOrder : onHandleCreateOrder}>
                Save
              </Button>
              <Button onClick={onHandleCancel} variant='contained' color='secondary'>
                Cancel
              </Button>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Box>
  );
};

export default OrderForm;

/* eslint-disable react/prop-types */
import { Box, Grid, Button, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import shipIcon from "../../../../resources/ship_icon.svg"
import airIcon from "../../../../resources/air.png"
import lclIcon from "../../../../resources/lcl.png"
import fclIcon from "../../../../resources/fcl.png"
import TargetIcon from "../../../../resources/target_icon.svg"
import ListView from "../../../../resources/list_icon.png"
import CloseIcon from "../../../../resources/close_icon.svg"
// Import Swiper Slider
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import './ShipmentDataMap.scss';
import GoogleMapWrapper from "shipTracker/GoogleMapWrapper";
import { TABS } from "constants/global-constants";
import { Scrollbars } from 'react-custom-scrollbars';
import { getDestination, getEstOn, getOrigin } from "../commonUtility";

const ShipmentDataMap = ({ orders, polAir, podAir, polSea, podSea, cOrigin, setisShipmentList, orderDetailHandle}) => {
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [ordersList, setOrdersList] = useState([]);
    const [isSlideDetails, setIsSlideDetails] = useState(false);
    // const [orderId, setOrderId] = useState("");
    // const [origin, setOrigin] = useState("");
    // const [destination, setDestination] = useState("");

    useEffect(() => {
        if (orders?.length > 0) {
           const orderFilter =  orders.filter((order) => order.orderStatus[4].status === 'SUCCESS' && order);

           if (orderFilter.length > 0)  {
            setOrdersList(orderFilter);
            setSelectedOrder(orderFilter[0])
            }
        }
    }, [orders])

    const getDate1 = () => {
        var date = new Date()
        var previous = new Date(date.getTime())
        previous.setDate(date.getDate() - 1);
        return previous.getTime();
     }
     const getDate = (d) => {
        var date = new Date(d);
        var month = date.getMonth(date);
        var day = date.getDate(date);
        var year = date.getFullYear(date);
 
         return `${day}/${month}/${year}`;
     }
     const getTime = (d) => {
         var date = new Date(d);
         var hr = date.getHours(date);
         var min = date.getMinutes(date);
  
          return `${hr}:${min}`;
      }
    
      const getStatus = (order) => {
        const status = [
            {id: 2, name: 'No Reply'},
            {id: 3, name: 'Not Ready'},
            {id: 4, name: 'Shipment on hold'},
            {id: 5, name: 'Expected Ready.'},
            {id: 6, name: 'Ready no trucks'},
            {id: 7, name: 'Ready no Vessel / flight'},
            {id: 8, name: 'Ready for pick-up'},
            {id: 9, name: 'ETA '},
            {id: 10, name: 'Booked'},
            {id: 11, name: 'Collected'},
            {id: 12, name: 'ETA Approved'},
            {id: 15, name: 'ETA / ETD'},
            {id: 16, name: 'Actual Departure'},
        ];
        let returnStatus = ''
        for (var i = 0; i < 5; i++) {
            if (order.orderStatus[i]?.status === 'IN_PROGRESS' || order.orderStatus[i]?.status === 'FAILED') {
                if (i !== 0 && i !== 3) {
                    status.forEach((s)=> {
                        if (s.id === order.orderStatus[i]?.stageStatusInfoId) {
                            returnStatus = s?.name;
                        }
                    });
                }
                else if (i === 0) {
                    returnStatus = 'Confirmation';
                }
                else if (i === 3) {
                    if (order.orderStatus[i].whStageStage[0]?.arrived) {
                        returnStatus = 'W/H: Arrived';
                    }
                    else {
                        returnStatus = 'W/H: Not Arrived';
                    }
                }
            }
        }
        if (order.orderStatus[4].status === 'SUCCESS') {
            returnStatus = 'Picked Up';
        }

        return returnStatus;
    };

    const onClickTargetHandle = (order) => {
        setSelectedOrder(order);
        setIsSlideDetails(!isSlideDetails);
    }


    const shipmentSlide = (order) => {
        return <Grid className="shipment-slide" onClick={() => setSelectedOrder(order)}>
            <Box className="shipment-img"><img src={order.shippingModeId == 3 ? fclIcon : order.shippingModeId == 2 ? lclIcon : airIcon} alt="" /></Box>
            <Box className="shipment-details">
                <Box className="shipment-no">
                    {order.jobnum}
                </Box>
                <Grid className="source-destination">
                    <Box className="from">{getOrigin(order)}</Box>
                    <Box className="to">{getDestination(order)}</Box>
                </Grid>
                <Box className="status-date">
                    <Box className="status">Picked Up</Box>
                    <Box className="eta-time">
                        {`ETD ${getEstOn(order)}`}
                    </Box>
                </Box>
            </Box>
            <Box className="shipment-actions">
                <Button className="target-action" onClick={() => onClickTargetHandle(order)}><img src={TargetIcon} alt="" /></Button>
                <Button className="btn-primary btn-more" onClick={() => orderDetailHandle(order, undefined)}>More Details</Button>
            </Box>
        </Grid>
    }

    return ( <Box className="shipment-map-details">
            <Box className="map-container">
                <Button className="list-view" onClick={() => setisShipmentList(true)}><img src={ListView} alt="" /> Tile View</Button>
                {selectedOrder?.orderStatus[5]?.orderStatusStage?.carrierStatus && <GoogleMapWrapper
                            data={selectedOrder.orderStatus[5].orderStatusStage.carrierStatus}
                            trackerType={selectedOrder.shippingModeId}
                        />}
                <Box className={`order-slide-details ${isSlideDetails && 'open'}`}>
                    <Button className="target-close" onClick={() => setIsSlideDetails(!isSlideDetails)}><img src={CloseIcon} alt="" /></Button>
                    {false && (
                        <Scrollbars>
                    <Box className="order-slide-progress">
                            <Grid className="shipment-slide details-box">
                                <Box className="shipment-img"><img src={selectedOrder.shippingModeId == 3 ? fclIcon : selectedOrder.shippingModeId == 2 ? lclIcon : airIcon} alt="" /></Box>
                            <Box className="shipment-details">
                                <Box className="shipment-no">
                                    {selectedOrder.jobNumber}
                                </Box>
                                <Grid className="source-destination">
                                    <Box className="from">origin</Box>
                                    <Box className="to">destination</Box>
                                </Grid>
                                <Box className="status-date">
                                    <Box className="status">Picked Up</Box>
                                    <Box className="eta-time">
                                        
                                    {`ETD ${getDate(selectedOrder?.orderStatus[4]?.departureStage[0]?.departureDate)} at ${getTime(selectedOrder?.orderStatus[4]?.departureStage[0]?.departureDate)}`}
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Box className="shipment-status">
                            <Box className="status-wrapper">
                                {/* <Grid className={`status-step  ${selectedOrder.orderStatus[0].status === 'SUCCESS' && 'done'} ${selectedOrder.orderStatus[0].status === 'IN_PROGRESS' || selectedOrder.orderStatus[0].status === 'FAILED' && 'inprogress'}`}>
                                    <Grid className="status"><Box component={'span'} className="status-circle"></Box></Grid>
                                    <Grid className="status-row">
                                        <Box className="status-col-1">
                                            <Typography className="status-title">Order Confirmation</Typography>
                                            {selectedOrder?.orderStatus[0]?.status === 'IN_PROGRESS' || selectedOrder?.orderStatus[0]?.status === 'FAILED' && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`${getStatus(selectedOrder)}`}</Typography>
                                                <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            </Fragment>}
                                            {selectedOrder?.orderStatus[0]?.status === 'NOT_STARTED' && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`ETA: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                                <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            </Fragment>}
                                            {selectedOrder?.orderStatus[0]?.status === 'SUCCESS' && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`Completed`}</Typography>
                                            </Fragment>}
                                        </Box>
                                        
                                    </Grid>
                                </Grid> */}
                                <Grid className={`status-step  ${selectedOrder.orderStatus[1].status === 'SUCCESS' && 'done'} ${(selectedOrder.orderStatus[1].status === 'IN_PROGRESS' || selectedOrder.orderStatus[1].status === 'FAILED') && 'inprogress'}`}>
                                    <Grid className="status"><Box component={'span'} className="status-circle"></Box></Grid>
                                    <Grid className="status-row">
                                        {(selectedOrder.orderStatus[4].status === 'IN_PROGRESS' || selectedOrder.orderStatus[4].status === 'FAILED') && <Box className="status-col-1">
                                            <Typography className="status-title">Shipper</Typography> 
                                            {(selectedOrder?.orderStatus[1]?.status === 'IN_PROGRESS' || selectedOrder?.orderStatus[1]?.status === 'FAILED') && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`${getStatus(selectedOrder)}`}</Typography>
                                                <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            </Fragment>}
                                            {selectedOrder?.orderStatus[1]?.status === 'NOT_STARTED' && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`ETA: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                                <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            </Fragment>}
                                            {selectedOrder?.orderStatus[1]?.status === 'SUCCESS' && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`Completed`}</Typography>
                                            </Fragment>}
                                        </Box>}
                                        
                                    </Grid>
                                </Grid>
                                {/* <Grid className={`status-step  ${selectedOrder.orderStatus[2].status === 'SUCCESS' && 'done'} ${selectedOrder.orderStatus[2].status === 'IN_PROGRESS' || selectedOrder.orderStatus[2].status === 'FAILED' && 'inprogress'}`}>
                                    <Grid className="status"><Box component={'span'} className="status-circle"></Box></Grid>
                                    <Grid className="status-row">
                                        <Box className="status-col-1">
                                            <Typography className="status-title">Trucking</Typography>
                                            {selectedOrder?.orderStatus[2]?.status === 'IN_PROGRESS' || selectedOrder?.orderStatus[2]?.status === 'FAILED' && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`${getStatus(selectedOrder)}`}</Typography>
                                                <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            </Fragment>}
                                            {selectedOrder?.orderStatus[2]?.status === 'NOT_STARTED' && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`ETA: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                                <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            </Fragment>}
                                            {selectedOrder?.orderStatus[2]?.status === 'SUCCESS' && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`Completed`}</Typography>
                                            </Fragment>}
                                        </Box>
                                        
                                    </Grid>
                                </Grid> */}
                                <Grid className={`status-step  ${selectedOrder.orderStatus[3].status === 'SUCCESS' && 'done'} ${(selectedOrder.orderStatus[3].status === 'IN_PROGRESS' || selectedOrder.orderStatus[3].status === 'FAILED') && 'inprogress'}`}>
                                    <Grid className="status"><Box component={'span'} className="status-circle"></Box></Grid>
                                    <Grid className="status-row">
                                        {(selectedOrder.orderStatus[4].status === 'IN_PROGRESS' || selectedOrder.orderStatus[4].status === 'FAILED') && <Box className="status-col-1">
                                            <Typography className="status-title">W/H</Typography>
                                            {(selectedOrder?.orderStatus[3]?.status === 'IN_PROGRESS' || selectedOrder?.orderStatus[3]?.status === 'FAILED') && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`${getStatus(selectedOrder)}`}</Typography>
                                                <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            </Fragment>}
                                            {selectedOrder?.orderStatus[3]?.status === 'NOT_STARTED' && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`ETA: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                                <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            </Fragment>}
                                            {selectedOrder?.orderStatus[3]?.status === 'SUCCESS' && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`Completed`}</Typography>
                                            </Fragment>}
                                        </Box>}
                                        
                                    </Grid>
                                </Grid>
                                <Grid className={`status-step  ${selectedOrder.orderStatus[4].status === 'SUCCESS' && 'done'} ${(selectedOrder.orderStatus[4].status === 'IN_PROGRESS' || selectedOrder.orderStatus[4].status === 'FAILED') && 'inprogress'}`}>
                                    <Grid className="status"><Box component={'span'} className="status-circle"></Box></Grid>
                                    <Grid className="status-row">
                                        {(selectedOrder.orderStatus[4].status === 'IN_PROGRESS' || selectedOrder.orderStatus[4].status === 'FAILED') && <Box className="status-col-1">
                                            <Typography className="status-title">Departure</Typography>
                                            {(selectedOrder?.orderStatus[4]?.status === 'IN_PROGRESS' || selectedOrder?.orderStatus[4]?.status === 'FAILED') && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`${getStatus(selectedOrder)}`}</Typography>
                                                <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            </Fragment>}
                                            {selectedOrder?.orderStatus[4]?.status === 'NOT_STARTED' && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`ETA: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                                <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            </Fragment>}
                                            {selectedOrder?.orderStatus[4]?.status === 'SUCCESS' && 
                                            <Fragment>
                                                <Typography className="desc-line-1">{`Completed`}</Typography>
                                            </Fragment>}
                                        </Box>}
                                        
                                    </Grid>
                                </Grid>
                                <Grid className={`status-step  ${selectedOrder.orderStatus[4].status === 'SUCCESS' && 'inprogress'}`}>
                                    <Grid className="status"><Box component={'span'} className="status-circle"></Box></Grid>
                                    <Grid className="status-row">
                                        {selectedOrder.orderStatus[4].status === 'SUCCESS' && <Box className="status-col-1">
                                            <Typography className="status-title">Port of Departure</Typography>
                                            {selectedOrder.orderStatus[4].status === 'SUCCESS' ? <Typography className="desc-line-1">
                                                {`ETD: ${getDate(selectedOrder?.orderStatus[4]?.departureStage[0]?.departureDate)}`}
                                            </Typography>
                                            : <>
                                            
                                            <Typography className="desc-line-1">{`ETA: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                                <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            </>}
                                        </Box>}
                                        
                                    </Grid>
                                </Grid>
                                <Grid className="status-step">
                                    <Grid className="status"><Box component={'span'} className="status-circle"></Box></Grid>
                                    <Grid className="status-row">
                                        {/* <Box className="status-col-1">
                                            <Typography className="status-title">Port of Destination</Typography>
                                            <Typography className="desc-line-1">{`ETA: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                        </Box> */}
                                        
                                    </Grid>
                                </Grid>
                                <Grid className="status-step">
                                    <Grid className="status"><Box component={'span'} className="status-circle"></Box></Grid>
                                    <Grid className="status-row">
                                        {/* <Box className="status-col-1">
                                            <Typography className="status-title">Custom</Typography>
                                            <Typography className="desc-line-1">{`ETA: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                        </Box> */}
                                        
                                    </Grid>
                                </Grid>
                                <Grid className="status-step">
                                    <Grid className="status"><Box component={'span'} className="status-circle"></Box></Grid>
                                    <Grid className="status-row">
                                        {/* <Box className="status-col-1">
                                            <Typography className="status-title">Importer</Typography>
                                            <Typography className="desc-line-1">{`ETA: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                            <Typography className="desc-line-2">{`ETD: ${getDate(selectedOrder.dueDate)}`}</Typography>
                                        </Box> */}
                                        
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box className="requested-actions">
                            <Typography className="title">Requested Actions</Typography>
                            <Typography className="description">In order not to delay the shipment, please provide the customs documents as soon as possible</Typography>
                            <Grid className="actions">
                                <Button className="btn-primary btn-filled" onClick={() => orderDetailHandle(selectedOrder, TABS[2])}>Upload Documents</Button>
                                <Button className="btn-primary" onClick={() => orderDetailHandle(selectedOrder, undefined)}>More Details</Button>
                            </Grid>
                        </Box>
                    </Box>
                    </Scrollbars>
                    )}
                </Box>
            </Box>
            <Box className={`shipment-slider ${isSlideDetails && 'hidden'}`}>
                <Swiper 
                    // install Swiper modules
                    modules={[Navigation]}
                    loop={false}
                    spaceBetween={24}
                    slidesPerView={3}
                    navigation
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}>
                    {ordersList.length > 0 && ordersList.map(order => <SwiperSlide key={order?.id}>{shipmentSlide(order)}</SwiperSlide>)}
                </Swiper>
            </Box>
        </Box>)
};

export default ShipmentDataMap;
import { useState, memo } from 'react';

import StageButton from '../StageButton';
import WhDialog from './WhDialog';
import {StyledParcelTableCell} from 'Utils';

const Wh = ({ loginUser, order }) => {
  const currentStage = order.parcelStage[3];

  const [openDialog, setOpenDialog] = useState(false);
  const onHandleOpenDialog = () => setOpenDialog(true);

  return (
      <StyledParcelTableCell colSpan={1} style={{ width: "3vw", p: 0 }}>
      <StageButton stage={currentStage} onHandleOpenDialog={onHandleOpenDialog} stageName='W/H' loginUser={loginUser}/>
      {openDialog && <WhDialog loginUser={loginUser} order={order} openDialog={openDialog} setOpenDialog={setOpenDialog} />}
    </StyledParcelTableCell>
  );
};

export default memo(Wh);

import { Grid, Typography } from '@mui/material';
import AuditLogsTable from './AuditLogsTable';

const AuditLogs = ({ jSearch }) => {
  return (
    <Grid container>
          <AuditLogsTable jSearch={jSearch} />
    </Grid>
  );
};

export default AuditLogs;

import { Fragment, useState } from 'react';

import { TableRow } from '@mui/material';
import Confirmation from './Stages/Confirmation/Confirmation';
import Shipper from './Stages/Shipper/Shipper';
import Trucking from './Stages/Trucking/Trucking';
import Wh from './Stages/Wh/Wh';
import Departure from './Stages/Departure/Departure';
import OrderRowCollapse from './OrderRowCollapse';
import OrderRowInfo from './OrderRowInfo';
import { onParcelSelected } from 'reducers/parcel-reducers/ordersSlicer';
import { orderRowBgColor } from '../Orders';
import { useSelector, useDispatch } from 'react-redux';
import { StyledParcelTableCell } from 'Utils';
const OrderRow = ({ order, loginUser, pageName }) => {
    const [openCollapse, setOpenCollapse] = useState(false);
    const onHandleSwitchCollapse = () => setOpenCollapse(!openCollapse);
    const { ParcelSelected } = useSelector(state => state.parcels);
    const dispatch = useDispatch();

    return (
        <Fragment>
            <OrderRowInfo
                loginUser={loginUser}
                order={order}
                openCollapse={openCollapse}
                onHandleSwitchCollapse={onHandleSwitchCollapse}
                pageName={pageName}
                stages={<><Confirmation loginUser={loginUser} order={order} pageName={pageName} />
                    <Shipper loginUser={loginUser} order={order} pageName={pageName} />
                    <Trucking loginUser={loginUser} order={order} pageName={pageName} />
                    <Wh loginUser={loginUser} order={order} pageName={pageName} />
                    <Departure loginUser={loginUser} order={order} pageName={pageName} />
                    {/*<StyledParcelTableCell colSpan={1}>
                    </StyledParcelTableCell>*/}</>}
            />
            {/*<TableRow sx={{ '& > *': { pr: 0 } }} onClick={() => dispatch(onParcelSelected(order?.id))} style={order?.id === ParcelSelected ? orderRowBgColor : null}>
                <Confirmation loginUser={loginUser} order={order} pageName={pageName} />
                <Shipper loginUser={loginUser} order={order} pageName={pageName} />
                <Trucking loginUser={loginUser} order={order} pageName={pageName} />
                <Wh loginUser={loginUser} order={order} pageName={pageName} />
                <Departure loginUser={loginUser} order={order} pageName={pageName} />
                <StyledParcelTableCell colSpan={2}>
                </StyledParcelTableCell>
            </TableRow>*/}
            {openCollapse && <OrderRowCollapse parcelDetails={order.parcelDetails} openCollapse={openCollapse} orderID={order?.id} />}
        </Fragment>
    );
};

export default OrderRow;

const getDate = (d) => {
    var date = new Date(d);
    var month = date.getMonth(date);
    var day = date.getDate(date);
    var year = date.getFullYear(date);

     return `${day}/${month+1}/${year}`;
 };
 
export const getStatus = (order) => {
    if (!order.orderStatus)
        return 'To be Picked';
    if (order.orderStatus[1].status === 'NOT_STARTED') {
        return 'To be Picked';
    }
    if (order.orderStatus[1].status !== 'SUCCESS') {
        return order.orderStatus[1].creationTime ? `Pickup Time: ${getDate(order.orderStatus[1].creationTime)}` : `Pickup Time: Not Available`;
    }
    if (order.orderStatus[3].status === 'NOT_STARTED' && order.orderStatus[1].status === 'SUCCESS') {
        return `To Warehouse`;
    }
    if (order.orderStatus[3].status === 'FAILED' || order.orderStatus[3].status === 'IN_PROGRESS') {
        return order.orderStatus[3].orderStatusStage?.etdTime ? `ETD: ${getDate(order.orderStatus[3].orderStatusStage?.etdTime)}` : `ETD: Not Available`;
    }
    if (order.orderStatus[4].status === 'NOT_STARTED' || order.orderStatus[3].status === 'SUCCESS') {
        if (order.orderStatus[4].status !== 'SUCCESS') {
            return `Arrived at W/H`;
        }
    }
    if (order.orderStatus[4].status === 'FAILED' || order.orderStatus[4].status === 'IN_PROGRESS') {
        return order.orderStatus[4].orderStatusStage?.departureDate ? `Departure Time: ${getDate(order.orderStatus[4].orderStatusStage?.departureDate)}` : `Departure Time: Not Available`;
    }
    if (order.orderStatus[4].status === 'SUCCESS' && order.orderStatus[5].status !== 'SUCCESS') {
        return `Moving to Port of Departure`;
    }
    if (order.orderStatus[5].status === 'SUCCESS' && order.orderStatus[6].status !== 'SUCCESS') {
        return `Moving to Port of Destination`;
    }
    if (order.orderStatus[6].status === 'SUCCESS' && order.orderStatus[7].status !== 'SUCCESS') {
        return `Arrived at Customs`;
    }
    if (order.orderStatus[7].status === 'SUCCESS') {
        return `Delivering to Customer`;
    }
    if (order.shippingModeId === 1) {
        if (order.orderStatus[5].orderStatusStage?.carrierStatus?.status) {
            return `${order.orderStatus[5].orderStatusStage?.carrierStatus?.status}: ${getDate(order.orderStatus[5].orderStatusStage?.carrierStatus?.estimated_on)}. From ${order.orderStatus[5].orderStatusStage?.carrierStatus?.originData.city} to ${order.orderStatus[5].orderStatusStage?.carrierStatus?.destinationData.city}`;
        }
    }
    if (order.shippingModeId === 2) {
        if (order.orderStatus[5].orderStatusStage?.carrierStatus?.navigation_status) {
            return `${order.orderStatus[5].orderStatusStage.carrierStatus.navigation_status}: ${getDate(order.orderStatus[5].orderStatusStage.carrierStatus.eta_UTC)}`;
        }
    }
    return 'Waiting for Status';
};

export const getStatusStatus = (order, index) => {
    if (index === 0) {
        if (order.mapStatus == 0) {
            return true;
        }
        return false;
    }
    if (index === 2) {
        if (order.mapStatus == 3) {
            return true;
        }
        return false;
    }
    if (index === 2) {
        if (order.mapStatus == 5) {
            return true;
        }
        return false;
    }
    if (index === 3) {
        if (order.mapStatus == 6) {
            return true;
        }
        return false;
    }
    if (index === 4) {
        if (order.mapStatus == 7) {
            return true;
        }
        return false;
    }
    if (index === 5) {
        if (order.mapStatus == 8) {
            return true;
        }
        return false;
    }
    return false;
};

export const getOrigin = (order) => {
    if (order.shippingModeId === 1) {
        if (order.orderStatus[5].orderStatusStage?.carrierStatus?.originData) {
            return `${order.orderStatus[5].orderStatusStage.carrierStatus.originData.country_code}, ${order.orderStatus[5].orderStatusStage.carrierStatus.originData.city}`;
        }
    }
    if (order.shippingModeId === 2) {
        if (order.orderStatus[5].orderStatusStage?.carrierStatus?.country_iso) {
            return ``;
        }
    }
    return 'N/A';
};

export const getDestination = (order) => {
    if (order.shippingModeId === 1) {
        if (order.orderStatus[5].orderStatusStage?.carrierStatus?.destinationData) {
            return `${order.orderStatus[5].orderStatusStage.carrierStatus.destinationData.country_code}, ${order.orderStatus[5].orderStatusStage.carrierStatus.destinationData.city}`;
        }
    }
    if (order.shippingModeId === 2) {
        if (order.orderStatus[5].orderStatusStage?.carrierStatus?.country_iso) {
            return `${order.orderStatus[5].orderStatusStage.carrierStatus.country_iso}, ${order.orderStatus[5].orderStatusStage.carrierStatus.destination}`;
        }
    }
    return 'N/A';
};

export const getEstOn = (order) => {
    if (order.shippingModeId === 1) {
        if (order.orderStatus[5].orderStatusStage?.carrierStatus?.status) {
            return `${getDate(order.orderStatus[5].orderStatusStage?.carrierStatus.estimated_on)}`;
        }
    }
    return '';
};
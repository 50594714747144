import { Grid, Typography } from '@mui/material';
import { Autocomplete, TextField } from 'style-guide';

import { useSelector } from 'react-redux';

const FCLDetails = ({ onStateChange, state }) => {
  const { quantity20, quantity40, fclType20Id, fclType40Id } = state;
  const onHandleChange = (event, field) => onStateChange(field, event.target.value);
    const { fclType20Data, fclType40Data } = useSelector(state => state.parcelPredefinedData);

  return (
    <Grid container item spacing={2}>
      <Grid container item alignItems='center'>
        <Typography variant='h6'>Shipment Details</Typography>
      </Grid>
      <TextField
        lg={5}
        type='number'
        label="Quantity 20'"
        value={quantity20}
        onChange={event => onHandleChange(event, 'quantity20')}
      />
      <Grid item xs={2} />
      <TextField
        lg={5}
        type='number'
        label="Quantity 40'"
        value={quantity40}
        onChange={event => onHandleChange(event, 'quantity40')}
      />
      <Autocomplete
        lg={5}
        field='fclType20Id'
        label="FCL Type 20'"
        value={fclType20Id}
        onChange={onStateChange}
        options={fclType20Data}
      />
      <Grid item xs={2} />
      <Autocomplete
        lg={5}
        field='fclType40Id'
        label="FCL Type 40'"
        value={fclType40Id}
        onChange={onStateChange}
        options={fclType40Data}
      />
    </Grid>
  );
};

export default FCLDetails;

/* eslint-disable react/prop-types */
import React from 'react';
import './CustomerCommon.scss';
import DefaultIcon from "../../../resources/default_icon.svg"
import { Box } from '@mui/material';

const TitleBar = ({ titleName, titleIcon = DefaultIcon, children }) => {
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return (
        <>{ isMobile() ? <Box className="title-bar">
            <Box className="title" sx={{ display: 'flex' }}>
                {/*<img src={titleIcon} alt={titleName} />*/}
                <p className="tk-good-times" style={{ marginLeft: "4vw", marginBottom: "1vw", fontSize: "3vw" }}>{titleName}</p>
            </Box>
            {children && <Box className="actions">{children}</Box>}
        </Box> : <Box className="title-bar">
                <Box className="title" sx={{ display: 'flex' }}>
                    {/*<img src={titleIcon} alt={titleName} />*/}
                    <p className="tk-good-times" style={{ marginLeft: "4vw", marginBottom: "1vw" }}>{titleName}</p>
                </Box>
                {children && <Box className="actions">{children}</Box>}
            </Box>
        }</>
    )
}

export default TitleBar;
import { createSlice } from '@reduxjs/toolkit';

export const shipperStageSlice = createSlice({
  name: 'shipperStage',
  initialState: {
    id: null,
    stageStatusInfoId: null,
    shipperStatuses: null,
    shipmentAddress: null,
    nextActionDate: null,
    requiredTimeSlot: null,
    estimatedPickupDate: null,
    pickupDate: null,
    notes: null,
    files: [],
  },
  reducers: {
    resetShipperStage: state => {
      state.id = null;
      state.stageStatusInfoId = null;
      state.shipmentAddress = null;
      state.nextActionDate = null;
      state.requiredTimeSlot = null;
      state.estimatedPickupDate = null;
      state.pickupDate = null;
      state.notes = null;
      state.files = [];
    },
    onFilesChange: (state, { payload }) => {
      state.files = payload;
    },
    onFilesAdd: (state, { payload }) => {
      state.files.push(payload);
    },
    onIdChange: (state, { payload }) => {
      state.id = payload;
    },
    onShipperStatusesChange: (state, { payload }) => {
      state.shipperStatuses = payload;
    },
    onStageStatusInfoIdChange: (state, { payload }) => {
      state.stageStatusInfoId = payload;
    },
    onShipmentAddressChange: (state, { payload }) => {
      state.shipmentAddress = payload;
    },
    onNextActionDateChange: (state, { payload }) => {
      state.nextActionDate = payload;
    },
    onRequiredTimeSlotChange: (state, { payload }) => {
      state.requiredTimeSlot = payload;
    },
    onShipperDateChange: (state, { payload }) => {
      state.estimatedPickupDate = payload;
    },
    onPickupDateChange: (state, { payload }) => {
      state.pickupDate = payload;
      state.estimatedPickupDate = payload;
    },
    onNotesChange: (state, { payload }) => {
      state.notes = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetShipperStage,
  onFilesChange,
  onFilesAdd,
  onIdChange,
  onShipperStatusesChange,
  onStageStatusInfoIdChange,
  onShipmentAddressChange,
  onNextActionDateChange,
  onRequiredTimeSlotChange,
  onShipperDateChange,
  onPickupDateChange,
  onNotesChange,
} = shipperStageSlice.actions;

export default shipperStageSlice.reducer;

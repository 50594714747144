import { useCallback, useEffect, useState } from 'react';

import { Grid, Box, Button } from '@mui/material';
import { Dialog, MultilineField, TextField, SelectField } from 'style-guide';

import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';

import { useDispatch, useSelector } from 'react-redux';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';
import { onParcelsCountChange, getOrders, onChatCountChange, onUserSpecificParcelsCountChange, onUnConfirmedParcelsCountChange } from 'reducers/parcel-reducers/ordersSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import {
    onIdChange,
    onNotesChange,
    onUserNLIdChange,
    onUserNLNumberChange,
    resetConfirmationStage,
} from 'reducers/parcel-stage-reducers/confirmationStageSlicer';
import { addParcelFilter, addSearchFilterParcel } from 'Utils';

const ConfirmationDialog = ({ order, openDialog, setOpenDialog, loginUser, pageName }) => {
    const dispatch = useDispatch();
    const onHandleChange = (event, action) => dispatch(action(event.target.value));
    const { userNLNames } = useSelector(state => state.parcelPredefinedData);
    const { userUSNames } = useSelector(state => state.parcelPredefinedData);
    const { page, size, chatCount, userSpecificParcelsCount } = useSelector(state => state.parcels);
    const { id, notes, userNLId, userNLNumber } = useSelector(state => state.confirmationStage);
    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const currentStage = order.parcelStage[0];

    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        selectedTabIndexName,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.parcelTableFilter);

    const onHandleCloseDialog = () => {
        dispatch(resetConfirmationStage());
        setOpenDialog(false);
    };

    useEffect(() => {
        const parcelConfirmationStage = currentStage.parcelConfirmationStage[0];

        dispatch(onNotesChange(currentStage.notes));

        if (parcelConfirmationStage?.id) {
            dispatch(onIdChange(parcelConfirmationStage.id));
            dispatch(onUserNLIdChange(parcelConfirmationStage.userNLId));
            dispatch(onUserNLNumberChange(parcelConfirmationStage.userNLNumber));
        }
    }, [dispatch]);


    useEffect(() => {
        // predefined user abroad data get
        if (!nlUsers || nlUsers == undefined) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setNlUsers(response); },
                failedCallback,
            });
        }
    }, [nlUsers, failedCallback, setNlUsers]);

    useEffect(() => {
        // predefined user abroad data get
        if (!usUsers || usUsers == undefined) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setUSUsers(response); },
                failedCallback,
            });
        }
    }, [usUsers, failedCallback, setUSUsers]);

    useEffect(() => {
        // predefined user local data get
        if (!ilUsers || ilUsers == undefined) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setIlUsers(response); },
                failedCallback,
            });
        }
    }, [ilUsers, failedCallback, setIlUsers]);

    const onStageHandleSave = id => {
        const parcelConfirmationStage = [
            {
                userNLId:
                    userNLId ||
                    ((loginUser.location.code === 'US' || selectedLocationIndex == 1) ? userUSNames.find(user => user.name === loginUser.fullName)?.id : ((loginUser.location.code === 'NL' || selectedLocationIndex == 0) ? userNLNames.find(user => user.name === loginUser.fullName)?.id : null)),
                userNLNumber,
            },
        ];

        if (id) {
            parcelConfirmationStage[0].id = id;
        }

        const newOrder = {
            ...order,
            parcelStage: [
                {
                    ...currentStage,
                    notes,
                    parcelConfirmationStage,
                },
                order.parcelStage[1],
                order.parcelStage[2],
                order.parcelStage[3],
                order.parcelStage[4],
            ],
        };

        if (userNLId != order?.parcelStage[0]?.parcelConfirmationStage[0]?.userNLId) {
            var userIL = order.userILId;
            var userNL = order.parcelStage[0].parcelConfirmationStage[0]?.userNLId;
            var lengthIL = ilUsers.length;
            var lengthNL = nlUsers.length;
            var exists = false;
            var chat = {
                'time': '',
                'text': 'Parcel has been confirmed',
                'initiator': '',
                'initiatorType': '',
                'location': '',
                'ILStatus': false,
                'NLStatus': false
            }
            for (var i = 0; i < lengthIL; i++) {
                if (ilUsers[i].fullName == loginUser.fullName) {
                    var date = new Date();
                    chat['time'] = date.toUTCString();
                    chat['initiator'] = loginUser.fullName;
                    chat['initiatorType'] = 1;
                    chat['location'] = 'IL';
                    if (userIL == ilUsers[i].id) {
                        chat['ILStatus'] = true;
                        chat['initiatorType'] = 2;
                    }
                    else if (ilUsers[i].roleId == 1)
                        chat['ILStatus'] = false;
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                for (var i = 0; i < lengthNL; i++) {
                    if (nlUsers[i].fullName == loginUser.fullName) {
                        var date = new Date();
                        chat['time'] = date.toUTCString();
                        chat['initiator'] = loginUser.fullName;
                        chat['initiatorType'] = 1;
                        chat['location'] = 'NL';
                        if (userNL == nlUsers[i].id) {
                            chat['NLStatus'] = true;
                            chat['initiatorType'] = 3;
                        }
                        else if (nlUsers[i].roleId == 1)
                            chat['NLStatus'] = false;
                        exists = true;
                        break;
                    }
                }

                for (var i = 0; i < usUsers.length; i++) {
                    if (usUsers[i].fullName == loginUser.fullName) {
                        var date = new Date();
                        chat['time'] = date.toUTCString();
                        chat['initiator'] = loginUser.fullName;
                        chat['initiatorType'] = 1;
                        chat['location'] = 'US';
                        if (userNL == usUsers[i].id) {
                            chat['NLStatus'] = true;
                            chat['initiatorType'] = 3;
                        }
                        else if (usUsers[i].roleId == 1)
                            chat['NLStatus'] = false;
                        exists = true;
                        break;
                    }
                }
            }
            if (!order.chat) {
                var chat_list = [];
                chat_list[0] = chat;
               // newOrder.chat = JSON.stringify(chat_list);
            }
            else {
                var chat_list = JSON.parse(order.chat);
                chat_list.push(chat);
               // newOrder.chat = JSON.stringify(chat_list);
            }
        }

        sendRequest(`/api/parcel?fromStage=1&loginUserId=` + loginUser?.id + `&actionOn=ConfirmationDialog`, REQUEST_ACTIONS.PUT, newOrder, {
            successCallback: () => {
                sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));

                        if (userNLId != order?.parcelStage[0]?.parcelConfirmationStage[0]?.userNLId) {
                            //newOrder.chat = addStatusChatMessage(order, 'WH', 'Arrived', ilUsers, nlUsers, usUsers, loginUser);
                            var chatString = JSON.stringify(chat) //getStatusChatMessage(order, 'Departure', departureStatuses.find(status => status.id === newOrder?.orderStage[4]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
                            //console.log(chatString)
                            onUpdateChat(order.id, chatString, 1)
                        } else {
                            dispatch(getOrders(response));
                            onHandleCloseDialog();
                        }
                    },
                    failedCallback,
                });
                //dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));
                

                sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => { dispatch(onParcelsCountChange(response?.parcelsCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount)); dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount)); },
                    failedCallback,
                });
            },
            failedCallback,
        });
    };
    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/parcel/updateChat/${id}?fromStage=${fromStage}`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {

                        dispatch(getOrders(response));
                        onHandleCloseDialog();
                    },
                    failedCallback,
                });
            },
            failedCallback,
        });
    };
    return (
        <Dialog maxWidth='md' open={openDialog} onHandleCancel={onHandleCloseDialog} title={'ORDER CONFIRMATION - ' + order?.jobNumber}>
            <Box component='form' noValidate autoComplete='off'>
                <Grid container spacing={2}>
                    <Grid container item spacing={2}>
                        <SelectField
                            lg={null}
                            label={(loginUser.location.code === 'US' || selectedLocationIndex == 1) ? 'User US' : 'User NL'}
                            value={
                                userNLId ||
                                ((loginUser.location.code === 'US' || selectedLocationIndex == 1)
                                    ? userUSNames.find(user => user.name === loginUser.fullName)?.id
                                    : ((loginUser.location.code === 'NL' || selectedLocationIndex == 0)
                                        ? userNLNames.find(user => user.name === loginUser.fullName)?.id
                                        : null))
                            }
                            onChange={event => onHandleChange(event, onUserNLIdChange)}
                            options={(loginUser.location.code === 'US' || selectedLocationIndex == 1) ? userUSNames : userNLNames}
                        />
                        <MultilineField
                            xs={12}
                            sm={null}
                            md={null}
                            lg={null}
                            label='Notes'
                            value={notes}
                            onChange={event => onHandleChange(event, onNotesChange)}
                        />
                    </Grid>
                    <Grid container item xs={12} justifyContent='flex-end'>
                        <Button
                            variant='contained'
                            sx={{ mr: 2 }}
                            onClick={id ? () => onStageHandleSave(id) : () => onStageHandleSave(null)}>
                            Save
                        </Button>
                        <Button variant='contained' color='secondary' onClick={onHandleCloseDialog}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default ConfirmationDialog;

import { useState, memo } from 'react';

import StageButton from '../StageButton';
import ShipperDialog from './ShipperDialog';
import {StyledParcelTableCell} from 'Utils';

const Shipper = ({ loginUser, order }) => {
  const currentStage = order.parcelStage[1];

  const [openDialog, setOpenDialog] = useState(false);
  const onHandleOpenDialog = () => setOpenDialog(true);

  return (
      <StyledParcelTableCell style={{ width: "3vw", p: 0 }}>
      <StageButton stage={currentStage} onHandleOpenDialog={onHandleOpenDialog} stageName='Shipper' loginUser={loginUser}/>
      {openDialog && <ShipperDialog loginUser={loginUser} order={order} openDialog={openDialog} setOpenDialog={setOpenDialog} />}
    </StyledParcelTableCell>
  );
};

export default memo(Shipper);

import { createSlice } from '@reduxjs/toolkit';
import { expandData } from 'utils/Communicator';

export const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    customers: [],
    customerNameOptions: [],
  },
  reducers: {
    onCustomersChange: (state, { payload }) => {
      state.customers = payload;
    },
    onCustomerNameOptionsChange: (state, { payload }) => {
      state.customerNameOptions = payload;
    },
    addCustomer: (state, { payload }) => {
      state.customers = [...state.customers, payload];
    },
    updateCustomer: (state, { payload }) => {
      const customer = state.customers.find(customer => customer.id === payload.id);
      const customerIndex = state.customers.indexOf(customer);
      const before = state.customers.slice(0, customerIndex);
      const after = state.customers.slice(customerIndex + 1);
      const newCustomers = [...before, payload, ...after];
      state.customers = newCustomers;
    },
  },
});

// Action creators are generated for each case reducer function
export const { onCustomersChange, onCustomerNameOptionsChange, updateCustomer, addCustomer} = customersSlice.actions;

export default customersSlice.reducer;

import { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table, TableBody, TableContainer, Paper, TablePagination } from '@mui/material';
import UserHeader from './UserHeader';
import UserRow from './UserRow';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { useSelector, useDispatch } from 'react-redux';
import { onUsersChange } from 'reducers/UsersSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { Snackbar } from 'style-guide';

const UsersTable = () => {
    const dispatch = useDispatch();
    const { users } = useSelector(state => state.users);
    const [userCount, setUserCount] = useState(0);
    const { snackbar } = useSelector(state => state.snackbar);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));

    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [location, setLocation] = useState(1);
    const [role, setRole] = useState(1);
    const [active, setActive] = useState(false);

    const failedCallback = useCallback(
        message => dispatch(snackbarToggle({ type: 'error', message })),
        [dispatch],
    );

    const setFilter = (name, un, loc, role, status) => {
        var filter = ""
        if (name) {
            filter += `&user=${name}`
        }
        if (un) {
            filter += `&username=${un}`
        }
        if (loc) {
            filter += `&location=${loc}`
        }
        if (role) {
            filter += `&role=${role}`
        }
        if (status) {
            filter += `&status=${status}`
        }
        console.log(filter);
        sendRequest(`/api/users?size=${size}&page=${page}` + filter, REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onUsersChange(response)),
            failedCallback,
        });
    }

    // pagination
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const onPageHandleChange = (_, newPage) => {
        setPage(newPage);
    };
    const onRowsPerPageHandleChange = event => {
        setSize(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        sendRequest(`/api/usersCount`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => setUserCount(response),
            failedCallback,
        });

    }, [failedCallback]);
    //}, [users, failedCallback]);

    useEffect(() => {
        // predefined users data get
        sendRequest(`/api/users?size=${size}&page=${page}`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onUsersChange(response)),
            failedCallback,
        });
    }, [page, size, dispatch, failedCallback]);



    return (

        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table>
                    <UserHeader setFilter={setFilter} />
                    <TableBody>
                        {
                            users.map(user => (
                                <UserRow key={user?.id || uuidv4()} data={user} />
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component='div'
                count={userCount}
                rowsPerPage={size}
                page={page}
                onPageChange={onPageHandleChange}
                onRowsPerPageChange={onRowsPerPageHandleChange}
            />
            {snackbar && (
                <Snackbar
                    open={!!snackbar}
                    message={snackbar.message}
                    type={snackbar.type}
                    onClose={onSnackbarHandleClose}
                />
            )}
        </Paper>


    );
};
export default UsersTable;

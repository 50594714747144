import { memo, useState } from 'react';
import StageButton from '../StageButton';
import ConfirmationDialog from './ConfirmationDialog';
import {StyledParcelTableCell} from 'Utils';

const Confirmation = ({ order ,loginUser, pageName}) => {
  const currentStage = order.parcelStage[0];

  const [openDialog, setOpenDialog] = useState(false);
  const onHandleOpenDialog = () => setOpenDialog(true);

  return (
      <StyledParcelTableCell colSpan={1} style={{ width: "3vw", p: 0 }}>
      <StageButton stage={currentStage} onHandleOpenDialog={onHandleOpenDialog} stageName='Confirm' loginUser={loginUser}/>
      {openDialog && <ConfirmationDialog loginUser={loginUser} order={order} openDialog={openDialog} setOpenDialog={setOpenDialog} pageName={pageName}/>}
    </StyledParcelTableCell>
  );
};

export default memo(Confirmation);

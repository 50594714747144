import { useEffect, useCallback, useState } from 'react';

import { Box, Typography, Grid, Button, Divider, CircularProgress } from '@mui/material';
import { SelectField, TextField, DateTimePicker, MultilineField, ParcelDropzone, ParcelFilesRender, Dialog } from 'style-guide';

import { STATUS } from 'constants/global-constants';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';

import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from 'reducers/parcel-reducers/ordersSlicer';
import AddServiceProvider from 'serviceProvider/AddServiceProvider';
import {
    resetShipperStage,
    onIdChange,
    onFilesChange,
    onFilesAdd,
    onStageStatusInfoIdChange,
    onShipmentAddressChange,
    onNextActionDateChange,
    onRequiredTimeSlotChange,
    onShipperDateChange,
    onPickupDateChange,
    onNotesChange,
    onShipperStatusesChange,
} from 'reducers/parcel-stage-reducers/shipperStageSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { addParcelFilter, addSearchFilterParcel, addStatusChatMessage, getStatusChatMessage } from 'Utils';

const ShipperDialog = ({ loginUser, order, openDialog, setOpenDialog, pageName }) => {
    const dispatch = useDispatch();
    const onHandleChange = (event, action) => dispatch(action(event.target.value));
    const [openShipperModal, setOpenShipperModal] = useState(false);
    const onHandleOpenShipperModal = () => setOpenShipperModal(true);
    const onHandleCancelShipperModal = () => setOpenShipperModal(false);
    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        selectedTabIndexName,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.parcelTableFilter);
    const { page, size } = useSelector(state => state.parcels);
    const {
        id,
        stageStatusInfoId,
        shipperStatuses,
        shipmentAddress,
        nextActionDate,
        requiredTimeSlot,
        estimatedPickupDate,
        pickupDate,
        notes,
        files,
    } = useSelector(state => state.parcelShipperStage);
    const { countryOfOriginData } = useSelector(state => state.parcelPredefinedData);

    const [isLoaded, setIsLoaded] = useState(false);

    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const currentStage = order.parcelStage[1];

    const onHandleCloseModal = () => {
        dispatch(resetShipperStage());
        setOpenDialog(false);
    };
    useEffect(() => {
        // predefined user abroad data get
        if (!nlUsers || nlUsers == undefined) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setNlUsers(response); },
                failedCallback,
            });
        }
    }, [nlUsers, failedCallback, setNlUsers]);

    useEffect(() => {
        // predefined user abroad data get
        if (!usUsers || usUsers == undefined) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setUSUsers(response); },
                failedCallback,
            });
        }
    }, [usUsers, failedCallback, setUSUsers]);

    useEffect(() => {
        // predefined user local data get
        if (!ilUsers || ilUsers == undefined) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setIlUsers(response); },
                failedCallback,
            });
        }
    }, [ilUsers, failedCallback, setIlUsers]);

    useEffect(() => {
        if (!shipperStatuses) {
            // predefined shipper statuses data get
            sendRequest('/api/stage/2', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onShipperStatusesChange(response)),
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, shipperStatuses]);

    useEffect(() => {
        setIsLoaded(!!shipperStatuses);
    }, [shipperStatuses]);

    useEffect(() => {
        const parcelShipperStage = currentStage.parcelShipperStage[0];

        dispatch(onFilesChange(order?.parcelStage[0]?.archives));
        dispatch(onStageStatusInfoIdChange(currentStage.stageStatusInfoId));
        dispatch(onNotesChange(currentStage.notes));

        if (parcelShipperStage?.id) {
            dispatch(onIdChange(parcelShipperStage.id));
            dispatch(onShipmentAddressChange(parcelShipperStage.shipmentAddress));
            dispatch(onNextActionDateChange(parcelShipperStage.nextActionDate));
            dispatch(onRequiredTimeSlotChange(parcelShipperStage.requiredTimeSlot));
            dispatch(onShipperDateChange(parcelShipperStage.estimatedPickupDate));
            dispatch(onPickupDateChange(parcelShipperStage.pickupDate));
        }
    }, [dispatch, order?.parcelStage[1]]);

    const onStageHandleSave = id => {
        const parcelShipperStage = [
            {
                shipmentAddress,
                requiredTimeSlot,
                estimatedPickupDate,
                nextActionDate,
                pickupDate,
            },
        ];

        if (id) {
            parcelShipperStage[0].id = id;
        }
        const newOrder = {
            ...order,
            parcelStage: [
                order.parcelStage[0],
                {
                    ...currentStage,
                    notes,
                    stageStatusInfoId,
                    parcelShipperStage,
                },
                order.parcelStage[2],
                order.parcelStage[3],
                order.parcelStage[4],
            ],
        };
        if (newOrder.parcelStage[1].stageStatusInfoId !== 8) {
            if (newOrder.parcelStage[1].parcelShipperStage[0].nextActionDate === undefined || newOrder.parcelStage[1].parcelShipperStage[0].nextActionDate == null || newOrder.parcelStage[1].parcelShipperStage[0].nextActionDate === "") {
                dispatch(snackbarToggle({ type: 'error', message: 'Next action date should be chosen.' }));
                return;
            }
            else {
                newOrder.parcelStage[1].parcelShipperStage[0].estimatedPickupDate = newOrder.parcelStage[1].parcelShipperStage[0].nextActionDate;
                newOrder.parcelStage[1].parcelShipperStage[0].pickupDate = newOrder.parcelStage[1].parcelShipperStage[0].nextActionDate;
            }
        }
        /*if (order?.parcelStage[1]?.stageStatusInfoId != newOrder?.parcelStage[1]?.stageStatusInfoId) {
            newOrder.chat = addStatusChatMessage(order, 'Shipper', shipperStatuses.find(status => status.id === newOrder?.parcelStage[1]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
        }*/
        sendRequest(`/api/parcel?fromStage=2&loginUserId=` + loginUser?.id + `&actionOn=ShipperDialog`, REQUEST_ACTIONS.PUT, newOrder, {
            successCallback: () => {

                sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));

                        if (order?.parcelStage[1]?.stageStatusInfoId != newOrder?.parcelStage[1]?.stageStatusInfoId) {
                            //newOrder.chat = addStatusChatMessage(order, 'WH', 'Arrived', ilUsers, nlUsers, usUsers, loginUser);
                            var chatString = getStatusChatMessage(order, 'Shipper', shipperStatuses.find(status => status.id === newOrder?.parcelStage[1]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
                            console.log(chatString)
                            onUpdateChat(order.id, chatString, 2)
                        } else {
                           dispatch(getOrders(response));
                            onHandleCloseModal();
                        }
                    },
                    failedCallback,
                });

                //dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));
                
                /**/
            },
            failedCallback,
        });
    };
    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/parcel/updateChat/${id}?fromStage=${fromStage}`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {

                        dispatch(getOrders(response));
                        onHandleCloseModal();
                    },
                    failedCallback,
                });
            },
            failedCallback,
        });
    };
    return (
        <Dialog maxWidth='md' open={openDialog} onHandleCancel={onHandleCloseModal} title={'SHIPPER - ' + order?.jobNumber}>
            <Box component='form' noValidate autoComplete='off'>
                {!isLoaded ? (
                    <Grid container justifyContent='center' mt={2}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        <TextField readOnly lg={null} label='Company name' value={order.shipper.companyName || '-'} />
                        <TextField readOnly lg={null} label='Address 1' value={order.shipper.address1 || '-'} />
                        <TextField readOnly lg={null} label='p/u address' value={order.shipper.address2 || '-'} />
                        <TextField readOnly lg={null} label='Zip code' value={order.shipper.zipCode || '-'} />
                        <TextField readOnly lg={null} label='City' value={order.shipper.city || '-'} />
                        <TextField
                            readOnly
                            lg={null}
                            label='Country'
                            value={countryOfOriginData.find(country => country.id === order.shipper.countryId).name || '-'}
                        />
                        <TextField readOnly lg={null} label='Email' value={order.shipper.email || '-'} />
                        <TextField readOnly lg={null} label='Name' value={order.shipper.name || '-'} />
                        <TextField readOnly lg={null} label='Position' value={order.shipper.position || '-'} />
                        <TextField readOnly lg={null} label='Business phone' value={order.shipper.businessPhone || '-'} />
                        <TextField readOnly lg={null} label='Mobile' value={order.shipper.mobile || '-'} />
                        <TextField readOnly lg={null} label='W/H address' value={order.shipper.mobile || '-'} />
                        <TextField readOnly xs={12} sm={null} md={null} lg={null} label="Supplier's PO" value={order.suppliersPO || '-'} />
                        <Grid container item xs={12} spacing={2}>
                            <SelectField
                                required
                                disabled={!!(currentStage.status === STATUS['2'])}
                                label='Status'
                                lg={null}
                                value={stageStatusInfoId}
                                onChange={event => onHandleChange(event, onStageStatusInfoIdChange)}
                                options={shipperStatuses}
                            />
                            <DateTimePicker
                                label='Next action date'
                                value={nextActionDate}
                                onChange={date => dispatch(onNextActionDateChange(date?.toISOString()))}
                            />
                            <Button
                                disabled={!(order?.shipper?.companyName != null)}
                                variant='contained'
                                sx={{ m: 1 }}
                                onClick={onHandleOpenShipperModal}>
                                Open Shipper Card
                            </Button>
                            {openShipperModal && (
                                <Dialog
                                    maxWidth='md'
                                    open={openShipperModal}
                                    title={'Shipper Card'}
                                    onHandleCancel={onHandleCancelShipperModal}>
                                    <AddServiceProvider data={order?.shipper} onHandleCancel={onHandleCancelShipperModal} id={null} readOnly={true} />
                                </Dialog>
                            )}
                        </Grid>
                        <DateTimePicker
                            required
                            label='Required time slot'
                            value={requiredTimeSlot}
                            onChange={date => dispatch(onRequiredTimeSlotChange(date?.toISOString()))}
                        />
                        {/* <DateTimePicker
              required
              label='Estimated p/u date'
              value={estimatedPickupDate}
              onChange={date => dispatch(onShipperDateChange(date?.toISOString()))}
            /> */}
                        {
                            stageStatusInfoId === 8 ?
                                <DateTimePicker
                                    required
                                    label='Pick-up date'
                                    value={pickupDate}
                                    onChange={date => dispatch(onPickupDateChange(date?.toISOString()))}
                                />
                                : null
                        }
                        <MultilineField
                            xs={12}
                            sm={null}
                            md={null}
                            lg={null}
                            label='Notes'
                            value={notes}
                            onChange={event => onHandleChange(event, onNotesChange)}
                        />
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h6'>Attachments</Typography>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <ParcelDropzone loginUser={loginUser} orderStageId={order?.parcelStage[0]?.id} orderId={order.id} onFilesAdd={onFilesAdd} pageName={pageName} onHandleCloseParentModal={onHandleCloseModal} />
                            </Grid>
                                <ParcelFilesRender loginUser={loginUser} files={files} orderId={order.id} />
                        </Grid>
                        <Grid container item xs={12} justifyContent='flex-end'>
                            <Button
                                variant='contained'
                                sx={{ mr: 2 }}
                                onClick={id ? () => onStageHandleSave(id) : () => onStageHandleSave(null)}>
                                Save
                            </Button>
                            <Button variant='contained' color='secondary' onClick={onHandleCloseModal}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Dialog>
    );
};

export default ShipperDialog;

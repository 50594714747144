import { useState, useCallback, useEffect, memo } from 'react';

import { IconButton, TableCell, TableRow, Typography, Grid, Button } from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import RefreshIcon from '@mui/icons-material/Refresh';
import BackIcon from '@mui/icons-material/SettingsBackupRestore';
import { ConfirmDialog, Dialog, Tooltip } from 'style-guide';
import OrderForm from '../OrderForm';
import ChatDialog from '../ChatDialog';
import BizChatDialog from '../BizChatDialog';
import EditLogo from './edit.svg';
import { styled } from '@mui/system';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';
import { REQUEST_ACTIONS, sendRequest, send_data_get } from 'utils/Communicator';
import AddServiceProvider from 'serviceProvider/AddServiceProvider';
import SetReminderDialog from './SetReminderDialog';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { deleteOrder, onParcelsCountChange, getOrders, onChatCountChange, onUserSpecificParcelsCountChange, onUnConfirmedParcelsCountChange } from 'reducers/parcel-reducers/ordersSlicer';
import {addParcelFilter, addSearchFilterParcel, StyledParcelTableCell} from 'Utils';
import { onParcelSelected } from 'reducers/parcel-reducers/ordersSlicer';
import {orderRowBgColor} from '../Orders';
import { COUNTRY_CODE_LIST } from 'constants/global-constants';
const OrderRowInfo = ({ order, openCollapse, onHandleSwitchCollapse, loginUser, pageName, stages }) => {
  const dispatch = useDispatch();
    const { orders, page, size, chatCount, ParcelSelected, userSpecificParcelsCount } = useSelector(state => state.parcels);
  const { subModeData, userNLNames, userUSNames, userILNames, shippingModeData, countryOfOriginData, airPolData, airPoaData, lclPolData, lclPoaData } = useSelector(
      state => state.parcelPredefinedData,
  );
  
  const { 
    userLocalNumber,  
    userAbroadNumber,  
    consigneeId,
    shipperId,
    countryOfOriginId, originState,
    shippingModeId,
    subModeId,
    userLocalId,
    userAbroadId,
    dueDate,
    lastUpdateDate,
    ilReminderDate,
    reminderDate,
    selectedLocationIndex,
    selectedTabIndexName,
    search,
    startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.parcelTableFilter);
  const orderTableFilter = { 
    userLocalNumber,  
    userAbroadNumber,  
    consigneeId,
    shipperId,
    countryOfOriginId, originState,
    shippingModeId,
    subModeId,
    userLocalId,
    userAbroadId,
    dueDate,
    lastUpdateDate,
    ilReminderDate,
    reminderDate,
    selectedLocationIndex,
    search,
      startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv, };
  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openHistoryConfirmDialog, setOpenHistoryConfirmDialog] = useState(false);
  const [backStageConfirmDialog, setBackStageConfirmDialog] = useState(false);
  const [openHistoryRevertConfirmDialog, setOpenHistoryRevertConfirmDialog] = useState(false);
  const [openChatDialog, setOpenChatDialog] = useState(false);
  const [chatActivity, setChatActivity] = useState(false);
  const onOrderModalHandleOpen = () => setOpenOrderModal(true);
  const onOrderModalHandleCancel = () => setOpenOrderModal(false);
  const onConfirmDialogHandleOpen = () => setOpenConfirmDialog(true);
  const onHistoryConfirmDialogHandleOpen = () => setOpenHistoryConfirmDialog(true);
  const onBackStageConfirmDialogHandleOpen = () => setBackStageConfirmDialog(true);
  const onHistoryRevertConfirmDialogHandleOpen = () => setOpenHistoryRevertConfirmDialog(true);
  const onConfirmDialogHandleClose = () => setOpenConfirmDialog(false);
  const onHistoryConfirmDialogHandleClose = () => setOpenHistoryConfirmDialog(false);
  const onBackStageConfirmDialogHandleClose = () => setBackStageConfirmDialog(false);
  const onHistoryRevertConfirmDialogHandleClose = () => setOpenHistoryRevertConfirmDialog(false);
  const onChatDialogHandleOpen = () => setOpenChatDialog(true);
  const onChatDialogHandleClose = () => setOpenChatDialog(false);
  const [openShipperModal, setOpenShipperModal] = useState(false);
  const onHandleOpenShipperModal = () => setOpenShipperModal(true);
  const onHandleCancelShipperModal = () => setOpenShipperModal(false);
  const [openConsigneeModal, setOpenConsigneeModal] = useState(false);
  const onHandleOpenConsigneeModal = () => setOpenConsigneeModal(true);
  const onHandleCancelConsigneeModal = () => setOpenConsigneeModal(false);
  const [openReminderDateDialog, setOpenReminderDateDialog] = useState(false);
  const onReminderDateDialogHandleOpen = () => setOpenReminderDateDialog(true);
  const [openBizChatDialog, setOpenBizChatDialog] = useState(false);
  const onBizChatDialogHandleOpen = () => setOpenBizChatDialog(true);
  const onBizChatDialogHandleClose = () => setOpenBizChatDialog(false);

  const downloadLabel = (hawb) => {
      var url = "/shipping/recieptWithBarCode/1/" + hawb;
      send_data_get("/api/parcel/invokeDownloadService?endpoint="+url, "CWB_" + hawb);  
  }

  const StyledTypography = styled(Typography)`
      cursor: pointer;
      text-decoration: underline;`;

  // delete order
  const onOrderHandleDelete = id => {
      sendRequest(`/api/parcel/${id}`, REQUEST_ACTIONS.DELETE, '', {
      successCallback: data => {
              sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                  successCallback: response => { dispatch(onParcelsCountChange(response?.parcelsCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount));sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount));dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount));},
          failedCallback,
        });
        dispatch(snackbarToggle({ type: 'success', message: data }));
        dispatch(deleteOrder(id));
      },
      failedCallback,
    });
  };

  const onOrderHandleHistory = id => {
      sendRequest(`/api/parcel/updateHistoryFlag/${id}/true?fromStage=0`, REQUEST_ACTIONS.PUT, '', {
        successCallback: data => {
              sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                  successCallback: response => { dispatch(onParcelsCountChange(response?.parcelsCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount));sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount));dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount));},
          failedCallback,
        });
        dispatch(snackbarToggle({ type: 'success', message: data }));
        dispatch(deleteOrder(id));
      },
      failedCallback,
    });
  };

  const onOrderHandleHistoryRevert = id => {
      sendRequest(`/api/parcel/updateHistoryFlag/${id}/false?fromStage=0`, REQUEST_ACTIONS.PUT, '', {
            successCallback: data => {
              sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                  successCallback: response => { dispatch(onParcelsCountChange(response?.parcelsCount)); dispatch(sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount)); onChatCountChange(response?.unreadChatCount);sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount));dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount));},
              failedCallback,
            });
              sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${page}&size=${size}`,REQUEST_ACTIONS.GET,'', {
            successCallback: response => {
              dispatch(getOrders(response));
            },
            failedCallback,
          },
        );
        dispatch(snackbarToggle({ type: 'success', message: data }));
      },
      failedCallback,
    });
  };

  const onOrderHandleBackStage = id => {
      sendRequest(`/api/parcel/backstage/${id}?fromStage=0`, REQUEST_ACTIONS.PUT, '', {
            successCallback: data => {
            onBackStageConfirmDialogHandleClose();
              sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                  successCallback: response => { dispatch(onParcelsCountChange(response?.parcelsCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount));sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount));dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount));},
              failedCallback,
            });
              sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${page}&size=${size}`,REQUEST_ACTIONS.GET,'', {
            successCallback: response => {
              dispatch(getOrders(response));
            },
            failedCallback,
          },
        );
        dispatch(snackbarToggle({ type: 'success', message: data }));
      },
      failedCallback,
    });
  };
  useEffect(() => {
    onConfirmDialogHandleClose();
    onHistoryConfirmDialogHandleClose();
    onHistoryRevertConfirmDialogHandleClose();
    onBackStageConfirmDialogHandleClose();
  }, [orders.length]);

  useEffect(() => {
    onConfirmDialogHandleClose();
    onHistoryConfirmDialogHandleClose();
    onHistoryRevertConfirmDialogHandleClose();
    onBackStageConfirmDialogHandleClose();
  }, [orders.length]);

  const noteStyle = {
    backgroundColor: "#2e7d329c",
    padding: "3px",
    borderRadius: "5px",
    width: '1.6vw !important', 
    height: '1.6vw !important',
  };

  const greenStyle = {
    backgroundColor: "#2e7d329c",
    padding: "3px",
    borderRadius: "5px",
    border: "2px solid grey",
    width: '1.6vw !important', 
    height: '1.6vw !important',
  };
  const greyStyle = {
    backgroundColor: "white",
    padding: "3px",
    borderRadius: "5px",
    border: "2px solid grey",
    width: '1.6vw !important', 
    height: '1.6vw !important',
  };
  const blueStyle = {
    backgroundColor: "#1976d270",
    padding: "3px",
    borderRadius: "5px",
    border: "2px solid grey",
    width: '1.6vw !important', 
    height: '1.6vw !important',
  };
  const orangeStyle = {
    backgroundColor: "#feb7348f",
    padding: "3px",
    borderRadius: "5px",
    border: "2px solid grey",
    width: '1.6vw !important', 
    height: '1.6vw !important',
  };
  const pastelStyle = {
    backgroundColor: "#F2A2E8",
    padding: "3px",
    borderRadius: "5px",
    border: "2px solid grey",
    width: '1.6vw !important', 
    height: '1.6vw !important',
  };

  const defaultStyle = {
    backgroundColor: "white",
    padding: "3px",
    borderRadius: "5px",
    marginTop: "6px",
    width: '1.6vw !important', 
    height: '1.6vw !important',
  };

  const getOrderChatStyle = (order) =>{
    if(order['chat']){
      var chat_list = JSON.parse(order['chat'])
      var len = chat_list.length;
      if(len > 0)
        return {display: 'block', marginTop:'0px'};
    }
    return {display: 'block'};
  }

  const getToolTip = (order) =>{
    if(order['chat']){
      var chat_list = JSON.parse(order['chat'])
      var len = chat_list.length;
      return chat_list[len-1]['text'];
    }
    return '';
  }

  const getBizChatToolTip = (order) =>{
    if(order['bizChat']){
      var chat_list = JSON.parse(order['bizChat'])
      var len = chat_list.length;
      return chat_list[len-1]['text'];
    }
    return '';
  }

  const getChatLastUpdateDate = (order) =>{
    if(order['chat']){
      var chat_list = JSON.parse(order['chat'])
      var len = chat_list.length;
      if (chat_list[len-1]['time']) {
        var chatDate = new Date(chat_list[len-1]['time']);
        return chatDate.getDate()+"-"+(chatDate.getMonth()+1);
      }
    }
    return '';
  }

  const getChatView = (order) =>{
    if(order['chat']){
      var chat_list = JSON.parse(order['chat'])
      var len = chat_list.length;
      if(chat_list[len-1]['text'] 
        && (chat_list[len-1]['text'].indexOf(' status changed to ') > -1 || chat_list[len-1]['text'].indexOf('Order has been confirmed') > -1))
        return pastelStyle;
      if(chat_list[len-1]['ILStatus'] && chat_list[len-1]['NLStatus'])
        return greyStyle;
      if(chat_list[len-1]['location']){
        if(chat_list[len-1]['location'] == 'IL')
          return blueStyle;
        if(chat_list[len-1]['location'] == 'NL' || chat_list[len-1]['location'] == 'US')
          return orangeStyle;
      }
      return greenStyle;
    }
    return defaultStyle;
  }

  const getBizChatView = (order) =>{
    if(order['bizChat']){
      var chat_list = JSON.parse(order['bizChat'])
      var len = chat_list.length;
      if(chat_list[len-1]['ILStatus'] && chat_list[len-1]['NLStatus'])
        return greyStyle;
      if(chat_list[len-1]['location']){
        if(chat_list[len-1]['location'] == 'IL')
          return blueStyle;
        if(chat_list[len-1]['location'] == 'NL')
          return orangeStyle;
      }
      return greenStyle;
    }
    return defaultStyle;
  }

  const userILDisplay = userILNames.find(user => user.id === order.userILId)?.name || '-';
  const JobNumberDisplay = ''+(order.jobNumber || '-')+'';
  const countryOfOriginDisplay = ((loginUser.location.code === 'US' || selectedLocationIndex == 1) ? order?.originState : (COUNTRY_CODE_LIST?.find(country => country.country_id === order.countryOfOriginId)?.country_code || countryOfOriginData?.find(country => country.id === order.countryOfOriginId)?.name)) || '-';
  const countryOfOriginDisplayTooltip = ((loginUser.location.code === 'US' || selectedLocationIndex == 1) ? order?.originState : (countryOfOriginData?.find(country => country.id === order.countryOfOriginId)?.name)) || '-';
  const shipperNameDisplay = order?.shipper?.companyName || '-';
  const consigneeNameDisplay = order?.consignee?.companyName || '-';
  const dueDateDisplay = order.dueDate && pageName !== 'orderHistory'? new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
           /* hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',*/
          }).format(order.dueDate):
          order.lastUpdateDate && (pageName === 'ParcelsHistory' || pageName === 'DropsHistory') ? new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
           /* hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',*/
          }).format(order.lastUpdateDate): '-';
      const reminderDateDisplay = order.ilRemindDate && loginUser?.location?.code === 'IL' ? new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            /*hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',*/
          }).format(order.ilRemindDate):
          order.remindDate && loginUser?.location?.code != 'IL' ? new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
           /* hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',*/
          }).format(order.remindDate): '-';
  const polDisplay = ((order?.shippingModeId === 1) ? (airPolData?.find(pol => pol.id === order.polId)?.name) : (lclPolData?.find(pol => pol.id === order.polId)?.name)) || '-';
  const poaDisplay = ((order?.shippingModeId === 1) ? (airPoaData?.find(poa => poa.id === order.poaId)?.name) : (lclPoaData?.find(poa => poa.id === order.poaId)?.name)) || '-';
    const getFirstName = (name) => {
        var arr = name?.split(" ");
        if (arr?.length) {
            return arr[0]
        }
        return ""
    }
  return (
    <TableRow onClick={() => dispatch(onParcelSelected(order?.id))} style={order?.id === ParcelSelected ?orderRowBgColor:null}>
      {/*<StyledParcelTableCell rowSpan={1} padding='none'>
        <IconButton size='small' onClick={onHandleSwitchCollapse}>
          {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </StyledParcelTableCell>*/}
      {/*<TableCell sx={{width: 50, maxWidth: 50, pr: 0, overflow: 'hidden',textOverflow : 'ellipsis', wordWrap: 'break-word',lineHeight: '1' }}>
        <Tooltip title={userILDisplay}>
          <Typography fontSize="1vw" variant='body1' component='span' sx={{whiteSpace: 'nowrap', lineHeight: '1'}}>
            {userILDisplay}
          </Typography>
        </Tooltip>
        <Tooltip title={JobNumberDisplay}>
          <Typography fontSize="1vw" variant='body1' component='span' sx={{lineHeight: '1'}}>
            {JobNumberDisplay}
          </Typography>
        </Tooltip>
      </TableCell>*/}
          <TableCell sx={{ width: 50, maxWidth: 55, p: 0, overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word', lineHeight: '1' }}>
              <Tooltip title={userILDisplay + " " + JobNumberDisplay}>
          <Typography fontSize="1vw" variant='body1' component='span' sx={{whiteSpace: 'nowrap', lineHeight: '1'}}>
                      {/*{getFirstName(userILDisplay)}*/}
                      {JobNumberDisplay}
          </Typography>
        </Tooltip>
        {/*<Tooltip title={userNLNumberDisplay}>
          <Typography fontSize="1vw" variant='body1' component='span' sx={{lineHeight: '1', pt: 0, pb: 0}}>
            {userNLNumberDisplay}
          </Typography>
        </Tooltip>*/}
      </TableCell>
     {/* <TableCell sx={{ width: 50, pr: 0,lineHeight: '1' }}>
        <Tooltip title={shippingModeNameDisplay}>
          <Typography fontSize="1vw" variant='body1' component='span' sx={{lineHeight: '1'}}>
            {shippingModeNameDisplay}
          </Typography>
        </Tooltip>
      </TableCell>*/}
      {/*<TableCell sx={{width: 50, maxWidth: 50, pr: 0, overflow: 'hidden',textOverflow : 'ellipsis', whiteSpace: 'nowrap', lineHeight: '1' }}>
        <Tooltip title={subModeNameDisplay}>
          <Typography fontSize="1vw" variant='body1' component='span' sx={{lineHeight: '1'}}>
            {subModeNameDisplay}
          </Typography>
        </Tooltip>
      </TableCell>*/}
      <TableCell sx={{ width: 50, pr: 0, lineHeight: '1', pt: 0, pb: 0 }}>
              <Tooltip title={countryOfOriginDisplayTooltip}>
          <Typography fontSize="1vw" variant='body1' component='span' sx={{lineHeight: '1', pt: 0, pb: 0}}>
            {countryOfOriginDisplay}
          </Typography>
           </Tooltip>
              
              {/*<Tooltip title={JobNumberDisplay}>
                  <Typography fontSize="1vw" variant='body1' component='span' sx={{ lineHeight: '1', pt: 0, pb: 0 }}>
                      {JobNumberDisplay}
                  </Typography>
              </Tooltip>*/}
      </TableCell>
          <TableCell sx={{ width: 50, p: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: '1' }}>
        <Tooltip title={shipperNameDisplay}>
          <StyledTypography fontSize="1vw" variant='body1' component='span' sx={{lineHeight: '1'}} onClick={onHandleOpenShipperModal}>
            {shipperNameDisplay}
          </StyledTypography>
        </Tooltip>
      </TableCell>
      {openShipperModal && (
          <Dialog
            maxWidth='md'
            open={openShipperModal}
            title={'Shipper Card'}
            onHandleCancel={onHandleCancelShipperModal}>
            <AddServiceProvider data={order?.shipper}  onHandleCancel={onHandleCancelShipperModal} id={null} readOnly={true}/>
          </Dialog>
        )}
      <TableCell sx={{width: 50, maxWidth: 50, pr: 0, overflow: 'hidden',textOverflow : 'ellipsis', whiteSpace: 'nowrap', lineHeight: '1', pt: 0, pb: 0 }}>
        <Tooltip title={consigneeNameDisplay}>
          <StyledTypography fontSize="1vw" variant='body1' component='span' sx={{lineHeight: '1'}} onClick={onHandleOpenConsigneeModal}>
            {consigneeNameDisplay}
          </StyledTypography>
        </Tooltip>
      </TableCell>
        {openConsigneeModal && (
          <Dialog
            maxWidth='md'
            open={openConsigneeModal}
            title={'Consignee Card'}
            onHandleCancel={onHandleCancelConsigneeModal}>
            <AddServiceProvider data={order?.consignee}  onHandleCancel={onHandleCancelConsigneeModal} id={null} readOnly={true}/>
          </Dialog>
        )}
      <TableCell sx={{ width: 50, pr: 0, lineHeight: '1', pt: 0, pb: 0 }}>
        <Tooltip title={reminderDateDisplay}>
          <StyledTypography fontSize="1vw" variant='body1' component='span' sx={{lineHeight: '1', pt: 0, pb: 0}} onClick={onReminderDateDialogHandleOpen}>
            {reminderDateDisplay}
          </StyledTypography>
        </Tooltip>
      </TableCell>
       {openReminderDateDialog && (
              <SetReminderDialog order={order} openDialog={openReminderDateDialog} setOpenDialog={setOpenReminderDateDialog} loginUser={loginUser} pageName={pageName} />
                      )}
      { /* <TableCell sx={{ width: 50, pr: 0, lineHeight: '1', pt: 0, pb: 0 }}>
          <Tooltip title={polDisplay}>
            <Typography fontSize="1vw" variant='body1' component='span' sx={{lineHeight: '1', pt: 0, pb: 0}}>
              {polDisplay}
            </Typography>
          </Tooltip>
      </TableCell> */}
      <TableCell sx={{ width: 50, pr: 0, lineHeight: '1', pt: 0, pb: 0 }}>
        <Tooltip title={poaDisplay}>
          <Typography fontSize="1vw" variant='body1' component='span' sx={{lineHeight: '1', pt: 0, pb: 0}}>
            {poaDisplay}
          </Typography>
        </Tooltip>
          </TableCell>
          {stages}

        <TableCell sx={{ width: 50, pr: 0, lineHeight: '1', pt: 0, pb: 0 }}>
          <Tooltip title='Download Label'>
            <StyledTypography fontSize="1vw" variant='body1' component='span' sx={{lineHeight: '1', pt: 0, pb: 0}} onClick={() => downloadLabel(order.jobNumber)}>
              Label
            </StyledTypography>
          </Tooltip>
        </TableCell>
          <TableCell rowSpan={1} colSpan={2} className="padding_0">
              <Grid container wrap='nowrap' justifyContent='center' flexDirection='row' alignItems='center' className="padding_0">
                  <Tooltip title={getBizChatToolTip(order)}>
                      <IconButton size='small' onClick={onBizChatDialogHandleOpen}>
                          <MailOutlineIcon style={getBizChatView(order)} />
                      </IconButton>
                  </Tooltip>
                  <Tooltip title={getToolTip(order)}>
                      <IconButton size='small' onClick={onChatDialogHandleOpen} style={getOrderChatStyle(order)}>
                          <Grid container justifyContent="center" alignItems="center"><span style={{ marginRight: "5px", fontSize: " 1vw" }}>{getChatLastUpdateDate(order)}</span> <ChatIcon style={getChatView(order)} /></Grid>
                      </IconButton>
                  </Tooltip>
                  {/*<Tooltip title={getToolTip(order)}>
                      <IconButton size='small' onClick={onChatDialogHandleOpen} style={getOrderChatStyle(order)}>
                          <ChatIcon style={getChatView(order)} />
                          <Typography fontSize="1vw" variant='body1' component='span' style={{ display: 'block', lineHeight: '1' }}>{getChatLastUpdateDate(order)}</Typography>
                      </IconButton>
                  </Tooltip>*/}
                  {order.notes ? (
                      <Tooltip title={order.notes}>
                          <NotesIcon style={noteStyle} />
                      </Tooltip>
                  ) : (
                      <NotesIcon />
                  )}

                  {openChatDialog && (
                      <Dialog
                          open={openChatDialog}
                          onHandleCancel={onChatDialogHandleClose}
                          title={'Chat Box - ' + order?.jobNumber}
                          maxWidth='md'>
                          <ChatDialog onHandleCancel={onChatDialogHandleClose} order={order} loginUser={loginUser} pageName={pageName} />
                      </Dialog>
                  )}

                  {openBizChatDialog && (
                      <Dialog
                          open={openBizChatDialog}
                          onHandleCancel={onBizChatDialogHandleClose}
                          title={'Biz Chat Box - ' + order?.jobNumber}
                          maxWidth='md'>
                          <BizChatDialog onHandleCancel={onBizChatDialogHandleClose} order={order} loginUser={loginUser} pageName={pageName} />
                      </Dialog>
                  )}
                  {/*<Button variant='outlined' sx={{ mr: 1, margin: "5px" }} onClick={onOrderModalHandleOpen}>
            Edit
          </Button>*/}
                  { /* pageName === 'orderHistory' ? null : */
                      <Tooltip title='Edit Order'>
                          <IconButton size='small' onClick={onOrderModalHandleOpen}>
                              <EditIcon style={{ width: '1.6vw !important', height: '1.6vw !important' }} />
                          </IconButton>
                      </Tooltip>
                  }
                  {openOrderModal && (
                      <Dialog
                          open={openOrderModal}
                          onHandleCancel={onOrderModalHandleCancel}
                          title={((selectedTabIndexName == 'Drops' || selectedTabIndexName=='DropsHistory') ? 'Drop information' : 'Parcel information') + (order?.jobNumber? ' - ' + order?.jobNumber:'')}
                          maxWidth='md'>
                          <OrderForm loginUser={loginUser} onHandleCancel={onOrderModalHandleCancel} order={order} orderTableFilter={orderTableFilter} pageName={selectedTabIndexName} />
                      </Dialog>
                  )}
                  {/*
            <Button variant='outlined' color='error' onClick={onConfirmDialogHandleOpen}>
              Del
            </Button>
*/}
                  {loginUser.authorities && loginUser.authorities[0].authority === 'Admin' && (
                      <Tooltip title='Delete Order'>
                          <IconButton size='small' onClick={onConfirmDialogHandleOpen}>
                              <DeleteForeverIcon style={{ width: '1.6vw !important', height: '1.6vw !important' }} />
                          </IconButton>
                      </Tooltip>
                  )}
                  {openConfirmDialog && (
                      <ConfirmDialog
                          open={openConfirmDialog}
                          onHandleClose={onConfirmDialogHandleClose}
                          message='Are you sure you want to permanently delete this order?'
                          onHandleYes={() => onOrderHandleDelete(order.id)}
                      />
                  )}
                  {pageName !== 'orderHistory' && (
                      <Tooltip title='Move to Order History'>
                          <IconButton size='small' onClick={onHistoryConfirmDialogHandleOpen}>
                              <ArrowRightIcon style={{ width: '1.6vw !important', height: '1.6vw !important' }} />
                          </IconButton>
                      </Tooltip>
                  )}
                  {openHistoryConfirmDialog && (
                      <ConfirmDialog
                          open={openHistoryConfirmDialog}
                          onHandleClose={onHistoryConfirmDialogHandleClose}
                          message='Are you sure you want to move order to history?'
                          onHandleYes={() => onOrderHandleHistory(order.id)}
                      />
                  )}
                  {(pageName === 'ParcelsHistory' || pageName === 'DropsHistory') && (
                      <Tooltip title='Move to Order'>
                          <IconButton size='small' onClick={onHistoryRevertConfirmDialogHandleOpen}>
                              <RefreshIcon style={{ width: '1.6vw !important', height: '1.6vw !important' }} />
                          </IconButton>
                      </Tooltip>
                  )}
                  {openHistoryRevertConfirmDialog && (
                      <ConfirmDialog
                          open={openHistoryRevertConfirmDialog}
                          onHandleClose={onHistoryRevertConfirmDialogHandleClose}
                          message='Are you sure you want to move order out of history?'
                          onHandleYes={() => onOrderHandleHistoryRevert(order.id)}
                      />
                  )}
                  {pageName !== 'orderHistory' && (
                      <Tooltip title='Back to Previous Stage'>
                          <IconButton size='small' onClick={onBackStageConfirmDialogHandleOpen}>
                              <BackIcon style={{ width: '1.6vw !important', height: '1.6vw !important' }} />
                          </IconButton>
                      </Tooltip>
                  )}
                  {backStageConfirmDialog && (
                      <ConfirmDialog
                          open={backStageConfirmDialog}
                          onHandleClose={onBackStageConfirmDialogHandleClose}
                          message='Are you sure you want to move order to previous stage?'
                          onHandleYes={() => onOrderHandleBackStage(order.id)}
                      />
                  )}
              </Grid>
          </TableCell>
    </TableRow>
  );
};

export default memo(OrderRowInfo);

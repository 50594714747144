import { createSlice } from '@reduxjs/toolkit';

export const departureStageSlice = createSlice({
  name: 'departureStage',
  initialState: {
    id: null,
    departureStatuses: null,
    stageStatusInfoId: null,
    cutOffTime: null,
    updateTime: null,
    refNumber: null,
    master: null,
    house: null,
    eta: null,
    etd: null,
    notes: null,
    shipName: "",
    imo: "",
    containerNumber: "",
    bookingNumber: "",
    vesselNumber: "",
    shippingLineName: "",
    airline: "",
    flightNumber: "",
    awb: "",
    departureDate: null,
    shippingLineNames: null,
      shippingLineNamesOption: null,
  },
  reducers: {
    resetDepartureStage: state => {
      state.id = null;
      state.stageStatusInfoId = null;
      state.cutOffTime = null;
      state.updateTime = null;
      state.refNumber = null;
      state.notes = null;
      state.master = null;
      state.eta = null;
      state.etd = null;
      state.house = null;
      state.shipName = null;
      state.imo = null;
      state.containerNumber = null;
      state.bookingNumber = null;
      state.vesselNumber = null;
      state.shippingLineName = null;
      state.airline = null;
      state.flightNumber = null;
          state.awb = null;
          state.departureDate = null;
      },
      onDepartureStatusesChange: (state, { payload }) => {
          state.departureStatuses = payload;
      },
    onShippingLineNamesChange: (state, { payload }) => {
      state.shippingLineNames = payload;
    },
    onShippingLineNamesOptionChange: (state, { payload }) => {
      state.shippingLineNamesOption = payload;
    },
    onIdChange: (state, { payload }) => {
      state.id = payload;
    },
    onStageStatusInfoIdChange: (state, { payload }) => {
      state.stageStatusInfoId = payload;
    },
    onCutOffTimeChange: (state, { payload }) => {
      state.cutOffTime = payload;
    },
    onETATimeChange: (state, { payload }) => {
      state.eta = payload;
    },
    onETDTimeChange: (state, { payload }) => {
      state.etd = payload;
    },
    onUpdateTimeChange: (state, { payload }) => {
      state.updateTime = payload;
    },
    onRefNumberChange: (state, { payload }) => {
      state.refNumber = payload;
    },
    onNotesChange: (state, { payload }) => {
      state.notes = payload;
    },
    onMasterChange: (state, { payload }) => {
      state.master = payload;
    },
    onHouseChange: (state, { payload }) => {
      state.house = payload;
    },
    onShipNameChange: (state, { payload }) => {
      state.shipName = payload;
    },
    onImoChange: (state, { payload }) => {
      state.imo = payload;
    },
    onContainerNumberChange: (state, { payload }) => {
      state.containerNumber = payload;
    },
    onBookingNumberChange: (state, { payload }) => {
      state.bookingNumber = payload;
    },
    onVesselNumberChange: (state, { payload }) => {
      state.vesselNumber = payload;
    },
    onShippingLineNameChange: (state, { payload }) => {
      state.shippingLineName = payload;
    },
    onAirlineChange: (state, { payload }) => {
      state.airline = payload;
    },
    onFlightNumberChange: (state, { payload }) => {
      state.flightNumber = payload;
    },
    onAWBChange: (state, { payload }) => {
      state.awb = payload;
    },
    onDepartureDateChange: (state, { payload }) => {
      state.departureDate = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetDepartureStage,
  onIdChange,
  onDepartureStatusesChange,
  onShippingLineNamesChange,
  onShippingLineNamesOptionChange,
  onStageStatusInfoIdChange,
  onCutOffTimeChange,
  onUpdateTimeChange,
  onRefNumberChange,
  onNotesChange,
  onMasterChange,
  onHouseChange,
  onETATimeChange,
  onETDTimeChange,
  onShipNameChange,
  onImoChange,
  onContainerNumberChange,
  onBookingNumberChange,
  onVesselNumberChange,
  onShippingLineNameChange,
  onAirlineChange,
  onFlightNumberChange,
  onAWBChange,
  onDepartureDateChange,
} = departureStageSlice.actions;

export default departureStageSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

export const orderTableFilterSlice = createSlice({
  name: 'orderTableFilter',
  initialState: {
    consigneeId: null,
    shipperId: null,
    countryOfOriginId: null,
    originState: null,
    shippingModeId: null,
    subModeId: null,
    userLocalId: null,
    userAbroadId: null,
    userLocalNumber: null,
    userAbroadNumber: null,
    search: null,
    dueDate: null,
    lastUpdateDate: null,
    reminderDate: null,
    ilReminderDate: null,
    selectedLocationIndex: 0,
    selectedTabIndexName: 'Parcels',
    reminderDateAdv: null,
    ilReminderDateAdv: null,
    startReminderDate: null,
    endReminderDate: null,
    startIlReminderDate: null,
    endIlReminderDate: null,
    stageSearch: null,
    stageStateSearch: null,
  },
  reducers: {
    onConsigneeIdChange: (state, { payload }) => {
      state.consigneeId = payload;
    },
    onCountryOfOriginIdChange: (state, { payload }) => {
      state.countryOfOriginId = payload;
    },
    onOriginStateChange: (state, { payload }) => {
      state.originState = payload;
    },
    onShipperIdChange: (state, { payload }) => {
      state.shipperId = payload;
    },
    onShippingModeIdChange: (state, { payload }) => {
      state.shippingModeId = payload;
    },
    onSubModeIdChange: (state, { payload }) => {
      state.subModeId = payload;
    },
    onUserLocalIdChange: (state, { payload }) => {
      state.userLocalId = payload;
    },
    onUserAbroadIdChange: (state, { payload }) => {
      state.userAbroadId = payload;
    },
    onUserLocalNumberChange: (state, { payload }) => {
      state.userLocalNumber = payload;
    },
    onUserAbroadNumberChange: (state, { payload }) => {
      state.userAbroadNumber = payload;
    },
    onSearchChange: (state, { payload }) => {
      state.search = payload;
    },
    onDueDateChange: (state, { payload }) => {
      state.dueDate = payload;
    },
    onLastUpdateDateChange: (state, { payload }) => {
      state.lastUpdateDate = payload;
    },
    onReminderDateChange: (state, { payload }) => {
      state.reminderDate = payload;
    },
    onIlReminderDateChange: (state, { payload }) => {
      state.ilReminderDate = payload;
    },
    onSelectedLocationIndexChange: (state, { payload }) => {
      state.selectedLocationIndex = payload;
    },
    onSelectedTabIndexNameChange: (state, { payload }) => {
      state.selectedTabIndexName = payload;
    },
    OnStartReminderDateChange: (state, { payload }) => {
      state.startReminderDate = payload;
    },
    OnEndReminderDateChange: (state, { payload }) => {
      state.endReminderDate = payload;
    },
    OnStartIlReminderDateChange: (state, { payload }) => {
      state.startIlReminderDate = payload;
    },
    OnEndIlReminderDateChange: (state, { payload }) => {
      state.endIlReminderDate = payload;
    },
    OnStageSearchChange: (state, { payload }) => {
      state.stageSearch = payload;
      if (payload == null) {
        state.stageStateSearch = payload;
      }
    },
    OnStageStateSearchChange: (state, { payload }) => {
      state.stageStateSearch = payload;
    },
    OnReminderDateAdvChange: (state, { payload }) => {
      state.reminderDateAdv = payload;
    },
    OnIlReminderDateAdvChange: (state, { payload }) => {
      state.ilReminderDateAdv = payload;
    },
    resetOrderTableFilterSlice: (state, { payload }) => {
        state.consigneeId = payload;
        state.shipperId = payload;
        state.countryOfOriginId = payload;
        state.originState = payload;
        state.shippingModeId = payload;
        state.subModeId = payload;
        //state.userLocalId = payload;
        //state.userAbroadId = payload;
        state.userLocalNumber = payload;
        state.userAbroadNumber = payload;
        state.search = payload;
        state.dueDate = payload;
        state.lastUpdateDate = payload;
        state.reminderDate = payload;
        state.ilReminderDate = payload;
        //state.selectedLocationIndex = 0;
        //state.lastUpdateDate = new Date(new Date().setHours(0,0,0,0)).toISOString();
        state.startReminderDate = payload;
        state.endReminderDate = payload;
        state.startIlReminderDate = payload;
        state.endIlReminderDate = payload;
        state.stageSearch = payload;
        state.stageStateSearch = payload;
        state.reminderDateAdv = payload;
        state.ilReminderDateAdv = payload;
    },
     resetAdvancedSearchFilter: (state, { payload }) => {
        state.startReminderDate = payload;
        state.endReminderDate = payload;
        state.startIlReminderDate = payload;
        state.endIlReminderDate = payload;
        state.stageSearch = payload;
        state.stageStateSearch = payload;
        state.reminderDateAdv = payload;
        state.ilReminderDateAdv = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onConsigneeIdChange,
  onCountryOfOriginIdChange,
  onOriginStateChange,
  onShipperIdChange,
  onShippingModeIdChange,
  onSubModeIdChange,
  onUserLocalIdChange,
  onUserAbroadIdChange,
  onUserLocalNumberChange,
  onUserAbroadNumberChange,
  onSearchChange,
  onDueDateChange,
  onLastUpdateDateChange,
  onReminderDateChange,
  onIlReminderDateChange,
  onSelectedLocationIndexChange,
  onSelectedTabIndexNameChange,
  resetOrderTableFilterSlice,
  OnStartReminderDateChange,
  OnEndReminderDateChange,
  OnStartIlReminderDateChange,
  OnEndIlReminderDateChange,
  OnStageSearchChange,
  OnStageStateSearchChange,
  OnReminderDateAdvChange,
  OnIlReminderDateAdvChange,
  resetAdvancedSearchFilter,
} = orderTableFilterSlice.actions;

export default orderTableFilterSlice.reducer;

import React from "react";
import './CustomerSidebar.scss'
import FaLogo  from '../../../../resources/FA.png'
import FaBGVert from '../../../../resources/bgGradVert.png'
import { Box, Grid, List, ListItem, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const CustomerSidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <Box className="customer-sidebar">
            {<Grid item container style={{ background: `url(${FaBGVert})`, width: "100%", height: "10.4vw", "background-size": "cover" }} flexDirection="column" flexWrap="nowrap">
                <Box className="brand-logo">
                    <img src={FaLogo} className="fa-logo" alt="" style={{ marginLeft: "7px", "background-size": "cover" }} />
                </Box>
                <List className="sidebar-menu" style={{ width: "100%", height: "5.2vw" }}>
                    <ListItem className="menu-item" style={{ width: "100%", height: "2.6vw" }}>
                        <Button
                            className={`item-button tk-good-times ${location.pathname === '/customer/dashboard' && 'active'}`}
                            onClick={() => navigate('/customer/dashboard')}>
                            <span style={{ marginLeft: "2.5vw", height: "2.6vw", marginTop: "1vw" }}>DASHBOARD</span>
                        </Button>
                    </ListItem>
                    <ListItem className="menu-item" style={{ width: "100%", height: "2.6vw" }}>
                        <Button
                            className={`item-button tk-good-times ${location.pathname === '/customer/shipments' && 'active'}`}
                            onClick={() => navigate('/customer/shipments')}>
                            <span style={{ marginLeft: "2.5vw", height: "2.6vw", marginTop: "1vw" }}>SHIPMENTS</span>

                        </Button>
                    </ListItem>
                </List>
            </Grid>}
            {<Grid item container style={{ background: `url(${FaBGVert})`, height: "calc(100% - 10.4vw)", width: "100%", backgroundSize: "cover" }}>
            </Grid>}
        </Box>
    )
};

export default CustomerSidebar;
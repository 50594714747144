import { useState, memo } from 'react';

import StageButton from '../StageButton';
import DepartureDialog from './DepartureDialog';
import {StyledParcelTableCell} from 'Utils';

const Departure = ({ loginUser, order }) => {
  const currentStage = order.parcelStage[4];

  const [openDialog, setOpenDialog] = useState(false);
  const onHandleOpenDialog = () => setOpenDialog(true);

  return (
      <StyledParcelTableCell style={{ width: "3vw", p: 0 }}>
      <StageButton stage={currentStage} onHandleOpenDialog={onHandleOpenDialog} stageName='Manifest' loginUser={loginUser}/>
      {openDialog && <DepartureDialog loginUser={loginUser} order={order} openDialog={openDialog} setOpenDialog={setOpenDialog} />}
    </StyledParcelTableCell>
  );
};

export default memo(Departure);

import { createSlice } from '@reduxjs/toolkit';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    roles: [],
    locations: [],
    usersLocation: [],
    customers: [],
    truckingUserOptions: [],
  },
  reducers: {
    onUsersChange: (state, { payload }) => {
      state.users = payload;
    },

    onRolesChange: (state, { payload }) => {
      state.roles = payload;
    },
    onLocationChange: (state, { payload }) => {
      state.locations = payload.filter(location => location.id != 3);
    },
    onUsersLocationChange: (state, { payload }) => {
      state.usersLocation = payload.filter(location => (location.id != 3 ));
    },
    onCustomerChange: (state, { payload }) => {
      state.customers = payload;
    },
    onUserOptionsChange: (state, { payload }) => {
      state.truckingUserOptions = payload;
    },
    addUser: (state, { payload }) => {
      state.users = [...state.users, payload];
    },
    updateUser: (state, { payload }) => {
      const user1 = state.users.find(user => user.id === payload.id);
      const userIndex = state.users.indexOf(user1);
      const before = state.users.slice(0, userIndex);
      const after = state.users.slice(userIndex + 1);
      const newUsers = [...before, payload, ...after];
      state.users = newUsers;
    },
    deleteUser: (state, { payload }) => {
      state.users = state.users.filter(user => user.id !== payload);
    },
  },
});
// Action creators are generated for each case reducer function
export const { onUsersChange, onRolesChange, onUserOptionsChange, addUser, updateUser, deleteUser, onLocationChange, onUsersLocationChange, onCustomerChange } =
  usersSlice.actions;

export default usersSlice.reducer;

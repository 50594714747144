import { snackbarToggle } from 'reducers/snackbarSlicer';
import ChatIcon from '@mui/icons-material/Chat';

export const sendNewChatMsgNotification = (loginUser, dispatch, oldCount, newCount) => {
  if (loginUser?.authorities 
      && loginUser?.authorities[0]?.authority === 'User'
      && oldCount < newCount) {
    dispatch(snackbarToggle({ type: 'info', message: 'New chat message received!', customIcon: <ChatIcon fontSize="inherit" style={{marginTop: '4px'}} /> }));
  }
}

export const sendNewOrderNotification = (loginUser, dispatch, oldCount, newCount) => {
  if (loginUser?.authorities 
      && loginUser?.authorities[0]?.authority === 'User'
      && loginUser.location.code === 'NL'
      && oldCount < newCount) {
    dispatch(snackbarToggle({ type: 'info', message: 'New Order has been opened!', customIcon: <ChatIcon fontSize="inherit" style={{marginTop: '4px'}} /> }));
  }
}
import { useState, memo } from 'react';

import StageButton from '../StageButton';
import TruckingDialog from './TruckingDialog';
import {StyledTableCell} from 'Utils';

const Trucking = ({ loginUser, order }) => {
    const getStatus = () => {
        var stage = order.orderStage ? order.orderStage[2] : null;
        if (order.locationId != 2) {
            if (order.incotermsId == 3 || order.incotermsId == 4 || order.incotermsId == 5 || order.incotermsId == 6 || order.incotermsId == 8 || order.incotermsId == 9) {
                stage = {};
                stage.status = "dissable";
                stage.notes = "";
            }
        } else {
            if (order.incotermsId == 8 || order.incotermsId == 9) {
                stage = {};
                stage.status = "dissable";
                stage.notes = "";
            }
        }
        return stage;
    }
    const currentStage = order.orderStage?order.orderStage[2]:null;

  const [openDialog, setOpenDialog] = useState(false);
  const onHandleOpenDialog = () => setOpenDialog(true);

  return (
      <StyledTableCell align='center' style={{ width: "5vw", p: 0 }}>
      <StageButton stage={getStatus()} onHandleOpenDialog={onHandleOpenDialog} stageName='Truck' loginUser={loginUser}/>
      {openDialog && <TruckingDialog loginUser={loginUser} order={order} openDialog={openDialog} setOpenDialog={setOpenDialog} />}
    </StyledTableCell>
  );
};

export default memo(Trucking);

import { createSlice } from '@reduxjs/toolkit';
import { expandData } from 'utils/Communicator';
export const vendorsSlice = createSlice({
  name: 'vendors',
  initialState: {
    vendors: [],

    all:[],
    vendorNameOptions: [],
  },
  reducers: {
    onVendorsChange: (state, { payload }) => {
      state.vendors = payload;
    },
    onAllChange: (state, { payload }) => {
      state.all = payload;
    },

    onVendorNameOptionsChange: (state, { payload }) => {
      state.vendorNameOptions = payload;
    },
    addVendor: (state, { payload }) => {
      state.vendors = [...state.vendors, payload];
    },
    updateVendor: (state, { payload }) => {
      const vendor = state.vendors.find(vendor => vendor.id === payload.id);
      const vendorIndex = state.vendors.indexOf(vendor);
      const before = state.vendors.slice(0, vendorIndex);
      const after = state.vendors.slice(vendorIndex + 1);
      const newVendors = [...before, payload, ...after];
      state.vendors = newVendors;
    },
    add: (state, { payload }) => {
      state.all = [...state.all, payload];
    },
    updateServiceProviders: (state, { payload }) => {
      const serviceProvider = state.all.find(serviceProvider => serviceProvider.id === payload.id);
      const serviceProviderIndex = state.all.indexOf(serviceProvider);
      const before = state.all.slice(0, serviceProviderIndex);
      const after = state.all.slice(serviceProviderIndex + 1);
      const newServiceProviders = [...before, payload, ...after];
      state.all = newServiceProviders;
    },
  },
});

// Action creators are generated for each case reducer function
export const { onVendorsChange, onVendorNameOptionsChange, updateVendor, addVendor,onAllChange,add,updateServiceProviders } =
  vendorsSlice.actions;

export default vendorsSlice.reducer;

import { configureStore } from '@reduxjs/toolkit';

// order reducers
import ordersReducer from 'reducers/order-reducers/ordersSlicer';
import orderInfoSlice from 'reducers/order-reducers/orderInfoSlicer';
import predefinedDataSlice from 'reducers/order-reducers/predefinedDataSlicer';

//parcel
import parcelsReducer from 'reducers/parcel-reducers/ordersSlicer';
import parcelInfoSlice from 'reducers/parcel-reducers/orderInfoSlicer';
import parcelPredefinedDataSlice from 'reducers/parcel-reducers/predefinedDataSlicer';
import manifestsSlice from 'reducers/parcel-reducers/manifestsSlicer';

// parcel stage reducers
import parcelConfirmationStageSlice from 'reducers/parcel-stage-reducers/confirmationStageSlicer';
import parcelShipperStageSlice from 'reducers/parcel-stage-reducers/shipperStageSlicer';
import parcelTruckingStageSlice from 'reducers/parcel-stage-reducers/truckingStageSlicer';
import parcelWhStageSlice from 'reducers/parcel-stage-reducers/whStageSlicer';
import parcelDepartureStageSlice from 'reducers/parcel-stage-reducers/departureStageSlicer';

// stage reducers
import confirmationStageSlice from 'reducers/stage-reducers/confirmationStageSlicer';
import shipperStageSlice from 'reducers/stage-reducers/shipperStageSlicer';
import truckingStageSlice from 'reducers/stage-reducers/truckingStageSlicer';
import whStageSlice from 'reducers/stage-reducers/whStageSlicer';
import departureStageSlice from 'reducers/stage-reducers/departureStageSlicer';

// global reducers
import snackbarSlice from 'reducers/snackbarSlicer';
import shippersSlice from 'reducers/shippersSlicer';
import customersSlice from 'reducers/customersSlicer';
import truckingCompaniesSlice from 'reducers/TruckingCompanySlicer';
import usersSlice from 'reducers/UsersSlicer';
import consigneesSlice from 'reducers/consigneesSlicer';
import vendorsSlice from 'reducers/VendorSlicer';
import orderTableFilterSlice from 'reducers/order-reducers/orderTableFilterSlicer';
import parcelTableFilterSlice from 'reducers/parcel-reducers/orderTableFilterSlicer';

export default configureStore({
    reducer: {
        orders: ordersReducer,
        parcels: parcelsReducer,
        manifests: manifestsSlice,
        parcelInfo: parcelInfoSlice,
        orderInfo: orderInfoSlice,
        predefinedData: predefinedDataSlice,
        parcelPredefinedData: parcelPredefinedDataSlice,
        snackbar: snackbarSlice,
        confirmationStage: confirmationStageSlice,
        parcelConfirmationStage: parcelConfirmationStageSlice,
        shipperStage: shipperStageSlice,
        parcelShipperStage: parcelShipperStageSlice,
        truckingStage: truckingStageSlice,
        parcelTruckingStage: parcelTruckingStageSlice,
        whStage: whStageSlice,
        parcelWhStage: parcelWhStageSlice,
        departureStage: departureStageSlice,
        parcelDepartureStage: parcelDepartureStageSlice,
        shippers: shippersSlice,
        customers: customersSlice,
        consignees: consigneesSlice,
        truckingCompanies: truckingCompaniesSlice,
        orderTableFilter: orderTableFilterSlice,
        parcelTableFilter: parcelTableFilterSlice,
        users: usersSlice,
        vendors: vendorsSlice
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

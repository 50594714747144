import { Fragment, useCallback, useEffect, useState, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { ConfirmDialog, Dialog, Tooltip } from 'style-guide';

import { sendRequest, REQUEST_ACTIONS, setOrderData } from 'utils/Communicator';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import {
    Table,
    TableBody,
    TableContainer,
    Button,
    Grid,
    TableHead,
    TableRow,
    TableCell,
    Typography,
} from '@mui/material';

const MailAuditDlg = ({ order }) => {

    const [mails, setMails] = useState([]);
    const [selectedMail, setSelectedMail] = useState({});
    const { selectedLocationIndex, } = useSelector(state => state.orderTableFilter);
    const [openCollapse, setOpenCollapse] = useState(false);

    const [openInfoModal, setOpenInfoModal] = useState(false);
    const onInfoModalOpen = () => setOpenInfoModal(true);
    const onInfoModalCancel = () => setOpenInfoModal(false);

    const dispatch = useDispatch();
    const {
        userUSNames,
        userNLNames,
        userILNames,
    } = useSelector(state => state.predefinedData);

    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    useEffect(() => {
        sendRequest(`/api/fa/getMails/${order.id}`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                setMails(response);
            },
            failedCallback,
        });
    }, []);

    const showMail = (mail) => {
        setSelectedMail(mail);
        onInfoModalOpen();
        window.setTimeout(function () {
            var dynamicContentNode = document.getElementById('dynamic_cont');
            dynamicContentNode.innerHTML = mail.content;
        }, 500);
    }

    function formatDate(date) {
        let p = new Intl.DateTimeFormat('en-GB', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            // second: '2-digit',
            hour12: false,
            timeZone: 'UTC',
            // timeZoneName: "longOffset",
        }).format(date)
        return p;
    }
    return (
        <Grid style={{ height: "400px", overflowY: "auto" }}>
            <Grid item container>
                <Table className="orderTable" style={{minWidth: "700px" }}>
                    <TableHead style={{ height: "30px" }}>
                        <TableCell>Time</TableCell>
                        <TableCell>Subject</TableCell>
                        <TableCell>To</TableCell>
                        <TableCell>BCC</TableCell>
                        <TableCell>Action</TableCell>
                    </TableHead>
                    <TableBody>
                        {mails?.map(mail => (<TableRow key={mail.id} style={{height: "30px"}}>
                            <TableCell>{formatDate(mail.time)}</TableCell>
                            <TableCell>{mail.sub.substring(0, 9) + "..."}</TableCell>
                            <TableCell>{mail.user.substring(0, 9) + (mail.user.length > 9 ? "..." : "")}</TableCell>
                            <TableCell>{mail.bcc.substring(0, 9) + (mail.bcc.length > 9 ? "..." : "")}</TableCell>
                            <TableCell><Button color='secondary' variant='contained' onClick={() => { showMail(mail) }}>See Mail</Button></TableCell>
                        </TableRow>))}
                    </TableBody>
                </Table>
                {openInfoModal && (
                    <Dialog
                        open={openInfoModal}
                        onHandleCancel={onInfoModalCancel}
                        title="Mail"
                        maxWidth='md'>
                        <Grid item container>
                            BCC: {selectedMail?.bcc}<br /><br />
                            SUB: {selectedMail?.sub}<br /><br />
                            <div id={`dynamic_cont`} style={{ width: "600px", height: "500px" }}></div>
                        </Grid>
                    </Dialog>
                )}
            </Grid>
        </Grid>
    );
};

export default MailAuditDlg;

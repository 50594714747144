import { useState, useCallback, useEffect } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { Dialog } from 'style-guide';
import { useDispatch, useSelector } from 'react-redux';
import ServiceProviderTable from 'serviceProvider/ServiceProviderTable';
import AddServiceProvider from './AddServiceProvider';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { onFunctionChange } from 'reducers/order-reducers/predefinedDataSlicer';
import { addShipper, updateShipper, onPickupAddressChange } from 'reducers/shippersSlicer';
import { addCustomer } from 'reducers/customersSlicer';
import { addConsignee, updateConsignee } from 'reducers/consigneesSlicer';
import { addVendor, updateVendor, add, updateServiceProviders } from 'reducers/VendorSlicer';
import { addTruckingCompany, updateTruckingCompany } from 'reducers/TruckingCompanySlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';

const ServiceProvider = ({ loginUser }) => {
  const dispatch = useDispatch();
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [completed, setCompleted] = useState(false);
  const onHandleOpenModal = () => setOpenOrderModal(true);
  const onHandleCancelModal = () => setOpenOrderModal(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const onHandleImportModal = () => setOpenImportModal(true);
  const onHandleImportCancelModal = () => setOpenImportModal(false);
  const { countryOfOriginData, serviceProviderType } = useSelector(state => state.predefinedData);
  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);

  const processCSV = (str1, delim=',') => {
    const str2 = str1.replace("\r", "");
    const str = str2.replace("\\r", "");
    const headers = str.slice(0,str.indexOf('\n')).split(delim);
    const rows = str.slice(str.indexOf('\n')+1).split('\n');
    setTotalEntries(rows.length);
    const newArray = rows.map( (row1, index ) => {
setTimeout(() => {
      const row2 = row1.replace("\r", "");
      const row = row2.replace("\\r", "");
      const values = row.split(delim);
      if(values.length < 3){return null;}
      var skip = false;
      const eachObject = headers.reduce((obj, header, i) => {
        if (header==="countryId"){
          const len = countryOfOriginData.length;
          var exists = false;
          var id = 0;
          for(var j = 0 ; j < len ; j++){
            if(countryOfOriginData[j].name == values[i] && !exists){exists = true; id=countryOfOriginData[j].id; break;}
          }
          if(exists){obj[header] = id;}
          else{skip = true;}
        }
        else if(header==="serviceProviderTypeId"){
          const len = serviceProviderType.length
          for(var j = 0 ; j < len ;j++){
            if(serviceProviderType[j].name == values[i] && !exists){exists = true; id=serviceProviderType[j].id; break;}
          }
          if(exists){obj[header] = id;}
          else{obj[header] = 1;}
        }
        else if(header==="serviceProviderTypeId1"){
          const len = serviceProviderType.length
          for(var j = 0 ; j < len ;j++){
            if(serviceProviderType[j].name == values[i] && !exists){exists = true; id=serviceProviderType[j].id; break;}
          }
          if(exists){obj[header] = id;}
          else{obj[header] = 3;}
        }
        else{ obj[header] = values[i]; }
        return obj;
      }, {})
      if(!skip){
        try{save(eachObject, index);}
        catch (error) {}
      }
      else{}
      return eachObject;
}, 200*index )
    })
    setCsvArray(newArray);
  }
  const submit = (cfile) => {
    const file = cfile;
    const reader = new FileReader();

    reader.onload = function(e) {
      const text = e.target.result;
      //console.log(text);
      processCSV(text)
    }
    reader.readAsText(file);
  }
  const save = (item, index) => {
    item.functionsId = null;
    item.sameAsShipper = "sameAsShipper";
    item.pickupAddress = null;
    sendRequest('/api/serviceProvider', REQUEST_ACTIONS.POST, item, {
      successCallback: response => {
        if (response.id == -100){dispatch(snackbarToggle({ type: 'error', message: response.companyName + ' already exists. index: ' + index }));}
        if (response.serviceProviderTypeId === 1) {
          dispatch(addShipper(response));
          dispatch(onPickupAddressChange(null));
        } else if (response.serviceProviderTypeId === 2) {
          dispatch(addConsignee(response));
          dispatch(onPickupAddressChange(null));
        } else if (response.serviceProviderTypeId === 4) {
          dispatch(addVendor(response));
        } else if (response.serviceProviderTypeId === 13) {
          dispatch(addCustomer(response));
        } else {
          dispatch(addTruckingCompany(response));
        }
        dispatch(add(response));
      },
      failedCallback,
    });
    if(index >= totalEntries-3){onHandleImportCancelModal();}

  };

  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

  useEffect(() => {
    sendRequest('/api/function', REQUEST_ACTIONS.GET, '', {
      successCallback: response => {
        dispatch(onFunctionChange(response.filter(functions => functions.name !== 'new pickup Address')));
        setCompleted(true);
      },
      failedCallback,
    });
  }, [dispatch, failedCallback]);

  return (
    completed && (
      <Grid container>
              <Grid container item xs={12} justifyContent='space-between' style={{marginBottom:"10px"}}>
          <Typography variant='h5'></Typography>
          <Button onClick={onHandleImportModal} variant='contained' sx={{ mb: 3 }}>
            {`Import`}
          </Button>
          {openImportModal && (
            <Dialog maxWidth='sm' open={openImportModal} title={`IMPORT CRM`} onHandleCancel={onHandleImportCancelModal}>
              <input
                type='file'
                accept='.csv'
                id='csvFile'
                onChange={(e) => {
                  setCsvFile(e.target.files[0]); submit(e.target.files[0]);
                }}
              ></input>
            </Dialog>
          )}
          <Button onClick={onHandleOpenModal} variant='contained' sx={{ mb: 3 }}>
            {`+ New CRM`}
          </Button>
          {openOrderModal && (
            <Dialog
              maxWidth='md'
              open={openOrderModal}
              title={`NEW CRM`}
              onHandleCancel={onHandleCancelModal}>
              <AddServiceProvider onHandleCancel={() => setOpenOrderModal(false)} id={null} readOnly={false}/>
            </Dialog>
          )}
        </Grid>
        <ServiceProviderTable loginUser={loginUser}/>
      </Grid>
    )
  );
};

export default ServiceProvider;

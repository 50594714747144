/* eslint-disable react/prop-types */
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from "@mui/material";
import { TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress } from '@mui/material';
import React, { Fragment, useEffect, useState, useCallback } from "react";
import { MultilineField, TextField } from 'style-guide';
import { useDispatch } from "react-redux";
import './ShipmentDetailDialog.scss';
import CloseIcon from "../../../../resources/close_icon.svg"
import ChatIcon from "../../../../resources/chatIcon.svg"
import ChatIconSelected from "../../../../resources/chatIconHover.svg"
import DocIcon from "../../../../resources/docIcon.svg"
import DocIconSelected from "../../../../resources/docIconHover.svg"
import detailsIcon from "../../../../resources/detailsIcon.svg"
import detailsIconSelected from "../../../../resources/detailsIconHover.svg"
import shipIcon from "../../../../resources/ship_icon.svg"
import airIcon from "../../../../resources/air.png"
import lclIcon from "../../../../resources/lcl.png"
import fclIcon from "../../../../resources/fcl.png"
import arrowLeft from "../../../../resources/arrowLeft.png"
import shipper from "../../../../resources/shipper.png"
import shipper_nt from "../../../../resources/shipper_white.png"
import wh from "../../../../resources/wh.png"
import wh_nt from "../../../../resources/wh_white.png"
import departure_air from "../../../../resources/departure.png"
import departure_air_nt from "../../../../resources/departure_white.png"
import departure_sea from "../../../../resources/departure_sea.png"
import departure_sea_nt from "../../../../resources/departure_sea_white.png"
import destination_air from "../../../../resources/arrival.png"
import destination_air_nt from "../../../../resources/arrival_white.png"
import destination_sea from "../../../../resources/arrival_sea.png"
import destination_sea_nt from "../../../../resources/arrival_sea_white.png"
import customs from "../../../../resources/customs.png"
import customs_nt from "../../../../resources/customs_white.png"
import delivered from "../../../../resources/delivered.png"
import delivered_nt from "../../../../resources/delivered_white.png"
import upload from "../../../../resources/upload.png"
import GoogleMapWrapper from "shipTracker/GoogleMapWrapper";
import { DOCUMENTS, TABS, TABS_M } from "constants/global-constants";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { getStatus } from "../commonUtility";
import { REQUEST_ACTIONS, sendRequest, getCustomerData } from "utils/Communicator";
import { snackbarToggle } from "reducers/snackbarSlicer";
import Chat from "../Chat"

import { useDropzone } from 'react-dropzone';

import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    Polyline,
    InfoWindow,
} from '@react-google-maps/api'

const ilChatNameStyle = { color: 'blue' };
const nlChatNameStyle = { color: 'orange' };
const adChatNameStyle = { color: 'black' };

const ShipmentDetailDialog = ({ setOrderList, orderList, loginUser, open, close, order, defaultTab = TABS[1], polAir, podAir, polSea, podSea, sMode, cOrigin }) => {

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles[0].size < 2097152) {
            //onDrop1(acceptedFiles);
            setFiles(acceptedFiles);
        }
        else {
            dispatch(snackbarToggle({ type: 'error', message: 'File size should be less than 2MB' }));
        }
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop
    });
    const [selectedDocument, setSelectedDocument] = useState(2);
    const [activeTab, setActiveTab] = useState(TABS[1]);
    const [chatList, setChatList] = useState([]);
    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const [incotermsData, setIncotermsData] = useState();
    const [ilUsersLoaded, setIlUsersLoaded] = useState(false);
    const [nlUsersLoaded, setNlUsersLoaded] = useState(false);
    const [usUsersLoaded, setUSUsersLoaded] = useState(false);

    const [mapPath, setMapPath] = useState([]);
    const [maplat1, setMaplat1] = useState(31);
    const [maplat2, setMaplat2] = useState(33);
    const [maplng1, setMaplng1] = useState(33);
    const [maplng2, setMaplng2] = useState(35);
    const [mapZoom, setMapZoom] = useState(2);
    const [chatText, setChatText] = useState("");
    const [mapX, setMapX] = useState({ lat: 32, lng: 34 });
    const [isExpanded, setIsExpanded] = useState(false);
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState(null);
    const [fileCodes, setFileCodes] = useState(false);
    const [fileCodesCount, setFileCodesCount] = useState(false);
    const [fileCodesCountIA, setFileCodesCountIA] = useState(false);
    const [fileCodesCountGen, setFileCodesCountGen] = useState(false);
    const [fileCodesCountCI, setFileCodesCountCI] = useState(false);
    const [fileCodesCountIPList, setFileCodesCountIPList] = useState(false);
    const [mapRef, setMapRef] = useState(null);
    const loadHandler = map => {
        setMapRef(map);
        fitBounds(map);
    }
    const fitBounds = (map, mPath) => {
        if (!map)
            return;
        var path = [];
        if (order.sinay?.status?.val?.routeData?.routeSegments?.length) {
            order.sinay?.status?.val?.routeData?.routeSegments.forEach(item => {
                item?.path.forEach(lt => {
                    path.push(lt);
                });
            });
            setMapPath(path);
        }
        const bounds = new window.google.maps.LatLngBounds();
        for (var i = 0; i < path?.length; i++) {
            bounds.extend(path[i]);
        }
        map.fitBounds(bounds);
    };

    const [libraries] = useState(['places']);
    const [mapLineColors] = useState(["#0000FF", "#32CD32", "#FFFF00", "#FF00FF", "#00FFFF", "#000000", "#A52A2A", "#FFA500", "#800080", "#00FF00"]);
    const handleClose = () => {
        document.body.classList.remove('dialog-open');
        close(!open);
    }
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => {
        dispatch(snackbarToggle({ type: 'error', message }))
    }, [dispatch]);
    const getDate1 = () => {
        var date = new Date()
        var previous = new Date(date.getTime())
        previous.setDate(date.getDate() - 1);
        return previous.getTime();
    }
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDgpzrxExQg_AUW8h199onppaSz72SYlSY",
        libraries,
    });

    useEffect(() => {
        // predefined user abroad data get
        if (!nlUsers || nlUsers == undefined) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setNlUsers(response); setNlUsersLoaded(true) },
                failedCallback,
            });
        }
    }, [nlUsers, failedCallback, setNlUsers]);

    useEffect(() => {
        if (!incotermsData) {
            sendRequest('/api/incoterms', REQUEST_ACTIONS.GET, '', {
                successCallback: response => setIncotermsData(response),
                failedCallback,
            });
        }
    }, [incotermsData, failedCallback, setIncotermsData]);

    useEffect(() => {
        // predefined user abroad data get
        if (!usUsers || usUsers == undefined) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setUSUsers(response); setUSUsersLoaded(true) },
                failedCallback,
            });
        }
    }, [usUsers, failedCallback, setUSUsers]);

    useEffect(() => {
        // predefined user abroad data get
        if (files)
            onDrop1(files);
    }, [files]);

    useEffect(() => {
        // predefined user local data get
        if (!ilUsers || ilUsers == undefined) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setIlUsers(response); setIlUsersLoaded(true) },
                failedCallback,
            });
        }
    }, [ilUsers, failedCallback, setIlUsers]);
    const deleteFile = (f, files) => {
        var index = files.indexOf(f);
        sendRequest(`/api/files/${f.id}`, REQUEST_ACTIONS.DELETE, '', {
            successCallback: (message) => {
                for (var p = 0; p < files.length; p++) {
                    if (files[p].id === f.id) {
                        files.splice(p, 1);
                    }
                }
            },
            failedCallback,
        });
    }

    useEffect(() => {
        if (defaultTab) {
            setActiveTab(defaultTab)
        }
    }, [defaultTab])
    useEffect(() => {
        if (order) {
            if (order.chatCustomer)
                setChatList(JSON.parse(order.chatCustomer));
            else
                setChatList([]);
            var path = [];
            var x1 = -1000;
            var x2 = -1000;
            var y1 = -1000;
            var y2 = -1000;
            if (order.sinay?.status?.val?.routeData?.routeSegments?.length) {
                order.sinay?.status?.val?.routeData?.routeSegments.forEach(item => {
                    item?.path.forEach(lt => {
                        path.push(lt);
                        if (lt.lat < x1 || x1 == -1000)
                            x1 = lt.lat;
                        if (lt.lat > x2 || x2 == -1000)
                            x2 = lt.lat;
                        if (lt.lng < y1 || y1 == -1000)
                            y1 = lt.lng;
                        if (lt.lng > y2 || y2 == -1000)
                            y2 = lt.lng;
                    });
                });
                setMaplat1(x1 - 1);
                setMaplat2(x2);
                setMaplng1(y1 - 1);
                setMaplng2(y2);
                setMapX(order.sinay?.status?.val?.routeData?.coordinates);
                setMapZoom(3);
            }
            else {
                setMaplat1(31);
                setMaplat2(33);
                setMaplng1(34);
                setMaplng2(36);
                setMapX(order.sinay?.status?.val?.routeData?.coordinates);
                setMapZoom(5);
            }
            setMapPath(path);
            fitBounds(mapRef, path);
            var itemCI = { code: "Commercial Invoice", count: 0, files: [] };
            var itemGen = { code: "GENERAL", count: 0, files: [] };
            var itemIP = { code: "INVOICE /PACKING LIST", count: 0, files: [] };
            var itemIA = { code: "IMPORT APPROVALS", count: 0, files: [] };
            setFileCodesCountIA(itemIA);
            setFileCodesCountCI(itemCI);
            setFileCodesCountGen(itemGen);
            setFileCodesCountIPList(itemIP);

            sendRequest('/api/codes', REQUEST_ACTIONS.GET, '', {
                successCallback: res => {
                    setFileCodes(res);
                    order.codeFileCount.forEach(item => {
                        if (item.code === "IMPORT APPROVALS") {
                            sendRequest('/api/' + order?.id + '/files/' + res.find(r => r.name === "IMPORT APPROVALS").id, REQUEST_ACTIONS.GET, '', {
                                successCallback: response => {
                                    item.files = response;
                                    setFileCodesCountIA(item);
                                },
                                failedCallback,
                            });
                        }
                        if (item.code === "Commercial Invoice") {
                            sendRequest('/api/' + order?.id + '/files/' + res.find(r => r.name === "Commercial Invoice").id, REQUEST_ACTIONS.GET, '', {
                                successCallback: response => {
                                    item.files = response;
                                    setFileCodesCountCI(item);
                                },
                                failedCallback,
                            });
                        }
                        if (item.code === "GENERAL") {
                            sendRequest('/api/' + order?.id + '/files/' + res.find(r => r.name === "GENERAL").id, REQUEST_ACTIONS.GET, '', {
                                successCallback: response => {
                                    item.files = response;
                                    setFileCodesCountGen(item);
                                },
                                failedCallback,
                            });
                        }
                        if (item.code === "INVOICE /PACKING LIST") {
                            sendRequest('/api/' + order?.id + '/files/' + res.find(r => r.name === "INVOICE /PACKING LIST").id, REQUEST_ACTIONS.GET, '', {
                                successCallback: response => {
                                    item.files = response;
                                    setFileCodesCountIPList(item);
                                },
                                failedCallback,
                            });
                        }
                    });
                },
                failedCallback,
            });
        }
    }, [order])
    const handleChange = (panel) => {
        if (isExpanded != panel)
            setIsExpanded(panel);
        else
            setIsExpanded(null);
    };

    const getDate = (d) => {
        var date = new Date(d);
        var month = date.getMonth(date) + 1;
        var day = date.getDate(date);
        var year = date.getFullYear(date);

        return `${day}/${month}/${year}`;
    }
    const getChatDate = (d) => {
        var date = new Date(d);
        var month = date.getMonth(date) + 1;
        var day = date.getDate(date);
        var year = date.getFullYear(date);

        var hr = date.getHours(date);
        var mn = date.getMinutes(date);

        return `${day}/${month}/${year} ${hr}:${mn}`;
    }
    const formatDate = (date) => {
        if (!date)
            return "-";
        var str = [];
        if (date.includes("T")) {
            str = date.split("T")[1].split(":");
            var time = str[0] + ":" + str[1];

            const dt = new Date(date);
            const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
            return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)} ${time}`;
        } else {
            return date;
        }
    }
    const getTaskyamStatus = (id) => {
        switch (id) {
            case 0:
                return "";
            case 1:
                return "מצהר";
            case 2:
                return "זמינות";
            case 3:
                return "פקודת מסירה";
            case 4:
                return "בדיקת מכס";
            case 5:
                return "התרת מכס";
            case 6:
                return "התרת נמל";
            case 7:
                return "תעודת ליווי";
            case 8:
                return "בקשה להוצאה";
            case 9:
                return "משוב מהנמל";
            case 10:
                return "יציאת מטען";
        }
        return "-"
    }

    const lineSymbol = {
        path: 'M 0,-1 0,1',
        scale: 2,
        strokeColor: 'blue',
        strokeOpacity: 1,
    }

    const getFileCodes = (codeId) => {
        if (fileCodes.length < codeId) {
            const code = fileCodes.find(code => code.id === codeId);
            return code?.name?.replace(/ /g, "_")?.toUpperCase();
        } else {
            return fileCodes[codeId - 1].name?.replace(/ /g, "_")?.toUpperCase();
        }
    }

    const onDrop1 = (acceptedFiles) => {
        if (acceptedFiles[0].size < 2097152) {
            var object = null;
            if (isExpanded === DOCUMENTS[1]) {
                object = fileCodesCountIPList;
            } else if (isExpanded === DOCUMENTS[2]) {
                object = fileCodesCountCI;
            } else if (isExpanded === DOCUMENTS[3]) {
                object = fileCodesCountGen;
            } else if (isExpanded === DOCUMENTS[4]) {
                object = fileCodesCountIA;
            }
            setFile(acceptedFiles[0]);
            sendRequest('/api/codes', REQUEST_ACTIONS.GET, '', {
                successCallback: res => {
                    setFileCodes(res);
                    var codeId = res.find(code => code.name === object.code).id;
                    const file_ext = acceptedFiles[0].name.split(".");
                    const time_now = new Date().toLocaleString("en-EN", { timeZone: 'Israel' }).split(",");
                    const cur_date = time_now[0].split("/");
                    const show_name = "" + getFileCodes(codeId).replace("/", "") + "_" + cur_date[0] + "_" + cur_date[1] + "_" + cur_date[2] + "_" + new Date(time_now).getTime() + "." + file_ext[1];

                    const codeFileName = {
                        codeId: codeId,
                        fileName: show_name,
                    };
                    const formData = new FormData();
                    formData.append('code', JSON.stringify([codeFileName]));

                    formData.append('files', acceptedFiles[0], show_name);
                    formData.append('orderStageId', order.orderStage[0].id);
                    formData.append('orderId', order.id);
                    formData.append('loginUserId', loginUser.id);

                    sendRequest('/api/upload', REQUEST_ACTIONS.POST, formData, {
                        successCallback: message => {
                            var orders = [];
                            if (orderList) {
                                var len = orderList.length;
                                var orders = [];
                                for (var i = 0; i < len; i++) {
                                    var order1 = JSON.parse(JSON.stringify(orderList[i]));
                                    if (order1.id == message.id) {
                                        order1.codeFileCount = message.codeFileCount;
                                    }
                                    orders.push(order1);
                                }
                                setOrderList(orders);
                            }
                            message.codeFileCount.forEach(item => {
                                if (item.code === "IMPORT APPROVALS") {
                                    sendRequest('/api/' + message?.id + '/files/' + res.find(r => r.name === "IMPORT APPROVALS").id, REQUEST_ACTIONS.GET, '', {
                                        successCallback: response => {
                                            item.files = response;
                                            setFileCodesCountIA(item);
                                        },
                                        failedCallback,
                                    });
                                }
                                if (item.code === "Commercial Invoice") {
                                    sendRequest('/api/' + message?.id + '/files/' + res.find(r => r.name === "Commercial Invoice").id, REQUEST_ACTIONS.GET, '', {
                                        successCallback: response => {
                                            item.files = response;
                                            setFileCodesCountCI(item);
                                        },
                                        failedCallback,
                                    });
                                }
                                if (item.code === "GENERAL") {
                                    sendRequest('/api/' + message?.id + '/files/' + res.find(r => r.name === "GENERAL").id, REQUEST_ACTIONS.GET, '', {
                                        successCallback: response => {
                                            item.files = response;
                                            setFileCodesCountGen(item);
                                        },
                                        failedCallback,
                                    });
                                }
                                if (item.code === "INVOICE /PACKING LIST") {
                                    sendRequest('/api/' + message?.id + '/files/' + res.find(r => r.name === "INVOICE /PACKING LIST").id, REQUEST_ACTIONS.GET, '', {
                                        successCallback: response => {
                                            item.files = response;
                                            setFileCodesCountIPList(item);
                                        },
                                        failedCallback,
                                    });
                                }
                            });
                        },
                        failedCallback,
                    });
                },
                failedCallback,
            });
        }
        else {
            dispatch(snackbarToggle({ type: 'error', message: 'File size should be less than 2MB' }));
        }
    };

    function handleSubmit(event, object) {
        var codeId = fileCodes.find(code => code.name === object.code).id;
        const file_ext = event.target.files[0].name.split(".");
        const time_now = new Date().toLocaleString("en-EN", { timeZone: 'Israel' }).split(",");
        const cur_date = time_now[0].split("/");
        const show_name = "" + getFileCodes(codeId).replace("/", "") + "_" + cur_date[0] + "_" + cur_date[1] + "_" + cur_date[2] + "_" + new Date(time_now).getTime() + "." + file_ext[1];

        const codeFileName = {
            codeId: codeId,
            fileName: show_name,
        };
        const formData = new FormData();
        formData.append('code', JSON.stringify([codeFileName]));

        formData.append('files', event.target.files[0], show_name);
        formData.append('orderStageId', order.orderStage[0].id);
        formData.append('orderId', order.id);
        formData.append('loginUserId', loginUser.id);

        event.preventDefault()
        sendRequest('/api/upload', REQUEST_ACTIONS.POST, formData, {
            successCallback: message => {
                var orders = [];
                if (orderList) {
                    var len = orderList.length;
                    var orders = [];
                    for (var i = 0; i < len; i++) {
                        var order1 = JSON.parse(JSON.stringify(orderList[i]));
                        if (order1.id == message.id) {
                            order1.codeFileCount = message.codeFileCount;
                        }
                        orders.push(order1);
                    }
                    setOrderList(orders);
                }
                sendRequest('/api/codes', REQUEST_ACTIONS.GET, '', {
                    successCallback: res => {
                        setFileCodes(res);
                        message.codeFileCount.forEach(item => {
                            if (item.code === "IMPORT APPROVALS") {
                                sendRequest('/api/' + message?.id + '/files/' + res.find(r => r.name === "IMPORT APPROVALS").id, REQUEST_ACTIONS.GET, '', {
                                    successCallback: response => {
                                        item.files = response;
                                        setFileCodesCountIA(item);
                                    },
                                    failedCallback,
                                });
                            }
                            if (item.code === "Commercial Invoice") {
                                sendRequest('/api/' + message?.id + '/files/' + res.find(r => r.name === "Commercial Invoice").id, REQUEST_ACTIONS.GET, '', {
                                    successCallback: response => {
                                        item.files = response;
                                        setFileCodesCountCI(item);
                                    },
                                    failedCallback,
                                });
                            }
                            if (item.code === "GENERAL") {
                                sendRequest('/api/' + message?.id + '/files/' + res.find(r => r.name === "GENERAL").id, REQUEST_ACTIONS.GET, '', {
                                    successCallback: response => {
                                        item.files = response;
                                        setFileCodesCountGen(item);
                                    },
                                    failedCallback,
                                });
                            }
                            if (item.code === "INVOICE /PACKING LIST") {
                                sendRequest('/api/' + message?.id + '/files/' + res.find(r => r.name === "INVOICE /PACKING LIST").id, REQUEST_ACTIONS.GET, '', {
                                    successCallback: response => {
                                        item.files = response;
                                        setFileCodesCountIPList(item);
                                    },
                                    failedCallback,
                                });
                            }
                        });
                    },
                    failedCallback,
                });
            },
            failedCallback,
        });
    }

    const getInitiatorStyle = (ch) => {
        var type = ch['initiatorType']
        if (type == 5)
            return nlChatNameStyle;
        else
            return ilChatNameStyle;
    }
    const onHandlechangeChat = (value) => {
        setChatText(value);
    }
    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/order/updateCustChat/${id}?fromStage=${fromStage}&loginUserId=${loginUser?.id}&actionOn=ChatDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                setChatList(JSON.parse(data.chatCustomer));
                var orders = [];
                if (orderList) {
                    var len = orderList.length;
                    var orders = [];
                    for (var i = 0; i < len; i++) {
                        var order = JSON.parse(JSON.stringify(orderList[i]));
                        if (order.id == data.id) {
                            order.chatCustomer = data.chatCustomer;
                        }
                        orders.push(order);
                    }
                    setOrderList(orders);
                }
                setChatText("");
            },
            failedCallback,
        });
    };

    const onHandleSendChat = (statusChatButton) => {
        var userIL = order.userILId;
        var userNL = order.orderStage[0].orderConfirmationStage[0]?.userNLId;
        var lengthIL = ilUsers.length;
        var lengthNL = nlUsers.length;
        var lengthUS = usUsers.length;
        var exists = false;
        var date = new Date();
        var chat = {
            'time': date.toUTCString(),
            'text': chatText,
            'initiator': loginUser.fullName,
            'initiatorType': 5,
            'location': 'All',
            'ILStatus': false,
            'NLStatus': true
        }
        let o1 = { ...order }

        var chat_list = [];
        if (order.chat) {
            chat_list = JSON.parse(order.chat);
        }
        if (chat_list?.length) {
            for (var j = 0; j < chat_list.length; j++) {
                if (!chat_list[j]['NLStatus']) {
                    chat_list[j]['NLStatus'] = true;
                }
            }
        }
        if (!order.chat) {
            chat_list[0] = chat;
        }
        else {
            chat_list.push(chat);
        }

        var chatString = JSON.stringify(chat);

        onUpdateChat(order.id, chatString, 0);
        return;
    }

    const getCountryName = (order) => {
        var cname = cOrigin.find(ctr => ctr.id == order.countryOfOriginId);
        return cname ? cname.name : "";
    }
    const getPOD = (order) => {
        var custDate = order.airStatus?.podDate ? order.airStatus?.podDate : (order.orderStage[4]?.departureStage[0]?.etd ? order.orderStage[4]?.departureStage[0].etd : null)
        var dt = order?.sinay?.arDate1 ? order.sinay.arDate1 : custDate;
        return formatDateTime(dt);
    }

    const chatRead = (order) => {
        if (order.chatCustomer == null)
            return true;
        var chat = JSON.parse(order.chatCustomer);
        if (!chat)
            return true;
        for (var i = 0; i < chat.length; i++) {
            if (!chat[i].NLStatus)
                return false;
        }
        return true;
    }

    const shipTransDetails = (order) => {
        return <Box className='ship-trans'>
            <Grid className="font-segoe" style={{ color: "#959494", "-webkit-text-stroke": "thin", fontSize: "14px", padding: "1vw", minWidth: "15vw" }}>
                {order.shipper?.companyShortName && <Box className='pickup mr-5' style={{ fontSize: "0.8vw", color: "#707070", "-webkit-text-stroke": "thin" }}>{order.shipper?.companyShortName ? order.shipper.companyShortName : "-"}</Box>}
                {(order.countryOfOriginId && order.poaId) && <Grid item container alignItems="center" justifyContent="center" className='pickup mr-5' style={{ fontSize: "1vw" }}><span>{getCountryName(order)}</span> <span><img src={arrowLeft} style={{ width: "1.5vw", margin: "0 0.5vw" }} /></span> <span>{order.shippingModeId == 1 ? podAir.find(air => air.id == order.poaId)?.name : podSea.find(sea => sea.id == order.poaId)?.name}</span></Grid>}
                {/*{<Box className='pickup mr-5'>{getStatus(order)}</Box>}*/}
                {/*{<Box className='pickup mr-5'>{getLastEvent(order)}</Box>}*/}
                {/*{<Grid item container className='shipment-action' alignItems="center" justifyContent="center" flexWrap="nowrap" flexDirection="row-reverse">
                    <Grid item container onClick={() => orderDetailHandle(order, TABS[5], shipTransDetails, statusProgressBar)} style={{ cursor: "pointer" }}>
                        <img src={chatRead(order) ? chatButton : chatButton1} style={{ width: "4.032vw", height: "2vw" }} />
                    </Grid>
                    <Grid item container onClick={() => orderDetailHandle(order, undefined, shipTransDetails, statusProgressBar)} style={{ cursor: "pointer" }}>
                        <img src={detailButton} style={{ width: "4.92vw", height: "2vw" }} />
                    </Grid>
                    <Grid item container onClick={() => orderDetailHandle(order, TABS[4], shipTransDetails, statusProgressBar)} style={{ cursor: "pointer" }}>
                        <img src={invoiceButton} style={{ width: "4.92vw", height: "2vw" }} />
                    </Grid>
                </Grid>}*/}
            </Grid>
        </Box>;
    };

    const getShipperStatus_color = (order) => {
        if (order.orderStatus[1].status === "SUCCESS")
            return "#2fadee";
        else {
            if (order.orderStatus[2].status === "SUCCESS")
                return "#2fadee";
            return "#3c5293";
        }
    }
    const getShipperStatus_image = (order) => {
            return shipper_nt;
    }
    const getWHStatus_color = (order) => {
        if (order.orderStatus[3].status === "SUCCESS")
            return "#2fadee";
        else {
            if (order.orderStatus[1].status === "SUCCESS")
                return "#3c5293";
            return "";
        }
    }
    const getWHStatus_image = (order) => {
        if (order.orderStatus[3].status === "SUCCESS")
            return wh_nt;
        else {
            if (order.orderStatus[1].status === "SUCCESS")
                return wh_nt;
            return wh;
        }
    }
    const getPOLStatus = (order) => {
        if (order.orderStatus[2].status === "SUCCESS" || getLastStatusNum(order) >= 2) {
            if (order.orderStatus[5].status) {
                if (order.orderStatus[5].status === "SUCCESS")
                    return "done";
                else (order.orderStatus[5].status === "IN_PROGRESS")
                return "inprogress";
            }
            else
                return "inprogress";
        }
        else
            return ""
    }
    const getPOLStatus_color = (order) => {
        if (order.orderStatus[2].status === "SUCCESS" || getLastStatusNum(order) >= 3) {
            if (order.orderStatus[5].status) {
                if (order.orderStatus[5].status === "SUCCESS")
                    return "#2fadee";
                else (order.orderStatus[5].status === "IN_PROGRESS")
                return "#3c5293";
            }
            else
                return "#3c5293";
        }
        else
            return ""
    }
    const getPOLStatus_image = (order) => {
        if (order.orderStatus[2].status === "SUCCESS" || getLastStatusNum(order) >= 3) {
            if (order.orderStatus[5].status) {
                if (order.orderStatus[5].status === "SUCCESS")
                    return order.shippingModeId == 1 ? departure_air_nt : departure_sea_nt;
                else (order.orderStatus[5].status === "IN_PROGRESS")
                return order.shippingModeId == 1 ? departure_air_nt : departure_sea_nt;
            }
            else
                return order.shippingModeId == 1 ? departure_air : departure_sea;
        }
        else
            return order.shippingModeId == 1 ? departure_air : departure_sea
    }
    const getPODStatus = (order) => {
        if (order.orderStatus[4].status === "SUCCESS") {
            if (order.orderStatus[5].status === "SUCCESS") {
                if (order.orderStatus[6]?.status) {
                    if (order.orderStatus[6]?.status === "SUCCESS")
                        return "done";
                    else if (order.orderStatus[6]?.status === "IN_PROGRESS")
                        return "inprogress";
                    else return "";
                }
                else
                    return "inprogress";
            }
            else
                return "";
        }
        else
            return "";
    }
    const getPODStatus_color = (order) => {
        if (order.orderStatus[5].status === "SUCCESS") {
            if (order.orderStatus[6]?.status) {
                if (order.orderStatus[6]?.status === "SUCCESS")
                    return "#2fadee";
                else return "#3c5293";
            }
            else
                return "#3c5293";
        }
        else
            return "";
    }
    const getPODStatus_image = (order) => {
        if (order.orderStatus[5].status === "SUCCESS") {
            if (order.orderStatus[6]?.status) {
                if (order.orderStatus[6]?.status === "SUCCESS" || order.orderStatus[6]?.status === "IN_PROGRESS")
                    return order.shippingModeId == 1 ? destination_air_nt : destination_sea_nt;
                else return order.shippingModeId == 1 ? destination_air : destination_sea;
            }
            else
                return order.shippingModeId == 1 ? destination_air : destination_sea;
        }
        else
            return order.shippingModeId == 1 ? destination_air : destination_sea;
    }
    const getCustomsStatus = (order) => {
        if (order.orderStatus[4].status === "SUCCESS" && order.orderStatus[5].status === "SUCCESS" && order.orderStatus[6].status === "SUCCESS") {
            if (order.orderStatus[7]?.status === "SUCCESS") {
                return "done";
            } else if (order.orderStatus[7]?.status === "IN_PROGRESS") {
                return "inprogress";
            } else
                return "";
        }
        else
            return ""
    }
    const getCustomsStatus_color = (order) => {
        if (order.orderStatus[6].status === "SUCCESS") {
            if (order.orderStatus[7]?.status === "SUCCESS") {
                return "#2fadee";
            } else
                return "#3c5293";
        }
        else
            return ""
    }
    const getCustomsStatus_image = (order) => {
        if (order.orderStatus[6].status === "SUCCESS") {
            if (order.orderStatus[7]?.status === "SUCCESS" || order.orderStatus[7]?.status === "IN_PROGRESS") {
                return customs_nt;
            } else
                return customs;
        }
        else
            return customs
    }
    const getImportStatus = (order) => {
        if (order.orderStatus[7].status === "SUCCESS") {
            if (order.orderStatus[8]?.status === "SUCCESS") {
                return "done";
            } else if (order.orderStatus[8]?.status === "IN_PROGRESS") {
                return "inprogress";
            } else
                return "";
        }
        else
            return ""
    }
    const getImportStatus_color = (order) => {
        if (order.orderStatus[7].status === "SUCCESS") {
            if (order.orderStatus[8]?.status === "SUCCESS") {
                return "#2fadee";
            } else if (order.orderStatus[8]?.status === "IN_PROGRESS") {
                return "#3c5293";
            } else
                return "#3c5293";
        }
        else
            return ""
    }
    const getImportStatus_image = (order) => {
        if (order.orderStatus[7].status === "SUCCESS") {
            if (order.orderStatus[8]?.status === "SUCCESS") {
                return delivered_nt;
            } else if (order.orderStatus[8]?.status === "IN_PROGRESS") {
                return delivered_nt;
            } else
                return delivered;
        }
        else
            return delivered
    }
    const statusProgressBar = (order) => {
        return <Grid className='progress-bar' style={{}}>

            {/* Shipper Status */}
            <Box className={`progress-step ${(order?.orderStatus[1].status === 'SUCCESS' || getLastStatusNum(order) > 1) ? 'done' : 'inprogress'}`}>

                <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="space-between">
                    <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getShipperStatus_color(order)}`, width: "2.36vw", height: "2.36vw" }}>
                        <img src={getShipperStatus_image(order)} style={{ width: "1.3vw", height: "1.3vw" }} />
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" style={{ background: "#3c5293", width: "calc(100% - 3vw)", height: "1px" }}>
                    </Grid>
                </Grid>
                <Box className='progress-text tk-good-times'>
                    {'Shipper'}
                </Box>
                {order?.orderStatus[1]?.status && <Box className='prog-dec'>
                    {((order?.orderStatus[1].status === 'FAILED' || order?.orderStatus[1].status === 'NOT_STARTED') ? '' : (order?.orderStatus[1].status === 'SUCCESS' ? formatDateTime(order?.orderStatus[1].updateTime) : ""))}{/*order.orderStatus[1].status.replace(/_/g, " ")*/}
                </Box>}
            </Box>

            {/* Port of Departure Status */}
            <Box className={`progress-step ${getPOLStatus(order)}`}>

                <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="space-between">
                    <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getPOLStatus_color(order)}`, width: "2.36vw", height: "2.36vw" }}>
                        <img src={getPOLStatus_image(order)} style={{ width: "1.3vw", height: "1.3vw" }} />
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" style={{ background: "#3c5293", width: "calc(100% - 3vw)", height: "1px" }}>
                    </Grid>
                </Grid>
                <Box className='progress-text tk-good-times'>
                    {'Departure'}
                </Box>
                {(order?.orderStatus[4]?.status === 'SUCCESS') && <Box className='prog-dec'>
                    {order?.orderStatus[5].status === 'SUCCESS' ? formatDateTime(order?.orderStatus[5].updateTime) : (order?.orderStatus[5].status === 'IN_PROGRESS' ? (order?.orderStatus[5].creationTime ? "ETA: " + formatDateTime(order?.orderStatus[5].creationTime) : '') : "")}
                </Box>}
            </Box>

            {/* Port of Destination Status */}
            <Box className={`progress-step ${getPODStatus(order)}`}>

                <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="space-between">
                    <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getPODStatus_color(order)}`, width: "2.36vw", height: "2.36vw" }}>
                        <img src={getPODStatus_image(order)} style={{ width: "1.3vw", height: "1.3vw" }} />
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" style={{ background: "#3c5293", width: "calc(100% - 3vw)", height: "1px" }}>
                    </Grid>
                </Grid>
                <Box className='progress-text tk-good-times'>
                    {'Destination'}
                </Box>
                {(order?.orderStatus[4]?.status === 'SUCCESS') && <Box className='prog-dec'>
                    {/*{order.shippingModeId == 1 ? podAir.find(air => air.id == order.poaId)?.name : podSea.find(sea => sea.id == order.poaId)?.name} {order.orderStatus[6].status === 'SUCCESS' ? formatDateTime(order.orderStatus[6].updateTime) : (order?.orderStatus[6].status === 'IN_PROGRESS' ? (order.orderStatus[6].creationTime ? formatDateTime(order.orderStatus[6].creationTime) : '') : "")}*/}
                    ETA: {getPOD(order)}
                </Box>}
            </Box>

            {/* Custom Status */}
            <Box className={`progress-step ${getCustomsStatus(order)}`}>

                <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="space-between">
                    <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getCustomsStatus_color(order)}`, width: "2.36vw", height: "2.36vw" }}>
                        <img src={getCustomsStatus_image(order)} style={{ width: "1.3vw", height: "1.3vw" }} />
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" style={{ background: "#3c5293", width: "calc(100% - 3vw)", height: "1px" }}>
                    </Grid>
                </Grid>
                <Box className='progress-text tk-good-times'>
                    {'Customs'}
                </Box>
                {<Box className='prog-dec'>
                    <p onClick={() => { openCustomsDialog(order) }} style={{ textDecoration: "underline", cursor: "pointer", margin: "0" }}>{order?.orderStatus[7].status === 'SUCCESS' || order?.orderStatus[7].status === 'IN_PROGRESS' ? getCustomStatus(order) : ""}</p>
                </Box>}
            </Box>

            {/* Importer Status */}
            <Box className={`progress-step ${getImportStatus(order)}`}>

                <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="space-between">
                    <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getImportStatus_color(order)}`, width: "2.36vw", height: "2.36vw" }}>
                        <img src={getImportStatus_image(order)} style={{ width: "1.3vw", height: "1.3vw" }} />
                    </Grid>
                </Grid>
                <Box className='progress-text tk-good-times'>
                    {'Delivered'}
                </Box>
                {<Box className='prog-dec'>
                    {order.orderStatus[8].status === 'SUCCESS' ? formatDateTime(order?.orderStatus[8].updateTime) : (order?.orderStatus[8].status === 'IN_PROGRESS' ? (order?.orderStatus[8].creationTime ? "ETA: " + formatDateTime(order?.orderStatus[8].creationTime) : '') : "")}
                </Box>}
            </Box>
        </Grid>;
    };

    const getLastStatusNum = (order) => {
        if (order.orderStatus[8]?.status === "IN_PROGRESS" || order.orderStatus[8]?.status === "FAILED")
            return 6;
        if (order.orderStatus[7]?.status === "IN_PROGRESS" || order.orderStatus[7]?.status === "FAILED")
            return 5;
        if (order.orderStatus[6]?.status === "IN_PROGRESS" || order.orderStatus[6]?.status === "FAILED")
            return 4;
        if (order.orderStatus[5]?.status === "IN_PROGRESS" || order.orderStatus[5]?.status === "FAILED")
            return 3;
        if (order.orderStage[4]?.status === "IN_PROGRESS" || order.orderStage[4]?.status === "FAILED")
            return 2;
        if (order.orderStage[3]?.status === "IN_PROGRESS" || order.orderStage[3]?.status === "FAILED")
            return 2;
        if (order.orderStage[2]?.status === "IN_PROGRESS" || order.orderStage[2]?.status === "FAILED")
            return 1;
        if (order.orderStage[1]?.status === "IN_PROGRESS" || order.orderStage[1]?.status === "FAILED")
            return 1;
        if (order.orderStage[0]?.status === "IN_PROGRESS" || order.orderStage[0]?.status === "FAILED")
            return 1;
    }
    const getLastEvent1 = (order) => {
        if (order.orderStatus[8]?.status === "IN_PROGRESS" || order.orderStatus[8]?.status === "FAILED")
            return "Delivery to Consignee";
        if (order.orderStatus[7]?.status === "IN_PROGRESS" || order.orderStatus[7]?.status === "FAILED")
            return getCustomStatus(order);
        if (order.orderStatus[6]?.status === "IN_PROGRESS" || order.orderStatus[6]?.status === "FAILED")
            return getPodStatus(order, true);
        if (order.orderStatus[5]?.status === "IN_PROGRESS" || order.orderStatus[5]?.status === "FAILED")
            return getPodStatus(order, false);
        if (order.orderStage[4]?.status === "IN_PROGRESS" || order.orderStage[4]?.status === "FAILED")
            return "Departure Waiting";
        if (order.orderStage[3]?.status === "IN_PROGRESS" || order.orderStage[3]?.status === "FAILED")
            return "Warehouse";
        if (order.orderStage[2]?.status === "IN_PROGRESS" || order.orderStage[2]?.status === "FAILED")
            return "Trucking";
        if (order.orderStage[1]?.status === "IN_PROGRESS" || order.orderStage[1]?.status === "FAILED")
            return "Shipper";
        if (order.orderStage[0]?.status === "IN_PROGRESS" || order.orderStage[0]?.status === "FAILED")
            return "Need Confirmation";
    }
    const getPodStatus = (order, pod) => {
        var res = 0;
        if (order.shippingModeId == 1) {
            res = order.airStatus?.lStatus ? (pod ? "POD - " : "POL - ") + order.airStatus?.lStatus : "";
        } else {
            res = getSinayStatus(order.sinay?.status?.val?.metadata?.shippingStatus ? order.sinay?.status?.val?.metadata?.shippingStatus : "", order.sinay?.status ? order.sinay?.status : "", pod)
        }
        return res ? res : "";
    }
    const getCustomStatus = (order) => {
        if (order.shippingModeId == 1) {
            return order.airStatus?.lStatus ? "Customs - " + order.airStatus?.lStatus : "";
        } else {
            return getTaskyamStatus(order.taskyam?.data?.status)
        }
    }
    const getSinayStatus = (str, status, pod) => {
        var msg = status?.val?.message ? status?.val?.message : "";
        if (msg) {
            return (pod ? "POD - " : "POL - ") + msg.toLowerCase().replaceAll("_", " ");
        }
        return str ? (pod ? "POD - " : "POL - ") + str.toLowerCase().replaceAll("_", " ") : "";
    }
    const formatDateTime = (date) => {
        if (!date) {
            if (!isMobile())
                return "-";
            else
                return "----------";
        }
        var dt = new Date(date);
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)}`;
    }
    return <Fragment>
        <Box className={`order-details ${open && 'open'}`} onClick={() => handleClose}>
            {order && <Fragment>
                <Box className="detail-wrapper">
                    <Grid item container style={{ background: "#70707017" }}>
                        <>{(order && !isMobile()) && <TableRow key={order.id} className={`shipment-row`} style={{ padding: "0", color: "#707070", marginTop: "30px", marginLeft: "5vw" }}>
                            <TableCell valign='left' style={{ width: "10vw", height: "7.4vw", paddingRight: "0" }}>

                                {order.jobNumber && <Grid className="font-heebo" item container justifyContent="flex-start" maxWidth={'100%'} flexDirection="row" flexWrap="nowrap" style={{ fontSize: "1.2vw", color: "#2fadee", "-webkit-text-stroke": "thin", minWidth: "7vw" }}>
                                    <b style={{ textAlign: "left", width: "100%", overflow: "hidden", textWrap: "nowrap" }} title={order.jobNumber}>{order.jobNumber.substring(0, 7)}</b>
                                </Grid>}
                                {order.suppliersPO && <Grid className="font-heebo" item container justifyContent="flex-start" maxWidth={'7.36vw'} flexDirection="row" flexWrap="nowrap" style={{ fontSize: "0.7vw", color: "#959494", "-webkit-text-stroke": "thin" }}>
                                    PO: <span onClick={() => orderDetailHandle(order, TABS[2])} style={{ overflow: "hidden", textWrap: "nowrap", textDecoration: "underline", cursor: "pointer" }} title={order.suppliersPO}>{order.suppliersPO}</span>
                                </Grid>}
                                {/*{<Grid className="font-heebo" item container justifyContent="flex-start" maxWidth={'140px'} flexDirection="row" flexWrap="nowrap" style={{ fontSize: "0.8vw", fontWeight: "bold", color: "#707070" }}>
                                    {getLastEvent1(order)}
                                </Grid>}*/}
                                {order.orderStage[4]?.departureStage[0]?.eta && <Grid className="font-heebo" item container justifyContent="flex-start" flexDirection="row" flexWrap="nowrap" style={{ fontSize: "0.9vw", fontWeight: "bold", color: "#707070" }}>
                                    ETA: {getPOD(order)}
                                </Grid>}
                            </TableCell>

                            <TableCell style={{ height: "7.4vw" }}>
                                {shipTransDetails(order)}
                            </TableCell>

                            <TableCell>
                                {statusProgressBar(order)}
                            </TableCell>

                        </TableRow>}</>
                    </Grid>
                    <Button className="close" onClick={handleClose}>
                        x
                    </Button>
                    {(isLoaded && order.sinay?.status?.val?.routeData?.coordinates) && <Box className="map-section">
                        {order.sinay?.status?.val?.routeData?.coordinates && <GoogleMap
                            center={mapX}
                            zoom={mapZoom}
                            mapContainerStyle={{ width: '100%', height: '100%' }}
                            options={{
                                zoomControl: true,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                            }}
                            onLoad={map => {
                                loadHandler(map);
                            }}
                        >
                            <Marker position={order.sinay?.status?.val?.routeData?.coordinates} label={``} />
                            {/*<Marker position={destCenter} icon={emptySymbol} label={destCity} />*/}
                            {/*{googleMapData?.length != 0 ? <Marker position={center} icon={getPlaneSymbol()}><InfoWindow position={center} options={{ width: '250px', pixelOffset: new google.maps.Size(0, -20), }}><div>{flightText}<br />{destText}</div></InfoWindow></Marker> : null}*/}

                            <Polyline path={mapPath} options={{ geodesic: true, strokeColor: '#FFFFFF', strokeOpacity: 0, strokeWeight: 1, icons: [{ icon: lineSymbol, offset: '0', repeat: '10px', },], }} />
                        </GoogleMap>}
                    </Box>}
                    <Grid item container style={{}} className="font-heebo order-detail-section1">
                        <Box className="order-detail-section">
                            {/*<Grid className='order-img'>
                            <img src={order.shippingModeId == 3 ? fclIcon : order.shippingModeId == 2 ? lclIcon : airIcon} alt="" />
                            <Box>
                                <Grid className='order-no'>
                                    {order.jobnum}
                                </Grid>
                            </Box>
                        </Grid>*/}
                            {!isMobile() && <Grid item container justifyContent="space-around" className='tab-wrapper' flexWrap="nowrap" style={{}}>
                                {Object.values(TABS).map((item, index) => <Button key={index} className={`tabButtons ${item === activeTab && 'active'}`} onClick={() => setActiveTab(item)} style={{ width: `${index == 2 ? "30%" : (index == 3 ? "20%" : "25%")}`, "justify-content": "flex-start", color: `${item === activeTab ? "#00aeef" : "#707070"}` }}>
                                    {item === TABS[1] && <img src={TABS[1] === activeTab ? detailsIconSelected : detailsIcon} />}
                                    {item === TABS[2] && <img src={TABS[2] === activeTab ? DocIconSelected : DocIcon} />}
                                    {item === TABS[3] && <img src={TABS[3] === activeTab ? DocIconSelected : DocIcon} />}
                                    {item === TABS[4] && <img src={TABS[4] === activeTab ? ChatIconSelected : ChatIcon} />} {item}
                                </Button>)}
                            </Grid>}
                            {isMobile() && <Grid item container justifyContent="space-around" className='tab-wrapper' flexWrap="nowrap" style={{}}>
                                {Object.values(TABS_M).map((item, index) => <Button key={index} className={`tabButtons ${item === activeTab && 'active'}`} onClick={() => setActiveTab(item)} style={{ width: `${index == 4 ? "10%" : "22.5%"}`, "justify-content": "flex-start", color: `${item === activeTab ? "#00aeef" : "#707070"}` }}>
                                    {item === TABS_M[1] && <img src={TABS_M[1] === activeTab ? detailsIconSelected : detailsIcon} />}
                                    {item === TABS_M[2] && <img src={TABS_M[2] === activeTab ? DocIconSelected : DocIcon} />}
                                    {item === TABS_M[3] && <img src={TABS_M[3] === activeTab ? DocIconSelected : DocIcon} />}
                                    {item === TABS_M[4] && <img src={TABS_M[4] === activeTab ? ChatIconSelected : ChatIcon} style={{marginLeft:"1vw"}} />}
                                    {item === TABS_M[0] && <img src={TABS_M[0] === activeTab ? detailsIconSelected : detailsIcon} />} {item}
                                </Button>)}
                            </Grid>}
                        </Box>
                        <Box className={`order-tab-content${order.sinay?.status?.val?.routeData?.coordinates ? "-map" : ""}`}>
                            {TABS[2] === activeTab &&
                                <Box className="documents font-heebo">
                                    <Grid item container flexWrap="nowrap" className="detailsFileUploadTabs">
                                        <Grid item container onClick={() => { setSelectedDocument(2) }} style={{ color: `${selectedDocument == 2 ? "#00AEEF" : "#707070"}`, cursor: "pointer" }}>
                                            {DOCUMENTS[2]}
                                        </Grid>
                                        <Grid item container onClick={() => { setSelectedDocument(4) }} style={{ color: `${selectedDocument == 4 ? "#00AEEF" : "#707070"}`, cursor: "pointer" }}>
                                            {DOCUMENTS[4]}
                                        </Grid>
                                        <Grid item container onClick={() => { setSelectedDocument(3) }} style={{ color: `${selectedDocument == 3 ? "#00AEEF" : "#707070"}`, cursor: "pointer" }}>
                                            {DOCUMENTS[3]}
                                        </Grid>
                                    </Grid>
                                    {selectedDocument == 2 && <Grid item container sm={12}><Box className="drag-drop-zone" {...getRootProps()}>
                                        <img src={upload} />
                                        <label htmlFor="doc-2">
                                            <span>UPLOAD</span>
                                            <input {...getInputProps()} id="doc-2" type="file" onChange={event => handleSubmit(event, fileCodesCountCI)} />
                                        </label>
                                    </Box>
                                        <Grid item container style={{ margin: "5px", marginTop: "20px" }}>
                                            {fileCodesCountGen?.files && <>{fileCodesCountCI.files.map(item => <Grid item container>
                                                <Grid item container lg={11}>
                                                    <a className="fileDownloadLink" href={item.uri} target="_blank">{item.name}</a>
                                                </Grid>
                                                {/*<Grid item container lg={1} >*/}{/*deleteFile(item, fileCodesCountIPList.files)*/}{/*
                                                    X
                                                </Grid>*/}
                                            </Grid>)}</>}
                                        </Grid>
                                    </Grid>}
                                    {selectedDocument == 4 && <Grid item container sm={12}><Box className="drag-drop-zone" {...getRootProps()}>
                                        <img src={upload} />
                                        <label htmlFor="doc-4">
                                            <span>UPLOAD</span>
                                            <input {...getInputProps()} id="doc-4" type="file" onChange={event => handleSubmit(event, fileCodesCountIA)} />
                                        </label>
                                    </Box>
                                        <Grid item container style={{ margin: "5px", marginTop: "20px" }}>
                                            {fileCodesCountGen?.files && <>{fileCodesCountIA.files.map(item => <Grid item container>
                                                <Grid item container lg={11}>
                                                    <a className="fileDownloadLink" href={item.uri} target="_blank">{item.name}</a>
                                                </Grid>
                                                {/*<Grid item container lg={1} >*/}{/*deleteFile(item, fileCodesCountIPList.files)*/}{/*
                                                    X
                                                </Grid>*/}
                                            </Grid>)}</>}
                                        </Grid>
                                    </Grid>}
                                    {selectedDocument == 3 && <Grid item container sm={12}><Box className="drag-drop-zone" {...getRootProps()}>
                                        <img src={upload} />
                                        <label htmlFor="doc-3">
                                            <span>UPLOAD</span>
                                            <input {...getInputProps()} id="doc-3" type="file" onChange={event => handleSubmit(event, fileCodesCountGen)} />
                                        </label>
                                    </Box>
                                        <Grid item container style={{ margin: "5px", marginTop: "20px" }}>
                                            {fileCodesCountGen?.files && <>{fileCodesCountGen.files.map(item => <Grid item container>
                                                <Grid item container lg={11}>
                                                    <a className="fileDownloadLink" href={item.uri} target="_blank">{item.name}</a>
                                                </Grid>
                                                {/*<Grid item container lg={1} >*/}{/*deleteFile(item, fileCodesCountIPList.files)*/}{/*
                                                    X
                                                </Grid>*/}
                                            </Grid>)}</>}
                                        </Grid>
                                    </Grid>}
                                </Box>}
                            {(TABS[3] === activeTab) &&
                                <Box className="documents">
                                    {<Grid item container sm={12}><Box className="drag-drop-zone" {...getRootProps()}>
                                        <img src={upload} />
                                        <label htmlFor="doc-1">
                                            <span>UPLOAD</span>
                                            <input {...getInputProps()} id="doc-1" type="file" onChange={event => handleSubmit(event, fileCodesCountIPList)} />
                                        </label>
                                    </Box>
                                        <Grid item container style={{ margin: "5px", marginTop: "20px", color: "#707070" }}>
                                            {fileCodesCountIPList?.files && <>{fileCodesCountIPList.files.map(item => <Grid item container>
                                                <Grid item container lg={11}>
                                                    <a className="fileDownloadLink" href={item.uri} target="_blank">{item.name}</a>
                                                </Grid>
                                                {/*<Grid item container lg={1} >*/}{/*deleteFile(item, fileCodesCountIPList.files)*/}{/*
                                                    X
                                                </Grid>*/}
                                            </Grid>)}</>}
                                        </Grid>
                                    </Grid>}
                                </Box>}
                            {TABS[1] === activeTab &&
                                <Grid item container style={{ padding: "10px", textAlign: "center" }} justifyContent="center">
                                    {<Grid item container style={{ margin: "10px" }}>
                                        <Grid item container flexWrap="nowrap" style={{ margin: "5px" }}>
                                            <Grid item container sm={4}>
                                                Incoterms:
                                            </Grid>
                                            <Grid item container sm={8} style={{ textAlign: "left" }}>
                                                {incotermsData.find(inc => inc.id == order.incotermsId)?.name}
                                            </Grid>
                                        </Grid>
                                        <Grid item container flexWrap="nowrap" style={{ margin: "5px" }}>
                                            <Grid item container sm={4}>
                                                Shipper:
                                            </Grid>
                                            <Grid item container sm={8} style={{ textAlign: "left" }}>
                                                {order.shipper?.companyName}
                                            </Grid>
                                        </Grid>
                                        <Grid item container flexWrap="nowrap" style={{ margin: "5px" }}>
                                            <Grid item container sm={4}>
                                                Consignee:
                                            </Grid>
                                            <Grid item container sm={8} style={{ textAlign: "left" }}>
                                                {order.consignee?.companyName}
                                            </Grid>
                                        </Grid>
                                        <Grid item container flexWrap="nowrap" style={{ margin: "5px" }}>
                                            <Grid item container sm={4}>
                                                Origin:
                                            </Grid>
                                            <Grid item container sm={8} style={{ textAlign: "left" }}>
                                                {cOrigin.find(ctr => ctr.id == order.countryOfOriginId)?.name}
                                            </Grid>
                                        </Grid>
                                        <Grid item container flexWrap="nowrap" style={{ margin: "5px" }}>
                                            <Grid item container sm={4}>
                                                Sub Mode:
                                            </Grid>
                                            <Grid item container sm={8} style={{ textAlign: "left" }}>
                                                {sMode.find(mode => mode.id == order.subModeId)?.name}
                                            </Grid>
                                        </Grid>
                                        <Grid item container flexWrap="nowrap" style={{ margin: "5px" }}>
                                            <Grid item container sm={4}>
                                                POL:
                                            </Grid>
                                            <Grid item container sm={8} style={{ textAlign: "left" }}>
                                                {order.shippingModeId == 1 ? (polAir.find(air => air.id == order.polId)?.name) : (polSea.find(sea => sea.id == order.polId)?.name)}
                                            </Grid>
                                        </Grid>
                                        <Grid item container flexWrap="nowrap" style={{ margin: "5px" }}>
                                            <Grid item container sm={4}>
                                                POD:
                                            </Grid>
                                            <Grid item container sm={8} style={{ textAlign: "left" }}>
                                                {order.shippingModeId == 1 ? (podAir.find(air => air.id == order.poaId)?.name) : (podSea.find(sea => sea.id == order.poaId)?.name)}
                                            </Grid>
                                        </Grid>
                                        {order.suppliersPO && <Grid item container flexWrap="nowrap" style={{ margin: "5px" }}>
                                            <Grid item container sm={4}>
                                                PO Number
                                            </Grid>
                                            <Grid item container sm={8}>
                                                {order.suppliersPO}
                                            </Grid>
                                        </Grid>}
                                        {order.orderStatus[4].orderStatusStage?.containerNumber && <Grid item container flexWrap="nowrap" style={{ margin: "5px" }}>
                                            <Grid item container sm={4}>
                                                Container Number:
                                            </Grid>
                                            <Grid item container sm={8} style={{ textAlign: "left" }}>

                                                <Grid item container> {order.orderStatus[4].orderStatusStage?.containerNumber}</Grid>
                                            </Grid>
                                        </Grid>}
                                    </Grid>}
                                    {(order.orderDetails?.length != 0 && !isMobile()) && <Grid item container>
                                        <Grid item container flexWrap="nowrap" style={{ margin: "15px" }}>
                                            Shipment Details :
                                        </Grid>
                                        <Grid item container lg={12} flexWrap="nowrap" style={{ borderBottom: "1px solid grey", borderTop: "1px solid grey" }}>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>No. Pieces</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>Height</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>Length</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>Width</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>Weight per Piece</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>Total Weight</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>Total Volume</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>Manual Weight</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>Manual Volume</Grid>
                                        </Grid>
                                        {Object.values(order.orderDetails).map((detail, index) => <Grid key={index} item container flexWrap="nowrap" style={{ borderBottom: "1px solid grey" }}>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>{detail.numberOfPieces ? detail.numberOfPieces : "-"}</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>{detail.height ? detail.height : "-"}</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>{detail.length ? detail.length : "-"}</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>{detail.width ? detail.width : "-"}</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>{detail.weightPerPiece ? detail.weightPerPiece : "-"}</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>{detail.totalweight ? detail.totalweight : "-"}</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>{detail.totalvolume ? detail.totalvolume : "-"}</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>{detail.manualWeight ? detail.manualWeight : "-"}</Grid>
                                            <Grid item justifyContent="center" container style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey", paddingTop: "5px", paddingBottom: "5px" }}>{detail.manualVolume ? detail.manualVolume : "-"}</Grid>
                                        </Grid>)}
                                    </Grid>}
                                    {(order.orderFCLDetails && !isMobile()) && <Grid sm={12} item containerstyle={{}}>
                                        <Grid item container flexWrap="nowrap" style={{ margin: "15px" }}>
                                            Shipment Details :
                                        </Grid>
                                        {order.orderFCLDetails.quantity20 && <Grid item container flexWrap="nowrap" style={{ margin: "10px" }}>
                                            <Grid item container flexWrap="nowrap" style={{ margin: "5px" }}>
                                                <Grid item container sm={4} >
                                                    FCL 20:
                                                </Grid>
                                                <Grid item container sm={8}>
                                                    {order.orderFCLDetails.fclType20?.name ? order.orderFCLDetails.fclType20?.name : ""} x {order.orderFCLDetails.quantity20 ? order.orderFCLDetails.quantity20 : ""}
                                                </Grid>
                                            </Grid>
                                        </Grid>}
                                        {order.orderFCLDetails.quantity40 && <Grid item container flexWrap="nowrap" style={{ margin: "10px" }}>
                                            <Grid item container flexWrap="nowrap" style={{ margin: "5px" }}>
                                                <Grid item container sm={4} >
                                                    FCL 40:
                                                </Grid>
                                                <Grid item container sm={8}>
                                                    {order.orderFCLDetails.fclType40?.name ? order.orderFCLDetails.fclType40?.name : ""} x {order.orderFCLDetails.quantity40 ? order.orderFCLDetails.quantity40 : ""}
                                                </Grid>
                                            </Grid>
                                        </Grid>}
                                    </Grid>}
                                </Grid>}
                            {TABS[4] === activeTab &&
                                <Chat order={order} orderList={orderList} setOrderList={setOrderList} loginUser={loginUser} />}
                            {(TABS_M[0] === activeTab) &&
                                <Box className="documents">
                                    <Grid alignItems="center" item container flexDirection="column" flexWrap="nowrap" className='progress-bar' style={{marginTop:"4vh"}}>

                                        {/* Shipper Status */}
                                        <Grid alignItems="center" justifyContent="center" item container flexDirection="column" flexWrap="nowrap" style={{ width: "30vw" }} className={`progress-step ${(order?.orderStatus[1].status === 'SUCCESS' || getLastStatusNum(order) > 1) ? 'done' : 'inprogress'}`}>

                                            <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="center" >
                                                <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getShipperStatus_color(order)}`, width: "9vw", height: "9vw" }}>
                                                    <img src={getShipperStatus_image(order)} style={{ width: "5vw", height: "5vw" }} />
                                                </Grid>
                                            </Grid>
                                            <Grid alignItems="center" justifyContent="center" item container className='progress-text tk-good-times' style={{ fontSize: "2.5vw", width: "30vw" }}>
                                                {'Shipper'}
                                            </Grid>
                                            {(order?.orderStatus[1]?.status) && <Grid alignItems="center" justifyContent="center" item container className='prog-dec' style={{ fontSize: "2.5vw", width: "30vw" }}>
                                                {((order?.orderStatus[1].status === 'FAILED' || order?.orderStatus[1].status === 'NOT_STARTED') ? '' : (order?.orderStatus[1].status === 'SUCCESS' ? formatDateTime(order?.orderStatus[1].updateTime) : ""))}
                                            </Grid>}
                                            <Grid item container justifyContent="center" alignItems="center" style={{ background: "#3c5293", width: "1px", height: "4vh", margin:"5px" }}>
                                            </Grid>
                                        </Grid>

                                        {/* Port of Departure Status */}
                                        <Grid alignItems="center" item container flexDirection="column" flexWrap="nowrap" style={{ width: "30vw" }} className={`progress-step ${getPOLStatus(order)}`}>

                                            <Grid alignItems="center" justifyContent="center" item container style={{ width: "100%" }}>
                                                <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getPOLStatus_color(order)}`, width: "9vw", height: "9vw" }}>
                                                    <img src={getPOLStatus_image(order)} style={{ width: "5vw", height: "5vw" }} />
                                                </Grid>
                                            </Grid>
                                            <Grid alignItems="center" justifyContent="center" item container className='progress-text tk-good-times' style={{ fontSize: "2.5vw", width: "30vw" }}>
                                                {'Departure'}
                                            </Grid>
                                            {(order?.orderStatus[4]?.status === 'SUCCESS') && <Grid alignItems="center" justifyContent="center" item container className='prog-dec' style={{ fontSize: "2.5vw", width: "30vw" }}>
                                                {order?.orderStatus[5].status === 'SUCCESS' ? formatDateTime(order?.orderStatus[5].updateTime) : (order?.orderStatus[5].status === 'IN_PROGRESS' ? (order?.orderStatus[5].creationTime ? "ETA: " + formatDateTime(order?.orderStatus[5].creationTime) : '') : "")}
                                            </Grid>}
                                            <Grid item container justifyContent="center" alignItems="center" style={{ background: "#3c5293", width: "1px", height: "4vh", margin: "5px" }}>
                                            </Grid>
                                        </Grid>

                                        {/* Port of Destination Status */}
                                        <Grid alignItems="center" justifyContent="center" item container flexDirection="column" flexWrap="nowrap" style={{ width: "30vw" }} className={`progress-step ${getPODStatus(order)}`}>

                                            <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="center" >
                                                <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getPODStatus_color(order)}`, width: "9vw", height: "9vw" }}>
                                                    <img src={getPODStatus_image(order)} style={{ width: "5vw", height: "5vw" }} />
                                                </Grid>
                                            </Grid>
                                            <Grid alignItems="center" justifyContent="center" item container className='progress-text tk-good-times' style={{ fontSize: "2.5vw", width: "30vw" }}>
                                                {'Destination'}
                                            </Grid>
                                            {(order?.orderStatus[4]?.status === 'SUCCESS') && <Grid alignItems="center" justifyContent="center" item container className='prog-dec' style={{ fontSize: "2.5vw", width: "30vw" }}>
                                                {/*{order.shippingModeId == 1 ? podAir.find(air => air.id == order.poaId)?.name : podSea.find(sea => sea.id == order.poaId)?.name} {order.orderStatus[6].status === 'SUCCESS' ? formatDateTime(order.orderStatus[6].updateTime) : (order?.orderStatus[6].status === 'IN_PROGRESS' ? (order.orderStatus[6].creationTime ? formatDateTime(order.orderStatus[6].creationTime) : '') : "")}*/}
                                                ETA: {getPOD(order)}
                                            </Grid>}
                                            <Grid item container justifyContent="center" alignItems="center" style={{ background: "#3c5293", width: "1px", height: "4vh", margin: "5px" }}>
                                            </Grid>
                                        </Grid>

                                        {/* Custom Status */}
                                        <Grid alignItems="center" justifyContent="center" item container flexDirection="column" flexWrap="nowrap" style={{ width: "30vw" }} className={`progress-step ${getCustomsStatus(order)}`}>

                                            <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="center" >
                                                <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getCustomsStatus_color(order)}`, width: "9vw", height: "9vw" }}>
                                                    <img src={getCustomsStatus_image(order)} style={{ width: "5vw", height: "5vw" }} />
                                                </Grid>
                                            </Grid>
                                            <Grid alignItems="center" justifyContent="center" item container className='progress-text tk-good-times' style={{ fontSize: "2.5vw", width: "30vw" }}>
                                                {'Customs'}
                                            </Grid>
                                            {<Grid alignItems="center" justifyContent="center" item container className='prog-dec' style={{ fontSize: "2.5vw", width: "30vw" }}>
                                                <p onClick={() => { openCustomsDialog(order) }} style={{ textDecoration: "underline", cursor: "pointer", margin: "0" }}>{order?.orderStatus[7].status === 'SUCCESS' || order?.orderStatus[7].status === 'IN_PROGRESS' ? getCustomStatus(order) : ""}</p>
                                            </Grid>}
                                            <Grid item container justifyContent="center" alignItems="center" style={{ background: "#3c5293", width: "1px", height: "4vh", margin: "5px" }}>
                                            </Grid>
                                        </Grid>

                                        {/* Importer Status */}
                                        <Grid alignItems="center" justifyContent="center" item container flexDirection="column" flexWrap="nowrap" style={{ width: "30vw" }} className={`progress-step ${getImportStatus(order)}`}>

                                            <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="center" >
                                                <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getImportStatus_color(order)}`, width: "9vw", height: "9vw" }}>
                                                    <img src={getImportStatus_image(order)} style={{ width: "5vw", height: "5vw" }} />
                                                </Grid>
                                            </Grid>
                                            <Grid alignItems="center" justifyContent="center" item container className='progress-text tk-good-times' style={{ fontSize: "2.5vw", width: "30vw" }}>
                                                {'Delivered'}
                                            </Grid>
                                            {<Grid alignItems="center" justifyContent="center" item container className='prog-dec' style={{ fontSize: "2.5vw", width: "30vw" }}>
                                                {order.orderStatus[8].status === 'SUCCESS' ? formatDateTime(order?.orderStatus[8].updateTime) : (order?.orderStatus[8].status === 'IN_PROGRESS' ? (order?.orderStatus[8].creationTime ? "ETA: " + formatDateTime(order?.orderStatus[8].creationTime) : '') : "")}
                                            </Grid>}
                                        </Grid>
                                    </Grid>
                                </Box>}
                        </Box>
                    </Grid>
                </Box>
            </Fragment>}
        </Box>
    </Fragment>;
}

export default ShipmentDetailDialog;



import { createSlice } from '@reduxjs/toolkit';

export const predefinedDataSlice = createSlice({
  name: 'predefinedData',
  initialState: {
    incotermsData: null,
    subModeData: [],
    shippingModeData: [],
    countryOfOriginData: [],
    userILData: [],
    userNLData: [],
    userUSData: [],
    userILNames: [],
    userNLNames: [],
    userUSNames: [],
    serviceProviderType: [],
    airPolData: null,
    airPoaData: null,
    lclPolData: null,
    lclPoaData: null,
    fileCodes: null,
    fclType20Data: null,
    fclType40Data: null,
    disableBlink: null,
  },
  reducers: {
    onIncotermsDataChange: (state, { payload }) => {
      state.incotermsData = payload;
    },
    onDisableBlinkChange: (state, { payload }) => {
      state.disableBlink = payload;
    },
    onFunctionChange: (state, { payload }) => {
      state.serviceProviderType = payload;
    },
    onSubModeDataChange: (state, { payload }) => {
      state.subModeData = payload;
    },
    onShippingModeDataChange: (state, { payload }) => {
      state.shippingModeData = payload;
    },
    onCountryOfOriginDataChange: (state, { payload }) => {
      state.countryOfOriginData = payload;
    },
    onUserILDataChange: (state, { payload }) => {
      state.userILData = payload;
    },
    onUserNLDataChange: (state, { payload }) => {
      state.userNLData = payload;
    },
    onUserUSDataChange: (state, { payload }) => {
      state.userUSData = payload;
    },
    onUserNLNamesChange: (state, { payload }) => {
      state.userNLNames = payload;
    },
    onUserUSNamesChange: (state, { payload }) => {
      state.userUSNames = payload;
    },
    onUserILNamesChange: (state, { payload }) => {
      state.userILNames = payload;
    },
    onAirPolDataChange: (state, { payload }) => {
      state.airPolData = payload;
    },
    onAirPoaDataChange: (state, { payload }) => {
      state.airPoaData = payload;
    },
    onLclPolDataChange: (state, { payload }) => {
      state.lclPolData = payload;
    },
    onLclPoaDataChange: (state, { payload }) => {
      state.lclPoaData = payload;
    },
    onFileCodesChange: (state, { payload }) => {
      state.fileCodes = payload;
    },
    onFCLType20DataChange: (state, { payload }) => {
      state.fclType20Data = payload;
    },
    onFCLType40DataChange: (state, { payload }) => {
      state.fclType40Data = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onIncotermsDataChange,
  onSubModeDataChange,
  onShippingModeDataChange,
  onCountryOfOriginDataChange,
  onUserILDataChange,
  onUserNLDataChange,
  onUserUSDataChange,
  onAirPolDataChange,
  onAirPoaDataChange,
  onLclPolDataChange,
  onLclPoaDataChange,
  onFileCodesChange,
  onFCLType20DataChange,
  onFCLType40DataChange,
  onUserILNamesChange,
  onUserNLNamesChange,
  onUserUSNamesChange,
  onFunctionChange,
  onDisableBlinkChange,
} = predefinedDataSlice.actions;

export default predefinedDataSlice.reducer;

import { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { TextField } from 'style-guide';

import { useDispatch } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';

const AirDetails = ({ onStateChange, state }) => {
  const { parcelDetails, numberOfPieces, weightPerPiece, width, height, length, totalweight, totalvolume } = state;

  const dispatch = useDispatch();
  const onHandleChange = (event, field) => onStateChange(field, event.target.value);

  const onHandleAdd = () => {
    const isAddAllDetails = (numberOfPieces && weightPerPiece && width && height && length) || (totalweight && totalvolume);

    if (!isAddAllDetails) {
      return dispatch(snackbarToggle({ type: 'error', message: 'Please add all details' }));
    }

    onStateChange('parcelDetails', [
      ...parcelDetails,
      {
        id: uuidv4(),
        numberOfPieces,
        weightPerPiece,
        width,
        height,
        length,
        totalweight, 
        totalvolume,
      },
    ]);
    onStateChange('numberOfPieces', null);
    onStateChange('weightPerPiece', null);
    onStateChange('width', null);
    onStateChange('height', null);
    onStateChange('length', null);
    onStateChange('totalweight', null);
    onStateChange('totalvolume', null);
  };
  const resetBasicDetails = () => {
    onStateChange('numberOfPieces', null);
    onStateChange('weightPerPiece', null);
    onStateChange('width', null);
    onStateChange('height', null);
    onStateChange('length', null);
  }

  const resetTotalDetails = () => {
    onStateChange('totalweight', null);
    onStateChange('totalvolume', null);
  }
  
  const onHandleDelete = id => {
    onStateChange(
      'parcelDetails',
      parcelDetails.filter(detail => detail.id !== id),
    );
  };

  return (
    <Fragment>
      <Grid container item spacing={2}>
        <Grid container item alignItems='center'>
          <IconButton onClick={onHandleAdd} color='primary' size='small'>
            <AddIcon />
          </IconButton>
          <Typography variant='h6'>Shipment Details</Typography>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item xs={0.5} />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            type='number'
            label={<Typography fontSize={14}>Pieces</Typography>}
            value={numberOfPieces}
            onChange={event => {resetTotalDetails(); onHandleChange(event, 'numberOfPieces')}}
          />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            type='number'
            label={<Typography fontSize={14}>L (cm)</Typography>}
            value={length}
            onChange={event => {resetTotalDetails(); onHandleChange(event, 'length')}}
          />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            type='number'
            label={<Typography fontSize={14}>W (cm)</Typography>}
            value={width}
            onChange={event => {resetTotalDetails(); onHandleChange(event, 'width')}}
          />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            type='number'
            label={<Typography fontSize={14}>H (cm)</Typography>}
            value={height}
            onChange={event => {resetTotalDetails(); onHandleChange(event, 'height')}}
          />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            type='number'
            label={<Typography fontSize={14}>Weight/Piece</Typography>}
            value={weightPerPiece}
            onChange={event => {resetTotalDetails(); onHandleChange(event, 'weightPerPiece')}}
          />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            label={<Typography fontSize={13}>Total Weight (kg)</Typography>}
            value={totalweight || numberOfPieces * weightPerPiece}
            onChange={event => {resetBasicDetails(); onHandleChange(event, 'totalweight')}}
          />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            label={
              <Typography fontSize={13}>
                Total Volume (m<sup>3</sup>)
              </Typography>
            }
            onChange={event => {resetBasicDetails(); onHandleChange(event, 'totalvolume')}}
            value={totalvolume || ((numberOfPieces * (length * width * height)) / 1000000)}
          />
        </Grid>
        {parcelDetails.map(detail => {
          return (
            <Grid container item spacing={1} alignItems='center' key={detail.id || uuidv4()}>
              <Grid item xs={0.5}>
                <IconButton onClick={() => onHandleDelete(detail.id)} color='primary' size='small'>
                  <RemoveIcon />
                </IconButton>
              </Grid>
              <TextField
                sm={1.5}
                md={null}
                lg={null}
                label={<Typography fontSize={14}>Pieces</Typography>}
                value={detail.numberOfPieces}
              />
              <TextField
                sm={1.5}
                md={null}
                lg={null}
                label={<Typography fontSize={14}>L (cm)</Typography>}
                value={detail.length}
              />
              <TextField
                sm={1.5}
                md={null}
                lg={null}
                label={<Typography fontSize={14}>W (cm)</Typography>}
                value={detail.width}
              />
              <TextField
                sm={1.5}
                md={null}
                lg={null}
                label={<Typography fontSize={14}>H (cm)</Typography>}
                value={detail.height}
              />
              <TextField
                sm={1.5}
                md={null}
                lg={null}
                label={<Typography fontSize={14}>Weight/Piece</Typography>}
                value={detail.weightPerPiece}
              />
              <TextField
                readOnly
                sm={1.5}
                md={null}
                lg={null}
                label={<Typography fontSize={13}>Total Weight (kg)</Typography>}
                value={detail.totalweight || detail.numberOfPieces * detail.weightPerPiece}
              />
              <TextField
                readOnly
                sm={1.5}
                md={null}
                lg={null}
                label={
                  <Typography fontSize={13}>
                    Total Volume (m<sup>3</sup>)
                  </Typography>
                }
                value={detail.totalvolume || ((detail.numberOfPieces * (detail.length * detail.width * detail.height)) / 1000000)}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid container item spacing={1}>
        <Grid item xs={7.5} />
        <TextField 
          readOnly
          sm={1.5}
          md={null}
          lg={null}
          label={<Typography fontSize={13}>Sum-Total Weight</Typography>}
          value={parcelDetails.reduce((acc, detail) => {
            return parseFloat(acc) + parseFloat(detail.totalweight || detail.numberOfPieces * detail.weightPerPiece);
          }, (totalweight || numberOfPieces * weightPerPiece))}
        />
        <TextField 
          readOnly
          sm={1.5}
          md={null}
          lg={null}
          label={<Typography fontSize={14}>Sum-Total W/M</Typography>}
          value={parcelDetails.reduce((acc, detail) => {
            return parseFloat(acc) + parseFloat(detail.totalvolume || ((detail.numberOfPieces * (detail.length * detail.width * detail.height)) / 1000000));
          }, totalvolume || ((numberOfPieces * (length * width * height)) / 1000000))}
        />
        <TextField 
          readOnly
          sm={1.5}
          md={null}
          lg={null}
          label={<Typography fontSize={14}>Chargeable Weight</Typography>}
          value={Math.max(
            parcelDetails.reduce((acc, detail) => {
            return parseFloat(acc) + parseFloat(detail.totalweight || detail.numberOfPieces * detail.weightPerPiece);
          }, (totalweight || numberOfPieces * weightPerPiece)),
            parcelDetails.reduce((acc, detail) => {
            return parseFloat(acc) + (parseFloat(detail.totalvolume*1000000 || (detail.numberOfPieces * detail.length * detail.width * detail.height))/5000);
          }, (totalvolume*1000000 || (numberOfPieces * length * width * height))/5000)
          )?.toFixed(2)}
        />
      </Grid>
    </Fragment>
  );
};

export default AirDetails;

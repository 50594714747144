import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import * as React from "react";
import { Grid, Button, IconButton, Typography, TablePagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { MultilineField, SelectField, TextField, Dropzone, FilesRender, DateTimePicker, Autocomplete, Dialog, Tooltip } from 'style-guide';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { createCustomEqual } from "fast-equals";
import BackIcon from '@mui/icons-material/SettingsBackupRestore';
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import EditIcon from '@mui/icons-material/Edit';
import * as XLSX from 'xlsx';

import { snackbarToggle } from 'reducers/snackbarSlicer';

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Polyline,
} from '@react-google-maps/api'

const MarineTracker = props => {
    const dispatch = useDispatch();
    const {
        subModeData,
        shippingModeData,
        countryOfOriginData,
        userILNames,
        incotermsData,
        airPolData,
        airPoaData,
        lclPolData,
        lclPoaData,
    } = useSelector(state => state.predefinedData);
    const { consigneeNameOptions } = useSelector(state => state.consignees);


    const [lclPolData1, setLclPolData1] = useState("")
    const [lclPoaData1, setLclPoaData1] = useState("")
    const [consigneeNameOptions1, setConsigneeNameOptions1] = useState("")

    const [customer, setCustomer] = useState("");
    const [customerid, setCustomerid] = useState("");
    const [pol, setPol] = useState("");
    const [polid, setPolid] = useState("");
    const [pod, setPod] = useState("");
    const [podid, setPodid] = useState("");
    const [st, setSt] = useState("");
    const [stInd, setStInd] = useState(0);
    const [depDate, setDepDate] = useState("");
    const [arDate, setArDate] = useState("");
    const [scac, setScac] = useState("");
    const [jobNumber, setJobNumber] = useState("");
    const [container, setContainer] = useState("");
    const [transactionID, setTransactionID] = useState("");
    const [shipName, setShipName] = useState("");
    const [shipLine, setShipLine] = useState("");
    const [ilUser, setIlUser] = useState("");
    const [selectedTrack, setSelectedTrack] = useState(null);
    const [selectedTrackTaskyam, setSelectedTrackTaskyam] = useState(false);
    const [showSelectedTrack, setShowSelectedTrack] = useState(false);
    const [expandTrack, setExpandTrack] = useState(false);
    const [tracks, setTracks1] = useState([]);
    const [orgTracks, setOrgTracks] = useState([]);
    const [dialogErrorText, setDialogErrorText] = useState("");
    const types = [{ id: 1, name: "LCL" }, { id: 2, name: "FCL" }];
    const getType = (id) => {
        for (var i = 0; i < types.length; i++) {
            if (types[i].id == id) {
                return types[i].name;
            }
        }
        return "";
    }
    const formatDate = (date) => {
        if (!date)
            return "-";
        var str = [];
        if (date.includes("T")) {
            str = date.split("T")[1].split(":");
            var time = str[0] + ":" + str[1];

            const dt = new Date(date);
            const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
            return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)} ${time}`;
        } else {
            return date;
        }
    }
    const formatDate1 = (date) => {
        if (!date)
            return "-";
        const dt = new Date(date);
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)}`;
    }
    function contains_heb(str) {
        var tes = (/[\u0590-\u05FF]/).test(str);
        return tes;
    }
    function setTracks(ds) {
        var dt = []
        if (!ds) {
            setTracks1(dt);
            return dt;
        }
        for (var j = 0; j < ds.length; j++) {
            var d = { ...ds[j] };
            if (d.statustask?.data?.CargoList) {
                d.imports = d.statustask.data.CargoList;
                d.imports.status = d.statustask.data.status;
                d.imports.statusUpdateTime = d.statustask.data.statusUpdateTime;

                /*if (d.statustask.data.CargoList.ExportProcess && d.shipmentType == "FCL" & d.imports.status == 10) {
                    if (d.statustask.data.CargoList.ExportProcess?.PortStorageFeedbackDate &&
                        d.statustask.data.CargoList.ImportProcess?.CargoExitDate) {
                        var date = new Date(d.statustask.data.CargoList.ExportProcess.PortStorageFeedbackDate)
                        var date1 = new Date(d.statustask.data.CargoList.ImportProcess.CargoExitDate);
                        if (date.getTime() > date1.getTime()) {
                            d.imports.containerReturned = true;
                            d.imports.containerReturnedDate = d.statustask.data.CargoList.ExportProcess.PortStorageFeedbackDate;
                        }
                    }
                }*/
                if (d.statustask.data.lclMap) {
                    d.lclMap = d.statustask.data.lclMap;
                }
            }
            if (d.status) {
                var status = d.status;
                var keys = [...Object.keys(status)];
                var data = [];
                for (var i = 0; i < keys.length; i++) {
                    try {
                        var v = JSON.parse(status[keys[i] + ""].toString());
                        if (v)
                            data.push({ key: keys[i], val: v });
                    } catch (e) { continue; }
                }
                data.sort(function (a, b) {
                    return b.key - a.key;
                });
                d.status = data;
                var podExists = false;
                var postpodExists = false;
                if (d.status[0].val?.route?.pod?.location?.countryCode === "IL")
                    podExists = true;
                if (d.status[0].val?.route?.postpod?.location?.countryCode === "IL")
                    postpodExists = true;
                if (podExists) {
                    d.arDate1 = d.status[0].val?.route?.pod?.date ? d.status[0].val?.route?.pod?.date : 0;
                    d.arPod = d.status[0].val?.route?.pod?.location?.name;
                    d.arCountry = d.status[0].val?.route?.pod?.location?.country;
                    d.arLocode = d.status[0].val?.route?.pod?.location?.locode;
                }
                else if (postpodExists) {
                    d.arDate1 = d.status[0].val?.route?.postpod?.date ? d.status[0].val?.route?.postpod?.date : 0;
                    d.arPod = d.status[0].val?.route?.postpod?.location?.name;
                    d.arCountry = d.status[0].val?.route?.postpod?.location?.country;
                    d.arLocode = d.status[0].val?.route?.postpod?.location?.locode;
                }
                else {
                    d.arDate1 =
                        d.arCountry = "";
                    d.arLocode = "";
                }
                d.arDate1 = d.arDate1 ? d.arDate1 : d.arDate;
                d.orgDate = d.arDate1 ? true : false;
            } else {
                d.arDate1 = d.arDate;
                d.orgDate = false;
            }
            dt.push(d);
        }
        setTracks1(dt);
        setOrgTracks(dt);
        return dt;
    }

    function downloadURI() {
        var link = document.createElement("a");
        link.download = "SeaTemplate.csv";
        link.href = "/SeaTemplate.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    const [ordersCount, setOrdersCount] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [pageNumber, setPageNumber] = useState(0);
    const onPageHandleChange = (_, newPage) => {
        setPageNumber(newPage);
        getAlltracksByPageChange(newPage, pageSize);
    }
    const onRowsPerPageHandleChange = (event) => {
        setPageSize(event.target.value);
        setPageNumber(0);
        getAlltracksByPageChange(0, event.target.value);
    }

    const getAlltracksByPageChange = (pageN, pageS) => {
        getAlltracksBySearch(pageN, pageS, customerSearch, scacSearch, polSearch, podSearch, containerSearch, typeSearch, statusSearch, tStatusSearch);
    }
    const getAlltracks = () => {
        getAlltracksBySearch(pageNumber, pageSize, customerSearch, scacSearch, polSearch, podSearch, containerSearch, typeSearch, statusSearch, tStatusSearch);
    }
    const getAlltracksBySearch = (pn, ps, cs, scac, pols, pods, cn, st, ss, ts) => {
        sendRequest('/api/track/allContainerTrackBySearch?pageNum=' + pn + "&pageSize=" + ps + "&customer=" + cs + "&scac=" + scac + "&pol=" + pols + "&pod=" + pods + "&container_name=" + cn + "&shipment_type=" + st + "&sinay_status=" + ss + "&taskyam_status=" + ts, REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                var dts = setTracks(response.tracks);
                setOrdersCount(response.count ? response.count : 0);
                var t = dts?.filter((word) => getSinayStatusAsString(word, ss)).
                    filter((word) => getTaskyamStatusAsString(word, ts));
                setTracks1(t);
                //setTracks1(dts);showSelectedTrack
            },
            failedCallback: response => {
            },
        });
    }
    
  const [shipNumber, setShipNumber] = useState("");
  const [mmsiNumber, setMmsiNumber] = useState("");
  const [shipData, setShipData] = useState([]);
  const [curShipData, setCurShipData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [map, setMap] = useState(/** @type google.maps.Map */ (null))
  const [dataLoaded, setDataLoaded] = useState(true);
  const [center, setCenter] = useState({lat:32, lng:35});
  const [rot, setRot] = useState(0);
  const [shipText, setShipText] = useState("");
    const [mapRef, setMapRef] = useState()
    const [addTrackItem, setAddTrackItem] = useState(false)
    const [customerSearch, setCustomerSearch] = useState("")
    const [containerSearch, setContainerSearch] = useState("")
    const [scacSearch, setScacSearch] = useState("")
    const [polSearch, setPolSearch] = useState("")
    const [podSearch, setPodSearch] = useState("")
    const [typeSearch, setTypeSearch] = useState("")
    const [statusSearch, setStatusSearch] = useState("")
    const [tStatusSearch, setTStatusSearch] = useState("")
    const [editTrack, setEditTrack] = useState({})
    const [showEditTrack, setShowEditTrack] = useState(false)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDgpzrxExQg_AUW8h199onppaSz72SYlSY",
    libraries: ['places'],
  })

    useEffect(() => {
        refreshTrack();
        window.setTimeout(function () {
            sendRequest(`/api/parcel/count?selectedLocation=NL&showUnassignedParcels=false&dropStatus=false`, REQUEST_ACTIONS.GET, '', {
                successCallback: response => { console.log("tracker count poll" + response?.parcelsCount) },
                failedCallback,
            });
        }, 5000);
    }, []);

    const allSeaTrack = () => {
        sendRequest('/api/track/allSeaTrack/', REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                alert("return success.")
            },
            failedCallback,
        });
    }

  const options = useMemo(() => ({
    disableDefaultUI: true,
  }), []);

  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const closeStatusDialog = () => {
        //showTrack(null);
        setShowSelectedTrack(false);
    }
    const closeAddTrackItem = () => {
        setCustomer("");
        setCustomerid(0);
        setPol("");
        setPolid(0);
        setPod("");
        setPodid(0);
        setSt("");
        setStInd(0);
        setScac("");
        setContainer("");
        setTransactionID("");
        setDialogErrorText("");
        setAddTrackItem(false);
    }
    const saveTrack = () => {
        if (editTrack.shipmentType !== "FCL" && editTrack.shipmentType !== "LCL") {
            setDialogErrorText("Please fill proper type, valid values are: LCL or FCL.");
                return;
        }
        if (editTrack.scac.length < 4) {
            setDialogErrorText("Invalid SCAC: SCAC should have atleast 4 characters.");
            return;
        }
        if (editTrack.containerName.length < 11) {
            setDialogErrorText("Invalid Container number: Container number should have atleast 11 characters.");
            return;
        }
        if (editTrack.customer == "") {
            setDialogErrorText("Please select valid customer.");
            return;
        }
        if (editTrack.pol == "") {
            setDialogErrorText("Please select valid POL.");
            return;
        }
        if (editTrack.pod == "") {
            setDialogErrorText("Please select valid POD.");
            return;
        }
        if (editTrack.arDate == "") {
            setDialogErrorText("Please select proper arrival date.");
            return;
        }
        if (editTrack.depDate == "") {
            setDialogErrorText("Please select proper departure date.");
            return;
        }
        if (!editTrack.ilUser) {
            setDialogErrorText("Please select a proper Referent.");
            return;
        }
        if (editTrack.transportId) {
            if (editTrack.transportId.charAt(0) != 'I') {
                setDialogErrorText("Invalid Transaction ID: Transaction ID should start with I, Capital i.");
                return;
            }
            if (editTrack.transportId.length != 10) {
                setDialogErrorText("Invalid Transaction ID: Transaction ID should have 10 characters.");
                return;
            }
        } else {
            editTrack.transportId = 0;
        }
        if (editTrack.containerName != "" && editTrack.scac != "" && editTrack.customer != "" && editTrack.pol != "" && editTrack.pod != "") {
            sendRequest('/api/track/editContainertrack/' + editTrack.id + '/' + editTrack.containerName + '/' + editTrack.scac + '/' + editTrack.shipmentType + '/' + editTrack.customer + '/' + editTrack.pol + '/' + editTrack.pod + '/' + editTrack.depDate + '/' + editTrack.transportId + '/' + editTrack.arDate + '/' + editTrack.shipName + '/' + editTrack.shipLine + '/' + editTrack.ilUser + '/' + editTrack.jobNumber, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    getAlltracks();
                    setShowEditTrack(false);
                },
                failedCallback,
            });
        } else {
            setDialogErrorText("Please fill in all the details.");
        }
    }
    const jsonToExcel = function (data, name, header) {
        //{ skipHeader: true }
        var ws = XLSX.utils.json_to_sheet(data, header);

        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "All");

        /* write workbook and force a download */
        XLSX.writeFile(wb, name + ".xlsx");
    }
    const exportCSV = () => {
        var data_main = [];
        var header = ["Customer", "container", "Type", "SCAC", "POL", "POD", "Status", "ETA", "Predictive ETA", "Last updated", "Taskyam Status", "Taskyam Update"]
        //data_main.push(header);
        for (var i = 0; i < tracks.length; i++) {
            var data = {
                "Customer": tracks[i].customer.replace(/,/g, ''),
                "container": tracks[i].containerName,
                "Type": tracks[i].containerName,
                "SCAC": tracks[i].scac,
                "POL": tracks[i].pol,
                "POD": tracks[i].pod,
                "Status": tracks[i].status ? (tracks[i].status[0]?.val?.metadata ? (tracks[i].status[0]?.val?.metadata?.shippingStatus) : (tracks[i].status[0].val.details ? (tracks[i].status[0].val.details[0]) : "-")) : "-",
                "ETA": tracks[i].status ? (tracks[i].status[0]?.val?.route ? formatDate1(tracks[i].status[0].val?.route?.pod?.date) : "-") : "-",
                "Predictive ETA": tracks[i].status ? (tracks[i].status[0]?.val?.route ? formatDate1(tracks[i].status[0].val?.route?.pod?.predictiveEta) : "-") : "-",
                "Last updated": tracks[i].status ? (tracks[i].status[0]?.val?.metadata ? formatDate1(tracks[i].status[0]?.val?.metadata?.updatedAt) : "-") : "-",
                "Taskyam Status": tracks[i].imports ? (String.fromCharCode(0xFEFF) +(tracks[i].imports.containerReturned ? "תעודת אחסנה" : getTaskyamStatus(tracks[i].imports.status))) : "-",
                "Taskyam Update": tracks[i].imports ? (formatDate(formatDate(tracks[i].imports.containerReturned ? tracks[i].imports.containerReturnedDate : tracks[i].imports.statusUpdateTime))) : "-",
            };
            data_main.push(data);
        }
        jsonToExcel(data_main, "Sea Tracks", header);
    }
    const importCSV = () => {
        var fileInput = document.getElementById('file');
        var element = document.getElementById("file_container");
        if (fileInput) {
            element.removeChild(fileInput);
        }
        var x = document.createElement("INPUT");
        x.setAttribute("type", "file");
        x.setAttribute("id", 'file');
        x.style.display = "none";
        element.appendChild(x);

        document.getElementById("file").addEventListener("change", changeHandler, true);
        document.getElementById('file').click();
    }

    const changeHandler = (event) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            var keys = data[0];
            var XL_row_object = []
            for (var i = 1; i < data.length; i++) {
                var t = {}
                keys.forEach(function (key, index) {
                    if (data[i][index])
                        t[key] = data[i][index];
                })
                //console.log(t);
                XL_row_object.push(t);
                if (t.Type !== "FCL" && t.Type !== "LCL") {
                    setDialogErrorText("Please fill proper type, valid values are: LCL or FCL. At line number: " + i);
                    return;
                }
                if (t.SCAC.length < 4) {
                    setDialogErrorText("Invalid SCAC: SCAC should have atleast 4 characters. At line number: " + i);
                    return;
                }
                if (t.ContainerNumber.length < 11) {
                    alert("Invalid Container number: Container number should have atleast 11 characters. At line number: " + i);
                    return;
                }
                if (t.arDate == "") {
                    setDialogErrorText("Please select proper arrival date.");
                    return;
                }
                if (t.depDate == "") {
                    setDialogErrorText("Please select proper departure date.");
                    return;
                }
                if (t.TransactionID) {
                    if (t.TransactionID.charAt(0) != 'I') {
                        setDialogErrorText("Invalid Transaction ID: Transaction ID should start with I, Capital i. At line number: " + i);
                        return;
                    }
                    if (t.TransactionID.length != 10) {
                        setDialogErrorText("Invalid Transaction ID: Transaction ID should have 10 characters. At line number: " + i);
                        return;
                    }
                }

            }
            var customersNotAdded = [];
            //console.log(XL_row_object);
            var index = 0;
            var totalIndex = XL_row_object.length;
            for (var j = 0; j < XL_row_object.length; j++) {
                sendRequest('/api/track/addContainertrack/' + XL_row_object[j].ContainerNumber + '/' + XL_row_object[j].SCAC + '/' + XL_row_object[j].Type + '/' + XL_row_object[j].Customer + '/' + XL_row_object[j].POL + '/' + XL_row_object[j].POD + "/" + XL_row_object[j].DepartureDate + '/' + XL_row_object[j].TransactionID + '/' + XL_row_object[j].arDate + '/' + XL_row_object[j].shipName + '/' + XL_row_object[j].shipLine + '/' + XL_row_object[j].ilUser, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        index++;
                        if (index == totalIndex) {
                            alert("Import sucessful");
                            getAlltracks();
                        }
                    },
                    failedCallback: response => {
                        alert("This customer was not imported: " + XL_row_object[j].Customer);
                    },
                });
            }
        };
        var file = event.target.files[0];
        if (file) {
            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        }
    };
    const resetAllContainerTrack = () => {
        sendRequest('/api/track/resetAllContainerTrack', REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                alert("resetAllContainerTrack done.")
            },
            failedCallback,
        });
    }
    const addTrack = () => {
        if (addTrackItem) {
            if (st !== "FCL" && st !== "LCL") {
                setDialogErrorText("Please fill proper type, valid values are: LCL or FCL.");
                return;
            }
            if (scac.length < 4) {
                setDialogErrorText("Invalid SCAC: SCAC should have atleast 4 characters.");
                return;
            }
            if (container.length < 11) {
                setDialogErrorText("Invalid Container number: Container number should have atleast 11 characters.");
                return;
            }
            if (customer == "") {
                setDialogErrorText("Please select valid customer.");
                return;
            }
            if (pol == "") {
                setDialogErrorText("Please select valid POL.");
                return;
            }
            if (pod == "") {
                setDialogErrorText("Please select valid POD.");
                return;
            }
            if (arDate == "") {
                setDialogErrorText("Please select proper arrival date.");
                return;
            }
            if (depDate == "") {
                setDialogErrorText("Please select proper departure date.");
                return;
            }
            if (!ilUser) {
                setDialogErrorText("Please select a proper Referent.");
                return;
            }
            var tId = "0";
            if (transactionID) {
                tId = transactionID;
                if (transactionID.charAt(0) != 'I') {
                    setDialogErrorText("Invalid Transaction ID: Transaction ID should start with I, Capital i.");
                    return;
                }
                if (transactionID.length != 10) {
                    setDialogErrorText("Invalid Transaction ID: Transaction ID should have 10 characters.");
                    return;
                }
            }
            if (container != "" && scac != "" && customer != "" && pol != "" && pod != "" && depDate != "") {
                sendRequest('/api/track/addContainertrack/' + container + '/' + scac + '/' + st + '/' + customer + '/' + pol + '/' + pod + "/" + depDate + '/' + tId + '/' + arDate + '/' + shipName + '/' + shipLine + '/' + ilUser + '/' + jobNumber, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        getAlltracks();
                        setTracks(response.tracks);

                        setCustomer("");
                        setCustomerid(0);
                        setPol("");
                        setPolid(0);
                        setPod("");
                        setPodid(0);
                        setSt("");
                        setStInd(0);
                        setScac("");
                        setContainer("");
                        setTransactionID("");
                        setDialogErrorText("");
                        setAddTrackItem(false);
                    },
                    failedCallback,
                });
            } else {
                setDialogErrorText("Please fill in all the details.");
            }

            //setAddTrackItem(false);
        }
        else {
            setAddTrackItem(true);
        }
    }

    const refreshTrack = () => {
        getAlltracks();
    }
    const showTrack = (data) => {
        setSelectedTrack(data);
        if (!data)
            return;
        setShowSelectedTrack(true);
        setSelectedTrackTaskyam(false);
        if (data?.status) {
            if (data.status[0].val.routeData) {
                if (data.status[0].val.routeData.coordinates) {
                    setCenter({ lat: data.status[0].val.routeData.coordinates.lat, lng: data.status[0].val.routeData.coordinates.lng });
                }
            }
        }
    }
    const showTaskyam = (data) => {
        setSelectedTrack(data);
        if (!data)
            return;
        setShowSelectedTrack(true);
        if(data)
            setSelectedTrackTaskyam(true);
        else
            setSelectedTrackTaskyam(false);
    }
    const reloadTrack = (id) => {
        sendRequest('/api/track/trackContainerTrack/' + id, REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                getAlltracks();
                alert("Refreshed data.")
                /*for (var i = 0; i < dts.length; i++) {
                    if (dts[i].id == id)
                        showTrack(dts[i]);
                }*/
            },
            failedCallback,
        });
    }
    const reloadTrackTaskyam = (id) => {
        sendRequest('/api/track/trackContainerTrackTaskyam/' + id, REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                getAlltracks();
                alert("Refreshed data.")
                /*for (var i = 0; i < dts.length; i++) {
                    if (dts[i].id == id)
                        showTrack(dts[i]);
                }*/
            },
            failedCallback,
        });
    }
    const closeEditTrack = () => {
        setDialogErrorText("")
        setShowEditTrack(false);
        setEditTrack(null);
    }
    const editTrack1 = (track) => {
        //let obj = lclPolData.find(o => o.id === v);
        setDialogErrorText("");
        var lclPolData2 = lclPolData.find(o => o.name === track.pol);
        var lclPoaData2 = lclPoaData.find(o => o.name === track.pod);
        var cust = consigneeNameOptions.find(o => o.name === track.customer);
        var lclPolData3 = [...lclPolData];
        var lclPoaData3 = [...lclPoaData];
        var consigneeNameOptions3 = [...consigneeNameOptions];
        if (!lclPolData2) {
            lclPolData3.push({ id: 10000, name: track.pol })
        }
        if (!lclPoaData2) {
            lclPoaData3.push({ id: 10000, name: track.pod })
        }
        if (!cust) {
            consigneeNameOptions3.push({ id: 10000, name: track.customer })
        }
        var polid = lclPolData3.find(o => o.name === track.pol);
        var podid = lclPoaData3.find(o => o.name === track.pod);
        var custid = consigneeNameOptions3.find(o => o.name === track.customer);
        track.polid = polid.id;
        track.podid = podid.id;
        track.custid = custid.id;

        setLclPolData1(lclPolData3);
        setLclPoaData1(lclPoaData3);
        setConsigneeNameOptions1(consigneeNameOptions3);
        track.shipType = track.shipmentType == "FCL" ? 2 : 1;
        if (track.transportId == "0") {
            track.transportId = "";
        }
        setEditTrack(track);
        setShowEditTrack(true);
    }
    
    const deleteTrack = (id) => {
        if (confirm("Are you sure you want to move this track to history?") == true) {
            sendRequest('/api/track/hideContainerTrack/' + id, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    getAlltracks();
                },
                failedCallback,
            });
        } else {
            //
        }
        
    }

    const setSearchBlur = (v,c,  d) => {
        v(d);
        if (orgTracks) {
            var t = [];

            var cs = c == 0 ? d : customerSearch;
            var sc = c == 2 ? d : scacSearch;
            var pols = c == 3 ? d : polSearch;
            var pods = c == 4 ? d : podSearch;
            var cn = c == 1 ? d : containerSearch;
            var st = c == 5 ? d : typeSearch;
            var ss = c == 6 ? d : statusSearch;
            var ts = c == 7 ? d : tStatusSearch;
            getAlltracksBySearch(pageNumber, pageSize, cs, sc, pols, pods, cn, st, ss, ts);
           /* t = orgTracks?.filter((word) => word.customer.includes(c == 0 ? d : customerSearch)).
                filter((word) => word.containerName.includes(c == 1 ? d : containerSearch)).
                filter((word) => word.scac.includes(c == 2 ? d : scacSearch)).
                filter((word) => word.pol.includes(c == 3 ? d : polSearch)).
                filter((word) => word.pod.includes(c == 4 ? d : podSearch)).
                filter((word) => word.shipmentType.includes(c == 5 ? d : typeSearch)).
                filter((word) => getSinayStatusAsString(word, c == 6 ? d : statusSearch)).
                filter((word) => getTaskyamStatusAsString(word, c == 7 ? d : tStatusSearch))
            setTracks1(t);*/
        }
    }
    const getSinayStatusAsString = (t, str) => {
        if (!str)
            return true;
        var status = "";
        if (t.imports?.containerReturnedSinay) {
            status = "Returned";
        } else if (t.status && t.status[0]) {
            status = t.status[0]?.val?.metadata ? getSinayStatus1(t.status[0]?.val?.metadata?.shippingStatus, t.status[0]) : (t.status[0].val.details ? getSinayStatus1(t.status[0].val.details[0], t.status[0]) : "-")
        } else {
            status = "--------------";
        }
        return status.includes(str);
    }
    const getTaskyamStatusAsString = (t, str) => {
        if (!str)
            return true;
        var status = t.imports?.containerReturned ? "תעודת אחסנה" : getTaskyamStatus(t.imports ? t.imports.status:"");
        return status.includes(str);
    }
    const getTaskyamStatus = (id) => {
        switch (id) {
            case 0:
                return "";
            case 1:
                return "מצהר";
            case 2:
                return "זמינות";
            case 3:
                return "פקודת מסירה";
            case 4:
                return "בדיקת מכס";
            case 5:
                return "התרת מכס";
            case 6:
                return "התרת נמל";
            case 7:
                return "תעודת ליווי";
            case 8:
                return "בקשה להוצאה";
            case 9:
                return "משוב מהנמל";
            case 10:
                return "יציאת מטען";
        }
        return "-"
    }
    const getSinayStatus1 = (str, status) => {
        var msg = status?.val?.message ? status?.val?.message : "";
        if (msg) {
            return msg;
        }
        return str;
    }
    const getSinayStatus = (str, status) => {
        var msg = status?.val?.message ? status?.val?.message : "";
        if (msg) {
            return <span style={{ textDecoration: "underline" }} title={str}>{msg}</span>;
        }
        /*else {
        }
        if (str.includes("Request completed"))
            return <span style={{ textDecoration: "underline" }} title={str}>No Data</span>;
        else if (str.includes("An error"))
            return <span style={{ textDecoration: "underline" }} title={str}>Line Error</span>;
        else if (str.includes("No support"))
            return <span style={{ textDecoration: "underline" }} title={str}>No support</span>;*/
        return str;
    }

  const fleetPosition = () => {
    sendRequest('/api/track/marineTrack/'+mmsiNumber+'/'+shipNumber, REQUEST_ACTIONS.GET, '', {
      successCallback: response => {
        try{
          var data = JSON.parse(response.data)
          setShipData(data[0]);
          setDataLoaded(true);
          setCenter({lat:data[0][3], lng:data[0][4]});
          setRot(response.data[0][6]);
          setShipText(""+data[0][12]+" ("+data[0][15]+" - "+data[0][15]+") Dest: "+data[0][15]+" ETA: "+data[0][15]);
        } catch {
          dispatch(snackbarToggle({ type: 'error', message:'Reached max number of call' }));
        }
      },
      failedCallback,
    });
  }
  const getShipSymbol = () => {
    return {
      path: 'M16,31.36c-0.064,0-0.128-0.018-0.185-0.052C15.604,31.182,10.64,28.156,10.64,24V4 c0-0.071,0.021-0.141,0.061-0.2l2-3C12.767,0.7,12.879,0.64,13,0.64h6c0.12,0,0.232,0.06,0.3,0.16l2,3 c0.039,0.059,0.06,0.129,0.06,0.2v20c0,4.156-4.964,7.182-5.175,7.309C16.128,31.343,16.064,31.36,16,31.36z M11.36,22.36V24 c0,3.321,3.754,5.989,4.64,6.573c0.886-0.584,4.64-3.252,4.64-6.573v-1.64H11.36z M19.36,21.64h1.279v-3.28H19.36V21.64z M17.36,21.64h1.279v-7.28H17.36V21.64z M15.36,21.64h1.28v-3.28h-1.28V21.64z M13.36,21.64h1.28v-3.28h-1.28V21.64z M11.36,21.64 h1.28v-7.28h-1.28V21.64z M19.36,17.64h1.279V6.36H19.36V17.64z M15.36,17.64h1.28v-7.28h-1.28V17.64z M13.36,17.64h1.28v-3.28 h-1.28V17.64z M17.36,13.64h1.279v-3.28H17.36V13.64z M13.36,13.64h1.28V6.36h-1.28V13.64z M11.36,13.64h1.28v-3.28h-1.28V13.64z M17.36,9.64h1.279V6.36H17.36V9.64z M15.36,9.64h1.28V6.36h-1.28V9.64z M11.36,9.64h1.28V6.36h-1.28V9.64z M11.36,5.64h9.28V4.109 L18.808,1.36H18.36V3c0,0.199-0.161,0.36-0.36,0.36h-4c-0.199,0-0.36-0.161-0.36-0.36V1.36h-0.447L11.36,4.109V5.64z M14.36,2.64 h3.28V1.36h-3.28V2.64z M17.5,26.36h-3c-0.199,0-0.36-0.161-0.36-0.36v-0.64H13v-0.72h1.14V24c0-0.199,0.161-0.36,0.36-0.36h3 c0.199,0,0.36,0.161,0.36,0.36v0.64H19v0.721h-1.14V26C17.86,26.199,17.699,26.36,17.5,26.36z M14.86,25.64h2.28v-1.28h-2.28V25.64z',
      scale: 1,
      strokeOpacity: 1,
      color: 'red',
      strokeWeight: 1,
      anchor:new window.google.maps.Point(16, 16),
      rotation: rot,
    };
  }

  const dataDiv = {
    border: "1px solid grey",
    padding: "5px",
    borderRadius: "5px"
  };
  const mapSpan = {
    background: "blue",
    padding: "5px",
    borderRadius: "5px"
  };
  const dataData = {
    color: "black",
    padding: "5px",
    borderRadius: "5px"
  };
  const mapDiv = {
    width: "100%",
    height: "300px"
    };

    const getBGColor = (t, index) => {
        if (t.id == selectedTrack?.id) {
            return "#bdbdbd";
        }
        if (t.shipmentType === "FCL") {
            if (t.imports?.status == 10 && !t.imports?.containerReturned) {
                var dt0 = new Date().getTime();
                var dt1 = new Date(t.imports.statusUpdateTime).getTime();
                var dif = dt0 - dt1;
                if (dif > 7 * 24 * 3600 * 1000)
                    return "#d32f2f82"
                return "#feb7348f"
            } else if (t.imports?.status == 10 && t.imports?.containerReturned) {
                return "#2e7d329c"
            }
            else /*if (index % 2 == 1)
                return "#9ac3eb4a";
            else */
                return "white";
        } else {
            /*if (t.imports?.status == 10 && !selectedTrack?.lclMap.ExitDepoTime)
                return "lightyellow"
            else */if (t.imports?.status == 10 && t?.lclMap?.ExitDepoTime)
                return "#2e7d329c"
            else /*if (index % 2 == 1)
                return "#9ac3eb4a";
            else*/
                return "white";
        }
    }

  return (
      <Grid container alignContent="flex-start" style={{ fontSize: "1vw" }} id="file_container">
          {showEditTrack && <Dialog
              open={showEditTrack}
              onHandleCancel={closeEditTrack}
              title='save Tracker'
              maxWidth='lg'>
              <Grid container item xs={12} justifyContent='center'>
                  <Grid container item xs={12} justifyContent='flex-start'>
                      <Grid item container lg={5} style={{ padding: "5px" }}>
                          <input style={{ width: "100%", padding: "5px", border: editTrack.customer ? "1px lightgrey solid" : "1px solid red", borderRadius: "4px" }} value={editTrack.customer} onChange={event => setEditTrack(prev => ({ ...prev, customer: event.target.value }))} placeholder="Customer *" list="opts" />
                          <datalist id="opts">
                              {consigneeNameOptions.map((v, index) => (<option>{v.name}</option>))}
                          </datalist>
                      </Grid>
                  </Grid>
                  <Grid container item xs={12} justifyContent='flex-start' style={{ marginTop: "10px" }}>
                      <TextField
                          lg={5}
                          required
                          style={{ padding: "5px" }}
                          type="Text"
                          label='Container'
                          value={editTrack.containerName}
                          onChange={event => setEditTrack(prev => ({ ...prev, containerName: event.target.value }))}
                      />
                      <TextField
                          style={{ padding: "5px" }}
                          required
                          lg={5}
                          type="Text"
                          label='Job Number'
                          value={editTrack.jobNumber}
                          onChange={event => setEditTrack(prev => ({ ...prev, jobNumber: event.target.value }))}
                      />
                      <Grid item container lg={5} style={{ padding: "5px" }}><Autocomplete
                          field='name'
                          required
                          label='Type'
                          lg={12}
                          value={editTrack.shipType}
                          onChange={(e, v) => {
                              setEditTrack(prev => ({ ...prev, shipType: v }));
                              setEditTrack(prev => ({ ...prev, shipmentType: getType(v) }));
                          }}
                          options={types}
                      /></Grid>
                      <TextField
                          style={{ padding: "5px" }}
                          required
                          lg={5}
                          type="Text"
                          label='SCAC'
                          value={editTrack.scac}
                          onChange={event => setEditTrack(prev => ({ ...prev, scac: event.target.value }))}
                      />
                      <Grid item container lg={5} style={{ padding: "5px" }}><Autocomplete
                          field='name'
                          required
                          label='POL'
                          lg={12}
                          value={editTrack.polid}
                          onChange={(e, v) => {
                              if (v) {
                                  let obj = lclPolData1.find(o => o.id === v);
                                  setEditTrack(prev => ({ ...prev, polid: obj.id }));
                                  setEditTrack(prev => ({ ...prev, pol: obj.name }));
                              }
                          }}
                          options={lclPolData1}
                      /></Grid>
                      <Grid item container lg={5} style={{ padding: "5px" }}><Autocomplete
                          field='name'
                          required
                          label='POD'
                          lg={12}
                          value={editTrack.podid}
                          onChange={(e, v) => {
                              if (v) {
                                  let obj = lclPoaData1.find(o => o.id === v);
                                  setEditTrack(prev => ({ ...prev, podid: obj.id }));
                                  setEditTrack(prev => ({ ...prev, pod: obj.name }));
                              }
                          }}
                          options={lclPoaData1}
                      /></Grid>
                      <TextField
                          lg={5}
                          style={{ padding: "5px" }}
                          type="Text"
                          label="Ship Name"
                          value={editTrack.shipName}
                          onChange={event => setEditTrack(prev => ({ ...prev, shipName: event.target.value }))}
                      />
                      <TextField
                          lg={5}
                          style={{ padding: "5px" }}
                          type="Text"
                          label="Ship Line"
                          value={editTrack.shipLine}
                          onChange={event => setEditTrack(prev => ({ ...prev, shipLine: event.target.value }))}
                      />
                      <Grid item container lg={5} justifyContent="space-between" style={{ padding: "5px" }}>
                          <Grid item container lg={5} style={{ padding: "5px" }}><DateTimePicker
                              label={'Departure Date'}
                              required
                              xs={12}
                              sm={12}
                              md={12}
                              value={editTrack.depDate}
                              isShowTime={true}
                              format="dd/MM/yyyy"
                              onChange={date => setEditTrack(prev => ({ ...prev, depDate: date?.toISOString() }))}
                          /></Grid>
                          <Grid item container lg={5} style={{ padding: "5px" }}><DateTimePicker
                              label={'Arrival Date'}
                              required
                              xs={12}
                              sm={12}
                              md={12}
                              value={editTrack.arDate}
                              isShowTime={true}
                              format="dd/MM/yyyy"
                              onChange={date => setEditTrack(prev => ({ ...prev, arDate: date?.toISOString() }))}
                          /></Grid>
                      </Grid>
                  </Grid>
                  <Grid container item xs={12} justifyContent='flex-start' style={{ marginTop: "10px" }}>
                      <Grid item container lg={5} style={{ padding: "5px" }}><Autocomplete
                          field='name'
                          required
                          label='Referent'
                          lg={12}
                          value={editTrack.ilUser}
                          onChange={(e, v) => {
                              setEditTrack(prev => ({ ...prev, ilUser: v }));
                          }}
                          options={userILNames}
                      /></Grid>
                  </Grid>
                  <Grid container item xs={12} justifyContent='flex-start' style={{marginTop: "10px"}} >
                      <TextField
                          lg={5}
                          style={{ padding: "5px" }}
                          type="Text"
                          label={editTrack.transportId ? "Transaction ID" : 'Transaction ID (eg: I025486A01)'}
                          value={editTrack.transportId}
                          onChange={event => setEditTrack(prev => ({ ...prev, transportId: event.target.value }))}
                      />
                  </Grid>

                  {dialogErrorText && <Grid justifyContent="center" item container style={{ fontSize: "20px", color: "red", margin: "10px"}}>
                      {dialogErrorText}
                  </Grid>}
                  
                  {<Button onClick={saveTrack} variant='contained' sx={{ m: 3 }}>
                      {`Save`}
                  </Button>}
              </Grid>
          </Dialog>}
          {addTrackItem && (
              <Dialog
                  open={addTrackItem}
                  onHandleCancel={closeAddTrackItem}
                  title='Add Tracker'
                  maxWidth='lg'>
                  <Grid container item xs={12} justifyContent='center'>
                      <Grid container item xs={12} justifyContent='flex-start'>
                          <Grid item container lg={5} style={{ padding: "5px" }}>
                              <input style={{ width: "100%", padding: "5px", border: customer ? "1px lightgrey solid" : "1px solid red", borderRadius: "4px" }} value={customer} onChange={(e) => { setCustomer(e.target.value) }} placeholder="Customer *" list="opts" />
                              <datalist id="opts">
                                  {consigneeNameOptions.map((v, index) => (<option>{v.name}</option>))}
                              </datalist>
                          </Grid>
                      </Grid>{/*space-evenly*/}
                      <Grid container item xs={12} justifyContent='flex-start' style={{ marginTop: "10px" }}>
                          <TextField
                              required
                              lg={5}
                              style={{ padding: "5px" }}
                              type="Text"
                              label='Container'
                              value={container}
                              onChange={event => setContainer(event.target.value)}
                          />
                          <TextField
                              style={{ padding: "5px" }}
                              required
                              lg={5}
                              type="Text"
                              label='Job Number'
                              value={jobNumber}
                              onChange={event => setJobNumber(event.target.value)}
                          />
                          <Grid item container lg={5} style={{ padding: "5px" }}> <Autocomplete
                              field='name'
                              required
                              label='Type'
                              lg={12}
                              value={stInd}
                              onChange={(e, v) => {
                                  setStInd(v);
                                  setSt(getType(v));
                              }}
                              options={types}
                          /></Grid>
                          <TextField
                              required
                              lg={5}
                              style={{ padding: "5px" }}
                              type="Text"
                              label='SCAC'
                              value={scac}
                              onChange={event => setScac(event.target.value)}
                          />
                          <Grid item container lg={5} style={{ padding: "5px" }}><Autocomplete
                              field='name'
                              required
                              label='POL'
                              lg={12}
                              value={polid}
                              onChange={(e, v) => {
                                  if (v) {
                                      let obj = lclPolData.find(o => o.id === v);
                                      setPolid(obj.id);
                                      setPol(obj.name);
                                  }
                              }}
                              options={lclPolData}
                          /></Grid>
                          <Grid item container lg={5} style={{ padding: "5px" }}><Autocomplete
                              field='name'
                              required
                              label='POD'
                              lg={12}
                              value={podid}
                              onChange={(e, v) => {
                                  if (v) {
                                      let obj = lclPoaData.find(o => o.id === v);
                                      setPodid(obj.id);
                                      setPod(obj.name);
                                  }
                              }}
                              options={lclPoaData}
                          /></Grid>
                          <TextField
                              lg={5}
                              style={{ padding: "5px" }}
                              type="Text"
                              label="Ship Name"
                              value={shipName}
                              onChange={event => setShipName(event.target.value)}
                          />
                          <TextField
                              lg={5}
                              style={{ padding: "5px" }}
                              type="Text"
                              label="Ship Line"
                              value={shipLine}
                              onChange={event => setShipLine(event.target.value)}
                          />

                          <Grid item container lg={5} justifyContent="space-between" style={{ padding: "5px" }}>
                              <Grid item container lg={5} style={{ padding: "5px" }}><DateTimePicker
                                  label={'Departure Date'}
                                  required
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  value={depDate}
                                  isShowTime={true}
                                  format="dd/MM/yyyy"
                                  onChange={date => setDepDate(date?.toISOString())}
                              /></Grid>
                              <Grid item container lg={5} style={{ padding: "5px" }}><DateTimePicker
                                  label={'Arrival Date'}
                                  required
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  value={arDate}
                                  isShowTime={true}
                                  format="dd/MM/yyyy"
                                  onChange={date => setArDate(date?.toISOString())}
                              /></Grid>
                          </Grid>
                      </Grid>
                      <Grid container item xs={12} justifyContent='flex-start' style={{ marginTop: "10px" }}>
                          <Grid item container lg={5} style={{ padding: "5px" }}><Autocomplete
                              field='name'
                              required
                              label='Referent'
                              lg={12}
                              value={ilUser}
                              onChange={(e, v) => {
                                  setIlUser(v);
                              }}
                              options={userILNames}
                          /></Grid>
                      </Grid>
                      <Grid container item xs={12} justifyContent='flex-start' style={{ marginTop: "10px" }}>
                          <TextField
                              lg={5}
                              style={{ padding: "5px" }}
                              type="Text"
                              label={transactionID ? "Transaction ID" : 'Transaction ID (eg: I025486A01)'}
                              value={transactionID}
                              onChange={event => setTransactionID(event.target.value)}
                          />
                      </Grid>
                      {dialogErrorText && <Grid justifyContent="center" item container style={{ fontSize: "20px", color: "red", margin: "10px" }}>
                          {dialogErrorText}
                      </Grid>}
                      <Grid container item xs={12} justifyContent='flex-end'>
                          {<Button onClick={addTrack} variant='contained' sx={{ m: 3 }}>
                              {`Add`}
                          </Button>}
                      </Grid>
                  </Grid>
              </Dialog>
          )}
          <Grid container item xs={12} flexWrap="nowrap" justifyContent="space-between" alignContent="flex-start" style={{ maxHeight: "50px" }} >
              <Grid container justifyContent="flex-start" item xs={12} alignContent="flex-start" style={{ maxHeight: "50px" }} >
                  {<Button variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                      {`Sea`}
                  </Button>}
                  {<Button onClick={() => { props.onHandleSeaHist() }} variant='contained' sx={{ fontSize: "1vw", background: "#bdbdbd", m: 1, maxHeight: "30px" }}>
                      {`Sea History`}
                  </Button>}
                  {<Button onClick={() => { props.onHandleAir() }} variant='contained' sx={{ fontSize: "1vw", background: "#bdbdbd", m: 1, maxHeight: "30px" }}>
                      {`Air`}
                  </Button>}
                  {<Button onClick={() => { props.onHandleAirHist() }} variant='contained' sx={{ fontSize: "1vw", background: "#bdbdbd", m: 1, maxHeight: "30px" }}>
                      {`Air History`}
                  </Button>}
              </Grid>
              <Grid container justifyContent="flex-end" item xs={12} alignContent="flex-start" style={{ maxHeight: "50px" }} >
                  {props.fullName == "Admin" &&<Button onClick={allSeaTrack} variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                      {`All SeaTrack`}
                  </Button>}
                  {<Button onClick={addTrack} variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                      {`Add Track`}
                  </Button>}
                  {<Button onClick={exportCSV} variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                      {`Export CSV`}
                  </Button>}
                  {<Button onClick={importCSV} variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                      {`Import CSV`}
                  </Button>}
                  {<Button onClick={downloadURI} variant='contained' sx={{ fontSize: "1vw", background: "#1976d270", m: 1, maxHeight: "30px" }}>
                      {`Template`}
                  </Button>}
                  {/*{<Button onClick={refreshTrack} variant='contained' sx={{ m: 3 }}>
                      {`Load Tracks`}
                  </Button>}*/}

              </Grid>
          </Grid>
          <Grid container item xs={12} alignContent="flex-start" style={{height:"calc(100vh - 160px)", fontSize:"0.8vw"}}>
              <Grid item container alignContent="flex-start" style={{ height: "52px", overflowY: "scroll", width: "98vw" }}>
                  {<Grid style={{ fontWeight: "bold" }} alignContent="flex-start" container item xs={12}>
                      <Grid alignItems="center" style={{ border: "1px solid Grey", padding: "5px" }} item container xs={0.25}>
                          #
                      </Grid>
                      <Grid alignItems="center" style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1.5}>
                          Actions
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1.7}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>Customer</Typography>}
                              value={customerSearch}
                              onChange={event => setSearchBlur(setCustomerSearch, 0, event.target.value)}
                          />
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={0.75}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>Container</Typography>}
                              value={containerSearch}
                              onChange={event => setSearchBlur(setContainerSearch, 1, event.target.value)}
                          />
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={0.5}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>Type</Typography>}
                              value={typeSearch}
                              onChange={event => setSearchBlur(setTypeSearch, 5, event.target.value)}
                          />
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={0.7}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>SCAC</Typography>}
                              value={scacSearch}
                              onChange={event => setSearchBlur(setScacSearch, 2, event.target.value)}
                          />
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={0.8}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>POL</Typography>}
                              value={polSearch}
                              onChange={event => setSearchBlur(setPolSearch, 3, event.target.value)}
                          />
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={0.8}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>POD</Typography>}
                              value={podSearch}
                              onChange={event => setSearchBlur(setPodSearch, 4, event.target.value)}
                          />
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>Status</Typography>}
                              value={statusSearch}
                              onChange={event => setSearchBlur(setStatusSearch, 6, event.target.value)}
                          />
                      </Grid>
                      {/*<Grid alignItems="center" style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          Status
                      </Grid>*/}
                      <Grid alignItems="center" style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          ETA
                      </Grid>
                      {false && <Grid alignItems="center" style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          Predictive ETA
                      </Grid>}
                      <Grid alignItems="center" style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          Last updated
                      </Grid>
                      <Grid style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          <TextField
                              sm={null}
                              md={null}
                              lg={null}
                              label={<Typography fontSize="0.8vw" fontWeight='bold'>Taskyam Status</Typography>}
                              value={tStatusSearch}
                              onChange={event => setSearchBlur(setTStatusSearch, 7, event.target.value)}
                          />
                      </Grid>
                      {/*<Grid alignItems="center" style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          Taskyam Status
                      </Grid>*/}
                      <Grid alignItems="center" style={{ border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          Taskyam Update
                      </Grid>
                  </Grid>}
              </Grid>
              <Grid item container alignContent="flex-start" style={{ height: "calc(100vh - 260px)", overflowY: "scroll", width: "98vw" }}>
                  {tracks.length > 0 && <>{tracks.map((t, index) => <Grid key={index} style={{ background: getBGColor(t, index) }} container item xs={12}>
                      <Grid item container alignItems="center" xs={0.25} style={{ height: "40px", border: "1px solid Grey", padding: "5px" }}>
                      { index+1}
                      </Grid>
                      <Grid item container style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} xs={1.5}  >
                      {<Grid item container lg={12}>
                              {/*<Tooltip title={"Shipping line show details"}><IconButton style={{ width: '20px !important', height: '20px !important' }} size='small' onClick={() => { selectedTrack?.id == t.id ? showTrack(null) : showTrack(t) }}>
                              <UnfoldMoreIcon style={{ width: '20px !important', height: '20px !important' }} />
                          </IconButton></Tooltip>*/}

                          <Tooltip title={"Move to history"}><IconButton style={{ width: '20px !important', height: '20px !important' }} size='small' onClick={() => { deleteTrack(t.id) }}>
                                  <ArrowRightIcon style={{ width: '20px !important', height: '20px !important' }} />
                          </IconButton></Tooltip>
                          <Tooltip title={"Edit row."}><IconButton style={{ width: '20px !important', height: '20px !important' }} size='small' onClick={() => { editTrack1(t) }}>
                              <EditIcon style={{ width: '20px !important', height: '20px !important' }} />
                          </IconButton></Tooltip>
                              {props.fullName == "Admin" && <>
                                  <Tooltip title={"Shipping line reload information"}><IconButton style={{ width: '20px !important', height: '20px !important' }} size='small' onClick={() => { reloadTrack(t.id) }}>
                                  <BackIcon style={{ width: '20px !important', height: '20px !important' }} />
                              </IconButton></Tooltip>

                              <Tooltip title={"Taskyam reload information"}><IconButton style={{ width: '20px !important', height: '20px !important' }} size='small' onClick={() => { reloadTrackTaskyam(t.id) }}>
                                  <BackIcon style={{ width: '20px !important', height: '20px !important' }} />
                              </IconButton></Tooltip>
                          </>}
                      </Grid>}
                      {/*<div title="Delete Tracker" onClick={() => { deleteTrack(t.id) }} style={{ height: "30px", padding: "3px 12px", cursor: "pointer", border: "1px solid black", background: "#1976d2", borderRadius: "5px" }}>X</div>
                      <div title="Hide Details" onClick={() => { showTrack(null) }} style={{ marginLeft: "3px", height: "30px", padding: "3px 12px", cursor: "pointer", border: "1px solid black", background: "#1976d2", borderRadius: "5px" }}>-</div>
                      <div title="Show Details" onClick={() => { showTrack(t) }} style={{ marginLeft: "3px", height: "30px", padding: "3px 12px", cursor: "pointer", border: "1px solid black", background: "#1976d2", borderRadius: "5px" }}>+</div>
                      <div title="Reload Tracker" onClick={() => { reloadTrack(t.id) }} style={{ marginLeft: "3px", height: "30px", padding: "3px 12px", cursor: "pointer", border: "1px solid black", background: "#1976d2", borderRadius: "5px" }}>R</div>
*/}
                      </Grid>
                      <Grid whiteSpace="noWrap" alignItems="center" title={t.customer} style={{ overflow: "hidden", height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1.7}>
                      {t.customer}
                  </Grid>
                  <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={0.75}>
                      {t.containerName}
                  </Grid>
                  <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={0.5}>
                      {t.shipmentType}
                  </Grid>
                  <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={0.7}>
                      {t.scac}
                  </Grid>
                  <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={0.8}>
                      {t.pol}
                  </Grid>
                  <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={0.8}>
                      {t.pod}
                  </Grid>
                      {(t.status && t.status[0]) ? <Grid onClick={() => { showTrack(t) }} whiteSpace="noWrap" title={t.status[0]?.val?.metadata ? getSinayStatus(t.status[0]?.val?.metadata?.shippingStatus, t.status[0]) : (t.status[0].val.details ? getSinayStatus(t.status[0].val.details[0], t.status[0]) : "-")} alignItems="center" style={{ textDecoration:"underline", cursor:"pointer", overflow: "hidden", height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          <>{t.imports?.containerReturnedSinay ? "Returned" : (t.status[0]?.val?.metadata ? getSinayStatus(t.status[0]?.val?.metadata?.shippingStatus, t.status[0]) : (t.status[0].val.details ? getSinayStatus(t.status[0].val.details[0], t.status[0]) : "-"))}</>
                      
                  </Grid> : <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1}>--------------</Grid>}


{/*d.status[0].val?.eta ? d.status[0].val?.eta : (d.status[0].val?.route?.pod?.date ? d.status[0].val?.route?.pod?.date : 0)*/}
                      {/*{(t.status && t.status[0]) ? <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          <>{t.status[0].val?.eta ? <span style={{ fontWeight: "bold" }}>{formatDate(t.status[0].val?.eta)}</span> : <>{t.arDate}</>}</>
                  </Grid> : <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1}>--------------</Grid>}*/}

                      <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                          {formatDate(t.arDate1)}{t.orgDate ? "" : " *"}
                      </Grid>
                  {/*{(t.status && t.status[0]) ? <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                      <>{t.status[0]?.val?.route ? <>{formatDate1(t.status[0]?.val?.route?.pod?.predictiveEta)}</> : "-"}</>
                  </Grid> : <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1}>--------------</Grid>}*/}

                  {(t.status && t.status[0]) ? <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                      <>{t.status[0]?.val?.metadata ? <>{formatDate(t.status[0]?.val?.metadata?.updatedAt)}</> : "-"}</>
                  </Grid> : <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1}>--------------</Grid>}

                      {t.imports?.ImportProcess ? <Grid alignItems="center" onClick={() => { showTaskyam(t) }} style={{ textDecoration:"underline", cursor:"pointer", height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                      <>{t.imports.containerReturned ?"תעודת אחסנה":getTaskyamStatus(t.imports.status)}
                          {/*<Tooltip title={"Taskyam show details"}><IconButton style={{ padding: 0 }} size='small' onClick={() => { selectedTrack?.id == t.id ? showTaskyam(null) : showTaskyam(t) }}>
                              <UnfoldMoreIcon style={{ padding: 0, width: '20px !important', height: '40px !important' }} />
                          </IconButton></Tooltip>*/}
                      </>
                  </Grid> : <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1}>--------------</Grid>}

                  {t.imports ? <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1}>
                      <>{formatDate(t.imports.containerReturned ? t.imports.containerReturnedDate : t.imports.statusUpdateTime)}</>
                  </Grid> : <Grid alignItems="center" style={{ height: "40px", border: "1px solid Grey", padding: "5px" }} item container xs={1}>--------------</Grid>}

                  </Grid>)}</>}</Grid>

              <TablePagination
                  rowsPerPageOptions={[50, 100, 500, 1000]}
                  component='div'
                  count={ordersCount}
                  rowsPerPage={pageSize}
                  page={pageNumber}
                  onPageChange={onPageHandleChange}
                  onRowsPerPageChange={onRowsPerPageHandleChange}
              />
              {showSelectedTrack && <Dialog
                  open={showSelectedTrack}
                  onHandleCancel={closeStatusDialog}
                  title='Information'
                  maxWidth='1400px'>
                  {!selectedTrackTaskyam && <Grid container item style={{ marginTop: "25px", fontSize:"1vw" }}>
                      {(selectedTrack && selectedTrack.status) && <Grid style={{ margin: "10px", borderRadius: "10px", border: "1px grey solid", padding: "5px" }} item container>
                          {selectedTrack.status[0].val?.route && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                              <Grid xs={4} item container>POL</Grid>
                              <Grid xs={7} item container>{selectedTrack.status[0]?.val?.route?.pol?.location?.country}, {selectedTrack.status[0]?.val?.route?.pol?.location?.state}, {selectedTrack.status[0]?.val?.route?.pol?.location?.locode}</Grid>
                          </Grid>}
                          {selectedTrack.status[0].val?.route && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                              <Grid xs={4} item container>POD</Grid>
                              <Grid xs={7} item container>{selectedTrack.arCountry}, {selectedTrack.arPod}, {selectedTrack.arLocode}</Grid>
                          </Grid>}
                          {selectedTrack.status[0].val?.route && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                              <Grid xs={4} item container>Estimated Time</Grid>
                              <Grid xs={7} item container>{formatDate(selectedTrack.arDate1)}</Grid>
                          </Grid>}
                          {selectedTrack.status[0].val.route?.pod?.predictiveEta && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                              <Grid xs={4} item container>Predictive Eta</Grid>
                              <Grid xs={7} item container>{formatDate(selectedTrack.status[0].val?.route?.pod?.predictiveEta)}</Grid>
                          </Grid>}
                          {selectedTrack.status[0].val?.metadata && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                              <Grid xs={4} item container>Updated At</Grid>
                              <Grid xs={7} item container>{formatDate(selectedTrack.status[0].val?.metadata?.updatedAt)}</Grid>
                          </Grid>}
                          {selectedTrack.status[0].val?.metadata && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                              <Grid xs={4} item container>Sealine</Grid>
                              <Grid xs={7} item container>{selectedTrack.status[0].val?.metadata?.sealine}</Grid>
                          </Grid>}
                          {selectedTrack.status[0].val?.metadata && <Grid item container style={{ borderBottom: "1px solid Grey", padding: "5px" }}>
                              <Grid xs={4} item container>Sealine Name</Grid>
                              <Grid xs={7} item container>{selectedTrack.status[0].val?.metadata?.sealineName}</Grid>
                              
                          </Grid>}
                          {selectedTrack.status[0].val?.metadata && <Grid item container style={{ padding: "5px" }}>
                              <Grid xs={4} item container>Shipping Status</Grid>
                              <Grid xs={7} item container>{selectedTrack.status[0].val?.metadata?.shippingStatus}</Grid>
                          </Grid>}
                      </Grid>}
                      <Grid style={{ fontSize:"1vw", fontWeight: "Bold", marginTop: "25px", width: "1400px" }} item container></Grid>
                      {(selectedTrack && selectedTrack.status) && <Grid style={{ fontWeight: "Bold", marginTop: "25px" }} item container>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>Date</Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>Status</Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>Location</Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>Route</Grid>
                      </Grid>}
                      {(selectedTrack && selectedTrack.status) && <>{selectedTrack.status[0].val?.containers != null ? <>{selectedTrack.status[0].val?.containers[0].events.map((t) => <Grid style={{ fontSize: "1vw"}} item container>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>{formatDate(t.date)}</Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>{t.description}</Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>{t.location?.countryCode}, {t.location?.state}</Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>{t.routeType}</Grid>
                      </Grid>)}</> : <Grid style={{ fontSize: "1vw"}} item container>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>Error from server</Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container></Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container></Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container></Grid>
                      </Grid>}</>}

                      {/*{(selectedTrack && selectedTrack.status && selectedTrack.status[0].val?.containers) && <>{selectedTrack.status[0].val?.containers[0].events.map((t) => <Grid style={{}} item container>
                      <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>{t.location?.countryCode}, {t.location?.state}</Grid>
                      <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>{t.facility?.name}</Grid>
                      <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>{t.date}</Grid>
                      <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={3} item container>{t.description}</Grid>
                  </Grid>)}</>}*/}
                  </Grid>}
                  {!selectedTrackTaskyam && <Grid container item style={{ fontSize: "1vw", marginTop: "25px" }}>

                      {(selectedTrack && selectedTrack.status) && <Grid style={{ fontWeight: "Bold", marginTop: "25px" }} item container>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>Vessel Name</Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>IMO</Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>Flag</Grid>
                      </Grid>}
                      {(selectedTrack && selectedTrack.status) && <>{selectedTrack.status[0].val?.vessels != null ? <>{selectedTrack.status[0].val?.vessels.map((t) => <Grid style={{}} item container>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>{t.name}</Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>{t.imo}</Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>{t.flag}</Grid>
                      </Grid>)}</> : <Grid style={{}} item container>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container>Error from server</Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container></Grid>
                          <Grid style={{ border: "1px grey solid", padding: "5px" }} xs={4} item container></Grid>
                      </Grid>}</>}
                  </Grid>}
                  {(selectedTrack?.imports != null && selectedTrackTaskyam) && <Grid container item style={{ fontSize: "1vw", marginTop: "25px", fontsize: "10px" }}>
                      <Grid item container lg={12}>
                          <Grid item container lg={12}>
                              <Grid item container lg={12} alignItems="center" justifyContent="center" style={{ margin: "25px", direction: `${contains_heb(selectedTrack?.imports.Port.Name) ? "rtl" : "ltr"}` }}>
                                  Port: {selectedTrack?.imports.Port.Name}
                              </Grid>
                              <Grid item container lg={12} justifyContent="space-around">
                                  <Grid item container lg={3} flexDirection="column" flexWrap="nowrap">
                                      <Grid item container lg={12}>
                                          <Grid item container lg={6} style={{ border: "1px solid grey", padding: "3px", direction: `${contains_heb(selectedTrack?.imports.ManifestNumber) ? "rtl" : "ltr"}`, justifyContent: `${contains_heb(selectedTrack?.imports.ManifestNumber) ? "flex-Start" : "flex-end"}` }}>{selectedTrack?.imports.ManifestNumber}</Grid>
                                          <Grid item container lg={6} justifyContent="flex-end" style={{ border: "1px solid grey", padding: "3px" }}>Manifest</Grid>
                                      </Grid>
                                      <Grid item container lg={12}>
                                          <Grid item container lg={6} style={{ border: "1px solid grey", padding: "3px", direction: `${contains_heb(selectedTrack?.imports.ShipName) ? "rtl" : "ltr"}`, justifyContent: `${contains_heb(selectedTrack?.imports.ShipName) ? "flex-Start" : "flex-end"}` }}>{selectedTrack?.imports.ShipName}</Grid>
                                          <Grid item container lg={6} justifyContent="flex-end" style={{ border: "1px solid grey", padding: "3px" }}>Ship Name</Grid>
                                      </Grid>
                                      <Grid item container lg={12}>
                                          <Grid item container lg={6} style={{ border: "1px solid grey", padding: "3px", direction: `${contains_heb(selectedTrack?.imports.ShipAgentName) ? "rtl" : "ltr"}`, justifyContent: `${contains_heb(selectedTrack?.imports.ShipAgentName) ? "flex-Start" : "flex-end"}` }}>{selectedTrack?.imports.ShipAgentName}</Grid>
                                          <Grid item container lg={6} justifyContent="flex-end" style={{ border: "1px solid grey", padding: "3px" }}>Ship Agent</Grid>
                                      </Grid>
                                      <Grid item container lg={12}>
                                          <Grid item container lg={6} style={{ border: "1px solid grey", padding: "3px", direction: `${contains_heb(selectedTrack?.imports.CustomsAgentName) ? "rtl" : "ltr"}`, justifyContent: `${contains_heb(selectedTrack?.imports.CustomsAgentName) ? "flex-Start" : "flex-end"}` }}>{selectedTrack?.imports.CustomsAgentName}</Grid>
                                          <Grid item container lg={6} justifyContent="flex-end" style={{ border: "1px solid grey", padding: "3px" }}>Customs Agent</Grid>
                                      </Grid>
                                      <Grid item container lg={12}>
                                          <Grid item container lg={6} style={{ border: "1px solid grey", padding: "3px", direction: `${contains_heb(selectedTrack?.imports.TransportCompanyName) ? "rtl" : "ltr"}`, justifyContent: `${contains_heb(selectedTrack?.imports.TransportCompanyName) ? "flex-Start" : "flex-end"}` }}>{selectedTrack?.imports.TransportCompanyName}</Grid>
                                          <Grid item container lg={6} justifyContent="flex-end" style={{ border: "1px solid grey", padding: "3px" }}>Transport Company</Grid>
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={3} flexDirection="column" flexWrap="nowrap" style={{ marginBottom: "25px" }}>
                                      <Grid item container lg={12}>
                                          <Grid item container lg={6} style={{ border: "1px solid grey", padding: "3px", direction: `${contains_heb(selectedTrack?.imports.StorageLine) ? "rtl" : "ltr"}`, justifyContent: `${contains_heb(selectedTrack?.imports.StorageLine) ? "flex-Start" : "flex-end"}` }}>{selectedTrack?.imports.StorageLine}</Grid>
                                          <Grid item container lg={6} justifyContent="flex-end" style={{ border: "1px solid grey", padding: "3px" }}>Storage Line</Grid>
                                      </Grid>
                                      <Grid item container lg={12}>
                                          <Grid item container lg={6} style={{ border: "1px solid grey", padding: "3px", direction: `${contains_heb(selectedTrack?.imports.CustomsDeclaration) ? "rtl" : "ltr"}`, justifyContent: `${contains_heb(selectedTrack?.imports.CustomsDeclaration) ? "flex-Start" : "flex-end"}` }}>{selectedTrack?.imports.CustomsDeclaration}</Grid>
                                          <Grid item container lg={6} justifyContent="flex-end" style={{ border: "1px solid grey", padding: "3px" }}>Customs Declaration</Grid>
                                      </Grid>
                                      <Grid item container lg={12}>
                                          <Grid item container lg={6} style={{ border: "1px solid grey", padding: "3px", direction: `${contains_heb(selectedTrack?.imports.ArrivalTime) ? "rtl" : "ltr"}`, justifyContent: `${contains_heb(selectedTrack?.imports.ArrivalTime) ? "flex-Start" : "flex-end"}` }}>{formatDate(selectedTrack?.imports.ArrivalTime)}</Grid>
                                          <Grid item container lg={6} justifyContent="flex-end" style={{ border: "1px solid grey", padding: "3px" }}>Arrival Time</Grid>
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={3} flexDirection="column" flexWrap="nowrap">
                                      <Grid item container lg={12}>
                                          <Grid item container lg={6} style={{ border: "1px solid grey", padding: "3px", direction: `${contains_heb(selectedTrack?.imports.CargoType) ? "rtl" : "ltr"}`, justifyContent: `${contains_heb(selectedTrack?.imports.CargoType) ? "flex-Start" : "flex-end"}` }}>{selectedTrack?.imports.CargoType}</Grid>
                                          <Grid item container lg={6} justifyContent="flex-end" style={{ border: "1px solid grey", padding: "3px" }}>Cargo Type</Grid>
                                      </Grid>
                                      <Grid item container lg={12}>
                                          <Grid item container lg={6} style={{ border: "1px solid grey", padding: "3px", direction: `${contains_heb(selectedTrack?.imports.ContainerType) ? "rtl" : "ltr"}`, justifyContent: `${contains_heb(selectedTrack?.imports.ContainerType) ? "flex-Start" : "flex-end"}` }}>{selectedTrack?.imports.ContainerType}</Grid>
                                          <Grid item container lg={6} justifyContent="flex-end" style={{ border: "1px solid grey", padding: "3px" }}>Container Type</Grid>
                                      </Grid>
                                      <Grid item container lg={12}>
                                          <Grid item container lg={6} style={{ border: "1px solid grey", padding: "3px", direction: `${contains_heb(selectedTrack?.imports.ContainerLength) ? "rtl" : "ltr"}`, justifyContent: `${contains_heb(selectedTrack?.imports.ContainerLength) ? "flex-Start" : "flex-end"}` }}>{selectedTrack?.imports.ContainerLength}</Grid>
                                          <Grid item container lg={6} justifyContent="flex-end" style={{ border: "1px solid grey", padding: "3px" }}>Container Length</Grid>
                                      </Grid>
                                      <Grid item container lg={12}>
                                          <Grid item container lg={6} style={{ border: "1px solid grey", padding: "3px", direction: `${contains_heb(selectedTrack?.imports.Weight) ? "rtl" : "ltr"}`, justifyContent: `${contains_heb(selectedTrack?.imports.Weight) ? "flex-Start" : "flex-end"}` }}>{selectedTrack?.imports.Weight}</Grid>
                                          <Grid item container lg={6} justifyContent="flex-end" style={{ border: "1px solid grey", padding: "3px" }}>Weight</Grid>
                                      </Grid>
                                  </Grid>
                              </Grid>
                              <Grid item container lg={12} style={{ marginBottom: "25px", marginTop: "25px" }}>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`מצהר`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.imports.status >= 1 ? "1px white solid" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.imports.status < 1 ? "white" : "#1976d2"}`, borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px" }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.imports.ImportProcess?.ManifestDate)}
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`זמינות`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.imports.status >= 2 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.imports.status < 2 ? "white" : "#1976d2"}` }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.imports.ImportProcess?.PortUnloadingDate)}
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`פקודת מסירה`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.imports.status >= 3 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.imports.status < 3 ? "white" : "#1976d2"}` }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.imports.ImportProcess?.DeliveryOrderDate)}
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`זמינות בדיקת מכס`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.imports.status >= 4 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.imports.status < 4 ? "white" : "#1976d2"}` }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.imports.ImportProcess?.CustomsCheckResponseDepoDate)}
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {selectedTrack.shipmentType === "LCL" ? `אישור העברה` : `התרת מכס`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.imports.status >= 5 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.imports.status < 5 ? "white" : "#1976d2"}` }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.imports.ImportProcess?.CustomsReleaseDate)}
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`התרת נמל`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.imports.status >= 6 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.imports.status < 6 ? "white" : "#1976d2"}` }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.imports.ImportProcess?.PortReleaseDate)}
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`תעודת ליווי`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.imports.status >= 7 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.imports.status < 7 ? "white" : "#1976d2"}` }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.imports.ImportProcess?.EscortCertificateDate)}
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`בקשה להוצאה`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.imports.status >= 8 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.imports.status < 8 ? "white" : "#1976d2"}` }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.imports.ImportProcess?.CargoExitRequestDate)}
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`משוב מהנמל`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.imports.status >= 9 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.imports.status < 9 ? "white" : "#1976d2"}` }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.imports.ImportProcess?.CargoExitResponseDate)}
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`יציאת מטען`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.imports.status >= 10 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.imports.status < 10 ? "white" : "#1976d2"}`, borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.imports.ImportProcess?.CargoExitDate)}
                                      </Grid>
                                  </Grid>
                              </Grid>
                              {selectedTrack.lclMap && <Grid item container justifyContent="center" lg={12} style={{ marginBottom: "25px", marginTop: "25px" }}>
                                  <Grid item container justifyContent="center" > {selectedTrack.lclMap.Depo} - {`מעבר למסוף עורפי`}</Grid>
                              </Grid>}
                              {selectedTrack.lclMap && <Grid item container lg={12} style={{ marginBottom: "25px", marginTop: "25px" }}>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`כניסה למסוף`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.lclMap.lclStatus >= 1 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.lclMap.lclStatus < 1 ? "white" : "#1976d2"}`, borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px" }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.lclMap.ArrivalDepoTime)}
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`בדיקת מכס`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.lclMap.lclStatus >= 2 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.lclMap.lclStatus < 2 ? "white" : "#1976d2"}` }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          -
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`התרת מכס`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.lclMap.lclStatus >= 3 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.lclMap.lclStatus < 3 ? "white" : "#1976d2"}` }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.lclMap.CustomsReleaseDepoDate)}
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`התרת נמל`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.lclMap.lclStatus >= 4 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.lclMap.lclStatus < 4 ? "white" : "#1976d2"}` }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.lclMap.PortReleaseForDepoDate)}
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`מסר איתור`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.lclMap.lclStatus >= 5 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.lclMap.lclStatus < 5 ? "white" : "#1976d2"}` }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.lclMap.EscortCertificateDepoDate)}
                                      </Grid>
                                  </Grid>
                                  <Grid item container lg={1.2} flexDirection="column">
                                      <Grid item container alignContent="flex-end" justifyContent="center" style={{ height: "40px", textAlign: "center", direction: "rtl" }}>
                                          {`יציאה מהמסוף`}
                                      </Grid>
                                      <Grid item container style={{ border: `${selectedTrack?.lclMap.lclStatus >= 6 ? "1px solid white" : "1px solid #1976d2"}`, height: "40px", background: `${selectedTrack?.lclMap.lclStatus < 6 ? "white" : "#1976d2"}`, borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                      </Grid>
                                      <Grid item container justifyContent="center">
                                          {formatDate(selectedTrack?.lclMap.ExitDepoTime)}
                                      </Grid>
                                  </Grid>
                              </Grid>}
                          </Grid>
                      </Grid>
                  </Grid>}
                  <Grid style={{ fontSize: "1vw", fontWeight: "Bold", marginTop: "2px", width: "1400px" }} item container></Grid>
                  {(isLoaded && selectedTrack && selectedTrack.status && !selectedTrackTaskyam) ? <Grid container item xs={12} justifyContent='space-between' style={{ fontSize: "1vw", marginTop: "25px" }}>
                      <div style={{ width: '100%', height: 'calc(100vh - 150px)', maxHeight: '600px', display: 'flex', padding: '10px' }}>
                          
                              <GoogleMap ref={(ref) => { setMapRef(ref) }}
                                  center={center}
                                  zoom={6}
                                  mapContainerStyle={{ width: '100%', height: '100%' }}
                                  options={{
                                      zoomControl: true,
                                      streetViewControl: false,
                                      mapTypeControl: true,
                                      fullscreenControl: false,
                                  }}
                                  onLoad={map => {
                                      setMap(map);
                                  }}
                              >
                                  <Marker position={center} title={shipText} />
                              </GoogleMap>
                              
                      </div>
                  </Grid> : <div></div>}
              </Dialog>}
              </Grid>
          
    </Grid>
  );
};

export default MarineTracker;

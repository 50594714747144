import { createSlice } from '@reduxjs/toolkit';

export const confirmationStageSlice = createSlice({
  name: 'confirmationStage',
  initialState: {
    id: null,
    userNLId: null,
    userNLNumber: null,
    notes: null,
  },
  reducers: {
    resetConfirmationStage: state => {
      state.id = null;
      state.userNLId = null;
      state.userNLNumber = null;
      state.notes = null;
    },
    onIdChange: (state, { payload }) => {
      state.id = payload;
    },
    onUserNLIdChange: (state, { payload }) => {
      state.userNLId = payload;
    },
    onUserNLNumberChange: (state, { payload }) => {
      state.userNLNumber = payload;
    },
    onNotesChange: (state, { payload }) => {
      state.notes = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onIdChange,
  onNotesChange,
  resetConfirmationStage,
  onUserNLIdChange,
  onUserNLNumberChange,
} = confirmationStageSlice.actions;

export default confirmationStageSlice.reducer;

import { useCallback, useEffect, useState, useRef } from 'react';
import { Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import { TableHead, TableRow, Typography, Grid, Button } from '@mui/material';
import { DateTimePicker, SelectField, Dialog, Tooltip } from 'style-guide';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { USA_STATES } from 'constants/global-constants';
import { getOrders, onParcelsCountChange, onChatCountChange, onUserSpecificParcelsCountChange, onPageChange, onUnConfirmedParcelsCountChange } from 'reducers/parcel-reducers/ordersSlicer';
import {
  onConsigneeIdChange,
  onCountryOfOriginIdChange,
  onShipperIdChange,
  onShippingModeIdChange,
  onSubModeIdChange,
  onUserLocalIdChange,
  onUserAbroadIdChange,
  onIlReminderDateChange,
  onReminderDateChange,
  onOriginStateChange,
  resetOrderTableFilterSlice,
} from 'reducers/parcel-reducers/orderTableFilterSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import {addParcelFilter, addSearchFilterParcel, StyledTableCell, advancedSearchSelectedStyle, advancedSearchDefaultStyle, usePrevious} from 'Utils';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AdvancedSearchDialog from './AdvancedSearchDialog';
import SearchOffIcon from '@mui/icons-material/SearchOff';

const OrderTableHeader = ({ loginUser, setFilterSpinner, filterAtStart, setFilterAtStart, pageName }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  const onHandleChange = (event, action) => dispatch(action(event.target.value));
  const [openAdvSearchDialog, setOpenAdvSearchDialog] = useState(false);
  const onAdvSearchDialogHandleOpen = () => setOpenAdvSearchDialog(true);
  const { 
    userLocalNumber,  
    userAbroadNumber,  
    consigneeId,
    shipperId,
    countryOfOriginId, originState,
    shippingModeId,
    subModeId,
    userLocalId,
    userAbroadId,
    dueDate,
    lastUpdateDate,
    ilReminderDate,
    reminderDate,
    selectedLocationIndex,
    selectedTabIndexName,
    search,
    startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.parcelTableFilter);
  const { shipperNameOptions } = useSelector(state => state.shippers);
  const { consigneeNameOptions } = useSelector(state => state.consignees);
  const { userILNames, userNLNames, userUSNames, shippingModeData, subModeData, countryOfOriginData } = useSelector(
      state => state.parcelPredefinedData,
  );
    const { page, size, chatCount, userSpecificParcelsCount, unConfirmedParcelsCount } = useSelector(state => state.parcels);
    const prevPage = usePrevious({page});
  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
  const dataRefreshInterval = useRef(null);
  useEffect(() => {
      setFilterSpinner(true);
      sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
          successCallback: response => { dispatch(onParcelsCountChange(response?.parcelsCount));  sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount));sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount));dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount));},
        failedCallback,
      });
      // filtered orders get
      sendRequest(
        `/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}&page=${prevPage?.page == page && addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
        REQUEST_ACTIONS.GET,
        '',
        {
          successCallback: response => {
            dispatch(getOrders(response));
            setFilterSpinner(false);
            if (prevPage?.page == page && addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                  dispatch(onPageChange(0));
                }
          },
          failedCallback,
        },
      );
  }, [
    consigneeId,
    shipperId,
    countryOfOriginId, originState,
    shippingModeId,
    subModeId,
    userLocalId,
    userAbroadId,
    dueDate,
    lastUpdateDate,
    ilReminderDate,
    reminderDate,
    selectedLocationIndex,
    selectedTabIndexName,
    page,
    size,
    setFilterSpinner,
    dispatch,
    failedCallback,
        startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv,
  ]);

  useEffect(() => {
    return () => {
      clearInterval(dataRefreshInterval.current);
      dataRefreshInterval.current = null;
    }
  }, []);
  
  useEffect(() => {
    if (dataRefreshInterval.current) {
      clearInterval(dataRefreshInterval.current);
      dataRefreshInterval.current = null;
    }
    var interval = setInterval(() => {
      if (!search || search?.length == 0 ||  search?.length >= 3) {
          sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
          successCallback: response => {
                  dispatch(onParcelsCountChange(response?.parcelsCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount));dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount));},
          failedCallback,
        });
        // filtered orders get
        sendRequest(
          `/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}&page=${prevPage?.page == page && addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
          REQUEST_ACTIONS.GET,
          '',
          {
            successCallback: response => {
              dispatch(getOrders(response));
              if (prevPage?.page == page && addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                  dispatch(onPageChange(0));
                }
            },
            failedCallback,
          },
        );
      }
    }, 40000);
    dataRefreshInterval.current = interval;
  }, [
    consigneeId,
    shipperId,
    countryOfOriginId, originState,
    shippingModeId,
    subModeId,
    userLocalId,
    userAbroadId,
    dueDate,
    lastUpdateDate,
    ilReminderDate,
    reminderDate,
    selectedLocationIndex,
    selectedTabIndexName,
    search,
    page,
    size,
    setFilterSpinner,
    dispatch,
    chatCount,
    userSpecificParcelsCount,
    unConfirmedParcelsCount,
    startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv,
    failedCallback,
  ]);

  return (
    <TableHead>
          <TableRow className="header">
        {/*<StyledTableCell />*/}
        {/*<StyledTableCell sx={{padding: "16px 0px 16px 0px", pr: 0, minWidth: "5.5rem", maxWidth: "5.9rem" }}>
          <SelectField
            sm={null}
            md={null}
            lg={null}
            label={<Typography fontSize="0.8vw" fontWeight='bold'>User IL</Typography>}
            value={userLocalId}
            onChange={event => onHandleChange(event, onUserLocalIdChange)}
            options={[{ id: null, name: 'Reset' }, ...userILNames]}
          />
        </StyledTableCell>*/}
        {(loginUser.location.code === 'US' || selectedLocationIndex == 1) ?
                  <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, minWidth: "6vw" }}>
                    <SelectField
            sm={null}
            md={null}
            lg={null}
            label={<Typography fontSize="0.8vw" fontWeight='bold'>User US</Typography>}
            value={userAbroadId}
            onChange={event => onHandleChange(event, onUserAbroadIdChange)}
            options={[{ id: null, name: 'Reset' }, ...userUSNames]}
          />
        </StyledTableCell> :
                  <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, minWidth: "6vw" }}>
          <SelectField
            sm={null}
            md={null}
            lg={null}
            label={<Typography fontSize="0.8vw" fontWeight='bold'>User NL</Typography>}
            value={userAbroadId}
            onChange={event => onHandleChange(event, onUserAbroadIdChange)}
            options={[{ id: null, name: 'Reset' }, ...userNLNames]}
          />
        </StyledTableCell>
        }
              {/*<StyledTableCell sx={{padding: "11px", pl: 0, pr: 0, minWidth: "4.5rem", maxWidth: "4.9rem" }}>
          <SelectField
            sm={null}
            md={null}
            lg={null}
            label={<Typography fontSize="0.8vw" fontWeight='bold'>Type</Typography>}
            value={shippingModeId}
            onChange={event => onHandleChange(event, onShippingModeIdChange)}
            options={[{ id: null, name: 'Reset' }, ...shippingModeData]}
          />
        </StyledTableCell>*/}
              {/*  <StyledTableCell sx={{padding: "11px", pl: 0, pr: 0, minWidth: "6.5rem", maxWidth: "6.9rem" }}>
        <SelectField
            sm={null}
            md={null}
            lg={null}
            label={<Typography fontSize="0.8vw" fontWeight='bold'>Sub Mode</Typography>}
            value={subModeId}
            onChange={event => onHandleChange(event, onSubModeIdChange)}
            options={[{ id: null, name: 'Reset' }, ...subModeData]}
          />
        </StyledTableCell>*/}
              <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, minWidth: "5.5vw" }}>
                {(loginUser.location.code === 'US' || selectedLocationIndex == 1) ?
                (<SelectField
                    sm={null}
                    md={null}
                    lg={null}
                    label={<Typography fontSize="0.8vw" fontWeight='bold'>Origin</Typography>}
                    value={originState}
                    onChange={event => onHandleChange(event, onOriginStateChange)}
                    options={[{ id: null, name: 'Reset' }, ...USA_STATES]}
                  />) :
                (<SelectField
                  sm={null}
                  md={null}
                  lg={null}
                  label={<Typography fontSize="0.8vw" fontWeight='bold'>Origin</Typography>}
                  value={countryOfOriginId}
                  onChange={event => onHandleChange(event, onCountryOfOriginIdChange)}
                  options={[{ id: null, name: 'Reset' }, ...countryOfOriginData]}
                />)}
        </StyledTableCell>
              <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, minWidth: "6vw" }}>
          <SelectField
            sm={null}
            md={null}
            lg={null}
            label={<Typography fontSize="0.8vw" fontWeight='bold'>Shipper</Typography>}
            value={shipperId}
            onChange={event => onHandleChange(event, onShipperIdChange)}
            options={[{ id: null, name: 'Reset' }, ...shipperNameOptions]}
          />
        </StyledTableCell>
              <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, minWidth: "6vw" }}>
          <SelectField
            sm={null}
            md={null}
            lg={null}
            label={<Typography fontSize="0.8vw" fontWeight='bold'>Consignee</Typography>}
            value={consigneeId}
            onChange={event => onHandleChange(event, onConsigneeIdChange)}
            options={[{ id: null, name: 'Reset' }, ...consigneeNameOptions]}
          />
              </StyledTableCell>
              
              <StyledTableCell sx={{ padding: "11px", pl: 0, pr: 0, minWidth: "40vw" }} align='center' colSpan="8"  className="headerDate">
                <Grid lg={12} container item justifyContent='flex-start' flexDirection='row' alignItems='center' className="padding_0" style={{flexFlow:'nowrap'}}>
                
                  <Grid container item lg={4} align='left'>
           { loginUser?.location?.code === 'IL' ? 
            <DateTimePicker
                sm={null}
                md={null}
                label='Reminder'
                boldLabel={<Typography fontSize="0.8vw" fontWeight='bold'>Reminder</Typography>}
                value={ilReminderDate}
                isShowTime={false} 
                format="dd/MM/yyyy"
                onChange={date => dispatch(onIlReminderDateChange(date?.toISOString()))}
              />
          :
            <DateTimePicker
            sm={null}
            md={null}
            label='Reminder'
            boldLabel={<Typography fontSize="0.8vw" fontWeight='bold'>Reminder</Typography>}
            value={reminderDate}
            isShowTime={false} 
            format="dd/MM/yyyy"
            onChange={date => dispatch(onReminderDateChange(date?.toISOString()))}
          /> 
                  }
                  </Grid>

                    <Grid container item lg={2} justifyContent='flex-start'>
                        <Tooltip title='Reminder Search'><ManageSearchIcon onClick={onAdvSearchDialogHandleOpen} style={(startReminderDate != null || endReminderDate != null || startIlReminderDate != null || endIlReminderDate != null || reminderDateAdv != null || ilReminderDateAdv != null)?advancedSearchSelectedStyle:advancedSearchDefaultStyle}/></Tooltip> <Tooltip title='Reset Search'><SearchOffIcon style={advancedSearchDefaultStyle}  fontSize='medium' onClick={() => dispatch(resetOrderTableFilterSlice(null))}/></Tooltip>
                        {openAdvSearchDialog && (
                              <AdvancedSearchDialog openDialog={openAdvSearchDialog} setOpenDialog={setOpenAdvSearchDialog} loginUser={loginUser} pageName={pageName} />
                      )}</Grid>
                      {(!(loginUser?.authorities && loginUser?.authorities[0]?.authority === 'User' && loginUser?.location?.code === 'IL') && window.location.pathname != '/dhome' && window.location.pathname != '/dropHistory' )&&
                        <Grid container item lg={2} justifyContent='flex-end'>
                          <Button color='secondary' variant='contained' sx={{ m: 0, p: 0, margin: "3px 7px 8px 3px", fontSize: "1vw !important", height: "2.1vw", width: "11vw" }} onClick={() => {
                            dispatch(getOrders([]));
                            dispatch(onPageChange(0));
                            // navigate("/home", { state: { name: "location", locInd: selectedLocationIndex } })
                            window.setTimeout(function () {
                                navigate("/manifest", { state: { name: "location", locInd: selectedLocationIndex } })
                            }, 500)
                              }}>
                          Manifest
                          </Button>
                        </Grid> }
                      {(window.location.pathname != '/dhome' && window.location.pathname != '/dropHistory')&& <Grid container item lg={4} justifyContent='flex-start'>
                          <Button color='secondary' variant='contained' sx={{ m: 0, p: 0, margin: "3px 7px 8px 3px", fontSize: "1vw !important", height: "2.1vw", width: "11vw" }} onClick={() => {
                              dispatch(getOrders([]));
                              dispatch(onPageChange(0));
                              // navigate("/home", { state: { name: "location", locInd: selectedLocationIndex } })
                              window.setTimeout(function () {
                                  navigate("/manifestHistory", { state: { name: "location", locInd: selectedLocationIndex } })
                              }, 500)
                          }}>
                              Manifest History
                          </Button>
                      </Grid>
                      }
                      </Grid>
              </StyledTableCell>
              
              <StyledTableCell sx={{ padding: "3px", minWidth: "5.8rem", maxWidth: "5.9rem" }} align='center' colSpan="1">
          {<Typography fontSize="0.8vw" fontWeight='bold'>OPERATIONS</Typography>}
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

export default OrderTableHeader;

import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.caption,
  padding: theme.spacing(1),
}));

const CustomerDepartureDetail = ({ detail, status }) => {
  return (
    <Grid container item xs={12} justifyContent='space-between' flexDirection='column'>
       <Typography style={{marginLeft:'10px'}} fontSize="0.8rem" variant='body1' component='span'>Status: {status}</Typography>
       <Typography style={{marginLeft:'10px'}} fontSize="0.8rem" variant='body1' component='span'>ETD: {new Intl.DateTimeFormat('en-GB', {
                                                                                      year: 'numeric',
                                                                                      month: '2-digit',
                                                                                      day: '2-digit',
                                                                                      hour: '2-digit',
                                                                                      minute: '2-digit',
                                                                                      second: '2-digit',
                                                                                    }).format(detail.etd)}</Typography>
       <Typography style={{marginLeft:'10px'}} fontSize="0.8rem" variant='body1' component='span'>ETA: {new Intl.DateTimeFormat('en-GB', {
                                                                                      year: 'numeric',
                                                                                      month: '2-digit',
                                                                                      day: '2-digit',
                                                                                      hour: '2-digit',
                                                                                      minute: '2-digit',
                                                                                      second: '2-digit',
                                                                                    }).format(detail.eta)}</Typography>
    </Grid>
  );
};

export default CustomerDepartureDetail;

import { useCallback, useEffect, useState } from 'react';

import { Box, Typography, Grid, Button, Divider, Checkbox, FormControlLabel } from '@mui/material';
import { Dialog, DateTimePicker, ParcelDropzone, MultilineField, ParcelFilesRender, SelectField, TextField } from 'style-guide';

import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import AirDetails from 'components/Parcel/OrderDetails/AirDetails';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from 'reducers/parcel-reducers/ordersSlicer';
import {
    onFilesChange,
    onCutOffTimeChange,
    onEtaTimeChange,
    onEtdTimeChange,
    onReceivedTimeChange,
    resetWhStage,
    onNotesChange,
    onWhCityChange,
    onWhBuildingChange,
    onWhStreetChange,
    onWhPhoneNumberChange,
    onWhCostChange,
    onWhSelectedCurrencyChange,
    onNcodeChange,
    onIdChange,
    onArrivedChange,
    onPartialShipmentChange,
    onFilesAdd,
    onEtaChange,
} from 'reducers/parcel-stage-reducers/whStageSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { addParcelFilter, addSearchFilterParcel, addStatusChatMessage, getStatusChatMessage } from 'Utils';
const currencies = [{ id: 1, name: 'Dollar' }, { id: 2, name: 'Euro' }];
const WhDialog = ({ loginUser, order, openDialog, setOpenDialog, pageName }) => {
    const dispatch = useDispatch();
    const initialState = {
        parcelDetails: [],
        numberOfPieces: null,
        weightPerPiece: null,
        width: null,
        height: null,
        length: null,
        manualVolume: null,
        manualWeight: null,
        totalweight: null,
        totalvolume: null,
    };
    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const [state, setState] = useState(initialState);
    const onStateChange = (field, value) => setState(state => ({ ...state, [field]: value }));
    const onHandleChange = (event, action) => dispatch(action(event.target.value));
    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        selectedTabIndexName,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.parcelTableFilter);
    const { page, size } = useSelector(state => state.parcels);
    const { id, files, receivedTime, cutOffTime, etdTime, etaTime, eta, arrived, partialShipment, notes, whCity, whBuilding, whStreet, whPhoneNumber, whCost, whSelectedCurrency, ncode } = useSelector(
        state => state.parcelWhStage,
    );

    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const currentStage = order.parcelStage[3];

    const onHandleCloseModal = () => {
        dispatch(resetWhStage());
        setOpenDialog(false);
    };

    useEffect(() => {
        // predefined user abroad data get
        if (!nlUsers || nlUsers == undefined) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setNlUsers(response); },
                failedCallback,
            });
        }
    }, [nlUsers, failedCallback, setNlUsers]);

    useEffect(() => {
        // predefined user abroad data get
        if (!usUsers || usUsers == undefined) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setUSUsers(response); },
                failedCallback,
            });
        }
    }, [usUsers, failedCallback, setUSUsers]);

    useEffect(() => {
        // predefined user local data get
        if (!ilUsers || ilUsers == undefined) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setIlUsers(response); },
                failedCallback,
            });
        }
    }, [ilUsers, failedCallback, setIlUsers]);

    useEffect(() => {
        let whStage = [];
        if (currentStage.parcelwhStage) {
            whStage = currentStage.parcelwhStage[0];
        }

        dispatch(onFilesChange(order?.parcelStage[0]?.archives));
        dispatch(onNotesChange(currentStage.notes));

        if (whStage?.id) {
            dispatch(onIdChange(whStage.id));
            dispatch(onArrivedChange(whStage.arrived));
            dispatch(onPartialShipmentChange(whStage.partialShipment));
            dispatch(onCutOffTimeChange(whStage.cutOffTime));
            dispatch(onEtaTimeChange(whStage.etaTime));
            dispatch(onEtdTimeChange(whStage.etdTime));
            dispatch(onEtaChange(whStage.eta));
            dispatch(onReceivedTimeChange(whStage.receivedTime));
            dispatch(onWhCityChange(whStage.whCity));
            dispatch(onWhBuildingChange(whStage.whBuilding));
            dispatch(onWhStreetChange(whStage.whStreet));
            dispatch(onWhPhoneNumberChange(whStage.whPhoneNumber));
            dispatch(onWhCostChange(whStage.whCost));
            dispatch(onWhSelectedCurrencyChange(whStage.whSelectedCurrency));
            dispatch(onNcodeChange(whStage.ncode));
        }
        if (order?.id) {
            setState(state => ({
                ...state,
                ...order,
            }));
        }
    }, [dispatch, order?.id, setState, order?.parcelStage[3]]);

    const onStageHandleSave = id => {
        const {
            numberOfPieces,
            weightPerPiece,
            width,
            height,
            length,
            totalweight,
            totalvolume,
        } = state;

        let parcelDetails = [];

        // shipping mode Air
        const isAddDetails = totalweight || totalvolume || numberOfPieces || weightPerPiece || width || height || length;

        // details without id
        const detailsWithoutId = state.parcelDetails.map(item => {
            if (typeof item.id === 'string') {
                return {
                    numberOfPieces: item.numberOfPieces,
                    length: item.length,
                    width: item.width,
                    height: item.height,
                    weightPerPiece: item.weightPerPiece,
                    manualWeight: null,
                    manualVolume: null,
                    totalweight: item.totalweight,
                    totalvolume: item.totalvolume,
                };
            }
            return item;
        });

        if (isAddDetails) {
            parcelDetails = [
                ...detailsWithoutId,
                {
                    numberOfPieces,
                    length,
                    width,
                    height,
                    weightPerPiece,
                    manualWeight: null,
                    manualVolume: null,
                    totalweight,
                    totalvolume,
                },
            ];
        } else {
            parcelDetails = detailsWithoutId;
        }

        const parcelwhStage = [
            {
                arrived,
                partialShipment,
                receivedTime,
                cutOffTime,
                etdTime,
                etaTime: etaTime || order.parcelStage[2].parcelTruckingStage[0]?.eta,
                eta: eta || order.parcelStage[2].parcelTruckingStage[0]?.eta,
                whCity,
                whBuilding,
                whStreet,
                whPhoneNumber,
                whCost,
                whSelectedCurrency,
                ncode,
            },
        ];
        if (!parcelwhStage[0].arrived) {
            dispatch(snackbarToggle({ type: 'error', message: 'Arived is not checked!' }));
            return;
        }

        if (id) {
            parcelwhStage[0].id = id;
        }

        const newOrder = {
            ...order,
            parcelDetails,
            parcelStage: [
                order.parcelStage[0],
                order.parcelStage[1],
                order.parcelStage[2],
                {
                    ...currentStage,
                    notes,
                    parcelwhStage,
                },
                order.parcelStage[4],
            ],
        };

        /*if (order?.parcelStage[3]?.parcelwhStage[0]?.arrived != newOrder?.parcelStage[3]?.parcelwhStage[0]?.arrived) {
            newOrder.chat = addStatusChatMessage(order, 'WH', 'Arrived', ilUsers, nlUsers, usUsers, loginUser);
        }*/
        sendRequest(`/api/parcel?fromStage=4&loginUserId=` + loginUser?.id + `&actionOn=WhDialog`, REQUEST_ACTIONS.PUT, newOrder, {
            successCallback: () => {

                sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));

                        if (order?.parcelStage[3]?.parcelwhStage[0]?.arrived != newOrder?.parcelStage[3]?.parcelwhStage[0]?.arrived) {
                            //newOrder.chat = addStatusChatMessage(order, 'WH', 'Arrived', ilUsers, nlUsers, usUsers, loginUser);
                            var chatString = getStatusChatMessage(order, 'WH', 'Arrived', ilUsers, nlUsers, usUsers, loginUser);
                            //console.log(chatString)
                            onUpdateChat(order.id, chatString, 4)
                        } else {
                            dispatch(getOrders(response));
                            onHandleCloseModal();
                        }
                    },
                    failedCallback,
                });

                
            },
            failedCallback,
        });
    };
    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/parcel/updateChat/${id}?fromStage=${fromStage}`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {

                        dispatch(getOrders(response));
                        onHandleCloseModal();
                    },
                    failedCallback,
                });
            },
            failedCallback,
        });
    };
    return (
        <Dialog maxWidth='md' open={openDialog} onHandleCancel={onHandleCloseModal} title={'W/H - ' + order?.jobNumber}>
            <Box component='form' noValidate autoComplete='off'>
                <Grid container spacing={2}>
                    <Grid container item spacing={2}>
                        <Grid container item spacing={2}>
                            <DateTimePicker
                                label='ETA W/H'
                                value={etaTime || order.parcelStage[2].parcelTruckingStage[0]?.eta}
                                onChange={date => dispatch(onEtaTimeChange(date?.toISOString()))}
                            />
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={arrived} onChange={event => dispatch(onArrivedChange(event.target.checked))} />
                                    }
                                    label='Arrived'
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={partialShipment} onChange={event => dispatch(onPartialShipmentChange(event.target.checked))} />
                                    }
                                    label='Partial Shipment'
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <DateTimePicker
                                disabled={!arrived}
                                label='Received time'
                                value={receivedTime}
                                onChange={date => dispatch(onReceivedTimeChange(date?.toISOString()))}
                            />
                        </Grid>
                        <Grid container item spacing={2}>
                            <TextField
                                lg={null}
                                label='City'
                                value={whCity}
                                onChange={event => onHandleChange(event, onWhCityChange)}
                            />
                            <TextField
                                lg={null}
                                type='number'
                                label='Building'
                                value={whBuilding}
                                onChange={event => onHandleChange(event, onWhBuildingChange)}
                            />
                            <TextField
                                lg={null}
                                label='Street'
                                value={whStreet}
                                onChange={event => onHandleChange(event, onWhStreetChange)}
                            />
                            <TextField
                                lg={null}
                                type='number'
                                label='Zip'
                                value={ncode}
                                onChange={event => onHandleChange(event, onNcodeChange)}
                            />
                            <TextField
                                lg={null}
                                label='Phone Number'
                                value={whPhoneNumber}
                                onChange={event => onHandleChange(event, onWhPhoneNumberChange)}
                            />
                        </Grid>
                        <Grid container item spacing={2}>
                            <TextField
                                lg={null}
                                type='number'
                                label='Cost'
                                value={whCost}
                                onChange={event => onHandleChange(event, onWhCostChange)}
                            />
                            <SelectField
                                lg={null}
                                label='Selected Currency'
                                options={currencies}
                                value={whSelectedCurrency}
                                onChange={event => onHandleChange(event, onWhSelectedCurrencyChange)}
                            />
                        </Grid>
                        {/*<DateTimePicker
              required
              label='Cut Off time'
              value={cutOffTime}
              onChange={date => dispatch(onCutOffTimeChange(date?.toISOString()))}
            />
            <DateTimePicker
              required
              label='ETD'
              value={etdTime}
              onChange={date => dispatch(onEtdTimeChange(date?.toISOString()))}
            />
            <DateTimePicker
              required
              label='ETA'
              value={eta || order.parcelStage[2].parcelTruckingStage[0]?.eta}
              onChange={date => dispatch(onEtaChange(date?.toISOString()))}
            />*/}
                        <MultilineField
                            xs={12}
                            sm={null}
                            md={null}
                            lg={null}
                            label='Notes'
                            value={notes}
                            onChange={event => onHandleChange(event, onNotesChange)}
                        />
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography variant='h6'>Attachments</Typography>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <ParcelDropzone loginUser={loginUser} orderStageId={order?.parcelStage[0]?.id} orderId={order.id} onFilesAdd={onFilesAdd} pageName={pageName} onHandleCloseParentModal={onHandleCloseModal} />
                            </Grid>
                            <ParcelFilesRender loginUser={loginUser} files={files} orderId={order.id} />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                        <AirDetails state={state} onStateChange={onStateChange} />
                    </Grid>
                    <Grid container item xs={12} justifyContent='flex-end'>
                        <Button
                            variant='contained'
                            sx={{ mr: 2 }}
                            onClick={id ? () => onStageHandleSave(id) : () => onStageHandleSave(null)}>
                            Save
                        </Button>
                        <Button variant='contained' color='secondary' onClick={onHandleCloseModal}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default WhDialog;
import { Fragment, useCallback, useEffect, useState, useRef } from 'react';

import { Box, Grid, Button, CircularProgress } from '@mui/material';
import { MultilineField, TextField } from 'style-guide';
import { sendRequest, REQUEST_ACTIONS } from 'utils/Communicator';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { getOrders, onParcelsCountChange, onChatCountChange, onUserSpecificParcelsCountChange, onPageChange, onUnConfirmedParcelsCountChange } from 'reducers/parcel-reducers/ordersSlicer';
import { addParcelFilter, addSearchFilterParcel, usePrevious } from 'Utils';

const ilChatNameStyle = { color: 'blue' };
const nlChatNameStyle = { color: 'orange' };
const adChatNameStyle = { color: 'black' };

const ChatDialog = ({ onHandleCancel, order, loginUser, pageName }) => {

    const [state, setState] = useState();
    const [chatText, setChatText] = useState("");
    const [chatList, setChatList] = useState(JSON.parse(order['chat'] ? order['chat'] : '[]'));
    const [isLoaded, setIsLoaded] = useState(false);
    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const [ilUsersLoaded, setIlUsersLoaded] = useState(false);
    const [nlUsersLoaded, setNlUsersLoaded] = useState(false);
    const [usUsersLoaded, setUSUsersLoaded] = useState(false);
    const [updatedChatView, setUpdatedChatView] = useState(false);
    const [varInterval, setVarInterval] = useState();
    const { page, size, chatCount, userSpecificParcelsCount } = useSelector(state => state.parcels);
    const prevPage = usePrevious({ page });
    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        selectedTabIndexName,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.parcelTableFilter);
    //var messagesEnd;

    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const failedCallback1 = useCallback(message => { }, [dispatch]);
    const mounted = useRef(false);

    const updateSeen = () => {
        if (chatList?.length != 0 && ilUsersLoaded && nlUsersLoaded && usUsersLoaded && !updatedChatView) {
            var userIL = order.userILId;
            var userNL = order.parcelStage[0].parcelConfirmationStage[0]?.userNLId;
            let o1 = { ...order }
            if (!order['chat'] || order['chat'] == undefined)
                return;
            var chat_list = JSON.parse(order['chat']);
            var len = chat_list.length;
            var lengthIL = ilUsers.length;
            var lengthNL = nlUsers.length;
            var lengthUS = usUsers.length;
            var changeMade = false;
            var exists = false;
            for (var i = 0; i < lengthIL; i++) {
                if (ilUsers[i].fullName == loginUser.fullName && userIL == ilUsers[i].id) {
                    for (var j = 0; j < len; j++) {
                        if (!chat_list[j]['ILStatus']) {
                            changeMade = true;
                            chat_list[j]['ILStatus'] = true;
                        }
                    }
                }
            }
            if (!exists) {
                for (var i = 0; i < lengthNL; i++) {
                    if (nlUsers[i].fullName == loginUser.fullName && userNL == nlUsers[i].id) {
                        for (var j = 0; j < len; j++) {
                            if (!chat_list[j]['NLStatus']) {
                                changeMade = true;
                                chat_list[j]['NLStatus'] = true;
                            }
                        }
                    }
                }
                for (var i = 0; i < lengthUS; i++) {
                    if (usUsers[i].fullName == loginUser.fullName && userNL == usUsers[i].id) {
                        for (var j = 0; j < len; j++) {
                            if (!chat_list[j]['NLStatus']) {
                                changeMade = true;
                                chat_list[j]['NLStatus'] = true;
                            }
                        }
                    }
                }
            }
            if (changeMade) {
                o1.chat = JSON.stringify(chat_list)
                sendRequest('/api/parcel?fromStage=0&loginUserId=' + loginUser?.id + '&actionOn=ChatSeen', REQUEST_ACTIONS.POST, o1, {
                    successCallback: response => {
                        setChatListFx1(JSON.parse(o1.chat));
                        sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                            successCallback: response => {
                                dispatch(onParcelsCountChange(response?.parcelsCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount)); dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount));
                            },
                            failedCallback,
                        });

                        sendRequest(
                            `/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}&page=${prevPage?.page == page && addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
                            REQUEST_ACTIONS.GET,
                            '',
                            {
                                successCallback: response => {
                                    dispatch(getOrders(response));
                                    if (prevPage?.page == page && addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                                        dispatch(onPageChange(0));
                                    }
                                },
                                failedCallback,
                            },
                        );
                    },
                    failedCallback,
                });
            }
        }
    };

    const setChatListFx = (chat) => {
        setChatList(chat);
        updateSeen();
    }

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    useEffect(() => {
        if (!isLoaded) {
            setVarInterval(setInterval(() => {
                sendRequest('/api/parcel/' + order['id'], REQUEST_ACTIONS.GET, '', {
                    successCallback: response => { setChatListFx(JSON.parse(response['chat'])); },
                    failedCallback1,
                });
            }, 15000));
            setIsLoaded(true);
        }
        return () => {
            if (!mounted.current) {
                clearInterval(varInterval);
            }
        }
    }, [chatList, setChatList, order, updateSeen,]);

    useEffect(() => {
        // predefined user abroad data get
        if (!nlUsers || nlUsers == undefined) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setNlUsers(response); setNlUsersLoaded(true) },
                failedCallback,
            });
        }
    }, [nlUsers, failedCallback, setNlUsers]);

    useEffect(() => {
        // predefined user abroad data get
        if (!usUsers || usUsers == undefined) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setUSUsers(response); setUSUsersLoaded(true) },
                failedCallback,
            });
        }
    }, [usUsers, failedCallback, setUSUsers]);

    useEffect(() => {
        // predefined user local data get
        if (!ilUsers || ilUsers == undefined) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setIlUsers(response); setIlUsersLoaded(true) },
                failedCallback,
            });
        }
    }, [ilUsers, failedCallback, setIlUsers]);




    useEffect(() => {
        // for construction
        if (chatList?.length != 0 && ilUsersLoaded && nlUsersLoaded && usUsersLoaded && !updatedChatView) {
            updateSeen()
            setUpdatedChatView(true);
        }
    }, [setUpdatedChatView, updatedChatView, updateSeen, chatList, ilUsersLoaded, nlUsersLoaded, usUsersLoaded]);


    const setChatListFx1 = (chat) => {
        setChatList(chat);
        setChatText("");
        setUpdatedChatView(true);
    }

    const onHandleSendChat = (statusChatButton) => {
        if (order.parcelStage[0].parcelConfirmationStage.length == 0) {
            //dispatch(snackbarToggle({ type: 'error', message: 'Order Conformation should be completed before chat can ne used.' }))
            // return;
        }
        if (chatText == "") {
            dispatch(snackbarToggle({ type: 'error', message: 'Text is empty.' }))
            return;
        }
        var userIL = order.userILId;
        var userNL = order.parcelStage[0].parcelConfirmationStage[0]?.userNLId;
        var lengthIL = ilUsers.length;
        var lengthNL = nlUsers.length;
        var lengthUS = usUsers.length;
        var exists = false;
        var chat = {
            'time': '',
            'text': chatText,
            'initiator': '',
            'initiatorType': '',
            'location': '',
            'ILStatus': false,
            'NLStatus': false
        }
        let o1 = { ...order }

        var chat_list = [];
        if (order.chat) {
            chat_list = JSON.parse(order.chat);
        }
        for (var i = 0; i < lengthIL; i++) {
            if (ilUsers[i].fullName == loginUser.fullName) {
                var date = new Date();
                chat['time'] = date.toUTCString();
                chat['initiator'] = loginUser.fullName;
                chat['initiatorType'] = 1;
                chat['location'] = 'IL';
                if (userIL == ilUsers[i].id) {
                    chat['ILStatus'] = true;
                    chat['initiatorType'] = 2;
                    for (var j = 0; j < chat_list.length; j++) {
                        if (!chat_list[j]['ILStatus']) {
                            chat_list[j]['ILStatus'] = true;
                        }
                    }
                }
                else if (ilUsers[i].roleId == 1)
                    chat['ILStatus'] = false;
                exists = true;
                break;
            }
        }
        if (!exists) {
            for (var i = 0; i < lengthNL; i++) {
                if (nlUsers[i].fullName == loginUser.fullName) {
                    var date = new Date();
                    chat['time'] = date.toUTCString();
                    chat['initiator'] = loginUser.fullName;
                    chat['initiatorType'] = 1;
                    chat['location'] = 'NL';
                    if (userNL == nlUsers[i].id) {
                        chat['NLStatus'] = true;
                        chat['initiatorType'] = 3;
                        for (var j = 0; j < chat_list.length; j++) {
                            if (!chat_list[j]['NLStatus']) {
                                chat_list[j]['NLStatus'] = true;
                            }
                        }
                    }
                    else if (nlUsers[i].roleId == 1)
                        chat['NLStatus'] = false;
                    exists = true;
                    break;
                }
            }
            for (var i = 0; i < lengthUS; i++) {
                if (usUsers[i].fullName == loginUser.fullName) {
                    var date = new Date();
                    chat['time'] = date.toUTCString();
                    chat['initiator'] = loginUser.fullName;
                    chat['initiatorType'] = 1;
                    chat['location'] = 'US';
                    if (userNL == usUsers[i].id) {
                        chat['USStatus'] = true;
                        chat['initiatorType'] = 3;
                        for (var j = 0; j < chat_list.length; j++) {
                            if (!chat_list[j]['NLStatus']) {
                                chat_list[j]['NLStatus'] = true;
                            }
                        }
                    }
                    else if (usUsers[i].roleId == 1)
                        chat['NLStatus'] = false;
                    exists = true;
                    break;
                }
            }
        }
        if (!order.chat) {
            chat_list[0] = chat;
            //o1.chat = JSON.stringify(chat_list);
        }
        else {
            chat_list.push(chat);
            //o1.chat = JSON.stringify(chat_list);
        }
        var chatString = JSON.stringify(chat) //getStatusChatMessage(order, 'Departure', departureStatuses.find(status => status.id === newOrder?.orderStage[4]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
        //console.log(chatString)

        onUpdateChat(order.id, chatString, 0)
        return
        sendRequest('/api/parcel?fromStage=0&loginUserId=' + loginUser?.id + '&actionOn=ChatDialog', REQUEST_ACTIONS.POST, o1, {
            successCallback: response => {
               // setChatListFx2(JSON.parse(o1.chat));
                var chatString = JSON.stringify(chat) //getStatusChatMessage(order, 'Departure', departureStatuses.find(status => status.id === newOrder?.orderStage[4]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
                //console.log(chatString)

                onUpdateChat(order.id, chatString, 0)

                /*sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => { dispatch(onParcelsCountChange(response?.parcelsCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount)); dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount)); },
                    failedCallback,
                });*/
                sendRequest(
                    `/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}&page=${prevPage?.page == page && addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
                    REQUEST_ACTIONS.GET,
                    '',
                    {
                        successCallback: response => {
                            dispatch(getOrders(response));
                            if (prevPage?.page == page && addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                                dispatch(onPageChange(0));
                            }
                        },
                        failedCallback,
                    },
                );
            },
            failedCallback,
        });
    }
    const onHandlechangeChat = (value) => {
        setChatText(value);
    }

    const setChatListFx2 = (chat) => {
        setChatList(chat);
        setChatText("");
    }

    const getInitiatorStyle = (ch) => {
        if (ch['location']) {
            if (ch['location'] == 'IL')
                return ilChatNameStyle;
            if (ch['location'] == 'NL')
                return nlChatNameStyle;
        }
        var type = ch['initiatorType']
        if (type == 1)
            return adChatNameStyle;
        if (type == 2)
            return ilChatNameStyle;
        if (type == 3)
            return nlChatNameStyle;
    }

    /*  const enterKeyPressed = (e) => {
        if(e.keyCode == 13) {
          e.preventDefault();
          onHandleSendChat(11);
        }
      } */

    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/parcel/updateChat/${id}?fromStage=${fromStage}&loginUserId=${loginUser?.id}&actionOn=ChatDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                setChatListFx2(JSON.parse(JSON.stringify([...chatList, JSON.parse(chatString)])));

                sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => { dispatch(onParcelsCountChange(response?.parcelsCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount)); dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount)); },
                    failedCallback,
                });
                sendRequest(
                    `/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}&page=${prevPage?.page == page && addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
                    REQUEST_ACTIONS.GET,
                    '',
                    {
                        successCallback: response => {
                            dispatch(getOrders(response));
                            if (prevPage?.page == page && addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                                dispatch(onPageChange(0));
                            }
                        },
                        failedCallback,
                    },
                );
            },
            failedCallback,
        });
    };
    return (
        <Box component='form' noValidate autoComplete='off'>
            <Grid container spacing={2} position='relative'>

                <Fragment>
                    <div style={{ height: '60vh', width: '900px', overflowY: 'auto', border: '1px solid black', display: 'flex', flexDirection: 'column-reverse' }}>
                        <div style={{ height: 'auto', width: '100%' }}>
                            {chatList?.map(ch => (
                                <div style={{ width: '100%', padding: '5px' }}> <span style={{ color: 'grey' }}>{(new Date(ch['time'])).toLocaleString()}</span> <span style={getInitiatorStyle(ch)}>{ch['initiator']}</span><span style={{ color: 'black' }}> : {ch['text']}</span></div>
                            ))}
                        </div>
                        <div style={{ float: "left", clear: "both" }} id="bottomOfScreen"></div>
                    </div>
                    <Grid container item justifyContent='space-between'>
                        <MultilineField
                            sm={10}
                            md={null}
                            lg={null} style={{ width: '100%' }} id="chatText" label="Chat" variant="outlined" type="text" value={chatText} onChange={(event) => onHandlechangeChat(event.target.value)}
                        />
                        <Button variant='contained' sx={{ mr: 2 }} onClick={(event) => onHandleSendChat(11)}>
                            Send
                        </Button>
                    </Grid>
                </Fragment>

            </Grid>
        </Box>
    );
};

export default ChatDialog;

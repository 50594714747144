import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from 'store/store';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
//import DateAdapter from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//import { LocalizationProvider } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers'
import './index.scss';
const theme = createTheme({
  palette: {
    secondary: {
      light: grey[300],
      main: grey[400],
      dark: grey[500],
    },
  },
});
/*
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </LocalizationProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);*/

//reportWebVitals();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </LocalizationProvider>
    </ThemeProvider>
);

reportWebVitals();

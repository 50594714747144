import { useCallback, useState, useEffect} from 'react';

import { Grid, Box, Button } from '@mui/material';
import { Dialog, DateTimePicker, SelectField } from 'style-guide';

import { REQUEST_ACTIONS, sendRequest, setOrderData } from 'utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders} from 'reducers/order-reducers/ordersSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';

import {addOrderFilter, addSearchFilter} from 'Utils';

const SetReminderDialog = ({ order, openDialog, setOpenDialog, loginUser, pageName }) => {
  const dispatch = useDispatch();

  const { page, size} = useSelector(state => state.orders);
  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
  const [remindDate, setRemindDate] = useState(null);
  const [ilRemindDate, setIlRemindDate] = useState(null);

  const { 
    userLocalNumber,  
    userAbroadNumber,  
      consigneeId,
      customerId,
    shipperId,
      countryOfOriginId, originState, fileNumber,
    shippingModeId,
    subModeId,
      userLocalId, userTracer, userCustoms,
      userAbroadId, urgentShipment,
    dueDate,
    lastUpdateDate,
    ilReminderDate,
    reminderDate,
    selectedLocationIndex,
    search,
    startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.orderTableFilter);
  useEffect(() => {
    setIlRemindDate(order?.ilRemindDate);
    setRemindDate(order?.remindDate);
  },[]);

  const onHandleCloseDialog = () => {
    setOpenDialog(false);
  };

  const OnReminderSearch = () => {

     /* if (!remindDate && !ilRemindDate) {
        dispatch(snackbarToggle({ type: 'error', message: 'Please enter Reminder Date!' }));
        return;
      } */

      const newOrder = {
            ...order,
            remindDate,
            ilRemindDate,
          }
    sendRequest(`/api/order?fromStage=0&loginUserId=`+loginUser?.id+`&actionOn=ReminderDialog`, REQUEST_ACTIONS.PUT, newOrder, {
      successCallback: () => {
            sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
          successCallback: response => {
                dispatch(snackbarToggle({ type: 'success', message: 'Reminder saved successfully' }));
                setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
            onHandleCloseDialog();
          },
          failedCallback,
        });
      },
      failedCallback,
    });
  }

  return (
    <Dialog maxWidth='md' open={openDialog} onHandleCancel={onHandleCloseDialog} title={'Set Reminder'}>
      <Box component='form' noValidate autoComplete='off'>
        <Grid container spacing={2}>
          
            {  loginUser?.location?.code === 'IL' ?
            (
            <Grid container item spacing={2}>
                  <DateTimePicker
                    lg={2.5}
                    label={'Reminder Date'}
                    value={ilRemindDate}
                    isShowTime={false} 
                    format="dd/MM/yyyy"
                    onChange={date => setIlRemindDate(date?.toISOString())}
                  /> 
              </Grid>) :
              (
              <Grid container item spacing={2}>
                  <DateTimePicker
                    lg={2.5}
                    label={'Reminder Date'}
                    value={remindDate}
                    isShowTime={false} 
                    format="dd/MM/yyyy"
                    onChange={date => setRemindDate(date?.toISOString())}
                  /> 
              </Grid>)
              }
          <Grid container item xs={12} justifyContent='flex-end'>
            <Button
              variant='contained'
              sx={{ mr: 2 }}
              onClick={OnReminderSearch}
              >
              Set
            </Button>
            <Button variant='contained' color='secondary' onClick={onHandleCloseDialog}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default SetReminderDialog;
import { useState, useEffect, useCallback } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { Dialog } from 'style-guide';
import UsersTable from './UsersTable';
import AddUser from './AddUser';
import { useDispatch } from 'react-redux';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { addUser, onRolesChange ,onLocationChange, onCustomerChange } from 'reducers/UsersSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';

const Users = () => {
  const dispatch = useDispatch();
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const onHandleOpenModal = () => setOpenOrderModal(true);
  const onHandleCancelModal = () => setOpenOrderModal(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const onHandleImportModal = () => setOpenImportModal(true);
  const onHandleImportCancelModal = () => setOpenImportModal(false);
  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);

  const processCSV = (str1, delim=',') => {
    const str2 = str1.replace("\r", "");
    const str = str2.replace("\\r", "");
    const headers = str.slice(0,str.indexOf('\n')).split(delim);
    const rows = str.slice(str.indexOf('\n')+1).split('\n');
    const newArray = rows.map( row1 => {
      const row2 = row1.replace("\r", "");
      const row = row2.replace("\\r", "");
      const values = row.split(delim);
      if(values.length < 3){return null;}
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {})
      save(eachObject);
      return eachObject;
    })
    setCsvArray(newArray);
    onHandleImportCancelModal();
  }
  const submit = (cfile) => {
    const file = cfile;
    const reader = new FileReader();

    reader.onload = function(e) {
      const text = e.target.result;
      //console.log(text);
      processCSV(text)
    }
    reader.readAsText(file);
  }
  const save = (item) => {
    if(item == null){return;}
    if (item.password === item.confirmPassword) {
      sendRequest('/api/addUser', REQUEST_ACTIONS.POST, item, {
        successCallback: response => {
          dispatch(addUser(response));
          dispatch(snackbarToggle({ type: 'success', message: 'User Created successfully' }));
        },
        failedCallback,
      });
    } else {
      dispatch(snackbarToggle({ type: 'error', message: 'password and confirm password not same' }));
    }
  };

  useEffect(() => {
    sendRequest('/api/roles', REQUEST_ACTIONS.GET, '', {
      successCallback: response => dispatch(onRolesChange(response)),
      failedCallback,
    });
  }, [dispatch, failedCallback]);

    sendRequest('/api/locations', REQUEST_ACTIONS.GET, '', {
      successCallback: response => dispatch(onLocationChange(response)) ,
      failedCallback
  });

  sendRequest('/api/serviceProvider/13?activeFlag=true', REQUEST_ACTIONS.GET, '', {
      successCallback: response => dispatch(onCustomerChange(response)) ,
      failedCallback
  });


  return (
    <Grid container>
      <Grid container item xs={12} justifyContent='space-between'>
        <Typography variant='h5'>Users</Typography>
        
        <Button onClick={onHandleImportModal} variant='contained' sx={{ mb: 3 }}>
          {`Import User`}
        </Button>
        {openImportModal && (
          <Dialog maxWidth='sm' open={openImportModal} title={`IMPORT USER`} onHandleCancel={onHandleImportCancelModal}>
            <input
              type='file'
              accept='.csv'
              id='csvFile'
              onChange={(e) => {
                setCsvFile(e.target.files[0]); submit(e.target.files[0]);
              }}
            ></input>
          </Dialog>
        )}
        <Button onClick={onHandleOpenModal} variant='contained' sx={{ mb: 3 }}>
          {`+ New User`}
        </Button>
        {openOrderModal && (
          <Dialog maxWidth='sm' open={openOrderModal} title={`NEW USER`} onHandleCancel={onHandleCancelModal}>
            <AddUser onHandleCancel={() => setOpenOrderModal(false)} id={null} viewProfile={false}/>
          </Dialog>
        )}
      </Grid>
      <UsersTable />
    </Grid>
  );
};

export default Users;

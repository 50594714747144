import { createSlice } from '@reduxjs/toolkit';

export const whStageSlice = createSlice({
  name: 'parcelWhStage',
  initialState: {
    id: null,
    receivedTime: null,
    cutOffTime: null,
    etdTime: null,
    etaTime: null,
    eta: null,
    arrived: false,
    partialShipment: false,
    notes: null,
    files: [],
    whCity: null,
    whBuilding: null,
    whStreet: null,
    whPhoneNumber: null,
    whCost: null,
    whSelectedCurrency: null,
    ncode: null,
  },
  reducers: {
    resetWhStage: state => {
      state.id = null;
      state.arrived = false;
      state.partialShipment = false;
      state.receivedTime = null;
      state.cutOffTime = null;
      state.etdTime = null;
      state.etaTime = null;
      state.eta = null;
      state.notes = null;
      state.files = [];
      state.whCity = null;
      state.whBuilding = null;
      state.whStreet = null;
      state.whPhoneNumber = null;
      state.whCost = null;
      state.whSelectedCurrency = null;
      state.ncode = null;
    },
    onFilesChange: (state, { payload }) => {
      state.files = payload;
    },
    onFilesAdd: (state, { payload }) => {
      state.files.push(payload);
    },
    onIdChange: (state, { payload }) => {
      state.id = payload;
    },
    onArrivedChange: (state, { payload }) => {
      state.arrived = payload;
      if(payload === true){
        var today = new Date();
        state.receivedTime = today.toISOString();
      }
    },
    onPartialShipmentChange: (state, { payload }) => {
      state.partialShipment = payload;
    },
    onReceivedTimeChange: (state, { payload }) => {
      state.receivedTime = payload;
    },
    onCutOffTimeChange: (state, { payload }) => {
      state.cutOffTime = payload;
    },
    onEtdTimeChange: (state, { payload }) => {
      state.etdTime = payload;
    },
    onEtaTimeChange: (state, { payload }) => {
      state.etaTime = payload;
    },
    onEtaChange: (state, { payload }) => {
      state.eta = payload;
    },
    onNotesChange: (state, { payload }) => {
      state.notes = payload;
    },
    onWhCityChange: (state, { payload }) => {
      state.whCity = payload;
    },
    onWhBuildingChange: (state, { payload }) => {
      state.whBuilding = payload;
    },
    onWhStreetChange: (state, { payload }) => {
      state.whStreet = payload;
    },
    onWhPhoneNumberChange: (state, { payload }) => {
      state.whPhoneNumber = payload;
    },
    onWhCostChange: (state, { payload }) => {
      state.whCost = payload;
    },
    onWhSelectedCurrencyChange: (state, { payload }) => {
      state.whSelectedCurrency = payload;
    },
    onNcodeChange: (state, { payload }) => {
      state.ncode = payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  resetWhStage,
  onFilesChange,
  onFilesAdd,
  onIdChange,
  onArrivedChange,
  onPartialShipmentChange,
  onReceivedTimeChange,
  onCutOffTimeChange,
  onEtdTimeChange,
  onEtaTimeChange,
  onNotesChange,
  onEtaChange,
  onWhCityChange,
  onWhBuildingChange,
  onWhStreetChange,
  onWhPhoneNumberChange,
  onWhCostChange,
  onWhSelectedCurrencyChange,
  onNcodeChange,
} = whStageSlice.actions;

export default whStageSlice.reducer;

import { useCallback, useState, useEffect } from 'react';

import { Grid, Box, Button, Typography } from '@mui/material';
import { Dialog, DateTimePicker, SelectField } from 'style-guide';

import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders, onParcelsCountChange, onChatCountChange, onUserSpecificParcelsCountChange, onUnConfirmedParcelsCountChange } from 'reducers/parcel-reducers/ordersSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';

import {addParcelFilter, addSearchFilterParcel} from 'Utils';
import {  
  OnStartReminderDateChange,
  OnEndReminderDateChange,
  OnStartIlReminderDateChange,
  OnEndIlReminderDateChange,
  OnReminderDateAdvChange,
  OnIlReminderDateAdvChange, } from 'reducers/parcel-reducers/orderTableFilterSlicer';

const AdvancedSearchDialog = ({ openDialog, setOpenDialog, loginUser, pageName }) => {
  const dispatch = useDispatch();

  const { page, size, chatCount, userSpecificParcelsCount } = useSelector(state => state.parcels);
  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

  const [startReminderDateAdvDlg, setStartReminderDateAdvDlg] = useState(null);
  const [endReminderDateAdvDlg, setEndReminderDateAdvDlg] = useState(null);
  const [startIlReminderDateAdvDlg, setStartIlReminderDateAdvDlg] = useState(null);
  const [endIlReminderDateAdvDlg, setEndIlReminderDateAdvDlg] = useState(null);
  const [reminderDateAdvDlg, setReminderDateAdvDlg] = useState(null);
  const [ilReminderDateAdvDlg, setIlReminderDateAdvDlg] = useState(null);

  const { 
    userLocalNumber,  
    userAbroadNumber,  
    consigneeId,
    shipperId,
    countryOfOriginId, originState,
    shippingModeId,
    subModeId,
    userLocalId,
    userAbroadId,
    dueDate,
    lastUpdateDate,
    ilReminderDate,
    reminderDate,
    selectedLocationIndex,
    selectedTabIndexName,
    search,
    startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.parcelTableFilter);

  useEffect(() => {
    setStartReminderDateAdvDlg(startReminderDate);
    setEndReminderDateAdvDlg(endReminderDate);
    setStartIlReminderDateAdvDlg(startIlReminderDate);
    setEndIlReminderDateAdvDlg(endIlReminderDate);
    setReminderDateAdvDlg(reminderDateAdv);
    setIlReminderDateAdvDlg(ilReminderDateAdv);
  }, []);

  const onHandleCloseDialog = () => {
    setOpenDialog(false);
  };

  const OnAdvancedSearch = () => {

      if ((startReminderDateAdvDlg && !endReminderDateAdvDlg) || (!startReminderDateAdvDlg && endReminderDateAdvDlg)) {
        dispatch(snackbarToggle({ type: 'error', message: 'Please enter Start/End Reminder Date!' }));
        return;
      }

      dispatch(OnStartReminderDateChange(startReminderDateAdvDlg));
      dispatch(OnEndReminderDateChange(endReminderDateAdvDlg));
      dispatch(OnStartIlReminderDateChange(startIlReminderDateAdvDlg));
      dispatch(OnEndIlReminderDateChange(endIlReminderDateAdvDlg));
      dispatch(OnReminderDateAdvChange(reminderDateAdvDlg));
      dispatch(OnIlReminderDateAdvChange(ilReminderDateAdvDlg));

      sendRequest(`/api/parcel/count?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDateAdvDlg, endReminderDateAdvDlg, startIlReminderDateAdvDlg, endIlReminderDateAdvDlg, stageSearch, stageStateSearch, reminderDateAdvDlg, ilReminderDateAdvDlg, pageName)}`, REQUEST_ACTIONS.GET, '', {
        successCallback: response => {dispatch(onParcelsCountChange(response?.parcelsCount));  sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount); dispatch(onChatCountChange(response?.unreadChatCount));sendNewOrderNotification(loginUser, dispatch, userSpecificParcelsCount, response?.userSpecificParcelsCount); dispatch(onUserSpecificParcelsCountChange(response?.userSpecificParcelsCount));dispatch(onUnConfirmedParcelsCountChange(response?.unConfirmedParcelsCount));},
        failedCallback,
      });
      // filtered orders get
      sendRequest(
        `/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDateAdvDlg, endReminderDateAdvDlg, startIlReminderDateAdvDlg, endIlReminderDateAdvDlg, stageSearch, stageStateSearch, reminderDateAdvDlg, ilReminderDateAdvDlg, pageName)}&page=${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDateAdvDlg, endReminderDateAdvDlg, startIlReminderDateAdvDlg, endIlReminderDateAdvDlg, stageSearch, stageStateSearch, reminderDateAdvDlg, ilReminderDateAdvDlg, pageName)?.length ? 0 : page}&size=${size}`,
        REQUEST_ACTIONS.GET,
        '',
        {
          successCallback: response => {
            dispatch(getOrders(response));
            setOpenDialog(false);
          },
          failedCallback,
        },
      );
  }

  return (
    <Dialog maxWidth='md' open={openDialog} onHandleCancel={onHandleCloseDialog} title={'Reminder Search'}>
      <Box component='form' noValidate autoComplete='off'>
        <Grid container spacing={2}>
          
            {  loginUser?.location?.code === 'IL' ?
            (
            <Grid container item spacing={2}>
              <Grid container item spacing={2}>
                 <Box component="fieldset"  sx={{ display: 'flex', width: '100%', 'border-width': '1px' }}>
                    <legend>Search by date range</legend>
                    <DateTimePicker
                      disabled = {ilReminderDateAdvDlg}
                      lg={2.5}
                      label={'Reminder Start Date'}
                      value={startIlReminderDateAdvDlg}
                      isShowTime={false} 
                      format="dd/MM/yyyy"
                      onChange={date => setStartIlReminderDateAdvDlg(date?.toISOString())}
                    /> 
                    <DateTimePicker
                      lg={2.5}
                      disabled = {ilReminderDateAdvDlg}
                      label='Reminder End Date'
                      value={endIlReminderDateAdvDlg}
                      isShowTime={false} 
                      format="dd/MM/yyyy"
                      onChange={date => setEndIlReminderDateAdvDlg(date?.toISOString())}
                    />
                  </Box>
                </Grid>
                <Grid container item spacing={2}>
                  <Typography sx={{ display: 'flex', width: '100%', 'border-width': '1px', 'margin-left': '40px' }} >OR</Typography>
                </Grid>
                <Grid container item spacing={2}>
                  <Box component="fieldset"  sx={{ display: 'flex', width: '100%', 'border-width': '1px' }}>
                    <legend>Search by date</legend>
                    <DateTimePicker
                      disabled = {startIlReminderDateAdvDlg || endIlReminderDateAdvDlg}
                      lg={2.5}
                      label={'Reminder Date'}
                      value={ilReminderDateAdvDlg}
                      isShowTime={false} 
                      format="dd/MM/yyyy"
                      onChange={date => setIlReminderDateAdvDlg(date?.toISOString())}
                    /> 
                  </Box>
                </Grid>
              </Grid>) :
              (
              <Grid container item spacing={2}>
                <Grid container item spacing={2}>
                  <Box component="fieldset"  sx={{ display: 'flex', width: '100%', 'border-width': '1px' }}>
                    <legend>Search by date range</legend>
                    <DateTimePicker
                      lg={2.5}
                      disabled = {reminderDateAdvDlg}
                      label={'Reminder Start Date'}
                      value={startReminderDateAdvDlg}
                      isShowTime={false} 
                      format="dd/MM/yyyy"
                      onChange={date => setStartReminderDateAdvDlg(date?.toISOString())}
                    /> 
                    <DateTimePicker
                      lg={2.5}
                      disabled = {reminderDateAdvDlg}
                      label='Reminder End Date'
                      value={endReminderDateAdvDlg}
                      isShowTime={false} 
                      format="dd/MM/yyyy"
                      onChange={date => setEndReminderDateAdvDlg(date?.toISOString())}
                    />
                  </Box>
                </Grid>
                <Grid container item spacing={2}>
                  <Typography sx={{ display: 'flex', width: '100%', 'border-width': '1px', 'margin-left': '40px' }} >OR</Typography>
                </Grid>
                <Grid container item spacing={2}>
                  <Box component="fieldset"  sx={{ display: 'flex', width: '100%', 'border-width': '1px' }}>
                    <legend>Search by date</legend>
                    <DateTimePicker
                      lg={2.5}
                      disabled = {startReminderDateAdvDlg || endReminderDateAdvDlg}
                      label={'Reminder Date'}
                      value={reminderDateAdvDlg}
                      isShowTime={false} 
                      format="dd/MM/yyyy"
                      onChange={date => setReminderDateAdvDlg(date?.toISOString())}
                    /> 
                  </Box>
                </Grid>
              </Grid>)
              }
          <Grid container item xs={12} justifyContent='flex-end'>
            <Button
              variant='contained'
              sx={{ mr: 2 }}
              onClick={OnAdvancedSearch}
              >
              Search
            </Button>
            <Button variant='contained' color='secondary' onClick={onHandleCloseDialog}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default AdvancedSearchDialog;
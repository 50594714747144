import { useState, useEffect, useCallback, Fragment } from 'react';
import {CircularProgress, Grid, Typography, Table, TableBody, TableContainer, Paper, TablePagination, TableRow, TableCell} from '@mui/material';
import { onPageChange, onSizeChange, getOrders, onOrdersCountChange } from 'reducers/order-reducers/ordersSlicer';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { REQUEST_ACTIONS, sendRequest, setOrderData } from 'utils/Communicator';
import CustomerOrderRowInfo from './OrderTable/Customer/CustomerOrderRowInfo';
import { onShippingModeDataChange,} from 'reducers/order-reducers/predefinedDataSlicer';
import {GoogleMapWrapper} from 'shipTracker/GoogleMapWrapper';

const CustomerOrders = ({ loginUser }) => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [trackerType, setTrackerType] = useState('Air');
  const [airNumber, setAirNumber] = useState('');
  const [shipNumber, setShipNumber] = useState('');
  const [mmsiNumber, setMmsiNumber] = useState('');
  const [depTime, setDepTime] = useState(new Date());
  const [orderId, setOrderId] = useState(0);
  const { orders, page, size, ordersCount } = useSelector(state => state.orders);
  const { shippingModeData } = useSelector(
      state => state.predefinedData,
    );
  // pagination
  const onPageHandleChange = (_, newPage) => {
    dispatch(onPageChange(newPage));
  };
  const onRowsPerPageHandleChange = event => {
    dispatch(onSizeChange(+event.target.value));
    dispatch(onPageChange(0));
  };

  const setGoogleMapVal = (orderId, trackerType, airNumber, depTime, shipNumber, mmsiNumber) => {
    setTrackerType(trackerType);
    setAirNumber(airNumber);
    setDepTime(depTime?depTime:new Date().getTime());
    setOrderId(orderId);
    setShipNumber(shipNumber);
    setMmsiNumber(mmsiNumber);
  };

  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    useEffect(() => {
      
      if (loginUser?.customer?.id) {
        // predefined shipping mode data get
        sendRequest('/api/shippingMode', REQUEST_ACTIONS.GET, '', {
          successCallback: response => dispatch(onShippingModeDataChange(response)),
          failedCallback,
        });

        const params = [];
        params.push(`customerId=${loginUser.customer.id}`);

        sendRequest(`/api/order/count?${params.join('&')}`, REQUEST_ACTIONS.GET, '', {
          successCallback: response => dispatch(onOrdersCountChange(response?.ordersCount)),
          failedCallback,
        });
        // filtered orders get
        sendRequest(
          `/api/order?${params.join('&')}&page=${params.length ? 0 : page}&size=${size}`,
          REQUEST_ACTIONS.GET,
          '',
          {
              successCallback: response => {
                  setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
              setIsLoaded(true);
            },
            failedCallback,
          },
        );
    } else {
      setIsLoaded(true);
    }
  }, [failedCallback]);

  return (
    <Grid container>
      {!isLoaded ? (
        <Grid container item justifyContent='center' mt={8}>
          <CircularProgress />
        </Grid>
      ) : (
        <Fragment>
          <Grid container>
            <Grid item xs={1}>
              <Typography variant='h5'>Orders</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent='space-between'>
            <Grid container item xs={8} justifyContent='flex-end' flexDirection='column-reverse'>
              <GoogleMapWrapper orderId={orderId} trackerType={trackerType} airNumber={airNumber} depTime={depTime} shipNumber={shipNumber} mmsiNumber={mmsiNumber}/>
            </Grid>
            <Grid container item xs={4} justifyContent='space-between'>
              <Paper sx={{ width: 'calc(100% - 5px)', overflow: 'hidden' }}>
                                  <TableContainer sx={{ maxHeight: 800, minHeight: "10vw" }}>
                  <Table>
                    { (!loginUser?.customer?.active) ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={13} align='center'>
                            <Typography variant='h4'>Associated Customer CRM is not active.</Typography>
                          </TableCell>
                        </TableRow>
                        </TableBody>
                      ):
                    (orders?.length ? (
                      <TableBody>
                        {orders.map(order => (
                          <CustomerOrderRowInfo order={order} onClickRow={setGoogleMapVal} />
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={13} align='center'>
                            <Typography variant='h4'>No data available</Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component='div'
                  count={ordersCount}
                  rowsPerPage={size}
                  page={page}
                  onPageChange={onPageHandleChange}
                  onRowsPerPageChange={onRowsPerPageHandleChange}
                />
              </Paper>
            </Grid> 
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default CustomerOrders;

/* eslint-disable react/prop-types */
/*global google*/
import { useState, useCallback, useEffect } from 'react';
import * as React from "react";
import { useDispatch } from 'react-redux';
// import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';

import { snackbarToggle } from 'reducers/snackbarSlicer';

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from '@react-google-maps/api'

export const GoogleMapWrapper = ({data, trackerType} ) => {
  const dispatch = useDispatch();
  const [googleMapData, setGoogleMapData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [center, setCenter] = useState({lat:32, lng:35});
  const [originCenter, setOriginCenter] = useState({lat:32, lng:35});
  const [originCity, setOriginCity] = useState("");
  const [originCountry, setOriginCountry] = useState("");
  const [destCenter, setDestCenter] = useState({lat:32, lng:35});
  const [destText, setDestText] = useState("");
  const [destCity, setDestCity] = useState("");
  const [destCountry, setDestCountry] = useState("");
  const [flightText, setFlightText] = useState("");
  const [shipText, setShipText] = useState("");
  const [map, setMap] = useState(/** @type google.maps.Map */ (null))
  const [airPath, setAirPath] = useState([originCenter, center, destCenter]);
  const [rot, setRot] = useState(0);
  const [ libraries ] = useState(['places']);
  const [loadingTxt, setloadingTxt] = useState('Loading...')
  const [origin, setOrigin] = useState('')
  const [destination, setDestination] = useState('')

  // const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
      googleMapsApiKey: "AIzaSyDgpzrxExQg_AUW8h199onppaSz72SYlSY",
    libraries,
  });

  useEffect(() => {
   if (trackerType === 1) { setGoogleMapData([data]);
      setCenter({lat:32, lng:35});
      setDestCenter({lat:32, lng:35});
      setOriginCenter({lat:32, lng:35});
      // map.fitBounds(new window.google.maps.LatLngBounds());
      setData(data);
    }
    else if (trackerType === 2){
      setCenter({lat: data.data.lat, lng: data.data.lon});
      setRot(data.data['heading']);
    }
  },[data]);

  const setData = (data) =>{
    if (data.positionData && !data.positionData.status) {
      if( data['positionData']['last_position']){
        setCenter({ lat: data['positionData']['last_position']['latitude'], lng: data['positionData']['last_position']['longitude']});
        setRot(data['positionData']['last_position']['heading']);
      }
      else{
        setCenter({ lat: data['originData']['latitude'], lng: data['originData']['longitude']});
        setRot(0);
      }
      setAirPath([{ lat: data['originData']['latitude'], lng: data['originData']['longitude']}, { lat: center.lat, lng: center.lng}, { lat: data['destinationData']['latitude'], lng: data['destinationData']['longitude']}]);
      
      try {
        var bounds = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(data['positionData']['bounding_box'][0], data['positionData']['bounding_box'][1]), new window.google.maps.LatLng(data['positionData']['bounding_box'][2], data['positionData']['bounding_box'][3]));
        map.fitBounds(bounds);
      } catch(err) {
        console.log('err', err)
      }

    }
    else {
      setAirPath([{ lat: data['originData']['latitude'], lng: data['originData']['longitude']}, { lat: data['originData']['latitude'], lng: data['originData']['longitude']}, { lat: data['destinationData']['latitude'], lng: data['destinationData']['longitude']}]);
    }
    setOriginCenter({ lat: data['originData']['latitude'], lng: data['originData']['longitude']});
    setOriginCity(data['originData']['city']);
    setOriginCountry(data['originData']['country_code']);
    setOrigin(`${data['originData']['city']}, ${data['originData']['country_code']}`);
    setDestination(`${data['destinationData']['city']}, ${data['destinationData']['country_code']}`);
    setDestCountry(data['destinationData']['country_code']);
    setDestCity(data['destinationData']['city']);
    setDestCenter({ lat: data['destinationData']['latitude'], lng: data['destinationData']['longitude']});
    setDestText( `${data['estimated_on'] ? 'Estimated on' : 'Estimated in'} (${data['originData']['alternate_ident']} to ${data['destinationData']['alternate_ident']}) : ${data['estimated_on'] ? data['estimated_on'] : data['estimated_in']}`);
    // setFlightText(data['ident'] + " " + data['registration']);
  }

  const getPlaneSymbol = () => {
    return {
      path: 'M362.985,430.724l-10.248,51.234l62.332,57.969l-3.293,26.145 l-71.345-23.599l-2.001,13.069l-2.057-13.529l-71.278,22.928l-5.762-23.984l64.097-59.271l-8.913-51.359l0.858-114.43 l-21.945-11.338l-189.358,88.76l-1.18-32.262l213.344-180.08l0.875-107.436l7.973-32.005l7.642-12.054l7.377-3.958l9.238,3.65 l6.367,14.925l7.369,30.363v106.375l211.592,182.082l-1.496,32.247l-188.479-90.61l-21.616,10.087l-0.094,115.684',
      scale: 0.05,
      strokeOpacity: 1,
      fillOpacity: 1,
      fillColor: 'red',
      color: 'red',
      stroke: true,
      weight: 2,
      anchor:new window.google.maps.Point(400, 400),
      rotation: rot,
    };
  }

  const getShipSymbol = () => {
    return {
      path: 'M16,31.36c-0.064,0-0.128-0.018-0.185-0.052C15.604,31.182,10.64,28.156,10.64,24V4 c0-0.071,0.021-0.141,0.061-0.2l2-3C12.767,0.7,12.879,0.64,13,0.64h6c0.12,0,0.232,0.06,0.3,0.16l2,3 c0.039,0.059,0.06,0.129,0.06,0.2v20c0,4.156-4.964,7.182-5.175,7.309C16.128,31.343,16.064,31.36,16,31.36z M11.36,22.36V24 c0,3.321,3.754,5.989,4.64,6.573c0.886-0.584,4.64-3.252,4.64-6.573v-1.64H11.36z M19.36,21.64h1.279v-3.28H19.36V21.64z M17.36,21.64h1.279v-7.28H17.36V21.64z M15.36,21.64h1.28v-3.28h-1.28V21.64z M13.36,21.64h1.28v-3.28h-1.28V21.64z M11.36,21.64 h1.28v-7.28h-1.28V21.64z M19.36,17.64h1.279V6.36H19.36V17.64z M15.36,17.64h1.28v-7.28h-1.28V17.64z M13.36,17.64h1.28v-3.28 h-1.28V17.64z M17.36,13.64h1.279v-3.28H17.36V13.64z M13.36,13.64h1.28V6.36h-1.28V13.64z M11.36,13.64h1.28v-3.28h-1.28V13.64z M17.36,9.64h1.279V6.36H17.36V9.64z M15.36,9.64h1.28V6.36h-1.28V9.64z M11.36,9.64h1.28V6.36h-1.28V9.64z M11.36,5.64h9.28V4.109 L18.808,1.36H18.36V3c0,0.199-0.161,0.36-0.36,0.36h-4c-0.199,0-0.36-0.161-0.36-0.36V1.36h-0.447L11.36,4.109V5.64z M14.36,2.64 h3.28V1.36h-3.28V2.64z M17.5,26.36h-3c-0.199,0-0.36-0.161-0.36-0.36v-0.64H13v-0.72h1.14V24c0-0.199,0.161-0.36,0.36-0.36h3 c0.199,0,0.36,0.161,0.36,0.36v0.64H19v0.721h-1.14V26C17.86,26.199,17.699,26.36,17.5,26.36z M14.86,25.64h2.28v-1.28h-2.28V25.64z',
      scale: 1,
      strokeOpacity: 1,
      color: 'red',
      strokeWeight: 1,
      anchor:new window.google.maps.Point(16, 16),
      rotation: rot,
    };
  }

  const lineSymbol = {
    path: 'M 0,-1 0,1',
    scale: 2.5,
    strokeColor: 'blue',
    strokeOpacity: 1,
  }

  const emptySymbol = {
    path: 'M 0,0 0,0',
    scale: 0,
    strokeColor: 'blue',
    strokeOpacity: 0,
  }

  return (
    <div style={{width:'98%', height: '98%', maxHeight:'900px', display: 'flex', padding: '10px'}}>
      {isLoaded?
      (trackerType && trackerType === 1) ?
        <GoogleMap
          center={center}
          zoom={6}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: true,
            fullscreenControl: false,
          }}
          onLoad={map => {
            if (googleMapData.length > 0 && !googleMapData[0]['positionData']['status']) {
              try{
                var bounds = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(googleMapData[0]['positionData']['bounding_box'][0], googleMapData[0]['positionData']['bounding_box'][1]), new window.google.maps.LatLng(googleMapData[0]['positionData']['bounding_box'][2], googleMapData[0]['positionData']['bounding_box'][3]));
                map.fitBounds(bounds);
                setMap(map);
              }catch(err) {
                console.log('err', err)
              }
            }
          }}
        >
          {/*<Marker position={originCenter} title={originText}/>
          <Marker position={destCenter} title={destText} /> */}
          <Marker position={originCenter} icon={emptySymbol} label={originCity} />
          <Marker position={destCenter} icon={emptySymbol} label={destCity} />
          {googleMapData?.length!=0?<Marker position={center} icon={getPlaneSymbol()}><InfoWindow position={center} options={{width:'250px',pixelOffset: new google.maps.Size(0,-20),}}><div>{flightText}<br/>{destText}</div></InfoWindow></Marker>:null}
          <Polyline path={airPath} options={{geodesic:true, strokeColor:'#0000FF', strokeOpacity:0, strokeWeight: 1,icons: [{icon: lineSymbol,offset: '0',repeat: '10px',}, ],}} />
        </GoogleMap> :
            (<GoogleMap
              center={center}
              zoom={6}
              mapContainerStyle={{ width: '100%', height: '100%' }}
              options={{
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: true,
                fullscreenControl: false,
              }}
              onLoad={map => {
                  setMap(map);
              }}
            >
            <Marker position={center} icon={getShipSymbol()} title={shipText} />
            </GoogleMap>)
      :<div>{loadingTxt}</div>}
    </div>
  );
};

export default GoogleMapWrapper;

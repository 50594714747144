import { Fragment, useState, useCallback, useEffect } from 'react';
import { sendRequest, REQUEST_ACTIONS } from 'utils/Communicator';

import { Divider, Grid, Typography, Box } from '@mui/material';
import { MultilineField, SelectField, TextField, ParcelDropzone, ParcelFilesRender, Autocomplete } from 'style-guide';

import { useDispatch, useSelector } from 'react-redux';
import {filterServiceProvider} from 'Utils';
import { USA_STATES } from 'constants/global-constants';
import { snackbarToggle } from 'reducers/snackbarSlicer';

const OrderInformation = ({ loginUser, orderId, state, onStateChange, pageName, onHandleCloseParentModal }) => {
  const dispatch = useDispatch();
  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
  const {
    subModeData,
    shippingModeData,
    countryOfOriginData,
    userILNames,
    incotermsData,
    airPolData,
    airPoaData,
    lclPolData,
    lclPoaData,
  } = useSelector(state => state.parcelPredefinedData);
  const {selectedLocationIndex,} = useSelector(state => state.parcelTableFilter);
  const { shipperNameOptions } = useSelector(state => state.shippers);
  const { consigneeNameOptions } = useSelector(state => state.consignees);
  const { customerNameOptions } = useSelector(state => state.customers);
  const [lockerId, setLockerId] = useState(null);

  const {
    jobNumber,
    coClient,
    coVatId,
    price,
    priceVat,
    priceWeight,
    priceDesc,
    priceTbd1,
    priceTbd2,
    userILId,
    incotermsId,
    shipperId,
    consigneeId,
    countryOfOriginId, originState,
    clientRefNumber,
    suppliersPO,
    customerId,
    shippingModeId,
    subModeId,
    polId,
    poaId,
    notes,
    files,
    orderStageId,
    codeFileCount,
  } = state;

  useEffect(() => {
    if (consigneeId) {
      sendRequest('/api/parcel/manifestToken/'+consigneeId, REQUEST_ACTIONS.GET,'' , {
        successCallback: response => {
          setLockerId(response?.token);
        },
        failedCallback,
        });
      }
    },[consigneeId]);

  const onHandleChange = (event, field) => onStateChange(field, event.target.value);
  const onFilesAdd = file => onStateChange('files', [...files, file]);
  const onCodeFileCountAdd = file => {
    const codeFile = codeFileCount.find(codeFile => codeFile.code === file.code);
    if (!!codeFile) {
      codeFile.count = codeFile.count + 1;
    } else {
      codeFileCount.push(file);
    }
  };

  return (
    <Fragment>
      <Grid container item spacing={2}>
      {orderId && 
        <Typography fontSize="0.9rem" variant='body1' component='span' sx={{px : '16px', pb : '8px', pt : '16px'}}>
          {new Intl.DateTimeFormat('en-GB', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }).format(new Date(state?.date)) + ' '+ new Intl.DateTimeFormat('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  }).format(new Date(state?.date))}

        </Typography> }
      </Grid>
      <Grid container item spacing={2}>
        <Autocomplete
          field='customerId'
          label='Customer'
          lg={null}
          value={customerId}
          disabled
          onChange={onStateChange}
          options={filterServiceProvider(loginUser, selectedLocationIndex, customerNameOptions)}
        />
        {orderId != null ?
        <TextField
          readOnly
          lg={null}
          label='Job #'
          value={jobNumber}
          onChange={event => onHandleChange(event, 'jobNumber')}
        /> : null}
        <Autocomplete
          field='userILId'
          label='USER IL'
          lg={null}
          value={userILId}
          onChange={onStateChange}
          options={userILNames}
        />
        <Autocomplete
          required
          field='incotermsId'
          label='Incoterms'
          lg={null}
          value={incotermsId}
          onChange={onStateChange}
          options={incotermsData}
        />
        <Autocomplete
          required
          field='shipperId'
          label='Shipper'
          lg={null}
          value={shipperId}
          onChange={onStateChange}
          options={filterServiceProvider(loginUser, selectedLocationIndex, shipperNameOptions)}
        />
        <Autocomplete
          required
          field='consigneeId'
          label='Consignee'
          lg={null}
          value={consigneeId}
          onChange={onStateChange}
          options={consigneeNameOptions}
        />
        {lockerId != null ?
            <TextField
              lg={null}
              value={lockerId}
              label='Locker Id'
              readOnly
            /> : null}
         {(loginUser.location.code === 'US' || selectedLocationIndex == 1) ?
         (<Autocomplete
            field='originState'
            label='Origin State'
            lg={null}
            value={originState}
            onChange={onStateChange}
            options={USA_STATES}
          />) :
          (<Autocomplete
            required
            field='countryOfOriginId'
            label='Country Of Origin'
            lg={null}
            value={countryOfOriginId}
            onChange={onStateChange}
            options={countryOfOriginData}
          />)}
        <TextField
          lg={null}
          type="Text"
          label='Client ref. #'
          value={clientRefNumber}
          onChange={event => onHandleChange(event, 'clientRefNumber')}
        />
        <SelectField
          label='Shipping'
          lg={null}
          value={shippingModeId}
          onChange={event => {
            onHandleChange(event, 'shippingModeId');
            onStateChange('poaId', '');
            onStateChange('polId', '');
          }}
          options={shippingModeData}
        />
        <Autocomplete
          required
          field='subModeId'
          label='Sub Mode'
          lg={null}
          value={subModeId}
          onChange={onStateChange}
          options={subModeData}
        />
        <Autocomplete
          field='polId'
          label='POL'
          lg={null}
          value={polId}
          onChange={onStateChange}
          options={shippingModeId === 1 ? airPolData : lclPolData}
        />
        <Autocomplete
          required
          field='poaId'
          label='POD'
          lg={null}
          value={poaId}
          onChange={onStateChange}
          options={shippingModeId === 1 ? airPoaData : lclPoaData}
        />
        <TextField
          lg={null}
          label='C\O Client'
          value={coClient}
          onChange={event => onHandleChange(event, 'coClient')}
        />
        { (coClient != null && coClient != "") ?
          <TextField
            lg={null}
            label='Covat Id'
            value={coVatId}
            onChange={event => onHandleChange(event, 'coVatId')}
          /> : null }
        <TextField
          lg={null}
          type='number'
          label='Price'
          value={price}
          onChange={event => onHandleChange(event, 'price')}
        />
        <TextField
          lg={null}
          label='VAT'
          value={priceVat}
          onChange={event => onHandleChange(event, 'priceVat')}
        />
        <TextField
          lg={null}
          label='Weight'
          value={priceWeight}
          onChange={event => onHandleChange(event, 'priceWeight')}
        />
        <TextField
          lg={null}
          label='TBD'
          value={priceTbd1}
          onChange={event => onHandleChange(event, 'priceTbd1')}
        />
        <TextField
          lg={null}
          label='TBD'
          value={priceTbd2}
          onChange={event => onHandleChange(event, 'priceTbd2')}
        />
        <TextField
          xs={12}
          sm={null}
          md={null}
          lg={null}
          label='Description'
          value={priceDesc}
          onChange={event => onHandleChange(event, 'priceDesc')}
        />
        <TextField
          xs={12}
          sm={null}
          md={null}
          lg={null}
          type="Text"
          label="Supplier's PO"
          value={suppliersPO}
          onChange={event => onHandleChange(event, 'suppliersPO')}
        />
          
        <MultilineField
          xs={12}
          sm={null}
          md={null}
          lg={null}
          label='Notes'
          value={notes}
          onChange={event => onHandleChange(event, 'notes')}
        />
      </Grid>
      {orderId != null ?
        <Grid item xs={12}>
          <Divider />
        </Grid>
      : null}
      {orderId != null ?
        <Grid item xs={12}>
          <Typography variant='h6'>Attachments</Typography>
        </Grid>
      : null}
      {orderId != null ?
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <ParcelDropzone loginUser={loginUser}
              orderStageId={orderStageId}
              orderId={orderId}
              type={orderId ? null : 'newOrder'}
              onFilesAdd={onFilesAdd}
              onCodeFileCountAdd={onCodeFileCountAdd}
              pageName={pageName}
              onHandleCloseParentModal={onHandleCloseParentModal}
            />
          </Grid>
                  <ParcelFilesRender loginUser={loginUser} files={files} type={orderId ? null : 'newOrder'} orderId={orderId} codeFileCount={codeFileCount} />
        </Grid>
      : null}
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Fragment>
  );
};

export default OrderInformation;

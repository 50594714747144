import { memo } from 'react';

import { Grid, FormControl, InputLabel} from '@mui/material';
import { DatePicker } from 'element-react';
import 'element-theme-default';
import { i18n } from 'element-react';
import locale from 'element-react/src/locale/lang/en';
import './DateTimePicker.scss'

const DateTimePicker = ({ value, label, onChange, required, xs, sm, md, disabled, boldLabel, isShowTime, format }) => {
  i18n.use(locale);
  return (
    <Grid item xs={xs} sm={sm} md={md}>
      <FormControl fullWidth disabled={disabled} required={required}>
        <InputLabel id={label} sx={{ background: 'white', maxWidth: 'calc(100% - 40px)', padding: '0px 5px', display: (value ? 'block' : 'none') }} shrink={value ? true : false}>
          {boldLabel ? boldLabel : label}
        </InputLabel>
        <DatePicker
          isShowTime={isShowTime}
          isDisabled={disabled}
          value={value ? new Date(value) : null}
          placeholder={required ? label+' *' : label}
          onChange={onChange}
          format={format || "dd/MM/yyyy HH:mm"} />
      </FormControl>               
    </Grid>
  );
};

DateTimePicker.defaultProps = {
  label: '',
  value: null,
  required: null,
  xs: 12,
  sm: 6,
  md: 4,
  isShowTime: true,
};

export default memo(DateTimePicker);

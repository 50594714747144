import { createSlice } from '@reduxjs/toolkit';

export const orderInfoSlice = createSlice({
  name: 'orderInfo',
  initialState: {
    formData: null,
  },
  reducers: {
    onFormDataChange: (state, { payload }) => {
      state.formData = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { onCodeFileCountAdd, onFormDataChange } = orderInfoSlice.actions;

export default orderInfoSlice.reducer;

import { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { TableCell, TableHead, TableRow, Typography, Table, TableBody, TableContainer, Paper, TablePagination, Button } from '@mui/material';
import ServiceProviderTableHeader from './ServiceProviderTableHeader';
import RowServiceProvider from './RowServiceProvider';
import { TextField, SelectField } from '../style-guide';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';

import { useSelector, useDispatch } from 'react-redux';
import { onShippersChange } from 'reducers/shippersSlicer';
import { onCustomersChange } from 'reducers/customersSlicer';
import { onConsigneesChange } from 'reducers/consigneesSlicer';
import { onTruckingCompaniesChange } from 'reducers/TruckingCompanySlicer';
import { onVendorsChange, onAllChange } from 'reducers/VendorSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { onUsersLocationChange } from 'reducers/UsersSlicer';

const ServiceProviderTable = ({ loginUser }) => {
    const dispatch = useDispatch();
    const { countryOfOriginData, serviceProviderType } = useSelector(state => state.predefinedData);
    const { all } = useSelector(state => state.vendors);
    const [pickupAddressCount, setPickupAddressCount] = useState(0);
    const [allCount, setAllCount] = useState(0);
    const [companyName, setCompanyName] = useState("");
    const [cityName, setCityName] = useState("");
    const [countryId, setCountryId] = useState(-1);
    const [countryName, setCountryName] = useState("Country");
    const [phoneNo, setPhoneNo] = useState("");
    const [contactName, setContactName] = useState("");
    const [emailName, setEmailName] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [majorFunctionId, setMajorFunctionId] = useState(-1);
    const [majorFunctionName, setMajorFunctionName] = useState("Reset");

    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    // pagination
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const onPageHandleChange = (_, newPage) => {
        setPage(newPage);
    };
    const onRowsPerPageHandleChange = event => {
        setSize(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        sendRequest(`/api/serviceProvider/count/5?loginUserType=${loginUser?.authorities && loginUser?.authorities[0]?.authority}&companyName=${companyName}&cityName=${cityName}&phoneNo=${phoneNo}&contactName=${contactName}&countryId=${countryId}&emailName=${emailName}&contactPhone=${contactPhone}&majorFunctionId=${majorFunctionId}`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => setPickupAddressCount(response),
            failedCallback,
        });
        //sendRequest(`/api/serviceProvider/count`, REQUEST_ACTIONS.GET, '', {
        sendRequest(`/api/serviceProvider/searchCount?loginUserType=${loginUser?.authorities && loginUser?.authorities[0]?.authority}&companyName=${companyName}&cityName=${cityName}&phoneNo=${phoneNo}&contactName=${contactName}&countryId=${countryId}&emailName=${emailName}&contactPhone=${contactPhone}&majorFunctionId=${majorFunctionId}`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => setAllCount(response),
            failedCallback,
        });
    }, [all, failedCallback]);

    useEffect(() => {
        // predefined shippers data get
        sendRequest(`/api/serviceProvider/1`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onShippersChange(response)),
            failedCallback,
        });

        sendRequest('/api/locations', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onUsersLocationChange(response)),
            failedCallback
        });

        // predefined consignee data get
        sendRequest(`/api/serviceProvider/2`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onConsigneesChange(response)),
            failedCallback,
        });

        sendRequest(`/api/serviceProvider/3`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onTruckingCompaniesChange(response)),
            failedCallback,
        });

        sendRequest(`/api/serviceProvider/4?size=${size}&page=${page}`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onVendorsChange(response)),
            failedCallback,
        });

        // predefined customers data get
        sendRequest(`/api/serviceProvider/13`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onCustomersChange(response)),
            failedCallback,
        });
        if (companyName || cityName || phoneNo || contactName || countryId || emailName || contactPhone || majorFunctionId) {
            sendRequest(`/api/serviceProvider/search?loginUserType=${loginUser?.authorities && loginUser?.authorities[0]?.authority}&companyName=${companyName}&cityName=${cityName}&phoneNo=${phoneNo}&contactName=${contactName}&countryId=${countryId}&emailName=${emailName}&contactPhone=${contactPhone}&majorFunctionId=${majorFunctionId}&size=${size}&page=${page}`, REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onAllChange(response)),
                failedCallback,
            });
        } else {
            sendRequest(`/api/serviceProvider?loginUserType=${loginUser?.authorities && loginUser?.authorities[0]?.authority}&size=${size}&page=${page}`, REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onAllChange(response)),
                failedCallback,
            });
        }
    }, [page, size, dispatch, failedCallback]);

    const onSearch = () => {
        //
        sendRequest(`/api/serviceProvider/search?loginUserType=${loginUser?.authorities && loginUser?.authorities[0]?.authority}&companyName=${companyName}&cityName=${cityName}&phoneNo=${phoneNo}&contactName=${contactName}&countryId=${countryId}&emailName=${emailName}&contactPhone=${contactPhone}&majorFunctionId=${majorFunctionId}&size=${size}&page=${page}`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onAllChange(response)),
            failedCallback,
        });

        //sendRequest(`/api/serviceProvider/searchc?companyName=${companyName}&size=${size}&page=${page}`, REQUEST_ACTIONS.GET, '', {
        //  successCallback: response => dispatch(onAllChange(response)),
        //  failedCallback,
        //});
    }

    const setCountry = (id) => {
        if (id == null) { setCountryName(""); setCountryId(-1); return; }
        var len = countryOfOriginData.length
        for (var i = 0; i < len; i++) {
            if (countryOfOriginData[i].id == id) { setCountryName(countryOfOriginData[i].name); setCountryId(countryOfOriginData[i].id); }
        }
    }

    const setFunction = (id) => {
        if (id == null) { setMajorFunctionName(""); setMajorFunctionId(-1); return; }
        var len = serviceProviderType.length
        for (var i = 0; i < len; i++) {
            if (serviceProviderType[i].id == id) { setMajorFunctionName(serviceProviderType[i].name); setMajorFunctionId(serviceProviderType[i].id); }
        }
    }

    return (
        <Paper sx={{ width: '98vw', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: '75vh' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    lg={null}
                                    md={null}
                                    sm={null}
                                    value={companyName}
                                    onChange={event => setCompanyName(event.target.value)}
                                    label='Company Name'
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    lg={null}
                                    md={null}
                                    sm={null}
                                    value={cityName}
                                    onChange={event => setCityName(event.target.value)}
                                    label='City'
                                />
                            </TableCell>
                            <TableCell>
                                <SelectField
                                    inputLabelTop={0}
                                    lg={null}
                                    md={null}
                                    sm={null}
                                    label={countryName}
                                    value={countryName}
                                    onChange={event => setCountry(event.target.value)}
                                    options={[{ id: null, name: 'Reset' }, ...countryOfOriginData]}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    lg={null}
                                    md={null}
                                    sm={null}
                                    value={phoneNo}
                                    onChange={event => setPhoneNo(event.target.value)}
                                    label='Phone'
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    lg={null}
                                    md={null}
                                    sm={null}
                                    value={contactName}
                                    onChange={event => setContactName(event.target.value)}
                                    label='Contact Name'
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    lg={null}
                                    md={null}
                                    sm={null}
                                    value={emailName}
                                    onChange={event => setEmailName(event.target.value)}
                                    label='Contact Email'
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    lg={null}
                                    md={null}
                                    sm={null}
                                    value={contactPhone}
                                    onChange={event => setContactPhone(event.target.value)}
                                    label='Contact mobile'
                                />
                            </TableCell>
                            <TableCell>
                                <SelectField
                                    inputLabelTop={0}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    label={majorFunctionName}
                                    value={majorFunctionName}
                                    onChange={event => setFunction(event.target.value)}
                                    options={[{ id: null, name: 'Reset' },
                                    ...(loginUser?.authorities && loginUser?.authorities[0]?.authority === 'User' ? serviceProviderType.filter(spType => (spType.id === 1 || spType.id === 2 || spType.id === 6)) : serviceProviderType)]}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                readOnly
                                    lg={null}
                                    md={null}
                                    sm={null}
                                    label='VAT'
                                />
                            </TableCell>
                            <TableCell>
                                Actions
                            </TableCell>
                            <TableCell>
                                <Button onClick={onSearch} variant='contained'>
                                    {`Search`}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {all.map(shipper => (
                            <RowServiceProvider key={shipper?.id || uuidv4()} type={shipper.serviceProviderTypeId} data={shipper} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={allCount - pickupAddressCount}
                rowsPerPage={size}
                page={page}
                onPageChange={onPageHandleChange}
                onRowsPerPageChange={onRowsPerPageHandleChange}
            />
        </Paper>
    );
};
export default ServiceProviderTable;

import { useEffect, useCallback, useState } from 'react';

import { Box, Grid, Button, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import { Dialog, DateTimePicker, SelectField, TextField, MultilineField } from 'style-guide';

import { STATUS } from 'constants/global-constants';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';

import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from 'reducers/parcel-reducers/ordersSlicer';
import { onTruckingCompanyNameOptionsChange, onTruckingCompaniesChange } from 'reducers/TruckingCompanySlicer';
import {
    resetTruckingStage,
    onIdChange,
    onSageStatusInfoIdChange,
    onTruckingModeIdChange,
    onEtaChange,
    onPuDateChange,
    onRequiredTimeSlotStartChange,
    onRequiredTimeSlotStopChange,
    onRequiredTimeSlotChange,
    onTruckingCompanyIdChange,
    onTruckingNumberChange,
    onPlatNumberChange,
    onSealNumberChange,
    onTeuNumberChange,
    onApproveFlagChange,
    onBuyingRateChange,
    onAdditionalChargesChange,
    onSellingRateChange,
    onNotesChange,
    onReadyForPickUpDateChange,
    onTruckingModeDataChange,
    onTruckingStatusesChange,
} from 'reducers/parcel-stage-reducers/truckingStageSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { addParcelFilter, addSearchFilterParcel, filterServiceProvider, addStatusChatMessage, getStatusChatMessage } from 'Utils';

const TruckingDialog = ({ loginUser, order, openDialog, setOpenDialog, pageName }) => {
    const dispatch = useDispatch();
    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const onHandleChange = (event, action) => dispatch(action(event.target.value));
    const onHandleCheckboxChange = (event, action) => dispatch(action(event.target.checked));
    const { truckingCompanies } = useSelector(state => state.truckingCompanies);
    const { truckingCompanyNameOptions } = useSelector(state => state.truckingCompanies);
    const { page, size } = useSelector(state => state.parcels);
    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        selectedTabIndexName,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.parcelTableFilter);
    const {
        id,
        truckingStatuses,
        truckingModeData,
        truckingModeId,
        stageStatusInfoId,
        readyForPickUpDate,
        eta,
        //    puDate,
        requiredTimeSlot,
        requiredTimeSlotStart,
        requiredTimeSlotStop,
        truckingCompanyId,
        truckingNumber,
        platNumber,
        sealNumber,
        teuNumber,
        approveFlag,
        buyingRate,
        additionalCharges,
        sellingRate,
        notes,
    } = useSelector(state => state.parcelTruckingStage);

    const [isLoaded, setIsLoaded] = useState(false);

    const currentStage = order.parcelStage[2];

    const onHandleCloseModal = () => {
        dispatch(resetTruckingStage());
        setOpenDialog(false);
    };
    useEffect(() => {
        // predefined user abroad data get
        if (!nlUsers || nlUsers == undefined) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setNlUsers(response); },
                failedCallback,
            });
        }
    }, [nlUsers, failedCallback, setNlUsers]);

    useEffect(() => {
        // predefined user abroad data get
        if (!usUsers || usUsers == undefined) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setUSUsers(response); },
                failedCallback,
            });
        }
    }, [usUsers, failedCallback, setUSUsers]);

    useEffect(() => {
        // predefined user local data get
        if (!ilUsers || ilUsers == undefined) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setIlUsers(response); },
                failedCallback,
            });
        }
    }, [ilUsers, failedCallback, setIlUsers]);

    // predefined trucking company data get
    useEffect(() => {
        if (!truckingCompanies || truckingCompanies.length <= 0) {
            sendRequest('/api/serviceProvider/3?activeFlag=true', REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    dispatch(onTruckingCompaniesChange(response));
                    dispatch(onTruckingCompanyNameOptionsChange({ id: response.id, name: response.companyName, serviceProviderLocationId: response.serviceProviderLocationId, }));
                },
                failedCallback,
            });
        }
    }, [failedCallback, dispatch, truckingCompanies]);

    useEffect(() => {
        const truckingCompanyNameOptions = truckingCompanies.map(company => {
            return { id: company.id, name: company.companyName, serviceProviderLocationId: company.serviceProviderLocationId, };
        });

        dispatch(onTruckingCompanyNameOptionsChange(truckingCompanyNameOptions));
    }, [truckingCompanies, dispatch]);

    // predefined trucking mode data get
    useEffect(() => {
        if (!truckingModeData) {
            sendRequest('/api/truckingMode', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onTruckingModeDataChange(response)),
                failedCallback,
            });
        }
    }, [failedCallback, dispatch, truckingModeData]);

    // predefined trucking statuses data get
    useEffect(() => {
        if (!truckingStatuses) {
            sendRequest('/api/stage/3', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onTruckingStatusesChange(response)),
                failedCallback,
            });
        }
    }, [failedCallback, dispatch, truckingStatuses]);

    useEffect(() => {
        const TruckingCompanyNameOptions = truckingCompanies.map(company => {
            return { id: company.id, name: company.companyName, serviceProviderLocationId: company.serviceProviderLocationId, };
        });

        dispatch(onTruckingCompanyNameOptionsChange(TruckingCompanyNameOptions));
    }, [truckingCompanies, dispatch]);

    useEffect(() => {
        setIsLoaded(truckingCompanies && truckingStatuses && truckingModeData);
    }, [truckingCompanies, truckingStatuses, truckingModeData]);

    useEffect(() => {
        const parcelTruckingStage = currentStage.parcelTruckingStage[0];

        dispatch(onSageStatusInfoIdChange(currentStage.stageStatusInfoId));
        dispatch(onNotesChange(currentStage.notes));

        if (parcelTruckingStage?.id) {
            dispatch(onIdChange(parcelTruckingStage.id));
            dispatch(onTruckingModeIdChange(parcelTruckingStage.truckingModeId));
            dispatch(onEtaChange(parcelTruckingStage.eta));
            dispatch(onPuDateChange(parcelTruckingStage.puDate));
            dispatch(onReadyForPickUpDateChange(parcelTruckingStage.readyForPickUpDate));
            dispatch(onRequiredTimeSlotChange(parcelTruckingStage.requiredTimeSlot));
            dispatch(onRequiredTimeSlotStopChange(parcelTruckingStage.requiredTimeSlotStop));
            dispatch(onRequiredTimeSlotStartChange(parcelTruckingStage.requiredTimeSlotStart));
            dispatch(onTruckingCompanyIdChange(parcelTruckingStage.truckingCompanyId));
            dispatch(onTruckingNumberChange(parcelTruckingStage.truckingNumber));
            dispatch(onPlatNumberChange(parcelTruckingStage.platNumber));
            dispatch(onSealNumberChange(parcelTruckingStage.sealNumber));
            dispatch(onTeuNumberChange(parcelTruckingStage.teuNumber));
            dispatch(onApproveFlagChange(parcelTruckingStage.approveFlag));
            dispatch(onBuyingRateChange(parcelTruckingStage.buyingRate));
            dispatch(onAdditionalChargesChange(parcelTruckingStage.additionalCharges));
            dispatch(onSellingRateChange(parcelTruckingStage.sellingRate));
        }
    }, [dispatch]);

    const onStageHandleSave = id => {
        const parcelTruckingStage = [
            {
                truckingModeId,
                truckingCompanyId,
                truckingNumber,
                platNumber,
                sealNumber,
                teuNumber,
                approveFlag,
                buyingRate,
                additionalCharges,
                sellingRate,
                readyForPickUpDate: readyForPickUpDate || order.parcelStage[1].parcelShipperStage[0]?.pickupDate,
                eta,
                puDate: readyForPickUpDate || order.parcelStage[1].parcelShipperStage[0]?.pickupDate,
                requiredTimeSlot,
                requiredTimeSlotStart,
                requiredTimeSlotStop,
            },
        ];

        if (id) {
            parcelTruckingStage[0].id = id;
        }

        const newOrder = {
            ...order,
            parcelStage: [
                order.parcelStage[0],
                order.parcelStage[1],
                {
                    ...currentStage,
                    notes,
                    stageStatusInfoId,
                    parcelTruckingStage,
                },
                order.parcelStage[3],
                order.parcelStage[4],
            ],
        };

        if (newOrder.parcelStage[2].stageStatusInfoId !== 12) {
            if (newOrder.parcelStage[2].parcelTruckingStage[0].requiredTimeSlot === undefined || newOrder.parcelStage[2].parcelTruckingStage[0].requiredTimeSlot == null || newOrder.parcelStage[2].parcelTruckingStage[0].requiredTimeSlot === "") {
                //dispatch(snackbarToggle({ type: 'error', message: 'Required time slot date should be chosen!' }));
                // return;
            }
            else {
                newOrder.parcelStage[2].parcelTruckingStage[0].eta = newOrder.parcelStage[2].parcelTruckingStage[0].requiredTimeSlot;
            }
        }
        /*if (order?.parcelStage[2]?.stageStatusInfoId != newOrder?.parcelStage[2]?.stageStatusInfoId) {
            newOrder.chat = addStatusChatMessage(order, 'Trucking', truckingStatuses.find(status => status.id === newOrder?.parcelStage[2]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
        }*/
        sendRequest(`/api/parcel?fromStage=3&loginUserId=` + loginUser?.id + `&actionOn=TruckingDialog`, REQUEST_ACTIONS.PUT, newOrder, {
            successCallback: () => {
                sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex, selectedTabIndexName)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));

                        if (order?.parcelStage[2]?.stageStatusInfoId != newOrder?.parcelStage[2]?.stageStatusInfoId) {
                            //newOrder.chat = addStatusChatMessage(order, 'WH', 'Arrived', ilUsers, nlUsers, usUsers, loginUser);
                            var chatString = getStatusChatMessage(order, 'Trucking', truckingStatuses.find(status => status.id === newOrder?.parcelStage[2]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
                            //console.log(chatString)
                            onUpdateChat(order.id, chatString, 3)
                        } else {
                            dispatch(getOrders(response));
                            onHandleCloseModal();
                        }
                    },
                    failedCallback,
                });
               // dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));
                

            },
            failedCallback,
        });
    };
    const getTruckingOptions = () => {
        //console.log("A")
      return  filterServiceProvider(loginUser, selectedLocationIndex, truckingCompanyNameOptions)
    }
    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/parcel/updateChat/${id}?fromStage=${fromStage}`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                sendRequest(`/api/parcel?${addParcelFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilterParcel(userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {

                        dispatch(getOrders(response));
                        onHandleCloseModal();
                    },
                    failedCallback,
                });
            },
            failedCallback,
        });
    };
    return (
        <Dialog maxWidth='md' open={openDialog} onHandleCancel={onHandleCloseModal} title={'TRUCKING - ' + order?.jobNumber}>
            <Box component='form' noValidate autoComplete='off'>
                {!isLoaded ? (
                    <Grid container justifyContent='center' mt={2}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        <TextField readOnly lg={null} label='Email' value={order.shipper.email || '-'} />
                        <TextField readOnly lg={null} label='Name' value={order.shipper.name || '-'} />
                        <TextField readOnly lg={null} label='Position' value={order.shipper.position || '-'} />
                        <TextField readOnly lg={null} label='Business phone' value={order.shipper.businessPhone || '-'} />
                        <TextField readOnly lg={null} label='Mobile' value={order.shipper.mobile || '-'} />
                        <Grid container item spacing={2}>
                            <DateTimePicker
                                required
                                label='Pick-up date'
                                value={readyForPickUpDate || order.parcelStage[1].parcelShipperStage[0]?.pickupDate}
                                onChange={date => dispatch(onReadyForPickUpDateChange(date?.toISOString()))}
                            />
                            <SelectField
                                required
                                disabled={!!(currentStage.status === STATUS['2'])}
                                lg={null}
                                label='Status'
                                value={stageStatusInfoId}
                                onChange={event => onHandleChange(event, onSageStatusInfoIdChange)}
                                options={truckingStatuses}
                            />
                            <SelectField
                                required
                                label='Trucking Mode'
                                lg={null}
                                value={truckingModeId}
                                onChange={event => onHandleChange(event, onTruckingModeIdChange)}
                                options={truckingModeData}
                            />
                            <Grid container item spacing={2} justifyContent='flex-end'>
                                <DateTimePicker
                                    required
                                    label='Required time slot'
                                    value={requiredTimeSlot}
                                    onChange={date => dispatch(onRequiredTimeSlotChange(date?.toISOString()))}
                                />
                                <DateTimePicker
                                    required
                                    label='Required time slot start'
                                    value={requiredTimeSlotStart}
                                    onChange={date => dispatch(onRequiredTimeSlotStartChange(date?.toISOString()))}
                                />
                                <DateTimePicker
                                    required
                                    label='Required time slot stop'
                                    value={requiredTimeSlotStop}
                                    onChange={date => dispatch(onRequiredTimeSlotStopChange(date?.toISOString()))}
                                />
                            </Grid>
                            {/* <DateTimePicker
                required
                label='Pick-up date'
                value={puDate}
                onChange={date => dispatch(onPuDateChange(date?.toISOString()))}
              /> */}
                            {
                                stageStatusInfoId === 12 ?
                                    <DateTimePicker
                                        required
                                        label='ETA W/H'
                                        value={eta}
                                        onChange={date => dispatch(onEtaChange(date?.toISOString()))}
                                    />
                                    : null
                            }
                            <SelectField
                                required
                                lg={null}
                                label='Trucking CO'
                                options={getTruckingOptions()}
                                value={truckingCompanyId}
                                onChange={event => onHandleChange(event, onTruckingCompanyIdChange)}
                                field='truckingCompanyId'
                            />
                            <TextField
                                lg={null}
                                type='number'
                                label='Tracking Number'
                                value={truckingNumber}
                                onChange={event => onHandleChange(event, onTruckingNumberChange)}
                            />
                            <TextField
                                lg={null}
                                type='number'
                                label='Plate Number'
                                value={platNumber}
                                onChange={event => onHandleChange(event, onPlatNumberChange)}
                            />
                            {/*<TextField
                required
                lg={null}
                type='number'
                label='Buying rate'
                value={buyingRate}
                onChange={event => onHandleChange(event, onBuyingRateChange)}
              />
              <view style={{marginLeft:15}}>
                <FormControlLabel
                  control={
                    <Checkbox disabled={loginUser?.authorities[0]?.authority === 'User' && loginUser?.location?.code === 'NL'} checked={(approveFlag != null)? approveFlag : false}  onChange={event => onHandleCheckboxChange(event, onApproveFlagChange)} />
                  }
                  label='Approve'
                />
              </view>*/}
                            {false && <Grid container item spacing={2} justifyContent='space-between'>
                                <TextField
                                    lg={null}
                                    label='TEU Number'
                                    value={teuNumber}
                                    onChange={event => onHandleChange(event, onTeuNumberChange)}
                                />
                                <TextField
                                    lg={null}
                                    label='Seal number'
                                    value={sealNumber}
                                    onChange={event => onHandleChange(event, onSealNumberChange)}
                                />
                                <TextField
                                    lg={null}
                                    type='number'
                                    label='Additional Charges'
                                    value={additionalCharges}
                                    onChange={event => onHandleChange(event, onAdditionalChargesChange)}
                                />
                            </Grid>}
                            <MultilineField
                                xs={12}
                                sm={null}
                                md={null}
                                lg={null}
                                label='Note'
                                value={notes}
                                onChange={event => onHandleChange(event, onNotesChange)}
                            />
                        </Grid>
                        <Grid container item xs={12} justifyContent='flex-end'>
                            <Button
                                variant='contained'
                                sx={{ mr: 2 }}
                                onClick={id ? () => onStageHandleSave(id) : () => onStageHandleSave(null)}>
                                Save
                            </Button>
                            <Button variant='contained' color='secondary' onClick={onHandleCloseModal}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Dialog>
    );
};

export default TruckingDialog;

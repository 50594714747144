import { memo } from 'react';

import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.caption,
  padding: theme.spacing(1),
}));

const OrderRowDetail = ({ detail }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={1.5}>
        <Typography fontSize="0.9rem" noWrap variant='caption'>
          Pieces
        </Typography>
        <Item>{detail.numberOfPieces}</Item>
      </Grid>
      <Grid item xs={1.5}>
        <Typography fontSize="0.9rem" noWrap variant='caption'>
          L (cm)
        </Typography>
        <Item>{detail.length}</Item>
      </Grid>
      <Grid item xs={1.5}>
        <Typography fontSize="0.9rem" noWrap variant='caption'>
          W (cm)
        </Typography>
        <Item>{detail.width}</Item>
      </Grid>
      <Grid item xs={1.5}>
        <Typography fontSize="0.9rem" noWrap variant='caption'>
          H (cm)
        </Typography>
        <Item>{detail.height}</Item>
      </Grid>
      <Grid item xs={2}>
        <Typography fontSize="0.9rem" noWrap variant='caption'>
          Weight per Piece (kg)
        </Typography>
        <Item>{detail.weightPerPiece}</Item>
      </Grid>
      <Grid item xs={2}>
        <Typography fontSize="0.9rem" noWrap variant='caption'>
          Total Weight (kg)
        </Typography>
        <Item sx={{ bgcolor: 'grey.400' }}>{detail.totalweight || (detail.numberOfPieces * detail.weightPerPiece)}</Item>
      </Grid>
      <Grid item xs={2}>
        <Typography fontSize="0.9rem" noWrap variant='caption'>
          Total Volume (m<sup>3</sup>)
        </Typography>
        <Item sx={{ bgcolor: 'grey.400' }}>
          {detail.totalvolume || ((detail.numberOfPieces * (detail.length * detail.width * detail.height)) / 1000)}
        </Item>
      </Grid>
    </Grid>
  );
};

export default memo(OrderRowDetail);

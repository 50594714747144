import { createSlice } from '@reduxjs/toolkit';


const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export const manifestsSlice = createSlice({
  name: 'manifests',
  initialState: {
    manifests: null,
    startCreateDate: null,
    endCreateDate: null,
    manifestsSearch: "",
    manifestUsers: [],
    warehouse: null,
  },
  reducers: {
    getManifests: (state, { payload }) => {
      if(!arraysEqual(state.manifests, payload)){
        state.manifests = payload;
      }
    },
    onStartCreateDateChange: (state, { payload }) => {
      state.startCreateDate = payload;
    },
    onEndCreateDateChange: (state, { payload }) => {
      state.endCreateDate = payload;
    },
    onManifestsSearchChange: (state, { payload }) => {
      state.manifestsSearch = payload;
    },
    onManifestUsersChange: (state, { payload }) => {
      let manifestUser = state.manifestUsers.find(user => user.id === payload.id);
      if (!manifestUser) {
        state.manifestUsers.push(payload);
      }
    },
    onWarehouseChange: (state, { payload }) => {
      state.warehouse = payload;
    },
    resetManifestSlice: (state, { payload }) => {
        state.manifests = null;
        state.startCreateDate = getStartCreateDate();
        state.endCreateDate = new Date().toISOString();
        state.manifestsSearch = "";
    },
  },
});

export const getStartCreateDate = () => {
  var dateOffset = (24*60*60*1000) * 3; //3 days
  var myDate = new Date();
  myDate.setTime(myDate.getTime() - dateOffset);
  return myDate.toISOString();
}
// Action creators are generated for each case reducer function
export const { getManifests, onStartCreateDateChange, onEndCreateDateChange, onManifestsSearchChange, resetManifestSlice, onManifestUsersChange, onWarehouseChange } =
  manifestsSlice.actions;

export default manifestsSlice.reducer;

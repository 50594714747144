import { Button, Typography } from '@mui/material';
import { Tooltip } from 'style-guide';
import { useSelector } from 'react-redux';
import { STATUS } from 'constants/global-constants';

const StageButton = ({ stage, onHandleOpenDialog, stageName, loginUser }) => {
    const { disableBlink } = useSelector(state => state.predefinedData);
    const blinkerAnimation = {
        '-webkit-animation': 'error-stage-glowing 1300ms infinite',
        '-moz-animation': 'error-stage-glowing 1300ms infinite',
        '-o-animation': 'error-stage-glowing 1300ms infinite',
        'animation': 'error-stage-glowing 1300ms infinite'
    }

    if (stage?.status === STATUS['4']) {
        return (
            <Tooltip title={stage?.notes}>
                <Button className="padding_0 hover_none box_shadow_none" fontSize="0.5vw" color='secondary' variant='contained' sx={{ background: "#bdbdbdb0 !important", width: '100%', py: '3px', 'text-transform': 'none', fontSize: "0.8vw !important" }} onClick={onHandleOpenDialog}>
                    {/*<Typography fontSize="0.9rem" noWrap sx={{lineHeight: '1.2'}}>{stageName}</Typography>*/}
                    {stageName}
                </Button>
            </Tooltip>
        );
    }

    if (stage?.status === STATUS['1']) {
        return (
            <Tooltip title={stage?.notes}>
                <Button className="padding_0 hover_none box_shadow_none" fontSize="0.5vw" style={{ background: "#1976d270" }} variant='contained' sx={{ width: '100%', py: '3px', 'text-transform': 'none', fontSize: "0.8vw !important", p: "0px !important" }} onClick={onHandleOpenDialog}>
                    {/*<Typography fontSize="0.9rem" noWrap sx={{lineHeight: '1.2'}}></Typography>*/}{stageName}
                </Button>
            </Tooltip>
        );
    }

    if (stage?.status === STATUS['2']) {
        return (
            <Tooltip title={stage?.notes}>
                <Button className="padding_0 hover_none box_shadow_none" fontSize="0.5vw" color='success' variant='contained' sx={{ background: "#2e7d329c !important", width: '100%', py: '3px', 'text-transform': 'none', fontSize: "0.8vw !important", p: "0px !important" }} onClick={onHandleOpenDialog}>
                    {/*<Typography fontSize="0.9rem" noWrap sx={{lineHeight: '1.2'}}>{stageName}</Typography>*/}{stageName}
                </Button>
            </Tooltip>
        );
    }

    if (stage?.status === STATUS['3']) {
        return (
            <Tooltip title={stage?.notes}>
                <Button className="padding_0 hover_none box_shadow_none" fontSize="0.5vw" variant='contained' sx={{ background: "#d32f2f82", color: "black !important", width: '100%', py: '3px', 'text-transform': 'none', fontSize: "0.8vw !important", p: "0px !important" }} style={!(disableBlink === null ? loginUser.disableBlink : disableBlink) ? blinkerAnimation : null} onClick={onHandleOpenDialog} className="padding_0 hover_none box_shadow_none">
                    {/*<Typography fontSize="0.9rem" noWrap sx={{lineHeight: '1.2'}}>{stageName}</Typography>*/}{stageName}
                </Button>
            </Tooltip>
        );
    }
};

export default StageButton;

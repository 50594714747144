import { useState } from 'react';
import { Container, Grid, Button, FormControlLabel, Checkbox } from '@mui/material';
import PredefinedDataSettingsList from './PredefinedDataSettingsList';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import {
  onAirPolDataChange,
  onAirPoaDataChange,
  onCountryOfOriginDataChange,
  onFileCodesChange,
  onIncotermsDataChange,
  onSubModeDataChange,
  onDisableBlinkChange,
} from 'reducers/order-reducers/predefinedDataSlicer';
import { onTruckingModeDataChange } from 'reducers/stage-reducers/truckingStageSlicer';

const PredefinedDataSettings = ({ loginUser }) => {
  const dispatch = useDispatch();
  const { incotermsData, subModeData, countryOfOriginData, airPolData, airPoaData, lclPolData, lclPoaData, fileCodes, disableBlink } =
    useSelector(state => state.predefinedData);

  const { truckingModeData } = useSelector(state => state.truckingStage);

  const [poaShippingModeId, setPoaShippingModeId] = useState(1);
  const [polShippingModeId, setPolShippingModeId] = useState(1);
  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const onReloadFiles = () => {
        /*sendRequest(`/api/reloadFiles`, REQUEST_ACTIONS.POST, '', {
            successCallback: message => {
                dispatch(snackbarToggle({ type: 'success', message }));
            },
            failedCallback,
        });*/
    }
  const onDisableBlinkUpdate = (id, disableBlink) => {
    sendRequest(`/api/users/updateDisableBlinkInd/${id}?disableBlink=${disableBlink}`, REQUEST_ACTIONS.PUT, '', {
      successCallback: message => {
        dispatch(onDisableBlinkChange(disableBlink));
        dispatch(snackbarToggle({ type: 'success', message }));
      },
      failedCallback,
    });
  };

  return (
    <Container maxWidth='xl'>
      <Grid container spacing={2} sx={{pl: '14px'}}>
        <FormControlLabel
              control={
                <Checkbox checked={disableBlink === null? loginUser.disableBlink: disableBlink} onChange={event => {onDisableBlinkUpdate(loginUser.id, event.target.checked);}} />
              }
              label='Disable Blink'
            />
      </Grid>
      <Grid container spacing={2}>
        <PredefinedDataSettingsList
          listData={incotermsData}
          title='Incoterms'
          field='incoterms'
          onFieldChange={onIncotermsDataChange}
        />
        <PredefinedDataSettingsList
          listData={subModeData}
          title='Sub Mode'
          field='subMode'
          onFieldChange={onSubModeDataChange}
        />
        <PredefinedDataSettingsList
          listData={countryOfOriginData}
          title='Country Of Origin'
          field='countryOfOrigin'
          onFieldChange={onCountryOfOriginDataChange}
        />
        <PredefinedDataSettingsList
          listData={truckingModeData}
          title='Trucking Mode'
          field='truckingMode'
          onFieldChange={onTruckingModeDataChange}
        />
        <PredefinedDataSettingsList
          listData={polShippingModeId === 1 ? airPolData : lclPolData}
          title='POL'
          field='pol'
          onFieldChange={onAirPolDataChange}
          shippingModeId={polShippingModeId}
          setShippingModeId={setPolShippingModeId}
        />
        <PredefinedDataSettingsList
          listData={poaShippingModeId === 1 ? airPoaData : lclPoaData}
          title='POD'
          field='poa'
          onFieldChange={onAirPoaDataChange}
          shippingModeId={poaShippingModeId}
          setShippingModeId={setPoaShippingModeId}
        />
        <PredefinedDataSettingsList
          listData={fileCodes}
          title='File codes'
          field='codes'
          onFieldChange={onFileCodesChange}
        />
          </Grid>
          {/*{loginUser.fullName === "Admin" && <Button variant='contained' sx={{ mr: 2 }} onClick={onReloadFiles}>
              reloadFiles
          </Button>}*/}
    </Container>
  );
};

export default PredefinedDataSettings;

import { useCallback, useState, useEffect, useRef } from 'react';

import { Grid, Box, Button, Typography, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { Dialog, DateTimePicker, SelectField, Tooltip, Autocomplete } from 'style-guide';
import { Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
//import { TextField } from 'style-guide';
import {
    Table,
    TableCell,
    TableBody,
    TableContainer,
    TableRow,
    TableHead,
} from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';

import { REQUEST_ACTIONS, sendRequest, setOrderData } from 'utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const AdvSearchDialog = ({ selectedGeneral, setSelectedGeneral, onHandleChange, subModeId, subModeData, onSubModeIdChange, STAGES_OPTIONS, stageSearch, OnStageSearchChange,
    fileNumber, setFileNumber, urgentShipment, onUrgentShipmentChange, advancedSearchDefaultStyle, resetAdvancedSearchFilter, globalSearch, setGlobalSearch,
    setSearchResult, isLoading, CircularProgress, globalSearchList, globalSearchList1, selectedLocationIndex, onSelectedLocationIndexChange, onSearchChange, setSearchSpinner,
    setSearchText, dispatch, sendRequest, failedCallback, onHandleCancelAdvSearchDialog, setOrderData, loginUser, getOrders, csvExportAdv, resetOrderTableFilterSlice, origin,
    activeSearch, setActiveSearch, userILSalesId, setUserILSalesId, userILSales }) => {
    const [fName, setFName] = useState(fileNumber);
    const navigate = useNavigate();
    return (
        <Grid item container flexDirection="column" flexWrap="nowrap" style={{ minHeight: "250px", minWidth: "800px" }}>
            <Grid item container flexDirection="row" flexWrap="nowrap" style={{ margin: "10px" }}>
                <Button variant='contained' sx={{ backgroundColor: `${selectedGeneral ? "#1976d270 !important" : "#e4e4e4"}`, textTransform: "none", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "16%", fontSize: "1vw !important" }} onClick={() => {
                    setSelectedGeneral(true);
                }}>
                    General
                </Button>
                <Button variant='contained' sx={{ backgroundColor: `${!selectedGeneral ? "#1976d270 !important" : "#e4e4e4"}`, textTransform: "none", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "16%", fontSize: "1vw !important" }} onClick={() => {
                    setSelectedGeneral(false);
                }}>
                    Filter
                </Button>
            </Grid>
            {!selectedGeneral && <Grid>
                <Grid item container flexDirection="row" flexWrap="nowrap">
                    <Grid sx={{ padding: "11px", pl: 0, pr: 0, width: "16%", margin: "2px" }}>
                        <SelectField
                            sm={null}
                            md={null}
                            lg={null}
                            label={<Typography fontSize="0.8vw" fontWeight='bold'>Mode</Typography>}
                            value={subModeId}
                            onChange={event => onHandleChange(event, onSubModeIdChange)}
                            options={[{ id: null, name: 'Reset' }, ...subModeData]}
                        />
                    </Grid>
                    <Grid sx={{ padding: "11px", pl: 0, pr: 0, width: "16%", margin: "2px" }} >
                        <SelectField
                            sm={null}
                            md={null}
                            lg={null}
                            label={<Typography fontSize="0.8vw" fontWeight='bold'>Stage</Typography>}
                            options={STAGES_OPTIONS}
                            value={stageSearch}
                            onChange={event => onHandleChange(event, OnStageSearchChange)}
                        />
                    </Grid>
                    <Grid sx={{ padding: "11px", pl: 0, pr: 0, width: "16%", margin: "2px" }} >
                        <TextField
                            lg={12}
                            type="Text"
                            label='File Number'
                            value={fName}
                            onChange={event => setFName(event.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    dispatch(setFileNumber(e.target.value));
                                }
                            }}
                        />
                    </Grid>
                    <Grid sx={{ padding: "11px", pl: 0, pr: 0, width: "20%", margin: "2px" }} >
                        <SelectField
                            sm={null}
                            md={null}
                            lg={null}
                            label={<Typography fontSize="0.8vw" fontWeight='bold'>Urgent Shipment</Typography>}
                            options={[{ id: null, name: 'Reset' }, { id: 1, name: 'Urgent' }, { id: 2, name: 'Not Urgent' }]}
                            value={urgentShipment}
                            onChange={event => onHandleChange(event, onUrgentShipmentChange)}
                        />
                    </Grid>
                    <Grid item container sx={{ padding: "11px", pl: 0, pr: 0, width: "16%", margin: "2px" }} alignItems='center' className="headerDate" >
                        <Tooltip title='Reset Search'>
                            <SearchOffIcon style={advancedSearchDefaultStyle} fontSize='medium' onClick={() => dispatch(resetAdvancedSearchFilter(null))} />
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>}
            {selectedGeneral && <Grid item container flexDirection="column" flexWrap="nowrap">
                <Grid item container style={{ paddingBottom: "10px", borderBottom: "1px solid grey" }} alignItems="center" flexWrap="nowrap">
                    <Grid item container lg={6}>
                        <Grid item lg={12}>
                            <TextField
                                lg={12}
                                md={12}
                                sm={12}
                                type="Text"
                                fullWidth
                                autoFocus
                                label='Search (Order, File Number, Consignee, Shipper, Client Ref, PO)'
                                importRef={(input) => input && input.focus()} 
                                value={globalSearch}
                                onChange={event => setGlobalSearch(event.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setGlobalSearch(e.target.value.trim());
                                        setSearchResult();
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container lg={6} justifyContent="flex-start" alignItems="center">
                        <view style={{ margin: "15px", maxWidth: "250px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={activeSearch} onChange={event => setActiveSearch(!activeSearch)} />
                                }
                                label='Active Orders'
                            />
                        </view>
                        <Autocomplete
                            field='userILSalesId'
                            label='Sales'
                            lg={null}
                            value={userILSalesId}
                            onChange={(f, v) => {
                                setUserILSalesId(v)
                            }}
                            options={userILSales}
                        />
                        {/*<view style={{ margin: "15px", maxWidth: "250px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={fileNameSearch} onChange={event => setFileNameSearch(!fileNameSearch)} />
                                }
                                label='File Number'
                            />
                        </view> */}
                        <Button color='secondary' onClick={setSearchResult} variant='contained' sx={{ width: "30px", minWidth: "auto", marginLeft: "5px", marginRight: "5px", padding: "0", backgroundColor: "#1976d270 !important", height: "2vw" }}>
                            <ManageSearchIcon />
                        </Button>
                        <Button color='secondary' onClick={csvExportAdv} variant='contained' sx={{ width: "30px", minWidth: "auto", marginRight: "5px", padding: "0", backgroundColor: "#1976d270 !important", height: "2vw" }}>
                            <FileDownloadIcon />
                        </Button>
                        {/*<Grid item container lg={} justifyContent="center"  alignItems="center">
                            <Button color='secondary' onClick={setSearchResult} variant='contained' style={{ marginRight: "10px", marginLeft: "10px" }} sx={{ padding: "0px 6px 0px 6px !important", backgroundColor: "#1976d270 !important", height: "2vw" }}>
                                <Typography noWrap variant='button'>
                                    <ManageSearchIcon />
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item container lg={} justifyContent="center"  alignItems="center">
                            <Button color='secondary' onClick={csvExportAdv} variant='contained' sx={{ padding: "0px 6px 0px 6px !important", backgroundColor: "#1976d270 !important", height: "2vw" }}>
                                <Typography noWrap variant='button'>
                                    <FileDownloadIcon />
                                </Typography>
                            </Button>
                        </Grid>*/}
                    </Grid>
                </Grid>
                {isLoading && <Grid item container style={{ height: "300px" }} alignItems="center" justifyContent="center">
                    <CircularProgress style={{ marginTop: "20px" }} />
                </Grid>}
                {!isLoading && <TableContainer sx={{ maxHeight: 500, minWidth: 500 }} className="orderTable">
                    <Table className="orderTable">
                        <TableHead>
                            <TableRow className="header">
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>Order</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>Creation</Typography>
                                </TableCell>
                                <TableCell colSpan={2} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>Consignee</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center' style={{ minWidth: "80px" }}>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>Client Ref</Typography>
                                </TableCell>
                                <TableCell colSpan={2} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>Shipper</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>Mode</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>ETD</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>ETA</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>Origin</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>Status</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>File Number</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>W/H</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b'>Action</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {globalSearchList?.map(data => <TableRow>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }} title={data.jobNumber}>{data.jobNumber?.substring(0, 7)}</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>{data.date}</Typography>
                                </TableCell>
                                <TableCell colSpan={2} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }} title={data.customerName}>{data.customerName?.substring(0, 11)} </Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center' style={{ minWidth: "80px" }}>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }} title={data.clientPo}>{data.clientPo?.substring(0, 11)}</Typography>
                                </TableCell>
                                <TableCell colSpan={2} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }} title={data.shipperName}>{data.shipperName?.substring(0, 11)}</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>{data.mode}</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>{data.etd}</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>{data.eta}</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }} title={data.origin}>{data.origin?.substring(0, 11)}</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>{data.status}</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }} title={data.fileNumber}>{data.fileNumber?.substring(0, 11)}</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Typography variant='b'>{data.location}</Typography>
                                </TableCell>
                                <TableCell colSpan={1} align='center'>
                                    <Button variant='text' onClick={() => {
                                        if (origin === "O") {
                                            if (data.location.includes("-H")) {
                                                if (data.location.includes("EXP"))
                                                    navigate("/orderHistory", { state: { name: "location", locInd: 3, tabInd: "OrdersHistory", search: data.jobNumber, searchList: globalSearchList, searchText: globalSearch, searchList1: globalSearchList1 } });
                                                if (data.location.includes("NL"))
                                                    navigate("/orderHistory", { state: { name: "location", locInd: 0, tabInd: "OrdersHistory", search: data.jobNumber, searchList: globalSearchList, searchText: globalSearch, searchList1: globalSearchList1 } });
                                                if (data.location.includes("US"))
                                                    navigate("/orderHistory", { state: { name: "location", locInd: 1, tabInd: "OrdersHistory", search: data.jobNumber, searchList: globalSearchList, searchText: globalSearch, searchList1: globalSearchList1 } });
                                                if (data.location.includes("ALL"))
                                                    navigate("/orderHistory", { state: { name: "location", locInd: 2, tabInd: "OrdersHistory", search: data.jobNumber, searchList: globalSearchList, searchText: globalSearch, searchList1: globalSearchList1 } });
                                            } else {
                                                var selLocation = selectedLocationIndex;
                                                /*setVarInterval(setInterval(() => {
                                                    clearInterval(varInterval);
                                                    dispatch(onSelectedLocationIndexChange(3));
                                                    dispatch(onSearchChange(data.jobNumber));
                                                }, 1000));*/
                                                dispatch(resetOrderTableFilterSlice(null))
                                                if (data.location === "EXP") {
                                                    if (selectedLocationIndex != 3) {
                                                        dispatch(onSelectedLocationIndexChange(3));
                                                        dispatch(onSearchChange(data.jobNumber));
                                                        selLocation = 3;
                                                    }
                                                }
                                                else if (data.location === "NL") {
                                                    if (selectedLocationIndex != 0) {
                                                        dispatch(onSelectedLocationIndexChange(0));
                                                        dispatch(onSearchChange(data.jobNumber));
                                                        selLocation = 0;
                                                    }
                                                }
                                                else if (data.location === "US") {
                                                    if (selectedLocationIndex != 1) {
                                                        dispatch(onSelectedLocationIndexChange(1));
                                                        dispatch(onSearchChange(data.jobNumber));
                                                        selLocation = 1;
                                                    }
                                                }
                                                else if (data.location === "ALL") {
                                                    if (selectedLocationIndex != 2) {
                                                        dispatch(onSelectedLocationIndexChange(2));
                                                        dispatch(onSearchChange(data.jobNumber));
                                                        selLocation = 2;
                                                    }
                                                }
                                                var dt = [];
                                                dt.push(data);
                                                var pageName = "Order";
                                                setSearchSpinner(true);
                                                dispatch(onSearchChange(data.jobNumber));
                                                setSearchText(data.jobNumber);
                                                var qp = "&selectedLocation=" + data.location;
                                                if (loginUser.department && loginUser.authority !== "Admin") {
                                                    qp += "&userVisibility=" + loginUser.department;
                                                } else if (loginUser.authority !== "Admin")
                                                    qp += "&userVisibility=1";
                                                sendRequest("/api/order?showUnassignedOrders=true&dep=1&page=0&size=20&search=" + data.jobNumber + qp, REQUEST_ACTIONS.GET, '', {
                                                    successCallback: response => {
                                                        setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                                                        setSearchSpinner(false);
                                                        onHandleCancelAdvSearchDialog();
                                                    },
                                                    failedCallback,
                                                });
                                            }
                                        } else {
                                            if (!data.location.includes("-H")) {
                                                if (data.location.includes("EXP"))
                                                    navigate("/home", { state: { name: "location", locInd: 3, tabInd: "OrdersHistory", search: data.jobNumber, searchList: globalSearchList, searchText: globalSearch, searchList1: globalSearchList1 } });
                                                if (data.location.includes("NL"))
                                                    navigate("/home", { state: { name: "location", locInd: 0, tabInd: "OrdersHistory", search: data.jobNumber, searchList: globalSearchList, searchText: globalSearch, searchList1: globalSearchList1 } });
                                                if (data.location.includes("US"))
                                                    navigate("/home", { state: { name: "location", locInd: 1, tabInd: "OrdersHistory", search: data.jobNumber, searchList: globalSearchList, searchText: globalSearch, searchList1: globalSearchList1 } });
                                                if (data.location.includes("ALL"))
                                                    navigate("/home", { state: { name: "location", locInd: 2, tabInd: "OrdersHistory", search: data.jobNumber, searchList: globalSearchList, searchText: globalSearch, searchList1: globalSearchList1 } });
                                            } else {
                                                var selLocation = selectedLocationIndex;
                                                /*setVarInterval(setInterval(() => {
                                                    clearInterval(varInterval);
                                                    dispatch(onSelectedLocationIndexChange(3));
                                                    dispatch(onSearchChange(data.jobNumber));
                                                }, 1000));*/
                                                dispatch(resetOrderTableFilterSlice(null))
                                                if (data.location === "EXP-H") {
                                                    if (selectedLocationIndex != 3) {
                                                        dispatch(onSelectedLocationIndexChange(3));
                                                        dispatch(onSearchChange(data.jobNumber));
                                                        selLocation = 3;
                                                    }
                                                }
                                                else if (data.location === "NL-H") {
                                                    if (selectedLocationIndex != 0) {
                                                        dispatch(onSelectedLocationIndexChange(0));
                                                        dispatch(onSearchChange(data.jobNumber));
                                                        selLocation = 0;
                                                    }
                                                }
                                                else if (data.location === "US-H") {
                                                    if (selectedLocationIndex != 1) {
                                                        dispatch(onSelectedLocationIndexChange(1));
                                                        dispatch(onSearchChange(data.jobNumber));
                                                        selLocation = 1;
                                                    }
                                                }
                                                else if (data.location === "ALL-H") {
                                                    if (selectedLocationIndex != 2) {
                                                        dispatch(onSelectedLocationIndexChange(2));
                                                        dispatch(onSearchChange(data.jobNumber));
                                                        selLocation = 2;
                                                    }
                                                }
                                                var dt = [];
                                                dt.push(data);
                                                var pageName = "Order";
                                                setSearchSpinner(true);
                                                dispatch(onSearchChange(data.jobNumber));
                                                setSearchText(data.jobNumber);
                                                var qp = "&selectedLocation=" + data.location.replace("-H", "");
                                                if (loginUser.department && loginUser.authority !== "Admin") {
                                                    qp += "&userVisibility=" + loginUser.department;
                                                } else if (loginUser.authority !== "Admin")
                                                    qp += "&userVisibility=1";
                                                sendRequest("/api/order?showHistoryOrdersOnly=true&showUnassignedOrders=true&dep=1&page=0&size=1&search=" + data.jobNumber + qp, REQUEST_ACTIONS.GET, '', {
                                                    successCallback: response => {
                                                        setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                                                        setSearchSpinner(false);
                                                        onHandleCancelAdvSearchDialog();
                                                    },
                                                    failedCallback,
                                                });
                                            }
                                        }

                                    }} style={{ color: "#1976d2", textDecoration: "underline" }}>
                                        <Typography noWrap variant='button'>
                                            Open
                                        </Typography>
                                    </Button>
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>}
                <Grid item container justifyContent="flex-end" style={{ marginTop: "10px" }}>
                    <Button variant='text' onClick={onHandleCancelAdvSearchDialog} sx={{ padding: "0px 6px 0px 6px !important", backgroundColor: "#1976d270 !important", height: "2vw" }}>
                        <Typography noWrap variant='button'>
                            Close
                        </Typography>
                    </Button>
                </Grid>
            </Grid>}
        </Grid>
    );
};

export default AdvSearchDialog;
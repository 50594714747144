import { memo } from 'react';
import { DateTimePicker, TextField } from 'style-guide';

import { TableCell, TableHead, TableRow, Typography, Grid, Box, Button } from '@mui/material';

const AuditLogsHeader = ({ jobSearch, startDate, setStartDate, endDate, setEndDate, auditlogsByJob, setJobSearch }) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell colSpan="7">

                    <Grid container item lg={12} justifyContent="flex-start">
                        <Grid container item lg={6} justifyContent="flex-start">
                            <Box component="fieldset" sx={{ display: 'flex', width: '100%', 'border-width': '1px' }}>
                                <legend>Search by job</legend>
                                <Grid container item lg={12} justifyContent="space-evenly">
                                    <TextField
                                        required
                                        lg={5.5}
                                        value={jobSearch}
                                        onChange={event => setJobSearch(event.target.value)}
                                        label='JobNumber'
                                    // readOnly={value.id ? true : false}
                                        />
                                    <Button variant='contained' sx={{ ml: 2, background:"#1976d270" }} onClick={auditlogsByJob}>
                                    Search
                                    </Button>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid container item lg={6} justifyContent="flex-start">
                            <Box component="fieldset" sx={{ display: 'flex', 'padding-top': '10px', width: '100%', 'border-width': '1px' }}>
                                <legend>Search by date range</legend>
                                <Grid container item lg={12} justifyContent="space-evenly">
                                <DateTimePicker
                                    label={'Start Date'}
                                    value={startDate}
                                    isShowTime={true}
                                    onChange={date => setStartDate(date?.toISOString())}
                                />
                                <DateTimePicker
                                    label={'End Date'}
                                    value={endDate}
                                    isShowTime={true}
                                    onChange={date => setEndDate(date?.toISOString())}
                                />
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography variant='h8'>User Name</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h8'>User FullName</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h8'>User Type</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h8'>Action On</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h8'>Job Number</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h8'>Time</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h8'>Data Changed</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export default memo(AuditLogsHeader);

import { useState, memo } from 'react';

import StageButton from '../StageButton';
import TruckingDialog from './TruckingDialog';
import {StyledParcelTableCell} from 'Utils';

const Trucking = ({ loginUser, order }) => {
  const currentStage = order.parcelStage[2];

  const [openDialog, setOpenDialog] = useState(false);
  const onHandleOpenDialog = () => setOpenDialog(true);

  return (
      <StyledParcelTableCell align='center' style={{ width: "3vw", p: 0 }}>
      <StageButton stage={currentStage} onHandleOpenDialog={onHandleOpenDialog} stageName='Trucking' loginUser={loginUser}/>
      {openDialog && <TruckingDialog loginUser={loginUser} order={order} openDialog={openDialog} setOpenDialog={setOpenDialog} />}
    </StyledParcelTableCell>
  );
};

export default memo(Trucking);
